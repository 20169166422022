/**
 * DepositOption type.
 * @typedef {Object} DepositOption
 * @property {String} depositTypeName - deposit type name.
 */

/**
 * isSameDepositOption
 * @param {DepositOption} firstOption - first deposit option
 * @param {DepositOption} secondOption - second deposit option
 * @returns {Boolean}
 */
const isSameDepositOption = (firstOption, secondOption) => {
    const { depositTypeName } = firstOption || {};
    const { depositTypeName: secondDepositTypeName } = secondOption || {};

    return depositTypeName?.toLowerCase() === secondDepositTypeName?.toLowerCase();
};

export default isSameDepositOption;
