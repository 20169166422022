import ScrollDirective from './directive';
import { setDefaults } from './scrollTo';

const install = function (Vue, options) {
    if (options) setDefaults(options);
    Vue.directive('scroll', ScrollDirective);
    Vue.prototype.$scroll = ScrollDirective;
};

if (typeof window !== 'undefined' && window.Vue) {
    window.Scroll = ScrollDirective;
    window.Scroll.setDefaults = setDefaults;
    window.Vue.use(install);
}

ScrollDirective.install = install;
export default ScrollDirective;
