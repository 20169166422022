<template>
    <div class="season-select">
        <select class="global-select" :value="season.id" @change="changeSeason($event.target.value)">
            <option v-for="(season, index) in seasons" :key="`season-${season.name}-${index}`" :value="season.id">
                {{ $t('ui.virtualSports.season') }} {{ season.name }}
            </option>
        </select>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sport } from '@agi.packages/sport';

export default {
    name: 'VirtualSeasonSelect',
    props: {
        seasonId: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        ...mapGetters({
            season: sport.getter.GET_SELECTED_VIRTUAL_SEASON,
        }),
        seasons() {
            const numberOfSeasonsToShow = 10;
            const lastSeason = this.seasonId - numberOfSeasonsToShow;
            const seasons = [];
            for (let i = this.seasonId; i > lastSeason; i--) {
                seasons.push({
                    id: i,
                    name: '#' + i,
                });
            }
            return seasons;
        },
    },
    created() {
        this.changeSeason(this.seasonId);
    },
    methods: {
        changeSeason(id) {
            const { name } =
                this.seasons.find((season) => {
                    return Number(season.id) === Number(id);
                }) || {};

            this.$store.commit(sport.mutation.SET_VIRTUAL_SEASON, { id, name });
            this.$store.dispatch(sport.action.GET_VIRTUAL_STANDINGS, id);
        },
    },
};
</script>
