<template>
    <div class="social-buttons">
        <a v-if="!isPresto" class="direct-link button button-margin-right" @click="getSocialLink(socialShareType.DIRECT, 'DirectLink')">
            <SvgIcon class="icon" icon-id="icon-direct-link-squared-black" />
        </a>
        <a class="facebook button" @click="getSocialLink(socialShareType.FB, 'Facebook')">
            <SvgIcon class="icon" icon-id="icon-facebook-squared-black" />
        </a>
        <a class="whatsapp button button-margin" @click="getSocialLink(socialShareType.WU, 'WhatsApp')">
            <SvgIcon class="icon" icon-id="icon-whatsapp-squared-black" />
        </a>
        <a v-if="!isPresto" class="twitter button button-margin" @click="getSocialLink(socialShareType.TWITTER, 'Twitter')">
            <SvgIcon class="icon" icon-id="icon-twitter-squared-black" />
        </a>
    </div>
</template>

<script>
import { deviceType } from '@agi.packages/core';
import { socialShareType } from '@agi.packages/sport';
import { copyIntoClipboard } from '@/modules/core/clipboard/copyIntoClipboard';

export default {
    name: 'SocialButtons',
    props: {
        shareLinks: {
            type: Object,
            required: true,
        },
    },
    emits: ['socialLinkClicked'],
    data() {
        return {
            isPresto: deviceType.isPresto(),
            socialShareType,
        };
    },
    methods: {
        getSocialLink(socialId, socialName) {
            const link = this.shareLinks[socialId];

            if (socialId === socialShareType.DIRECT) {
                copyIntoClipboard(link);
            } else {
                window.open(link);
            }

            this.$gtm.query({
                event: 'share_social_button_clicked',
                click_text: socialName,
            });

            this.$emit('socialLinkClicked', socialId);
        },
    },
};
</script>

<style lang="scss">
.social-buttons {
    margin: 10px 0 24px;

    .button {
        padding: 0;
        &-margin {
            margin-left: 8px;
        }

        &-margin-right {
            margin-right: 8px;
        }
    }

    .icon {
        vertical-align: middle;
    }
}
</style>
