<template>
    <div class="casino-no-favs">
        <p class="casino-no-favs-text">
            {{ $t('ui.casinoLobby.noSavedFavourites') }}<br />
            <strong>{{ $t('ui.casinoLobby.hereAreOtherGames') }}</strong>
        </p>
        <CasinoCollectionSlider :games="filteredGames" :games-count="gamesCount" :number-of-games-per-slide="oneRowLimit" title="" />
    </div>
</template>

<script>
import { getter as casinoGetter, action as casinoAction } from '@/store/modules/casino';
import { mapGetters } from 'vuex';
import CasinoCollectionSlider from '@/modules/casino/strapiComponents/components/CasinoCollectionSlider.vue';
import { getCasinoGamesLimitForGridPerRow, sortGamesByPopular } from '@/modules/casino/utils/CasinoCollection-utils';

export default {
    name: 'CasinoFavouritesEmpty',
    components: { CasinoCollectionSlider },
    props: {
        data: Object,
    },
    computed: {
        ...mapGetters({
            games: casinoGetter.GET_GAMES,
        }),
        filteredGames() {
            return [...this.games.data].sort(sortGamesByPopular).slice(0, this.gamesLimit);
        },
        gamesCount() {
            return this.games.data.length;
        },
        oneRowLimit() {
            return getCasinoGamesLimitForGridPerRow(this.$mq.size);
        },
        gamesLimit() {
            return getCasinoGamesLimitForGridPerRow(this.$mq.size) * 3; // 3 slides
        },
    },
    mounted() {
        this.$store.dispatch(casinoAction.GET_CASINO_GAMES_NEW);
    },
};
</script>
<style scoped lang="scss">
.casino-no-favs {
    margin: 0 -12px;

    .casino-no-favs-text {
        text-align: center;
        padding: 0 12px;
        @extend %small-details-text;
    }
}
</style>
