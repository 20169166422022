<template>
    <SingleEventView :data="eventData" />
</template>

<script>
import { SingleEventView } from '@agi.packages/sport/components';
export default {
    name: 'SingleEvent',
    components: { SingleEventView },
    props: ['data'],
    computed: {
        eventData() {
            return {
                ...this.data.content,
                id: this.data.content.eventId,
            };
        },
    },
};
</script>
