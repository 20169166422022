import { render, staticRenderFns } from "./EventHeaderCompact.vue?vue&type=template&id=310aad26&scoped=true"
import script from "./EventHeaderCompact.vue?vue&type=script&lang=js"
export * from "./EventHeaderCompact.vue?vue&type=script&lang=js"
import style0 from "./EventHeaderCompact.vue?vue&type=style&index=0&id=310aad26&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "310aad26",
  null
  
)

export default component.exports