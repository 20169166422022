import { deviceType, helper } from '@agi.packages/core';

const Filter = {
    install(Vue, options = {}) {
        const defaultOptions = {
            name: 'currency',
            thousandsDelimiter: ',',
            fractionDelimiter: '.',
            decimal: 2,
        };

        const config = { ...options, ...defaultOptions };

        Vue.filter(config.name, filterCurrency(config));
    },
};

function filterCurrency(config) {
    return function (value, format, decimal) {
        if (typeof value === 'string') {
            return handleCurrencyToStringValue(value);
        }
        if (typeof value !== 'number') {
            return value;
        }

        const amount = helper.numberFormat(
            value,
            Number.isInteger(decimal) ? decimal : config.decimal,
            config.fractionDelimiter,
            config.thousandsDelimiter
        );

        if (typeof format !== 'string' && format.symbol) {
            const symbol = deviceType.hasNoSymbolSupport() ? format.symbolFallback || format.symbol : format.symbol;
            return { symbol, amount };
        }
        return applyCurrencyFormat(format, amount);
    };
}

/**
 * @deprecated
 */
function applyCurrencyFormat(format, amount) {
    if (deviceType.hasNoSymbolSupport()) {
        format = replaceUnSupportedCurrencies(format);
    }
    return format ? format.replace(/%s(.*?)/g, amount) : amount;
}

/**
 * @deprecated
 */
function replaceUnSupportedCurrencies(format) {
    const ngCurrencySymbol = '\u20A6';
    const regExp = new RegExp(ngCurrencySymbol, 'g');
    return format.replace(regExp, 'N');
}

/**
 * @deprecated
 */
function handleCurrencyToStringValue(value) {
    if (deviceType.hasNoSymbolSupport()) {
        return replaceUnSupportedCurrencies(value);
    }
    return value;
}

export default Filter;
