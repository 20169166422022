<template>
    <Modal
        class="verification-modal"
        :name="modalName"
        :is-empty-header="false"
        is-highest
        fixed
        :options="{ backDrop: !isPresto }"
        v-bind="{ ...(isPresto && { width: '100%' }) }"
        @close="close"
    >
        <template #header>
            <div class="verification-modal-header">
                <div class="verification-modal-title">{{ title }}</div>
                <a class="verification-modal-close" @click="close">
                    <SvgIcon vertical-align="top" class="icon icon-size-small" icon-id="icon-close" />
                </a>
            </div>
        </template>
        <div v-if="isUssdEnabled" class="ussd-content">
            <div class="ussd-content-container">
                <div class="ussd-content-number">
                    <PhoneNumber disabled form-name="ussd-content" :value="phoneNumber" data-test-id="phoneNumber" />
                </div>
                <DynamicSlot :component="ussdModalSlot" />
            </div>
        </div>
        <Callmecomponent v-else class="call-me" :data="callMeData" :phone-number="phoneNumber" @callRequested="isCallRequested = true">
            <template #successMessage>{{ $t('ui.accountVerification.callMe.successMessage') }}</template>
        </Callmecomponent>
    </Modal>
</template>
<script>
import { action as platformAction, getter as platformGetter, strapiPageSlots } from '@agi.packages/platform';
import { modalNames } from '@/js/utils/cms/cms-modals';
import { DynamicSlot } from '@agi.packages/platform/components';
import Callmecomponent from '@/components/content/Callmecomponent';
import PhoneNumber from '@/components/PhoneNumber';
import { deviceType } from '@agi.packages/core';

export default {
    name: 'VerificationModal',
    components: { Callmecomponent, DynamicSlot, PhoneNumber },
    props: {
        phoneNumber: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
            isCallRequested: false,
            callMeData: {
                content: {
                    hideTitle: true,
                    hideReasonField: true,
                },
            },
            ussdModalSlot: strapiPageSlots.USSD_MODAL_SLOT_NAME,
            modalName: modalNames.VERIFICATION_MODAL,
        };
    },
    computed: {
        isUssdEnabled() {
            return !!this.$store.getters[platformGetter.GET_CONTENT_SLOTS][this.ussdModalSlot];
        },
        title() {
            if (this.isUssdEnabled) return this.$t('ui.accountVerification.ussd.title');
            if (this.isCallRequested) return this.$t(`ui.accountVerification.callMe.titleAfterRequest`);
            return this.$t(`ui.accountVerification.callMe.title`);
        },
    },
    created() {
        this.$store.dispatch(platformAction.GET_CONTENT, this.ussdModalSlot);
    },
    methods: {
        close() {
            this.$modal.hide(this.modalName);
        },
    },
};
</script>

<style scoped lang="scss">
.verification-modal {
    &-header {
        width: 100%;
        padding: 16px 16px 0 16px;
    }
    &-title {
        font-size: $h4-font-size;
        font-weight: $font-weight-500;
        color: $main-text;
    }
    &-close {
        display: inline-block;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 16px;
        height: 16px;
        padding: 1px;

        svg {
            fill: $betpawa-black;
        }
    }

    ::v-deep {
        .modal-wrapper {
            @include only_mini {
                margin-left: 0;
                margin-right: 0;
            }
        }
        .country-code {
            @include only_mini {
                margin-right: 0;
            }
        }
    }
}
.call-me {
    padding: 0;
    background: unset;
    margin-bottom: 0;
}
.ussd-content {
    &-number {
        position: relative;
        margin-bottom: 12px;
    }
    ::v-deep {
        .input-field {
            margin: 0;
            padding: 0;
        }
        .rich-text-components p {
            line-height: 16px;
        }
    }
}
</style>
