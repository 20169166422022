export default {
    NIN: (value) => {
        return /^([a-zA-Z])([mM]|[fF])([0-9]{5})([a-zA-Z0-9]{7})$/.test(value);
    },
    NON_NATIONAL: (value) => {
        return /^([a-zA-Z0-9/-])+$/.test(value);
    },
    /**
     * @deprecated since v3.12.0 - use `NON_NATIONAL()` instead.
     */
    documentNumber: (value) => {
        return /^([a-zA-Z0-9/-])+$/.test(value);
    },
    amount: (value) => {
        return parseInt(value) > 0;
    },
    name: (value) => {
        return /^(?!.*[-‒–—―‐\s'"’]{3,})^([\u00c0-\u01ffa-zA-Z\-‒–—―‐\s'"’]{2,40})$/.test(value);
    },
    verificationCode: (value) => {
        return /^\d{4}$/.test(value);
    },
    GH_VOTER: (value) => {
        return /^\d{10}$/.test(value);
    },
    GH_OLD_VOTER: (value) => {
        return /^\d{10}$/.test(value);
    },
    GH_DRIVER_LICENCE: (value) => {
        return value.length > 4;
    },
    GH_PASSPORT: (value) => {
        return /^[a-zA-Z]\d{7}$/.test(value);
    },
    GH_SSNIT: (value) => {
        return /^[a-zA-Z]\d{12}$/.test(value);
    },
    GH_GHANA_CARD: (value) => {
        return value.replace(/\D/g, '').length === 10;
    },
};
