<template>
    <div class="event-not-found-page">
        <h3>{{ $t('ui.event404Page.eventEnded') }}</h3>
        <p>{{ $t('ui.event404Page.mainText') }}</p>

        <router-link :to="{ name: 'Upcoming' }" class="button button-primary event-not-found-page-button">
            {{ $t('ui.event404Page.buttonText') }}
        </router-link>
    </div>
</template>

<script>
import SEOMixin from '@/components/Pages/SEO.mixin';
import PageMixin from '@/components/Pages/Page.mixin';

export default {
    name: 'EventNotFound',
    mixins: [PageMixin, SEOMixin],
};
</script>

<style scoped lang="scss">
.event-not-found-page {
    margin: 20px;

    @include maxonecolumn {
        text-align: center;
    }
}

.event-not-found-page-button {
    margin: 20px 0;
}
</style>
