<template>
    <span class="checkbox">
        <input
            :id="id"
            class="checkbox-input"
            type="checkbox"
            :checked="checked"
            :disabled="disabled"
            @click="onInput($event.target.checked)"
        />
        <label class="checkbox-label" :class="{ 'checkbox-label-disabled': disabled }" :for="id">
            <div v-if="isLoading" class="checkbox-loader">
                <Loader />
            </div>
            <span v-else class="checkbox-label-extra-space">
                <span
                    class="checkbox-input-custom"
                    :class="[v && v.$dirty && (v.$error ? 'checkbox-input-custom-error' : 'checkbox-input-custom-valid')]"
                >
                    <div class="icon-checkbox">
                        <SvgIcon icon-id="icon-checkbox" vertical-align="text-top" />
                    </div>
                </span>
            </span>
            <span class="checkbox-label-wrapper">
                <slot />
                <div v-if="v && v.$error" class="field-validation-error">
                    <renderer :input="errorMessage" />
                </div>
            </span>
        </label>
    </span>
</template>

<script>
import Loader from '@/modules/core/components/atoms/Loader.vue';

export default {
    name: 'Checkbox',
    components: { Loader },
    props: {
        id: {
            type: String,
            default: 'checkbox',
        },
        checked: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        v: {
            type: Object,
        },
        errorMessages: {
            type: Object,
            default: () => ({}),
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isChecked: false,
    }),
    computed: {
        errorMessage() {
            for (const param of Object.keys(this.v.$params)) {
                if (this.v[param] === false) {
                    return this.errorMessages[param] || this.$t('ui.common.form.error.required');
                }
            }
            return '';
        },
    },
    mounted() {
        this.isChecked = this.checked;
    },
    methods: {
        onInput(checked) {
            this.isChecked = checked;
            this.$emit('onCheck', checked);
        },
    },
};
</script>

<style lang="scss" scoped>
.checkbox {
    margin: 0 0 20px 0;
    color: $main-text;
    font-weight: normal;
    font-size: 14px;
    cursor: pointer;
    &-label {
        display: flex;
        align-items: flex-start;
        margin: 0;
        &-extra-space {
            padding: 8px;
            margin: -8px 0 -8px -8px;
        }
        &-wrapper {
            width: 100%;
        }
    }
    &-input {
        display: none;
        &-custom {
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #aaaeb0;
            border-radius: 2px;
            background: white;
            .icon-checkbox {
                width: 10px;
                height: 10px;

                svg {
                    width: 100%;
                    height: 100%;
                    fill: white;
                }
            }

            &-error {
                border-color: $error-red;
            }

            &-valid {
                border-color: $greenish;
            }
        }
        &:checked {
            & + .checkbox-label {
                & .checkbox-input-custom {
                    background: #8dc63f;
                    border-color: #8dc63f;
                }
            }

            & + .checkbox-label-disabled {
                cursor: not-allowed;
                & .checkbox-input-custom {
                    background: #d9edb2;
                    border-color: #d9edb2;
                    .icon-checkbox svg {
                        fill: #aaaeb0;
                    }
                }
            }
        }
    }
    &-loader {
        margin-right: 8px;
        width: 18px;
        height: 18px;
    }
}

.field-validation-error {
    @extend %form-validation-error;
}
</style>
