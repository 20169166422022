import { getObjectField, isNil } from '../helper';

export function getAttribute(attributeName, defVal) {
    return (entity) => {
        const field = getObjectField(entity, attributeName);

        if (!isNil(field)) {
            return field;
        }

        const field2 = getObjectField(entity, 'attributes.' + attributeName);

        if (!isNil(field2)) {
            return field2;
        }

        return getObjectField(entity, 'data.attributes.' + attributeName, defVal);
    };
}
