<template>
    <h2 :id="data.id">{{ data.text }}</h2>
</template>
<script>
export default {
    name: 'Headline',
    props: ['data'],
};
</script>
<style scoped lang="scss">
h2 {
    @extend %h2-font-700;
    margin: 1.2rem 0 0.5rem 0;
}
</style>
