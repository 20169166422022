export const DEVICE_TYPE_NAMES = {
    APP: 'app',
    ANY_PRESTO: 'presto',
    SMARTPHONE_PRESTO: 'smartphone_presto',
    SMART_PHONE: 'smart_phone',
    IOS: 'ios',
    IOS_LE_12: 'ios_le_12',
    IE: 'ie',
    WEB: 'web',
    ANDROID: 'android',
    MACOS: 'mac_os',
    OTHER_PC: 'other_pc',
};
