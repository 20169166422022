<template>
    <form class="page" @submit.prevent>
        <h2>{{ $t(`ui.accountVerification.title`) }}</h2>
        <span class="sub-header">
            <renderer :input="$t(`ui.accountVerification.description`, { fullPhoneNumber, phoneNumber, routeName: changePhoneRoute })" />
        </span>
        <div class="verification-code">
            <Password
                v-if="!otpLimitsModalVisible"
                id="input_code"
                class="verification-code-field"
                :label="$t(`ui.accountVerification.verificationCode`)"
                pin
                type="text"
                autocomplete="one-time-code"
                help-disabled
                placeholder="XXXX"
                data-test-id="verificationCode"
                :form-name="formNameId"
                :value="code"
                :v="$v.code"
                :error-messages="{ code: $t(`ui.accountVerification.invalidVerificationCode`) }"
                @value="code = $event"
                @optional:click="handleOptionalSlotClick"
            >
                <template slot="optional">
                    <span class="input-label">
                        <span v-if="showOtpTimer" class="otp-timer">
                            {{ $t(`ui.accountVerification.codeSent`) }}
                            <Timer
                                :start="otpSendTime"
                                :seconds="otpCountdownDuration"
                                :is-minutes-visible="true"
                                :hidden="isPresto"
                                @update="setOtp"
                            />
                        </span>
                        <span v-else-if="isOtpAttemptsLimited" class="bold underline" @click.prevent="onDidNotReceiveClick()">
                            {{ $t(`ui.accountVerification.didNotReceiveCode`) }}
                        </span>
                        <span v-else class="bold underline" data-test-id="sendNewCodeButton" @click.prevent="sendNewCode()">
                            {{ $t(`ui.accountVerification.resendOtp`) }}
                        </span>
                    </span>
                </template>
            </Password>
            <OtpLimitsLabel v-if="otpLimits" :limits="otpLimits" @toggleModal="handleModalVisibilityChange" />
        </div>
        <Password
            v-if="isPasswordRequired && !otpLimitsModalVisible"
            name="newPassword"
            :label="$t(`ui.common.${pinOrPassword}.setYourPassword`)"
            :form-name="formNameId"
            :value="password"
            :v="$v.password"
            @value="password = $event"
        />
        <renderer v-if="!inProgress && error" class="notify error" :input="error" />

        <CaptchaWidget v-if="showCaptcha" :name="$options.name" @error="trackCloudflareFailures" />

        <button
            v-if="!otpLimitsModalVisible"
            class="button button-submit button-full"
            data-test-id="verifyAccountButton"
            :disabled="isVerifyButtonDisabled"
            @click="verifyAccount()"
        >
            {{ buttonText }}
        </button>
        <VerificationModal :phone-number="phoneNumber" />
    </form>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { minLength, numeric, required } from 'vuelidate/lib/validators';
import { auth, AUTH_ERRORS, getter as platformGetter } from '@agi.packages/platform';
import { modalNames } from '@/js/utils/cms/cms-modals';
import { VerificationModal, OtpLimitsLabel } from '@agi.packages/platform/components';

import { helper, VALIDATORS, deviceType, getter as coreGetter } from '@agi.packages/core';

import { getter } from '@/store/modules/translations';
import { user as userEndpoints } from '@/modules/platform/endpoints';
import Password from '@/components/Password.vue';
import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import Timer from '@/components/Timer';

import AuthenticationMixin from '../mixins/Authentication.mixin';

export default {
    name: 'AccountVerification',
    components: { VerificationModal, OtpLimitsLabel, Password, Timer },
    mixins: [PageMixin, SEOMixin, AuthenticationMixin],
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch(auth.action.RESET_ERROR);
        this.$store.commit(auth.mutation.SET_PHONE_NUMBER, {
            phoneNumber: this.phoneNumber,
            phonePrefix: this.phonePrefix,
        });
        next();
    },
    props: {
        smsVerification: {
            type: String,
            default: undefined,
        },
        verificationCodeSent: {
            type: Boolean,
            default: false,
        },
        isPasswordRequired: {
            type: Boolean,
            default: true,
        },
        isVerificationModalOpened: {
            type: Boolean,
            default: false,
        },
        changePhoneRoute: String,
    },
    data() {
        return {
            code: '',
            password: '',
            isCodeSent: this.verificationCodeSent,
            formNameId: 'account-verification-form',
            isPresto: deviceType.isPresto(),
            modalName: modalNames.VERIFICATION_MODAL,
            otpLimitsModalVisible: false,
        };
    },
    validations() {
        const isPin = this.brandPreference.pin;
        return {
            ...(this.isPasswordRequired && {
                password: {
                    required,
                    minLength: minLength(4),
                    ...(isPin && { numeric }),
                },
            }),
            code: { code: VALIDATORS.verificationCode, ...(isPin && { numeric }) },
        };
    },
    computed: {
        ...mapState({
            authError(state) {
                const { error, errorCode } = state.platform.auth;
                return error && AUTH_ERRORS.includes(errorCode) ? this.$t(`ui.accountVerification.errors.otpMismatchError`) : error;
            },
            storedPhoneNumber: (state) => state.platform.auth.phoneNumber,
            phonePrefix: (state) => state.platform.settings?.brandIdentity?.phoneCountryCode,
            showOtpTimer() {
                return (!this.isPresto && this.otpSendTime) || this.isCodeSending;
            },
        }),
        ...mapGetters({
            otpSendTime: auth.getter.GET_OTP_TIMER,
            pinOrPassword: getter.PIN_OR_PASSWORD,
            token: auth.getter.SECURED_TOKEN,
            country: platformGetter.GET_COUNTRY,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            captchaError: auth.getter.GET_CAPTCHA_ERROR,
            isOtpAttemptsLimited: auth.getter.IS_OTP_ATTEMPTS_LIMITED,
            otpCountdownDuration: auth.getter.GET_OTP_COUNTDOWN_SECONDS,
            otpLimits: auth.getter.GET_OTP_LIMITS,
        }),
        isCodeSending() {
            return this.$store.getters[coreGetter.IS_LOADING](auth.action.RESET_PASSWORD);
        },
        inProgress() {
            return this.$store.getters[coreGetter.ARE_LOADING]([
                auth.action.LOGIN,
                auth.action.VERIFY_ACCOUNT_BY_HASH,
                auth.action.RESET_PASSWORD,
                auth.action.UPDATE_PASSWORD_BY_OTP,
            ]);
        },
        error() {
            return this.captchaError || this.authError;
        },
        phoneNumber() {
            return helper.removeCountryCode(this.storedPhoneNumber || this.$route.params.phoneNumber, this.phonePrefix);
        },
        fullPhoneNumber() {
            return helper
                .formatPhoneNumber(this.phoneNumber, this.phonePrefix, this.brandPreference.phoneNumberFormat)
                .replace(this.phonePrefix, `${this.phonePrefix} `);
        },
        buttonText() {
            if (this.isPasswordRequired) return this.$t(`ui.common.${this.pinOrPassword}.savePassword`);
            return this.$t('ui.accountVerification.verifyAccount');
        },
        showCaptcha() {
            const { pathsWithCaptcha } = this.brandPreference;
            if (!pathsWithCaptcha) return false;
            return pathsWithCaptcha.includes(userEndpoints.updatePassword) || pathsWithCaptcha.includes('/' + userEndpoints.updatePassword);
        },
        isVerifyButtonDisabled() {
            const captcha = this.showCaptcha && !this.token;
            const validation = !this.isPresto && this.$v.$invalid;
            return captcha || this.inProgress || validation;
        },
    },
    created() {
        if (this.smsVerification) {
            this.$store.dispatch(auth.action.VERIFY_ACCOUNT_BY_HASH, {
                hash: this.smsVerification,
            });
        }
    },
    mounted() {
        if (this.isVerificationModalOpened && !this.otpSendTime) {
            this.$modal.show(this.modalName);
        }
    },
    methods: {
        ...mapMutations({
            setOtp: auth.mutation.SET_OTP_TIMER,
        }),
        handleModalVisibilityChange(visible) {
            if (this.isPresto) {
                this.otpLimitsModalVisible = visible;
            }
        },
        reset() {
            this.code = '';
            this.password = '';
            this.$v.code.$reset();
        },
        verifyAccount() {
            if (this.isPresto && this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            if (this.isPasswordRequired) {
                this.$store
                    .dispatch(auth.action.UPDATE_PASSWORD_BY_OTP, {
                        username: this.phoneNumber,
                        otpCode: this.code,
                        newPassword: this.password,
                        rememberMe: true,
                    })
                    .finally(() => this.reset());
                return;
            }
            this.$store
                .dispatch(auth.action.LOGIN, {
                    username: this.phoneNumber,
                    password: this.code,
                    isVerification: true,
                })
                .finally(() => this.reset());
        },
        sendNewCode() {
            if (this.otpSendTime && !this.isPresto) return;
            this.$store
                .dispatch(auth.action.RESET_PASSWORD, {
                    areaCode: this.country,
                    phoneNumber: this.phoneNumber,
                })
                .then(() => {
                    this.isCodeSent = true;
                })
                .catch(() => {
                    this.$gtm.query({ event: 'verification_new_pin_failed' });
                });
        },
        trackCloudflareFailures(reason) {
            this.$gtm.query({
                event: 'verification_cloudflare_failure',
                reason,
            });
        },
        onDidNotReceiveClick() {
            this.$modal.show(this.modalName);
            this.$gtm.query({ event: 'verification_did_not_receive_code_click' });
        },
        handleOptionalSlotClick() {
            // Since OM doesn't support events in nested slots, there's a separate click listener for it
            if (this.showOtpTimer) {
                return;
            }

            if (this.isOtpAttemptsLimited) {
                this.onDidNotReceiveClick();
            } else {
                this.sendNewCode();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
button:not(:last-child) {
    margin-bottom: 16px;
}
.notify {
    margin: 0 0 16px 0;
}
.otp-timer {
    font-weight: normal;
    color: $disabled-text;
}
::v-deep .input-field {
    label .optional {
        color: $main-text;
        cursor: pointer;
        font-weight: bold;
    }

    @include oldschool {
        position: relative;
        padding-bottom: 20px;

        .input-label {
            position: absolute;
            z-index: 1;
            bottom: 0;
            right: 0;
            width: 100%;
            text-align: right;
        }
    }
}
.verification-code {
    margin-bottom: 16px;
    &-field {
        margin-bottom: 6px;
    }
}
</style>
