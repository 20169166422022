import { getObjectField } from '@/modules/core/utils/helper';
import { getAttribute } from '@/modules/core/utils/strapi/attribute';

export function getProviderOptionByProviderName(providerName, depositOptions) {
    const options = getObjectField(depositOptions, 'data', []);
    return options.find((option) => {
        const operator = getAttribute('phoneOperator')(option);
        return operator && providerName && operator.toUpperCase() === providerName.toUpperCase();
    });
}
