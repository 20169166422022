import deviceType from './deviceType';
import helper from './utils/helper';
import config from './api/config';
import android from './android';
import VALIDATORS from './validators';
import ios from './ios';
import filters from './filters';
import store from './store';
import processMenu from './utils/processMenu';
import attributesForNumberInput from './utils/attributesForNumberInput';

import { isSupportedCopyIntoClipboard } from './clipboard/const';
import { copyIntoClipboard } from './clipboard/copyIntoClipboard';
import { copyIntoClipboardDelay } from './clipboard/copyIntoClipboardDelay';

export const NETWORK_ERROR = 'NETWORK_ERROR';
export { DEVICE_TYPE_NAMES } from './deviceType-const';
export { DEVICE_TYPES, deviceTypesArray, DEVICE_TYPE } from './deviceType';
export { getJurisdictionHostname } from './get-jurisdiction-hostname';
export { NATIONALITIES } from './nationalities-const';
export { meetCondition } from './async-utils';
export { ENV, ENV_LIST } from './env';
export { ENV_NAMES } from './env-const';
export { userState } from './user-state-const';
export { action, mutation, getter } from './store';
export { actionLoader } from './store/utils';
export { badgeType } from './badge-type-const';

const clipboard = {
    isSupportedCopyIntoClipboard,
    copyIntoClipboard,
    copyIntoClipboardDelay,
};

export { VALIDATORS, deviceType, helper, config, android, ios, filters, processMenu, clipboard, attributesForNumberInput };

export default store;
