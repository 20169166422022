import { render, staticRenderFns } from "./DepositTelcoFeeLabel.vue?vue&type=template&id=93f9851c&scoped=true"
import script from "./DepositTelcoFeeLabel.vue?vue&type=script&lang=js"
export * from "./DepositTelcoFeeLabel.vue?vue&type=script&lang=js"
import style0 from "./DepositTelcoFeeLabel.vue?vue&type=style&index=0&id=93f9851c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93f9851c",
  null
  
)

export default component.exports