/* eslint-disable */
// import { lazyLoad } from '@/js/plugins/util'
import { helper } from '@agi.packages/core';

export let options = {
	resourceURL: 'https://widgets.sir.sportradar.com/3ce7b7ceaafea16ca3e9692394b97eda/widgetloader',
	lazyLoadEnabled: true,
	isReady: false,
	enabled: true,
	disableScriptLoad: false,
	bootstrap: true,
	theme: true,
	id: null,
	clientId: '3ce7b7ceaafea16ca3e9692394b97eda',
	domElOrSelector: '#sr-widget',
	buffer: [],
};

export default {
	install(Vue, _options = {}) {
		options = { ...options, ..._options };

		Vue.$srwidget = Vue.prototype.$srwidget = this.api;
	},
	api: {
		update(matchId, widgetName, widgetOptions = {}, domElOrSelector = null, method = 'addWidget') {
			// updateWidget(domElOrSelector, propsopt, callbackopt, onlyServerRenderopt)
			this.add(matchId, widgetName, widgetOptions, domElOrSelector, 'updateWidget', true);
		},
		add(matchId, widgetName, widgetOptions = {}, domElOrSelector = null, method = 'addWidget', update = false, callback) {
			if (typeof SIR === 'undefined') this.bootstrap();

			const defaultOptions = {
				width: '100%',
				label: ' ',
				branding: { // GLOBAL FOR ANY WIDGET
					tabs: {
						option: 'text', // 'icon', 'iconText'
						iconPosition: 'start',
						variant: 'fullWidth', // 'fullWidth', 'standard'
						align: 'start',
					},
				},
				tabsPosition: 'top',
			};
			const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
			const mergedOptions = helper.deepMerge(defaultOptions, widgetOptions, { arrayMerge: overwriteMerge });
			const params = [
				method,
				domElOrSelector || options.domElOrSelector,
				...[...update ? [] : [widgetName]], // : 'headToHead.standalone',
				{
					matchId, // : 31561633,
					...mergedOptions,
				},
				callback,
			];
			SIR(...params);
		},
		remove(domElOrSelector) {
			if (typeof SIR !== 'undefined') SIR('removeWidget', domElOrSelector);
		},
		bootstrap() {
			const { resourceURL, theme, language = 'en' } = options;
			if (typeof document === 'undefined' || typeof window === 'undefined') {
				return;
			}

			(function(a, b, c, d, e, f, g, h, i) {
				a[e] || (
					i = a[e] = function() {
						(a[e].q = a[e].q || []).push(arguments);
					},
						i.l = 1 * new Date(),
						i.o = f,
						g = b.createElement(c),
						h = b.getElementsByTagName(c)[0],
						g.async = 1,
						g.src = d,
						g.onload = function() {
							options.isReady = true;
						},
						g.setAttribute('n', e),
						h.parentNode.insertBefore(g, h)
				);
			})(
				window,
				document,
				'script',
				resourceURL,
				'SIR',
				{ theme, language, silent: false, debug: true },
			);
		},
	},
};
