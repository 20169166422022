<template>
    <span v-if="isAuthenticated">
        {{ accountBalance }}
    </span>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { auth } from '@agi.packages/platform';

import { helper } from '@agi.packages/core';

export default {
    name: 'UserBalance',
    props: ['data'],
    computed: {
        ...mapState({
            currency: (state) => state.platform.settings.currency,
        }),
        ...mapGetters({
            balance: auth.getter.GET_BALANCE,
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
        }),
        accountBalance() {
            const balance = this.$numberFormat(this.balance);
            return this.currency.format.replace(/%s(.*?)/g, balance);
        },
    },
    methods: {
        $numberFormat: helper.numberFormat,
    },
};
</script>
