<template>
    <div class="betline-list">
        <div class="betline-list-data">
            <div v-for="(market, marketIndex) in markets" :key="market.id">
                <Betline
                    v-if="showMarket(market)"
                    :key="market.id"
                    :raw-date="event.startTime"
                    :sport-id="Number(event.category.id)"
                    :market="market"
                    :fullname="event.name"
                    :event-id="Number(event.id)"
                    :ex-id="Number(-event.id)"
                    :is-live="event.additionalInfo.live"
                    :is-virtual="event.additionalInfo.virtual"
                    :type="getEventType(event)"
                    :season="season"
                    :available-open-betslip="availableOpenBetslip"
                    :is-first-tab="isFirstTab"
                    @select:price="$emit('select:price', $event)"
                >
                    <template #default="{ chunkIndex }">
                        <BetlineCountButton
                            v-if="isShownCountButton"
                            :event="event"
                            :is-hidden="chunkIndex > 0 || marketIndex > 0"
                            :is-unavailable="isShownMarketUnavailable"
                        />
                    </template>
                </Betline>
            </div>
            <div v-if="isShownMarketUnavailable">
                <Betline market-unavailable :sport-id="Number(event.category.id)" :is-first-tab="isFirstTab">
                    <template #default="{ chunkIndex }">
                        <BetlineCountButton
                            v-if="isShownCountButton"
                            :event="event"
                            :is-hidden="chunkIndex > 0"
                            :is-unavailable="isShownMarketUnavailable"
                        />
                    </template>
                </Betline>
            </div>
        </div>
    </div>
</template>

<script>
import { deviceType } from '@agi.packages/core';
import { betslipType } from '@agi.packages/sport';

import Betline from './Betline.vue';
import BetlineCountButton from './BetlineCountButton.vue';

export default {
    name: 'BetlineList',
    components: { Betline, BetlineCountButton },
    props: {
        event: {
            type: Object,
            require: true,
        },
        showMarketCount: {
            type: Boolean,
            default: false,
        },
        availableOpenBetslip: {
            type: Boolean,
            default: false,
        },
        markets: {
            type: Array,
            default: () => [],
        },
        marketGroupToShow: {
            type: Number,
            default: null,
        },
        season: {
            type: Object,
            default: () => ({}),
        },
        isFirstTab: {
            type: Boolean,
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        isShownCountButton() {
            return !this.event.additionalInfo.virtual && this.showMarketCount;
        },
        isShownMarketUnavailable() {
            return !this.markets.length;
        },
    },
    methods: {
        getEventType(event) {
            return event.additionalInfo.virtual ? betslipType.VIRTUAL : betslipType.REGULAR; // set event type within event response BP-16141
        },
        showMarket({ marketType }) {
            return !this.marketGroupToShow || this.marketGroupToShow === Number(marketType.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.betline-list {
    padding-bottom: 3px;
    @include all_but_mini {
        display: flex;
        flex: 1;
        flex-direction: row;
        width: 100%;
    }

    @include no_flex_box_supported {
        display: table;
        width: 100%;
    }
}

.betline-list-data {
    @include all_but_mini {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    @include no_flex_box_supported {
        display: table-cell;
        vertical-align: top;
        width: 100%;
    }
}
</style>
