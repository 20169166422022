<template>
    <div class="link-hashtag">
        <a v-if="leadsToCurrentPath" :href="data.link" class="underline" @click.prevent="$scroll.scrollTo($route.hash)">
            {{ data.text }}
        </a>
        <router-link v-else :to="{ path: data.link }" class="underline">{{ data.text }}</router-link>
        {{ data.additionalText }}
    </div>
</template>
<script>
export default {
    name: 'Linkhashtag',
    props: ['data'],
    computed: {
        // workaround for https://github.com/vuejs/vue-router/issues/1668
        leadsToCurrentPath() {
            if (this.data.link.startsWith('#')) {
                return this.$route.hash === this.data.link;
            }
            return this.$route.fullPath === this.data.link;
        },
    },
};
</script>

<style scoped lang="scss">
.link-hashtag {
    @extend %body-big-font-400;
    display: inline;
}
</style>
