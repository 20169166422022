<template>
    <div ref="container" class="sticky-scroll-element">
        <slot />
    </div>
</template>

<script>
import { deviceType } from '@agi.packages/core';

export default {
    name: 'StickyScrollElement',
    props: {
        requiredTopOffset: {
            // top offset after which element becomes sticky
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
            isSticky: false,
        };
    },
    mounted() {
        if (!this.isPresto) {
            this.$scroll.setHandler(this.updateStickyState);
        }
    },
    beforeDestroy() {
        if (!this.isPresto) {
            this.$scroll.removeHandler(this.updateStickyState);
        }
    },
    methods: {
        updateStickyState() {
            const pageOffsetTop = this.$refs.container.offsetTop;
            const currentStickyState = this.isSticky;

            this.isSticky = pageOffsetTop >= this.requiredTopOffset;

            if (currentStickyState !== this.isSticky) {
                this.$emit('updateStickyState', this.isSticky);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.sticky-scroll-element {
    position: sticky;
    top: 0;
    z-index: 2;
}
</style>
