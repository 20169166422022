<template>
    <div class="user-phone">{{ data.userphone }}</div>
</template>
<script>
export default {
    name: 'Userphonenumber',
    props: ['data'],
};
</script>
<style lang="scss" scoped>
.user-phone {
    display: inline;
}
</style>
