<template>
    <div class="mybets-regulation">
        <p class="mybets-regulation-msg"><renderer :input="$t(`ui.myBets.regulatoryMsg.${betType}`)" /></p>
    </div>
</template>

<script>
export default {
    name: 'MyBetsRegulatoryMessage',
    props: {
        betType: {
            type: String,
            default: 'realBets',
            validator: (value) => ['realBets', 'virtualBets', 'jackpotTickets'].includes(value),
        },
    },
};
</script>

<style lang="scss" scoped>
.mybets-regulation {
    border-top: 1px solid #e6e7e2;
    margin-top: 8px;
    padding: 8px 0;
    &-msg {
        @extend %small-details-font-400;
        padding: 4px 11px;
        font-style: italic;
        text-align: center;
    }
}
</style>
