import { getObjectField } from '@/modules/core/utils/helper';
import { flatDataAttributes } from '@/modules/core/utils/strapi/flatDataAttributes';

export const unique = (array) => {
    return array.filter((el, index, arr) => index === arr.indexOf(el));
};

export const getLocalTypes = (actions, regex) => {
    // rename to `getLocalNames` BP-16141
    return Object.keys(actions).reduce((types, actionName) => {
        return { ...types, [actionName]: actions[actionName].split(regex || '/').pop() };
    }, {});
};

export const MAKE_BETTING_FRIENDLY = 'r00dkc4b_s1_s1h7';

export default {
    MAKE_BETTING_FRIENDLY,
    unique,
    getLocalTypes,
};

export const getModelWithLocalisedName = (model, locale, key) => {
    let transformedModel = JSON.parse(JSON.stringify(model));
    const nameList = transformedModel[key];
    if (nameList && Array.isArray(nameList) && nameList.length) {
        const localisedValue = nameList.find((v) => v.locale === locale) ?? nameList.find((v) => v.locale === 'en') ?? nameList[0];
        transformedModel[key] = localisedValue.text;
    } else {
        transformedModel[key] = undefined;
    }
    return transformedModel;
};

export const getModelsWithLocalisedName = (data, locale, key) => {
    return data.map((model) => getModelWithLocalisedName(model, locale, key));
};

export const getGameWithLocalizedRibbonName = (game, locale) => {
    if (getObjectField(game, 'ribbon.data')) {
        let ribbon = flatDataAttributes(game.ribbon.data);
        game.ribbon = getModelWithLocalisedName(ribbon, locale, 'name');
    }
    return game;
};

export const getGamesWithLocalizedRibbonName = (games, locale) => {
    return games.map((game) => getGameWithLocalizedRibbonName(game, locale));
};
