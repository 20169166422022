<template>
    <Modal
        :class="MODAL_NAME"
        :name="MODAL_NAME"
        :options="{ overlay: true }"
        is-highest
        :fixed="!isPresto"
        width="332px"
        @close="closeModal"
    >
        <template v-if="bookingError">
            <img v-if="imgSrc" class="error-image" :src="imgSrc" alt="bad request" />
            <div class="error-message">
                {{ bookingError }}
            </div>
            <button class="button button-primary" :disabled="sharingLoading" @click="loadBookingCode">
                {{ sharingLoading ? $t('ui.common.loading') : $t('ui.common.tryAgain') }}
            </button>
        </template>
        <template v-else>
            <div class="title">{{ isPresto ? $t('ui.bookingCode.bookingCode') : $t('ui.betslip.shareYourBet') }}</div>
            <template v-if="!isPresto">
                <div v-if="isCopiedLinkMessageVisible" class="notify success copied-link-message">
                    {{ $t('ui.common.copied') }}
                </div>
                <SocialButtons v-else :share-links="shareLinks" @socialLinkClicked="setCopiedLinkMessageVisible" />
            </template>
            <div class="booking-code">
                <span v-if="!isPresto" class="label">{{ $t('ui.bookingCode.bookingCode') }}:</span>
                <ClipboardCopyTextSmall
                    class="copy-text-button"
                    :text="bookingCode"
                    :gtm-query="{ event: 'bet_slip_booking_code_copy_link' }"
                />
            </div>
        </template>
    </Modal>
</template>

<script>
import SocialButtons from '@/modules/sport/components/Fragments/Betslip/SocialButtons.vue';
import ClipboardCopyTextSmall from '@/components/ClipboardCopyTextSmall.vue';
import { mapGetters, mapMutations } from 'vuex';
import { betslip } from '@/modules/sport';
import { deviceType, getter as coreGetter } from '@/modules/core';
import { socialShareType } from '@agi.packages/sport';
import { ERROR_IMAGES } from '@/components/content/content-const';
import { NOTIFICATION_DURATION } from '@/const/notification';

const MODAL_NAME = 'bet-sharing-modal';

export default {
    name: 'BetSharingModal',
    components: { ClipboardCopyTextSmall, SocialButtons },
    data() {
        return {
            isPresto: deviceType.isPresto(),
            isCopiedLinkMessageVisible: false,
            MODAL_NAME,
        };
    },
    computed: {
        ...mapGetters({
            bookingCode: betslip.getter.GET_BETSLIP_BOOKING_CODE,
            bookingError: betslip.getter.GET_BETSLIP_BOOKING_ERROR,
            shareLinks: betslip.getter.GET_SHARE_LINKS,
            isLoading: coreGetter.IS_LOADING,
        }),
        imgSrc() {
            return ERROR_IMAGES.errorBadRequest;
        },
        sharingLoading() {
            return this.isLoading(betslip.action.LOAD_SHARE_LINKS);
        },
    },
    methods: {
        ...mapMutations({
            resetCreatedBookingCodeErrors: betslip.mutation.RESET_CREATED_BOOKING_CODE_ERRORS,
        }),
        closeModal() {
            this.resetCreatedBookingCodeErrors();
            this.$modal.hide('bet-sharing-modal');
        },
        setCopiedLinkMessageVisible(socialLinkType) {
            if (socialShareType.DIRECT !== socialLinkType) return;

            this.isCopiedLinkMessageVisible = true;

            setTimeout(() => {
                this.isCopiedLinkMessageVisible = false;
            }, NOTIFICATION_DURATION);
        },
        loadBookingCode() {
            const { shareTextKey = '' } = this.$modal.params[this.MODAL_NAME] || {};
            this.$store.dispatch(betslip.action.LOAD_SHARE_LINKS, { shareText: this.$t(shareTextKey) });
        },
    },
};
</script>

<style scoped lang="scss">
.bet-sharing-modal.modal-mask.overlay {
    padding: 16px;
    z-index: 10000;

    ::v-deep .modal-wrapper {
        align-items: center;

        .modal-container {
            padding-bottom: 0;

            .modal-header {
                height: 20px;

                .modal-header-button.no-title {
                    margin: 12px 12px 0 0;
                }
            }

            .modal-body {
                text-align: center;
                padding-bottom: 20px;
                padding-top: 0;

                @include all_but_mini {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }
    .error-message {
        margin: 4px 0 12px;
    }
    .title {
        @extend %h4-font-700;
        text-align: center;
        color: $grey-text;
        margin-bottom: 8px;
    }
    .notify.copied-link-message {
        margin: 10px 0 24px;
        height: 32px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
    }
    .booking-code {
        display: flex;
        flex-direction: column;
        align-items: center;

        .label {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: $main-text;
            margin-bottom: 8px;
        }
    }
}
</style>
