<template>
    <span class="radio-button">
        <input :id="id" class="radio-button-input" type="radio" :checked="checked" />
        <label class="radio-button-label" :for="id">
            <SvgIcon class="icon-size-medium" :icon-id="iconId" vertical-align="text-top" />
        </label>
    </span>
</template>

<script>
export default {
    name: 'Radiobutton',
    props: {
        id: {
            type: String,
            default: 'radiobutton',
        },
        checked: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String,
            default: 'light',
        },
    },
    data: () => ({
        isChecked: false,
    }),
    computed: {
        iconId() {
            return this.checked ? 'icon-radio-circle-selected' : 'icon-radio-circle';
        },
    },
    mounted() {
        this.isChecked = this.checked;
    },
    methods: {
        onInput(checked) {
            this.isChecked = checked;
            this.$emit('onCheck', checked);
        },
    },
};
</script>

<style lang="scss" scoped>
.radio-button {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    &-label {
        width: 100%;
        height: 100%;
        cursor: pointer;
        margin: 0;
    }

    &-input {
        display: none;

        &-custom {
            display: block;
        }
    }
}
</style>
