import store from './store';
import endpoints from './endpoints';
import paystack from './paystack';
import updateDepositOptions from './utils/updateDepositOptions';
import isSameDepositOption from './utils/isSameDepositOption';
import isPaystackOption from './utils/isPaystackOption';

export { action, mutation, getter } from './store';
export { depositStatus, telcoIcon, paymentOptions, paymentMethodStatus, paystackOptions } from './payment-const';
export { paystack, endpoints, updateDepositOptions, isSameDepositOption, isPaystackOption };
export default store;
