<template>
    <AuthWrapper
        v-if="!isAuthenticated"
        :is-router-tab-link="data.content.isRouterTabLink"
        :tab-name="data.content.activeTab || undefined"
    />
</template>

<script>
import { mapGetters } from 'vuex';
import { auth } from '@agi.packages/platform';

import PageMixin from '@/components/Pages/Page.mixin';
import AuthenticationMixin from '@/modules/platform/components/mixins/Authentication.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import AuthWrapper from '@/modules/platform/components/AuthWrapper.vue';

export default {
    name: 'Authentication',
    components: { AuthWrapper },
    mixins: [PageMixin, AuthenticationMixin, SEOMixin],
    props: ['data'],
    computed: {
        ...mapGetters({
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
        }),
    },
};
</script>
