import configs from './config';
import betConfig from './betconfig';

export default class CookieStorage {
    setItem(key, state) {
        configs.forEach(({ path, fields }) => {
            // BP-16141 this is not fail safe, will fail without prompt if configs has incorrect path
            const data = this._getData(state, path);
            this._saveToCookie(`${key}.${path.join('.')}`, this._reduceCookie(data, fields));
        });
    }

    setString(key, value) {
        this._saveToCookie(key, value);
    }

    getString(key) {
        return this._getCookie(key);
    }

    setBets(key, data) {
        this._saveToCookie(key, this._reduceCookie(data, betConfig));
    }

    getBets(key) {
        return this._parseCookie(this._getCookie(key), betConfig);
    }

    _saveToCookie(key, value = '') {
        const data = JSON.stringify(value)
            // replace symbols that increase cookie size after encodeURIComponent
            .replace(/]/, '))')
            .replace(/\[/g, '((')
            .replace(/\s/g, '~')
            .replace(/,/g, '!')
            .replace(/'/g, '^') // preserve apostrophes
            .replace(/"/g, "'");
        document.cookie = `${key}=${this._encode(encodeURIComponent(data))};path=/`;
    }

    _getData(data, path) {
        return path.reduce((accumulator, currentPath) => accumulator[currentPath], data);
    }

    getItem(key) {
        return configs
            .map((config) => ({
                ...config,
                cookiePath: `${key}.${config.path.join('.')}`,
            }))
            .map(({ cookiePath, ...config }) => ({
                ...config,
                cookie: this._getCookie(cookiePath),
            }))
            .filter(({ cookie }) => !!cookie)
            .map(({ cookie, ...config }) => ({
                ...config,
                data: this._parseCookie(cookie, config.fields),
            }))
            .reduce((accumulator, { path, data }) => {
                this._addValue(accumulator, path, data);
                return accumulator;
            }, {});
    }

    _getCookie(name) {
        const match = document.cookie.match(new RegExp(name + '=([^;]+)'));
        if (!match) {
            return;
        }
        return match[1];
    }

    /**
     * @param value
     * @param {Array<string>} mapping is optional field. It's used for picking field from object
     * @returns {*[]|*}
     * @private
     */
    _reduceCookie(value, mapping = null) {
        if (!mapping) {
            return value;
        }
        if (!Array.isArray(value)) {
            value = [value];
        }
        return value.map((data) => {
            return mapping.map((path) => this._getData(data, path.split('.')));
        });
    }

    /**
     * @param cookieValue
     * @param {Array<string>} mapping is optional field. It's used for parsing objects
     * @returns {any}
     * @private
     */
    _parseCookie(cookieValue, mapping = null) {
        const value = JSON.parse(this._convertToJS(decodeURIComponent(this._decode(cookieValue))));
        if (!mapping) {
            return value;
        }
        return value.map((val) =>
            val.reduce((accumulator, currentValue, index) => {
                const key = mapping[index];
                this._addValue(accumulator, key.split('.'), currentValue);
                return accumulator;
            }, {})
        );
    }

    _convertToJS(value) {
        return value
            .replace(/\)\)/, ']')
            .replace(/\(\(/g, '[')
            .replace(/~/g, ' ')
            .replace(/!/g, ',')
            .replace(/'/g, '"')
            .replace(/\^/g, "'"); // preserve apostrophes
    }

    _addValue(accumulator, path, value) {
        let result = accumulator;
        const lastKey = path.length - 1;
        path.forEach((key, index) => {
            if (lastKey === index) {
                result[key] = value;
            } else {
                if (!(key in result)) {
                    result[key] = {};
                }
                result = result[key];
            }
        });
    }

    _encode(c) {
        var x = 'charCodeAt';
        var b;
        var e = {};
        var f = c.split('');
        var d = [];
        var a = f[0];
        var g = 256;
		for (b = 1; b < f.length; b++) (c = f[b]), e[a + c] != null ? (a += c) : (d.push(a.length > 1 ? e[a] : a[x](0)), (e[a + c] = g), g++, (a = c)); // eslint-disable-line
        d.push(a.length > 1 ? e[a] : a[x](0));
        for (b = 0; b < d.length; b++) d[b] = String.fromCharCode(d[b]);
        return d.join('');
    }

    _decode(b) {
        var a;
        var f;
        var o;
        var e = {};
        var d = b.split('');
        var c = (f = d[0]);
        var g = [c];
        var h = (o = 256);
		for (b = 1; b < d.length; b++) (a = d[b].charCodeAt(0)), (a = h > a ? d[b] : e[a] ? e[a] : f + c), g.push(a), (c = a.charAt(0)), (e[o] = f + c), o++, (f = a); // eslint-disable-line
        return g.join('');
    }
}
