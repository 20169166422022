export const routePath = {
    ACCOUNT_VERIFICATION: '/verification',
    BETSLIP: '/betslip',
    CASHOUT_LOCKED: '/cashout-locked',
    CASINO: '/casino',
    CASINO_GAMES: '/casino',
    CASINO_GAMES_DETAILS: '/casino/:gameId',
    GAMES: '/games',
    CASINO_GAME: '/games/:game',
    CHANGE_PASSWORD: '/change-password',
    COUNTRY: '/country',
    DEPOSIT: '/deposit',
    DEPOSIT_PRESTO: '/deposit-presto',
    EVENT: '/event/:id',
    EVENT_NOT_FOUND: '/event404',
    GENERIC_PAGE: '/*',
    GROUP: '/group',
    HOMEPAGE: '/',
    JACKPOT_PAGE: '/jackpot',
    JACKPOT_TICKET: '/jackpot-ticket',
    JOIN_NOW: '/join-now',
    LANDING: '/change-country',
    LIVE: '/live',
    LIVE_CATEGORY: '/live-(ice-hockey|[A-Za-z]+)',
    LOGIN: '/login',
    LOGOUT: '/logout',
    MATCH_DAY: '/virtual-sports/matchday',
    MESSAGES: '/messages',
    MTN_AIRTIME_BANNER: '/mtn-airtime-banner',
    MY_ACCOUNT: '/account',
    MY_BETS: '/bets',
    NOT_FOUND: '/404',
    PAWA6: '/pawa6',
    POPULAR: '/popular',
    PRESTO_SELF_EXCLUSION: '/presto-self-exclusion',
    PREVIEW: '/preview',
    RESET: '/reset',
    RESET_PASSWORD: '/forgot',
    RULES: '/rules',
    RULES_PRESTO: '/presto/rules',
    SEARCH: '/search',
    SET_PASSWORD: '/set-password',
    SHARE: '/share/:hash',
    SHARE_ALIAS: '/share-:hash',
    SIGNUP: '/signup',
    SMS_VERIFICATION: '/vf',
    SNAPSHOT_BETSLIP: '/snapshot/betslip',
    STATEMENT: '/statements',
    UPCOMING: '/upcoming',
    VERIFY_PAGE: '/verify',
    VIRTUAL_SPORTS: '/virtual-sports',
    WITHDRAW: '/withdraw',
    WITHDRAW_DISABLED: '/withdraw-disabled',
    EXTERNAL_PREFILL: '/external-prefill',
};
