import Headline from '@/components/Headline.vue';

export default {
    components: { Headline },
    mounted() {
        if (this.$route.meta?.scrollSelector) {
            setTimeout(() => {
                this.$scroll.scrollTo(this.$route.meta.scrollSelector);
                this.$route.meta.scrollSelector = undefined;
            }, 0);
        } else {
            this.$scroll.scrollTo(0, 1);
        }
    },
    updated() {
        this.$root.$once('triggerScroll', () => {
            const { hash } = this.$router.currentRoute;
            if (!hash) {
                return;
            }

            setTimeout(() => {
                this.$scroll.scrollTo(hash);
            }, 0);
        });
    },
};
