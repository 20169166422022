import * as Sentry from '@sentry/browser';
import { Vue as SentryVue } from '@sentry/integrations';
import { addExtensionMethods } from '@sentry/tracing';

const DEFAULT_CONFIG = {
    enabled: true,
    debug: false,
    ignoreUrls: [/extensions\//i, /^chrome:\/\//i, /^chrome-extensions:\/\//i],
};

function initSentry(Vue, config) {
    // `errorHandler` should be defined before Sentry.init() - see GitHub Issue: https://github.com/vuejs/vue/issues/8433
    Vue.config.errorHandler = (err, vm, info) => {
        if (config.environment !== 'production') {
            Vue.util.warn(`Error in ${info}: "${err.toString()}"`, vm);
        }
        console.error(err);
    };
    Vue.prototype.$sentry = Vue.$sentry = Sentry;
    addExtensionMethods();
    Vue.$sentry.init({
        ...config,
        integrations: [
            // BP-16534 Range error: apparently this workaround doesn't work
            // Maximum call stack size exceeded: https://github.com/getsentry/sentry-javascript/issues/2957
            // new Sentry.Integrations.TryCatch({ eventTarget: false }),

            // BP-16534 Range error: doesn't seem to help to see more data, rollback to default
            // new ExtraErrorData({
            // depth: 5 // default depth is 3
            // }),

            // BP-16534 Range error: disable DOM breadcrumbs
            new Sentry.Integrations.Breadcrumbs({ dom: false }),
            new SentryVue({
                Vue,
                attachProps: true,
                logErrors: true,
            }),
        ],
        beforeSend(event, hint) {
            const originalThrownObject = hint.originalException;
            if (hint && originalThrownObject instanceof Event) {
                Sentry.withScope((scope) => {
                    const { currentTarget, isTrusted, target = {}, type } = originalThrownObject;
                    scope.setExtras({ currentTarget, isTrusted, target, type });
                    Sentry.captureException(new Error(`${target.src || 'Unknown '} Event Error`));
                });
                return null;
            }
            return event;
        },
        tracesSampleRate: 0.01,
    });
    // BP-16534 Range error: more info to debug logs - differentiate by platform
    Vue.$sentry.setTag('platform_type', window.isBridgeEnabled ? 'android' : 'web');
}

export default function install(Vue, config = DEFAULT_CONFIG) {
    try {
        initSentry(Vue, { ...DEFAULT_CONFIG, ...config });
    } catch (error) {
        Vue.util.warn(`Error in [vue-sentry]: ${error}`);
    }
}
