import { getLocalTypes, unique } from '@/store/utils'; // to core utils BP-16141

export const action = {
    WATCHER: 'core/watcher',
    LOADER: 'core/loader',
};

export const mutation = {
    START_LOAD: 'core/startLoad',
    END_LOAD: 'core/endLoad',
};

export const getter = {
    IS_LOADING: 'core/isLoading',
    ARE_LOADING: 'core/areLoading',
};

const state = {
    loader: [],
};

const getters = {
    isLoading: (state) =>
        function (loaderName) {
            return state.loader.indexOf(loaderName) > -1;
        },
    areLoading: (state) => (loaderNames) => {
        return loaderNames.some((elem) => state.loader.includes(elem));
    },
    anyLoading: (state) => state.loader.length > 0,
};

// const _mutation = getLocalTypes(mutation)

const mutations = {
    startLoad(state, value) {
        if (!value) return;
        state.loader.push(value);
        state.loader = unique(state.loader);
    },
    endLoad(state, value) {
        if (!value) return;
        state.loader = unique(state.loader).filter((elem) => elem !== value);
    },
};

const _action = getLocalTypes(action);

const actions = {
    [_action.WATCHER](context, payload) {
        const [getter, promise, options = {}, callbackFn = () => {}] = payload;
        return new Promise((resolve) => {
            if (getter()) {
                resolve(promise({ ...getter() }));
            } else {
                const unwatch = this.watch(
                    getter,
                    (getterPayload) =>
                        resolve(
                            promise({ ...getterPayload })
                                .finally(() => unwatch())
                                .finally(() => callbackFn({ ...getterPayload }))
                        ),
                    options
                );
            }
        });
    },
    [_action.LOADER](context, payload) {
        const [getter, promise] = payload;
        return new Promise((resolve, reject) => {
            if (getter && Object.keys(getter).length) {
                resolve(getter);
            } else {
                promise(resolve, reject);
            }
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
