<template>
    <div v-if="participants.length >= 2" class="event-header-participants">
        <div class="event-participant-info event-participant-name">
            <span>{{ participants[0].name }}</span>
        </div>
        <div v-if="participants[0].score && participants[1].score" class="event-participant-info event-participant-score">
            <span>{{ participants[0].score }} : {{ participants[1].score }}</span>
        </div>
        <div v-else class="event-participant-info event-participant-score no-score">-</div>
        <div class="event-participant-info event-participant-name">
            <span>{{ participants[1].name }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventHeaderCompact',
    props: {
        participants: Array,
    },
};
</script>

<style scoped lang="scss">
.event-header-participants {
    margin-top: 0;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 6px 20px;
    color: $light-grey;
    background: $brand-secondary-lighten-1;
    overflow: hidden;

    .event-participant-info {
        @extend %small-details-font-500;
    }

    .event-participant-name {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:first-of-type {
            text-align: right;
        }
    }

    .event-participant-score {
        margin: 0 8px;
        color: $event-live-score-color;
        text-align: center;
        white-space: nowrap;

        &.no-score {
            color: $light-grey;
        }
    }
}
</style>
