import { ENV_NAMES } from './env-const';

export const ENV_LIST = {
    [ENV_NAMES.PRODUCTION]: false,
    [ENV_NAMES.TEST]: false,
    [ENV_NAMES.STAGING]: false,
    [ENV_NAMES.DEVELOPMENT]: false,
    [ENV_NAMES.ANDROID]: false,
};

const splitHost = window.location.hostname.split('.');

export const ENV = (function () {
    // development
    if (splitHost.includes('develop')) {
        ENV_LIST[ENV_NAMES.DEVELOPMENT] = true;
        return ENV_NAMES.DEVELOPMENT;
    }

    // staging
    if (['stg', 'staging'].some((env) => splitHost.includes(env))) {
        ENV_LIST[ENV_NAMES.STAGING] = true;
        return ENV_NAMES.STAGING;
    }

    // test
    if (['test', 'experience'].some((env) => splitHost.includes(env))) {
        ENV_LIST[ENV_NAMES.TEST] = true;
        return ENV_NAMES.TEST;
    }

    // android
    if (splitHost.includes('android-apk')) {
        ENV_LIST[ENV_NAMES.ANDROID] = true;
        return ENV_NAMES.ANDROID;
    }

    // production
    ENV_LIST[ENV_NAMES.PRODUCTION] = true;
    return ENV_NAMES.PRODUCTION;
})();
