<template>
    <div :class="['table', 'list-item', (actionIconEnabled || pointer) && 'action', simple && 'simple']">
        <span v-if="contentIconId" class="row-cell icon" :class="[`align-${simple ? 'top' : 'middle'}`]">
            <SvgIcon :icon-id="contentIconId" class="icon-size-large" />
        </span>
        <span class="row-cell align-middle full-width">
            <span class="table">
                <span class="align-middle text-left content" :class="[`row${(!simple || '') && '-cell'}`]">
                    <slot name="content" />
                </span>
                <span
                    v-if="!unWrapAction"
                    class="align-middle text-right action-content"
                    :class="[noWrapActionContent && 'no-wrap', `row${(!simple || '') && '-cell'}`, `text-${simple ? 'left' : 'right'}`]"
                >
                    <span>
                        <slot name="action-content" />
                    </span>
                </span>
                <slot v-else name="action-content" />
            </span>
        </span>
        <span v-if="actionIconEnabled" class="row-cell align-middle icon-action">
            <SvgIcon :icon-id="actionIconId" class="icon-arrow icon-size-small" />
        </span>
    </div>
</template>

<script>
export default {
    name: 'ListItem',
    props: {
        simple: {
            type: Boolean,
            default: false,
        },
        actionIconId: {
            type: String,
            default: 'arrow_right',
        },
        pointer: {
            type: Boolean,
            default: false,
        },
        actionIconEnabled: {
            type: Boolean,
            default: false,
        },
        actionContentEnabled: {
            type: Boolean,
            default: true,
        },
        contentIconId: {
            type: String,
            default: null,
        },
        unWrapAction: {
            type: Boolean,
            default: false,
        },
        noWrapActionContent: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isActive: false,
    }),
};
</script>
<style lang="scss" scoped>
.list-item.table {
    padding: 8px;
    margin-top: 8px;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    font-weight: bold;
    font-size: 13px;

    .full-width {
        width: 100%;
    }

    &.action {
        padding: 14px 8px;
    }

    .action-content {
        &.no-wrap {
            white-space: nowrap;
        }
    }

    .icon {
        padding-right: 8px;

        &-action {
            padding-left: 8px;
        }
    }

    .text-right {
        text-align: right;
    }

    .text-left {
        text-align: left;
    }

    .icon-arrow {
        margin-right: 0;
    }
}
</style>
