<script>
import { createNodes } from '@/js/utils/cms';

export default {
    name: 'Heading',
    functional: true,
    props: {
        data: {
            default: () => [],
        },
    },
    render(h, context) {
        const element = context.props.data;
        const content = context.props.data.content;

        return h(`h${element.attrs.level}`, { style: { textAlign: element.attrs.textAlign } }, createNodes(h, content));
    },
};
</script>
