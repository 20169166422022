const { VUE_APP_JURISDICTION_HOSTNAME, VUE_APP_SUB_DOMAIN } = process.env;

export const getJurisdictionHostname = () => {
    const hostname = window.location.hostname;

    if (VUE_APP_JURISDICTION_HOSTNAME) {
        return VUE_APP_JURISDICTION_HOSTNAME;
    } else if (VUE_APP_SUB_DOMAIN) {
        const schemaRegex = /^([a-z]{2})\.(\w+)\.\w+/;
        return hostname.replace(schemaRegex, `$1.$2.${VUE_APP_SUB_DOMAIN}`);
    }

    return hostname;
};
