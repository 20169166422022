const config = {
    key: 'tracingId',
    uuidPattern: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx',
};

export default {
    _cookie: null,
    install(Vue, options) {
        this._cookie = Vue.prototype.$cookie;
        if (this.isExist(config.key)) {
            return;
        }
        const uuid = this.generateUUID();
        this.setUUID(uuid);
    },
    isExist(cookieName) {
        return this._cookie.isKey(cookieName);
    },
    generateUUID() {
        return config.uuidPattern.replace(/[xy]/g, (current) => {
            const random = (Math.random() * 16) | 0;
            const value = current === 'x' ? random : (random & 0x3) | 0x8;
            return value.toString(16);
        });
    },
    setUUID(uuid) {
        this._cookie.set(config.key, uuid);
    },
};
