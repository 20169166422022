export const whtTax = {
    WHT_GROSS: 'WHT_GROSS',
    WHT_NET: 'WHT_NET',
    WHT_NET_TIERED: 'WHT_NET_TIERED',
};

export const stakeFormat = {
    AMOUNT: 'AMOUNT',
    STAKE: 'STAKE',
};

export const grossTax = {
    GROSS: 'GROSS',
    WHT_GROSS: 'WHT_GROSS',
};
export const WHT_TYPES = [whtTax.WHT_GROSS, whtTax.WHT_NET, whtTax.WHT_NET_TIERED];
export const GROSS_TAX_TYPES = [grossTax.GROSS, grossTax.WHT_GROSS];

export const betType = {
    REAL: 'REAL',
    VIRTUAL: 'VIRTUAL',
    JACKPOT: 'JACKPOT',
};

export const betStatus = {
    WON: 'WON',
    PENDING: 'PENDING',
    CASHOUT: 'CASHOUT',
    CANCELLED: 'CANCELLED',
    LOST: 'LOST',
};

export const bonusType = {
    BONUS: 'BONUS',
    ODDS_MULTIPLIER: 'ODDS_MULTIPLIER',
};

export const betslipRedirects = {
    LOGIN: 'Login',
    DEPOSIT: 'Deposit',
};

export const singleBetslipOddStatus = {
    WIN: 'WIN',
    LOSE: 'LOSE',
    PENDING: 'PENDING',
    CANCELLED: 'CANCELLED',
    STAKE_BACK: 'STAKE_BACK',
};

export const cancellationReasonType = {
    ABANDONED: 'ABANDONED',
    POSTPONED: 'POSTPONED',
    CANCELLED: 'CANCELLED',
    ODDS_ERROR: 'ODDS_ERROR',
};

export const cancellationReasonTranslationKey = {
    [cancellationReasonType.ABANDONED]: 'abandoned',
    [cancellationReasonType.POSTPONED]: 'postponed',
    [cancellationReasonType.CANCELLED]: 'cancelled',
    [cancellationReasonType.ODDS_ERROR]: 'oddsError',
};

export const cancellationReasonLinkId = {
    [cancellationReasonType.ABANDONED]: '#id-0-2-2',
    [cancellationReasonType.POSTPONED]: '#id-0-2-5',
    [cancellationReasonType.CANCELLED]: '#id-0-2-5',
};

export const DISABLED_BUTTON_TIMEOUT = 3000;
