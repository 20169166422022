<template>
    <div class="content" :class="{ 'is-home-page': isHomePage }">
        <div class="title">
            <Headline v-if="isHomePage" :title="title" :is-category="true" :icon="iconId" />
            <CasinoCollectionTitle
                v-else-if="title"
                :games-count="gamesCount"
                :games-limit="games.length"
                :title="title"
                :icon-id="iconId"
                @openCasinoGames="openCasinoGames"
            />
        </div>
        <div v-if="games.length > 0">
            <div ref="casino-list" class="casino-list">
                <div v-for="(game, index) in games" :key="game.id" :ref="`casino-tile-${index}`" class="casino-list-item">
                    <CasinoCollectionGameTile :game="game" />
                </div>
            </div>
            <ul class="casino-dots" role="tablist" style="">
                <li v-for="i in sliderNumberOfSlides" :key="i" :class="`pointer ${activeDot === i - 1 ? `active` : ''}`">
                    <button type="button" @click="scrollToDot(i - 1)">{{ i }}</button>
                </li>
            </ul>
        </div>
        <div v-else-if="isFavourites && games.length === 0" class="fav-empty">
            <CasinoFavouritesEmpty />
        </div>
        <CasinoShowAllGames v-if="showMoreGamesOnBottom" />
    </div>
</template>

<script>
import CasinoCollectionGameTile from '@/modules/casino/strapiComponents/components/CasinoCollectionGameTile.vue';
import CasinoCollectionTitle from '@/modules/casino/strapiComponents/components/CasinoCollectionTitle.vue';
import Headline from '@/components/Headline.vue';
import CasinoShowAllGames from '@/components/content/CasinoShowAllGames.vue';

export default {
    name: 'CasinoCollectionSlider',
    components: {
        CasinoShowAllGames,
        Headline,
        CasinoFavouritesEmpty: () => import('@/modules/casino/components/CasinoFavouritesEmpty.vue'),
        CasinoCollectionTitle,
        CasinoCollectionGameTile,
    },
    props: {
        games: Array,
        gamesCount: Number,
        title: String,
        iconId: String,
        numberOfGamesPerSlide: Number,
        isFavourites: Boolean,
        isHomePage: Boolean,
        showMoreGamesOnBottom: Boolean,
    },
    data() {
        return {
            scrollPos: 0,
        };
    },
    computed: {
        sliderNumberOfSlides() {
            return Math.ceil(this.games.length / this.numberOfGamesPerSlide);
        },
        activeDot() {
            return Math.round(this.scrollPos * this.sliderNumberOfSlides);
        },
    },
    mounted() {
        const listDOM = this.$refs['casino-list'];
        if (listDOM) {
            listDOM.addEventListener('scroll', this.onScrolled, { passive: true });
        }
    },
    beforeDestroy() {
        const listDOM = this.$refs['casino-list'];
        if (listDOM) {
            this.$refs['casino-list'].removeEventListener('scroll', this.onScrolled);
        }
    },
    methods: {
        openCasinoGames() {
            this.$emit('openCasinoGames');
        },
        scrollToDot(dotIndex) {
            let slideIdToScrollTo = dotIndex * this.numberOfGamesPerSlide;
            const tileRefId = `casino-tile-${slideIdToScrollTo}`;
            this.$nextTick(() => {
                const [tile] = this.$refs[tileRefId] ?? [];
                if (tile) {
                    tile.scrollIntoView({ block: 'nearest', inline: 'start', behavior: 'smooth' });
                }
            });
        },
        onScrolled(event) {
            const { target } = event;
            const { scrollLeft, scrollWidth } = target;
            this.scrollPos = scrollLeft / scrollWidth;
        },
    },
};
</script>

<style scoped lang="scss">
.content {
    margin-top: 16px;
    margin-bottom: 16px;

    .title {
        padding: 0 12px;
    }

    &.is-home-page {
        margin-top: 0;
        .title {
            padding: 0;
        }
    }

    .casino-list {
        padding: 12px 12px 0 12px;
        display: grid;
        grid-auto-flow: column;
        --gap: 12px;
        grid-gap: calc(var(--gap) - 4px);
        overflow-y: hidden;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scrollbar-width: none;
        user-select: none;
        --columns: 2;
        @include mq-xxs {
            --columns: 3;
        }

        @include mq-xs {
            --columns: 3;
        }

        @include mq-sm {
            --columns: 4;
        }

        @include mq-md {
            --columns: 4;
        }

        grid-auto-columns: calc((100% - (var(--columns) - 1) * var(--gap)) / var(--columns));

        .casino-list-item {
            scroll-margin: 12px;
            user-select: none;
            scroll-snap-align: center;
        }
    }

    .casino-dots {
        display: flex;
        justify-content: center;

        margin: 0;
        padding: 8px 0;

        list-style-type: none;

        li {
            margin: 0 3px;
        }

        button {
            display: block;
            width: 6px;
            height: 6px;
            padding: 0;

            border: none;
            border-radius: 100%;
            background-color: $greyish;

            text-indent: -9999px; // hides text from the dots, keeping the dots still accessible
        }

        li.active button {
            background-color: $green;
        }
    }

    .fav-empty {
        padding: 0 12px;
    }
}
</style>
