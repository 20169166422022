<template>
    <div class="call-me-component">
        <h3>{{ title }}</h3>
        <div v-if="errorMessage" class="notify error">{{ errorMessage }}</div>
        <div v-if="successMessage" class="notify success">{{ successMessage }}</div>
        <form v-else @submit.prevent="callMeRequest(phone) && trackCallMe()">
            <InputField
                name="phone"
                type="tel"
                form-name="call-me-form"
                class="phone-wrapper"
                :attrs="{ placeholder: $t('ui.common.yourMobileNumber'), 'data-test-id': 'callMePhone' }"
                :value="phone"
                @value="phone = $event"
            />
            <input type="submit" data-test-id="callMeButton" class="button button-primary" :value="buttonText" :disabled="formIsLoading" />
        </form>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { InputField } from '@agi.packages/core/components';
import { getter as coreGetter } from '@agi.packages/core';
import { action, mutation } from '@agi.packages/platform';

export default {
    name: 'CallMe',
    components: { InputField },
    props: ['data'],
    data() {
        const settings = this.$store.state.platform.settings;
        const phonePrefix = this.$store.state.platform.settings?.brandIdentity?.phoneCountryCode;
        return {
            phone: (settings.user && settings.user.phoneNumber) || phonePrefix ? '+' + phonePrefix : '',
        };
    },
    computed: {
        ...mapState({
            errorMessage: (state) => state.platform.callMeRequest.errorMessage,
            successMessage: (state) => state.platform.callMeRequest.successMessage,
            phoneNumber: (state) => state.platform.settings.user && state.platform.settings.user.phoneNumber,
        }),
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
        }),
        formIsLoading() {
            return this.isLoading(action.CALL_ME_REQUEST);
        },
        title() {
            return this.data.content.title;
        },
        buttonText() {
            return this.data.content.buttonText;
        },
    },
    watch: {
        phoneNumber(value) {
            this.phone = value;
        },
    },
    methods: {
        ...mapActions({
            callMeRequest: action.CALL_ME_REQUEST,
        }),
        ...mapMutations({
            setCallMeStatus: mutation.SET_CALL_ME_STATUS,
        }),
        trackCallMe() {
            this.$gtm.query({
                event: 'contact',
                reason: 'request a call',
            });
        },
    },
};
</script>

<style scoped lang="scss">
.call-me-component {
    margin: 20px 0;
    padding: 15px;
    background: $light-grey-bg;
    border-radius: $border-radius;

    h3 {
        @extend %h3-font-700;
        margin: 14px 0;
    }

    .phone-wrapper {
        margin-bottom: 14px;
    }
}
</style>
