import { getAttribute } from './attribute';
import { getLinkComponent } from '../helper';

export function getActualLinkFrom(entity) {
    const getLinksFrom = getAttribute('links', []);

    return getLinkComponent(getLinksFrom(entity));
}

export function getActualHref(entity) {
    return getActualLinkFrom(entity).link;
}
