<template>
    <div class="payment-adviser">
        <div v-if="articleTop" class="payment-adviser-article">
            <div class="payment-adviser-article-title">{{ articleTop.title }}</div>
            <div v-if="articleTop.content" class="payment-adviser-article-content">{{ articleTop.content }}</div>
        </div>
        <div class="payment-adviser-action-panel" @click="openInvitationUrl">
            <div class="payment-adviser-info">
                <div v-if="iconUrl" class="payment-adviser-icon">
                    <img :src="iconUrl" alt="media" />
                </div>
                <div class="payment-adviser-text">
                    <h5>{{ name }}</h5>
                    <p class="payment-adviser-help-text">{{ $t('ui.common.maxLabel') }}: {{ maxAmount }}</p>
                </div>
            </div>
            <div class="payment-adviser-action">
                <div class="payment-adviser-action-button">
                    {{ $t('ui.common.tryNow') }}
                </div>
                <div class="payment-adviser-action-icon">
                    <SvgIcon icon-id="arrow_right" vertical-align="baseline" />
                </div>
            </div>
        </div>
        <div v-if="articleBottom" class="payment-adviser-article">
            <div class="payment-adviser-article-title">{{ articleBottom.title }}</div>
            <div v-if="articleBottom.content" class="payment-adviser-article-content">{{ articleBottom.content }}</div>
        </div>
    </div>
</template>

<script>
import { getObjectField, numberFormat } from '@/modules/core/utils/helper';
import { action } from '@/modules/payment';

export default {
    name: 'PaymentAdviser',
    props: ['data'],
    computed: {
        articleTop() {
            return getObjectField(this.data, 'content.articleTop');
        },
        articleBottom() {
            return getObjectField(this.data, 'content.articleBottom');
        },
        name() {
            return getObjectField(this.data, 'content.name');
        },
        iconUrl() {
            return getObjectField(this.data, 'content.icon.data.attributes.url', '');
        },
        maxAmount() {
            return numberFormat(Number(getObjectField(this.data, 'content.maxAmount', 0)), 0);
        },
        provider() {
            return getObjectField(this.data, 'content.provider', '');
        },
    },
    methods: {
        openInvitationUrl() {
            const urlBase = window.location.origin + window.location.pathname;

            this.$store
                .dispatch(action.FETCH_PAYMENT_INVITATION_LINK, {
                    successfulUrl: urlBase + '?payment-adviser-status=success',
                    failedUrl: urlBase + '?payment-adviser-status=failed',
                    provider: this.provider,
                })
                .then(({ invitationUrl }) => {
                    window.location.href = invitationUrl;
                });
        },
    },
};
</script>

<style scoped lang="scss">
.payment-adviser {
    $self: &;

    display: flex;
    flex-direction: column;
    gap: 16px;
    border: 1px solid $royal-purple;
    background: $soft-lilac;
    padding: 16px;
    margin: 16px 0;

    &-article {
        &-title {
            @extend %body-big-font-700;
            margin-bottom: 8px;
        }

        &-content {
            @extend %body-normal-font-400;
        }
    }

    &-action-panel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid rgba($royal-purple, 0.15);
        padding: 8px;
        cursor: pointer;

        #{$self}-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;

            #{$self}-icon {
                width: 40px;
                height: 40px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            #{$self}-text {
                display: flex;
                flex-direction: column;

                h5 {
                    @extend %body-normal-font-700;
                }

                p {
                    color: $dark-grey-3;
                    @extend %small-details-font-400;
                }
            }
        }

        #{$self}-action {
            display: flex;
            align-items: center;
            gap: 8px;

            #{$self}-action-button {
                @extend %small-details-font-500;
                text-wrap: nowrap;
                color: $light-grey;
                background-color: $royal-purple;
                padding: 3px 6px;
                border-radius: 2px;
            }

            #{$self}-action-icon {
                width: 16px;
                height: 16px;

                svg {
                    width: 100%;
                    height: 100%;
                    fill: $royal-purple;
                }
            }
        }
    }
}
</style>
