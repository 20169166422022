<template>
    <div v-if="!isPresto" class="sr-widget" style="width: 100%">
        <div :id="widgetId" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getter, auth } from '@agi.packages/platform';
import { deviceType, helper } from '@agi.packages/core';
import { sport } from '@agi.packages/sport';

const themeName = {
    LIGHT: 'light',
    DARK: 'dark',
};
export default {
    name: 'SRWidget',
    props: {
        matchId: {
            type: [Number, null],
            required: false,
        },
        name: {
            type: String,
            required: true,
        },
        theme: {
            type: String,
            default: themeName.DARK,
        },
        options: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        onInit: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data: () => ({
        css: '',
        themes: {
            dark: `/*
Using formula solid
$primary: #8DC63F;
$home: #9CE800;
$away: #FF7A00;
$cl: #FFFFFF;
$base: #2C3033;
*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=latin,latin-ext");
.sr-bb {
  font-family: "Roboto", "Noto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: left;
  background: #2C3033;
}

.sr-bb.sr-rtl {
  text-align: right;
}

.sr-bb .sr-bb {
  background: none;
}

.sr-bb .srt-base-1 {
  background-color: transparent;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-win {
  background-color: transparent;
  color: #9CE800;
  border-color: #9CE800;
}

.sr-bb .srt-base-1-draw {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.4);
  border-color: rgba(255, 255, 255, 0.4);
}

.sr-bb .srt-base-1-lose {
  background-color: transparent;
  color: #FF7A00;
  border-color: #FF7A00;
}

.sr-bb .srt-base-1-is-active {
  background-color: rgba(141, 198, 63, 0.12);
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-2 {
  background-color: #3b3f42;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-hoverable:hover {
  background-color: rgba(141, 198, 63, 0.12);
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-primary {
  background-color: transparent;
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-home-1 {
  background-color: transparent;
  color: #9CE800;
  border-color: #9CE800;
}

.sr-bb .srt-base-1-away-1 {
  background-color: transparent;
  color: #FF7A00;
  border-color: #FF7A00;
}

.sr-bb .srt-base-1-home-2 {
  background-color: transparent;
  color: #9CE800;
  border-color: #bdde90;
}

.sr-bb .srt-base-1-away-2 {
  background-color: transparent;
  color: #FF7A00;
  border-color: #bdde90;
}

.sr-bb .srt-base-1-home-3 {
  background-color: transparent;
  color: #9CE800;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-away-3 {
  background-color: transparent;
  color: #FF7A00;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-home-4 {
  background-color: transparent;
  color: #78af0e;
  border-color: #78af0e;
}

.sr-bb .srt-base-1-away-4 {
  background-color: transparent;
  color: #be620e;
  border-color: #be620e;
}

.sr-bb .srt-base-1-home-5 {
  background-color: transparent;
  color: #bff059;
  border-color: #bff059;
}

.sr-bb .srt-base-1-away-5 {
  background-color: transparent;
  color: #ffa959;
  border-color: #ffa959;
}

.sr-bb .srt-base-1-background {
  background-color: #2C3033;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-2 {
  background-color: #2C3033;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-2-is-active {
  background-color: #313d2f;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-2-is-hoverable:hover {
  background-color: #313d2f;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3 {
  background-color: #313d2f;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3-is-active {
  background-color: #374530;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3-is-hoverable:hover {
  background-color: #374530;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-3-background {
  background-color: #2C3033;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-4 {
  background-color: #2a322e;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-5-is-active {
  background-color: #405332;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-5-is-hoverable:hover {
  background-color: #405332;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-6 {
  background-color: transparent;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.3);
}

.sr-bb .srt-primary-1 {
  background-color: #8DC63F;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-1-is-active {
  background-color: #7fb239;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-1-is-hoverable:hover {
  background-color: #7fb239;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-1-is-disabled {
  background-color: #719e32;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-2 {
  background-color: #afd779;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-3 {
  background-color: #7fb239;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-4 {
  background-color: #638838;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-5 {
  background-color: #638838;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-5-is-hoverable:hover {
  background-color: #8DC63F;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-6 {
  background-color: #bdde90;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-7 {
  background-color: #8DC63F;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-8 {
  background-color: #8DC63F;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-8-is-active-1 {
  background-color: #7fb239;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-8-is-active-2 {
  background-color: #7fb239;
  color: #000000;
  border-color: #2C3033;
}

.sr-bb .srt-primary-9 {
  background-color: #445932;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-primary-10 {
  background-color: #78a73b;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-11 {
  background-color: #7fb239;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-12 {
  background-color: #8DC63F;
  color: #000000;
  border-color: #000000;
}

.sr-bb .srt-primary-13 {
  background-color: #2a322e;
  color: #000000;
  border-color: rgba(141, 198, 63, 0.3);
}

.sr-bb .srt-base-1-primary-1 {
  background-color: transparent;
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-primary-2 {
  background-color: transparent;
  color: #afd779;
  border-color: #afd779;
}

.sr-bb .srt-base-1-primary-3 {
  background-color: transparent;
  color: #7fb239;
  border-color: #7fb239;
}

.sr-bb .srt-base-1-primary-4 {
  background-color: transparent;
  color: #638838;
  border-color: #638838;
}

.sr-bb .srt-base-1-primary-5 {
  background-color: transparent;
  color: #638838;
  border-color: #638838;
}

.sr-bb .srt-base-1-primary-6 {
  background-color: transparent;
  color: #bdde90;
  border-color: #bdde90;
}

.sr-bb .srt-base-1-primary-7 {
  background-color: transparent;
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-primary-8 {
  background-color: transparent;
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-primary-9 {
  background-color: transparent;
  color: #445932;
  border-color: #445932;
}

.sr-bb .srt-base-1-primary-10 {
  background-color: transparent;
  color: #78a73b;
  border-color: #78a73b;
}

.sr-bb .srt-base-1-primary-11 {
  background-color: transparent;
  color: #7fb239;
  border-color: #7fb239;
}

.sr-bb .srt-base-1-primary-13 {
  background-color: #2C3033;
  color: #2a322e;
  border-color: rgba(141, 198, 63, 0.3);
}

.sr-bb .srt-base-1-neutral-1 {
  background-color: transparent;
  color: #414648;
  border-color: #414648;
}

.sr-bb .srt-base-1-neutral-2 {
  background-color: transparent;
  color: #e9eaea;
  border-color: #e9eaea;
}

.sr-bb .srt-base-1-neutral-3 {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-neutral-4 {
  background-color: transparent;
  color: #b3b4b6;
  border-color: #b3b4b6;
}

.sr-bb .srt-base-1-neutral-5 {
  background-color: transparent;
  color: #c9cacb;
  border-color: #c9cacb;
}

.sr-bb .srt-base-1-neutral-6 {
  background-color: transparent;
  color: #595d5f;
  border-color: #595d5f;
}

.sr-bb .srt-base-1-neutral-7 {
  background-color: transparent;
  color: #b3b4b6;
  border-color: #b3b4b6;
}

.sr-bb .srt-base-1-neutral-8 {
  background-color: transparent;
  color: #414648;
  border-color: #414648;
}

.sr-bb .srt-base-1-neutral-9 {
  background-color: transparent;
  color: #32373a;
  border-color: #32373a;
}

.sr-bb .srt-base-1-neutral-10 {
  background-color: transparent;
  color: #3f4446;
  border-color: #3f4446;
}

.sr-bb .srt-base-1-neutral-11 {
  background-color: transparent;
  color: #7c7f81;
  border-color: #7c7f81;
}

.sr-bb .srt-base-1-neutral-12 {
  background-color: transparent;
  color: #bebfc0;
  border-color: #bebfc0;
}

.sr-bb .srt-base-1-neutral-13 {
  background-color: transparent;
  color: #3b3f42;
  border-color: #3b3f42;
}

.sr-bb .srt-base-1-is-active-primary {
  background-color: rgba(141, 198, 63, 0.12);
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-is-active-home-1 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #9CE800;
  border-color: #9CE800;
}

.sr-bb .srt-base-1-is-active-away-1 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #FF7A00;
  border-color: #FF7A00;
}

.sr-bb .srt-base-1-is-active-home-2 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #9CE800;
  border-color: #bdde90;
}

.sr-bb .srt-base-1-is-active-away-2 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #FF7A00;
  border-color: #bdde90;
}

.sr-bb .srt-base-1-is-active-home-3 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #9CE800;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-away-3 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #FF7A00;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-home-4 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #78af0e;
  border-color: #78af0e;
}

.sr-bb .srt-base-1-is-active-away-4 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #be620e;
  border-color: #be620e;
}

.sr-bb .srt-base-1-is-active-home-5 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #bff059;
  border-color: #bff059;
}

.sr-bb .srt-base-1-is-active-away-5 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #ffa959;
  border-color: #ffa959;
}

.sr-bb .srt-base-1-is-active-primary-1 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-is-active-primary-2 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #afd779;
  border-color: #afd779;
}

.sr-bb .srt-base-1-is-active-primary-3 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #7fb239;
  border-color: #7fb239;
}

.sr-bb .srt-base-1-is-active-primary-4 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #638838;
  border-color: #638838;
}

.sr-bb .srt-base-1-is-active-primary-5 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #638838;
  border-color: #638838;
}

.sr-bb .srt-base-1-is-active-primary-6 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #bdde90;
  border-color: #bdde90;
}

.sr-bb .srt-base-1-is-active-primary-7 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-is-active-primary-8 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-is-active-primary-9 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #445932;
  border-color: #445932;
}

.sr-bb .srt-base-1-is-active-primary-10 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #78a73b;
  border-color: #78a73b;
}

.sr-bb .srt-base-1-is-active-primary-11 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #7fb239;
  border-color: #7fb239;
}

.sr-bb .srt-base-1-is-active-neutral-1 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #414648;
  border-color: #414648;
}

.sr-bb .srt-base-1-is-active-neutral-2 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #e9eaea;
  border-color: #e9eaea;
}

.sr-bb .srt-base-1-is-active-neutral-3 {
  background-color: rgba(141, 198, 63, 0.12);
  color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-base-1-is-active-neutral-4 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #b3b4b6;
  border-color: #b3b4b6;
}

.sr-bb .srt-base-1-is-active-neutral-5 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #c9cacb;
  border-color: #c9cacb;
}

.sr-bb .srt-base-1-is-active-neutral-6 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #595d5f;
  border-color: #595d5f;
}

.sr-bb .srt-base-1-is-active-neutral-7 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #b3b4b6;
  border-color: #b3b4b6;
}

.sr-bb .srt-base-1-is-active-neutral-8 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #414648;
  border-color: #414648;
}

.sr-bb .srt-base-1-is-active-neutral-9 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #32373a;
  border-color: #32373a;
}

.sr-bb .srt-base-1-is-active-neutral-10 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #3f4446;
  border-color: #3f4446;
}

.sr-bb .srt-base-1-is-active-neutral-11 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #7c7f81;
  border-color: #7c7f81;
}

.sr-bb .srt-base-1-is-active-neutral-12 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #bebfc0;
  border-color: #bebfc0;
}

.sr-bb .srt-base-1-is-active-neutral-13 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #3b3f42;
  border-color: #3b3f42;
}

.sr-bb .srt-home-1 {
  background-color: #9CE800;
  color: #000000;
  border-color: #9CE800;
}

.sr-bb .srt-away-1 {
  background-color: #FF7A00;
  color: #000000;
  border-color: #FF7A00;
}

.sr-bb .srt-home-2 {
  background-color: #9CE800;
  color: #000000;
  border-color: #bdde90;
}

.sr-bb .srt-away-2 {
  background-color: #FF7A00;
  color: #000000;
  border-color: #bdde90;
}

.sr-bb .srt-home-3 {
  background-color: #9CE800;
  color: #000000;
  border-color: #2C3033;
}

.sr-bb .srt-away-3 {
  background-color: #FF7A00;
  color: #000000;
  border-color: #2C3033;
}

.sr-bb .srt-home-4 {
  background-color: #78af0e;
  color: #000000;
  border-color: #78af0e;
}

.sr-bb .srt-away-4 {
  background-color: #be620e;
  color: #000000;
  border-color: #be620e;
}

.sr-bb .srt-home-5 {
  background-color: #bff059;
  color: #000000;
  border-color: #bff059;
}

.sr-bb .srt-away-5 {
  background-color: #ffa959;
  color: #000000;
  border-color: #ffa959;
}

.sr-bb .srt-home-6 {
  background-color: rgba(156, 232, 0, 0.2);
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-away-6 {
  background-color: rgba(255, 122, 0, 0.2);
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-home-6-is-hoverable:hover {
  background-color: rgba(156, 232, 0, 0.2);
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-away-6-is-hoverable:hover {
  background-color: rgba(255, 122, 0, 0.2);
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-1 {
  background-color: #414648;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-2 {
  background-color: #e9eaea;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-3 {
  background-color: rgba(255, 255, 255, 0.12);
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-4 {
  background-color: #b3b4b6;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-5 {
  background-color: #c9cacb;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-6 {
  background-color: #595d5f;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-7 {
  background-color: #b3b4b6;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-8 {
  background-color: #414648;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-9 {
  background-color: #32373a;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-10 {
  background-color: #3f4446;
  color: rgba(255, 255, 255, 0.82);
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-11 {
  background-color: #7c7f81;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-neutral-12 {
  background-color: #bebfc0;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-13 {
  background-color: #3b3f42;
  color: #FFFFFF;
  border-color: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-win {
  background-color: #9CE800;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-draw {
  background-color: rgba(255, 255, 255, 0.4);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-lose {
  background-color: #FF7A00;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-text-secondary {
  opacity: 0.8;
  -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-text-disabled {
  opacity: 0.6;
}

.sr-bb .srt-text-tertiary {
  opacity: 0.7;
}

.sr-bb .srt-primary-1 .srt-text-secondary, .sr-bb .srt-primary-1.srt-text-secondary,
.sr-bb .srt-primary-1-is-active .srt-text-secondary,
.sr-bb .srt-primary-1-is-active.srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled .srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled.srt-text-secondary,
.sr-bb .srt-primary-2 .srt-text-secondary,
.sr-bb .srt-primary-2.srt-text-secondary,
.sr-bb .srt-primary-3 .srt-text-secondary,
.sr-bb .srt-primary-3.srt-text-secondary,
.sr-bb .srt-primary-4 .srt-text-secondary,
.sr-bb .srt-primary-4.srt-text-secondary,
.sr-bb .srt-primary-5 .srt-text-secondary,
.sr-bb .srt-primary-5.srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-6 .srt-text-secondary,
.sr-bb .srt-primary-6.srt-text-secondary,
.sr-bb .srt-primary-7 .srt-text-secondary,
.sr-bb .srt-primary-7.srt-text-secondary,
.sr-bb .srt-primary-8 .srt-text-secondary,
.sr-bb .srt-primary-8.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2.srt-text-secondary,
.sr-bb .srt-primary-9 .srt-text-secondary,
.sr-bb .srt-primary-9.srt-text-secondary,
.sr-bb .srt-primary-10 .srt-text-secondary,
.sr-bb .srt-primary-10.srt-text-secondary,
.sr-bb .srt-primary-11 .srt-text-secondary,
.sr-bb .srt-primary-11.srt-text-secondary,
.sr-bb .srt-primary-12 .srt-text-secondary,
.sr-bb .srt-primary-12.srt-text-secondary,
.sr-bb .srt-primary-13 .srt-text-secondary,
.sr-bb .srt-primary-13.srt-text-secondary {
  opacity: 0.7;
  -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-primary-1 .srt-text-disabled, .sr-bb .srt-primary-1.srt-text-disabled,
.sr-bb .srt-primary-1-is-active .srt-text-disabled,
.sr-bb .srt-primary-1-is-active.srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled .srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled.srt-text-disabled,
.sr-bb .srt-primary-2 .srt-text-disabled,
.sr-bb .srt-primary-2.srt-text-disabled,
.sr-bb .srt-primary-3 .srt-text-disabled,
.sr-bb .srt-primary-3.srt-text-disabled,
.sr-bb .srt-primary-4 .srt-text-disabled,
.sr-bb .srt-primary-4.srt-text-disabled,
.sr-bb .srt-primary-5 .srt-text-disabled,
.sr-bb .srt-primary-5.srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-6 .srt-text-disabled,
.sr-bb .srt-primary-6.srt-text-disabled,
.sr-bb .srt-primary-7 .srt-text-disabled,
.sr-bb .srt-primary-7.srt-text-disabled,
.sr-bb .srt-primary-8 .srt-text-disabled,
.sr-bb .srt-primary-8.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2.srt-text-disabled,
.sr-bb .srt-primary-9 .srt-text-disabled,
.sr-bb .srt-primary-9.srt-text-disabled,
.sr-bb .srt-primary-10 .srt-text-disabled,
.sr-bb .srt-primary-10.srt-text-disabled,
.sr-bb .srt-primary-11 .srt-text-disabled,
.sr-bb .srt-primary-11.srt-text-disabled,
.sr-bb .srt-primary-12 .srt-text-disabled,
.sr-bb .srt-primary-12.srt-text-disabled,
.sr-bb .srt-primary-13 .srt-text-disabled,
.sr-bb .srt-primary-13.srt-text-disabled {
  opacity: 0.35;
}

.sr-bb .srt-primary-1 .srt-text-tertiary, .sr-bb .srt-primary-1.srt-text-tertiary,
.sr-bb .srt-primary-1-is-active .srt-text-tertiary,
.sr-bb .srt-primary-1-is-active.srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled .srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled.srt-text-tertiary,
.sr-bb .srt-primary-2 .srt-text-tertiary,
.sr-bb .srt-primary-2.srt-text-tertiary,
.sr-bb .srt-primary-3 .srt-text-tertiary,
.sr-bb .srt-primary-3.srt-text-tertiary,
.sr-bb .srt-primary-4 .srt-text-tertiary,
.sr-bb .srt-primary-4.srt-text-tertiary,
.sr-bb .srt-primary-5 .srt-text-tertiary,
.sr-bb .srt-primary-5.srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-6 .srt-text-tertiary,
.sr-bb .srt-primary-6.srt-text-tertiary,
.sr-bb .srt-primary-7 .srt-text-tertiary,
.sr-bb .srt-primary-7.srt-text-tertiary,
.sr-bb .srt-primary-8 .srt-text-tertiary,
.sr-bb .srt-primary-8.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2.srt-text-tertiary,
.sr-bb .srt-primary-9 .srt-text-tertiary,
.sr-bb .srt-primary-9.srt-text-tertiary,
.sr-bb .srt-primary-10 .srt-text-tertiary,
.sr-bb .srt-primary-10.srt-text-tertiary,
.sr-bb .srt-primary-11 .srt-text-tertiary,
.sr-bb .srt-primary-11.srt-text-tertiary,
.sr-bb .srt-primary-12 .srt-text-tertiary,
.sr-bb .srt-primary-12.srt-text-tertiary,
.sr-bb .srt-primary-13 .srt-text-tertiary,
.sr-bb .srt-primary-13.srt-text-tertiary {
  opacity: 0.54;
}

.sr-bb .srt-icon {
  opacity: 0.33;
}

.sr-bb .srt-icon-secondary {
  opacity: 0.7;
}

.sr-bb .srt-elevation-1 {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.sr-bb .srt-elevation-2 {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.23), 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-elevation-3 {
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.26), 0px 10px 20px 0px rgba(0, 0, 0, 0.19);
}

.sr-bb .srt-elevation-center-2 {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23), 0 1px 5px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-inset-top-1 {
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-1 {
  box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-2 {
  box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-2 {
  box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-3 {
  box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-3 {
  box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-fill-blue-card {
  fill: #2579ad;
}

.sr-bb .srt-stroke-blue-card {
  stroke: #2579ad;
}

.sr-bb .srt-fill-green-card {
  fill: #2cbd00;
}

.sr-bb .srt-stroke-green-card {
  stroke: #2cbd00;
}

.sr-bb .srt-fill-soccer-yellow-card {
  fill: #ffbf00;
}

.sr-bb .srt-stroke-soccer-yellow-card {
  stroke: #ffbf00;
}

.sr-bb .srt-fill-soccer-red-card {
  fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-red-card {
  stroke: #e43b3b;
}

.sr-bb .srt-stroke-soccer-substitution-in {
  stroke: #4fbe30;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-in {
  fill: #4fbe30;
}

.sr-bb .srt-stroke-soccer-substitution-out {
  stroke: #e43b3b;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-out {
  fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-own-goal {
  stroke: #e43b3b;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-own-goal {
  fill: #e43b3b;
}

.sr-bb .srt-fill-soccer-relegation-1 {
  fill: #fdd835;
}

.sr-bb .srt-stroke-soccer-relegation-1 {
  stroke: #fdd835;
}

.sr-bb .srt-fill-soccer-relegation-2 {
  fill: #ffb848;
}

.sr-bb .srt-stroke-soccer-relegation-2 {
  stroke: #ffb848;
}

.sr-bb .srt-fill-soccer-relegation-3 {
  fill: #ef6c00;
}

.sr-bb .srt-stroke-soccer-relegation-3 {
  stroke: #ef6c00;
}

.sr-bb .srt-fill-soccer-relegation-4 {
  fill: #e93a34;
}

.sr-bb .srt-stroke-soccer-relegation-4 {
  stroke: #e93a34;
}

.sr-bb .srt-fill-soccer-relegation-5 {
  fill: #941d1d;
}

.sr-bb .srt-stroke-soccer-relegation-5 {
  stroke: #941d1d;
}

.sr-bb .srt-fill-soccer-promotion-1 {
  fill: #51d151;
}

.sr-bb .srt-stroke-soccer-promotion-1 {
  stroke: #51d151;
}

.sr-bb .srt-fill-soccer-promotion-2 {
  fill: #1b911b;
}

.sr-bb .srt-stroke-soccer-promotion-2 {
  stroke: #1b911b;
}

.sr-bb .srt-fill-soccer-promotion-3 {
  fill: #0e8094;
}

.sr-bb .srt-stroke-soccer-promotion-3 {
  stroke: #0e8094;
}

.sr-bb .srt-fill-soccer-promotion-4 {
  fill: #0a6cce;
}

.sr-bb .srt-stroke-soccer-promotion-4 {
  stroke: #0a6cce;
}

.sr-bb .srt-fill-soccer-promotion-5 {
  fill: #4a9fe4;
}

.sr-bb .srt-stroke-soccer-promotion-5 {
  stroke: #4a9fe4;
}

.sr-bb .srt-nfl-timeout-1 {
  background-color: #f5a623;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-nfl-timeout-2 {
  background-color: rgba(245, 166, 35, 0.3);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-nfl-penalty {
  background-color: #f4a621;
  color: #000000;
  border-color: #f4a621;
}

.sr-bb .srt-nfl-negative-yards-1 {
  background-color: #ac182e;
  color: #000000;
  border-color: #ac182e;
}

.sr-bb .srt-nfl-negative-yards-2 {
  background-color: #ac182e;
  color: #000000;
  border-color: #ac182e;
}

.sr-bb .srt-nfl-first-line {
  background-color: #4b90de;
  color: #000000;
  border-color: #4b90de;
}

.sr-bb .srt-nfl-ten-line {
  background-color: #f5a623;
  color: #000000;
  border-color: #f5a623;
}

.sr-bb .srt-fill-nfl-penalty {
  fill: #f4a621;
}

.sr-bb .srt-stroke-nfl-penalty {
  stroke: #f4a621;
}

.sr-bb .srt-fill-nfl-negative-yards-1 {
  fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-1 {
  stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-negative-yards-2 {
  fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-2 {
  stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-first-line {
  fill: #4b90de;
}

.sr-bb .srt-stroke-nfl-first-line {
  stroke: #4b90de;
}

.sr-bb .srt-fill-nfl-ten-line {
  fill: #f5a623;
}

.sr-bb .srt-stroke-nfl-ten-line {
  stroke: #f5a623;
}

.sr-bb .srt-mlb-run-1 {
  background-color: #009900;
  color: #ffffff;
  border-color: #009900;
}

.sr-bb .srt-mlb-run-2 {
  background-color: #ffffff;
  color: #009900;
  border-color: #009900;
}

.sr-bb .srt-mlb-hit-1 {
  background-color: #f7ab11;
  color: #FFFFFF;
  border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-2 {
  background-color: #ffffff;
  color: #f7ab11;
  border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-3 {
  background-color: #00a4ff;
  color: #ffffff;
  border-color: #00a4ff;
}

.sr-bb .srt-mlb-error-1 {
  background-color: #d0021b;
  color: #ffffff;
  border-color: #d0021b;
}

.sr-bb .srt-mlb-error-2 {
  background-color: #ffffff;
  color: #d0021b;
  border-color: #d0021b;
}

.sr-bb .srt-mlb-neutral-1 {
  background-color: #212121;
  color: #ffffff;
  border-color: #ffffff;
}

.sr-bb .srt-fill-mlb-base {
  fill: #00a4ff;
}

.sr-bb .srt-strole-mlb-base {
  fill: #00a4ff;
}

.sr-bb .srt-fill-change-increase {
  fill: #9CE800;
}

.sr-bb .srt-stroke-change-increase {
  stroke: #9CE800;
}

.sr-bb .srt-fill-change-decrease {
  fill: #FF7A00;
}

.sr-bb .srt-stroke-change-decrease {
  stroke: #FF7A00;
}

.sr-bb .srt-fill-text {
  fill: #FFFFFF;
}

.sr-bb .srt-fill-text-invert {
  fill: #000000;
}

.sr-bb .srt-fill-text-secondary {
  fill-opacity: 0.8;
}

.sr-bb .srt-fill-text-disabled {
  fill-opacity: 0.6;
}

.sr-bb .srt-fill-base-1 {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1 {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-active {
  fill: rgba(141, 198, 63, 0.12);
}

.sr-bb .srt-stroke-base-1-active {
  stroke: rgba(141, 198, 63, 0.12);
}

.sr-bb .srt-fill-base-1-active-2 {
  fill: #3b3f42;
}

.sr-bb .srt-stroke-base-1-active-2 {
  stroke: #3b3f42;
}

.sr-bb .srt-fill-base-1-primary {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-primary {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-home {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-home {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-away {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-away {
  stroke: transparent;
}

.sr-bb .srt-fill-base-2 {
  fill: currentColor;
}

.sr-bb .srt-stroke-base-2 {
  stroke: currentColor;
}

.sr-bb .srt-fill-base-2-active {
  fill: #313d2f;
}

.sr-bb .srt-stroke-base-2-active {
  stroke: #313d2f;
}

.sr-bb .srt-fill-base-2-hover {
  fill: #313d2f;
}

.sr-bb .srt-stroke-base-2-hover {
  stroke: #313d2f;
}

.sr-bb .srt-fill-base-3 {
  fill: #313d2f;
}

.sr-bb .srt-stroke-base-3 {
  stroke: #313d2f;
}

.sr-bb .srt-fill-base-3-active {
  fill: #374530;
}

.sr-bb .srt-stroke-base-3-active {
  stroke: #374530;
}

.sr-bb .srt-fill-base-3-hover {
  fill: #374530;
}

.sr-bb .srt-stroke-base-3-hover {
  stroke: #374530;
}

.sr-bb .srt-fill-primary-1 {
  fill: #8DC63F;
}

.sr-bb .srt-stroke-primary-1 {
  stroke: #8DC63F;
}

.sr-bb .srt-fill-primary-2 {
  fill: #afd779;
}

.sr-bb .srt-stroke-primary-2 {
  stroke: #afd779;
}

.sr-bb .srt-fill-primary-3 {
  fill: #7fb239;
}

.sr-bb .srt-stroke-primary-3 {
  stroke: #7fb239;
}

.sr-bb .srt-fill-primary-4 {
  fill: #638838;
}

.sr-bb .srt-stroke-primary-4 {
  stroke: #638838;
}

.sr-bb .srt-fill-primary-5 {
  fill: #638838;
}

.sr-bb .srt-stroke-primary-5 {
  stroke: #638838;
}

.sr-bb .srt-fill-primary-6 {
  fill: #bdde90;
}

.sr-bb .srt-stroke-primary-6 {
  stroke: #bdde90;
}

.sr-bb .srt-fill-primary-7 {
  fill: #8DC63F;
}

.sr-bb .srt-stroke-primary-7 {
  stroke: #8DC63F;
}

.sr-bb .srt-fill-primary-8 {
  fill: #8DC63F;
}

.sr-bb .srt-stroke-primary-8 {
  stroke: #8DC63F;
}

.sr-bb .srt-fill-primary-8-is-active-1 {
  fill: #7fb239;
}

.sr-bb .srt-stroke-primary-8-is-active-1 {
  stroke: #7fb239;
}

.sr-bb .srt-fill-primary-8-is-active-2 {
  fill: #7fb239;
}

.sr-bb .srt-stroke-primary-8-is-active-2 {
  stroke: #7fb239;
}

.sr-bb .srt-fill-primary-9 {
  fill: #445932;
}

.sr-bb .srt-stroke-primary-9 {
  stroke: #445932;
}

.sr-bb .srt-fill-primary-10 {
  fill: #78a73b;
}

.sr-bb .srt-stroke-primary-10 {
  stroke: #78a73b;
}

.sr-bb .srt-fill-primary-11 {
  fill: #7fb239;
}

.sr-bb .srt-stroke-primary-11 {
  stroke: #7fb239;
}

.sr-bb .srt-fill-primary-12 {
  fill: #8DC63F;
}

.sr-bb .srt-stroke-primary-12 {
  stroke: #8DC63F;
}

.sr-bb .srt-fill-home-1 {
  fill: #9CE800;
}

.sr-bb .srt-stroke-home-1 {
  stroke: #9CE800;
}

.sr-bb .srt-fill-home-2 {
  fill: #9CE800;
}

.sr-bb .srt-stroke-home-2 {
  stroke: #9CE800;
}

.sr-bb .srt-fill-home-3 {
  fill: #9CE800;
}

.sr-bb .srt-stroke-home-3 {
  stroke: #9CE800;
}

.sr-bb .srt-fill-home-4 {
  fill: #78af0e;
}

.sr-bb .srt-stroke-home-4 {
  stroke: #78af0e;
}

.sr-bb .srt-fill-home-5 {
  fill: #bff059;
}

.sr-bb .srt-stroke-home-5 {
  stroke: #bff059;
}

.sr-bb .srt-fill-away-1 {
  fill: #FF7A00;
}

.sr-bb .srt-stroke-away-1 {
  stroke: #FF7A00;
}

.sr-bb .srt-fill-away-2 {
  fill: #FF7A00;
}

.sr-bb .srt-stroke-away-2 {
  stroke: #FF7A00;
}

.sr-bb .srt-fill-away-3 {
  fill: #FF7A00;
}

.sr-bb .srt-stroke-away-3 {
  stroke: #FF7A00;
}

.sr-bb .srt-fill-away-4 {
  fill: #be620e;
}

.sr-bb .srt-stroke-away-4 {
  stroke: #be620e;
}

.sr-bb .srt-fill-away-5 {
  fill: #ffa959;
}

.sr-bb .srt-stroke-away-5 {
  stroke: #ffa959;
}

.sr-bb .srt-fill-neutral-1 {
  fill: #414648;
}

.sr-bb .srt-stroke-neutral-1 {
  stroke: #414648;
}

.sr-bb .srt-fill-neutral-2 {
  fill: #e9eaea;
}

.sr-bb .srt-stroke-neutral-2 {
  stroke: #e9eaea;
}

.sr-bb .srt-fill-neutral-3 {
  fill: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-stroke-neutral-3 {
  stroke: rgba(255, 255, 255, 0.12);
}

.sr-bb .srt-fill-neutral-4 {
  fill: #b3b4b6;
}

.sr-bb .srt-stroke-neutral-4 {
  stroke: #b3b4b6;
}

.sr-bb .srt-fill-neutral-5 {
  fill: #c9cacb;
}

.sr-bb .srt-stroke-neutral-5 {
  stroke: #c9cacb;
}

.sr-bb .srt-fill-neutral-6 {
  fill: #595d5f;
}

.sr-bb .srt-stroke-neutral-6 {
  stroke: #595d5f;
}

.sr-bb .srt-fill-neutral-7 {
  fill: #b3b4b6;
}

.sr-bb .srt-stroke-neutral-7 {
  stroke: #b3b4b6;
}

.sr-bb .srt-fill-neutral-8 {
  fill: #414648;
}

.sr-bb .srt-stroke-neutral-8 {
  stroke: #414648;
}

.sr-bb .srt-fill-neutral-9 {
  fill: #32373a;
}

.sr-bb .srt-stroke-neutral-9 {
  stroke: #32373a;
}

.sr-bb .srt-fill-neutral-10 {
  fill: #3f4446;
}

.sr-bb .srt-stroke-neutral-10 {
  stroke: #3f4446;
}

.sr-bb .srt-fill-neutral-11 {
  fill: #7c7f81;
}

.sr-bb .srt-stroke-neutral-11 {
  stroke: #7c7f81;
}

.sr-bb .srt-fill-neutral-12 {
  fill: #bebfc0;
}

.sr-bb .srt-stroke-neutral-12 {
  stroke: #bebfc0;
}

.sr-bb .srt-fill-neutral-13 {
  fill: #3b3f42;
}

.sr-bb .srt-stroke-neutral-13 {
  stroke: #3b3f42;
}

.sr-bb .srt-fill-win {
  fill: #9CE800;
}

.sr-bb .srt-stroke-win {
  stroke: #9CE800;
}

.sr-bb .srt-fill-draw {
  fill: rgba(255, 255, 255, 0.4);
}

.sr-bb .srt-stroke-draw {
  stroke: rgba(255, 255, 255, 0.4);
}

.sr-bb .srt-fill-lose {
  fill: #FF7A00;
}

.sr-bb .srt-stroke-lose {
  stroke: #FF7A00;
}

.sr-bb .srt-stop-base-1 {
  stop-color: transparent;
}

.sr-bb .srt-stop-primary-1 {
  stop-color: #8DC63F;
}

.sr-bb .srt-stop-primary-2 {
  stop-color: #afd779;
}

.sr-bb .srt-stop-primary-3 {
  stop-color: #7fb239;
}

.sr-bb .srt-stop-primary-4 {
  stop-color: #638838;
}

.sr-bb .srt-stop-primary-5 {
  stop-color: #638838;
}

.sr-bb .srt-stop-primary-6 {
  stop-color: #bdde90;
}

.sr-bb .srt-stop-primary-7 {
  stop-color: #8DC63F;
}

.sr-bb .srt-stop-primary-8 {
  stop-color: #8DC63F;
}

.sr-bb .srt-stop-primary-9 {
  stop-color: #445932;
}

.sr-bb .srt-stop-primary-10 {
  stop-color: #78a73b;
}

.sr-bb .srt-stop-primary-11 {
  stop-color: #7fb239;
}

.sr-bb .srt-stop-primary-12 {
  stop-color: #8DC63F;
}

.sr-bb .srt-stop-home-1 {
  stop-color: #9CE800;
}

.sr-bb .srt-stop-away-1 {
  stop-color: #FF7A00;
}

.sr-bb .srt-fill-neutral-14 {
  fill: #2C3033;
}

.sr-bb .srt-stroke-neutral-14 {
  stroke: #2C3033;
}

.sr-bb .srt-logo-powered-by-light {
  display: none;
}

.sr-bb .srt-logo-powered-by-dark {
  display: inline-block;
}`,
            light: `/*
Using formula solid
$primary: #8DC63F;
$home: #8DC63F;
$away: #FF7A00;
$cl: #000000;
$base: #F9F9F9;
*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=latin,latin-ext");
.sr-bb {
  font-family: "Roboto", "Noto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  text-align: left;
  background: #F9F9F9;
}

.sr-bb.sr-rtl {
  text-align: right;
}

.sr-bb .sr-bb {
  background: none;
}

.sr-bb .srt-base-1 {
  background-color: transparent;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-win {
  background-color: transparent;
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-draw {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.4);
  border-color: rgba(0, 0, 0, 0.4);
}

.sr-bb .srt-base-1-lose {
  background-color: transparent;
  color: #1E1E1E;
  border-color: #1E1E1E;
}

.sr-bb .srt-base-1-is-active {
  background-color: rgba(141, 198, 63, 0.12);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-2 {
  background-color: #e0e0e0;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-hoverable:hover {
  background-color: rgba(141, 198, 63, 0.12);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-primary {
  background-color: transparent;
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-home-1 {
  background-color: transparent;
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-away-1 {
  background-color: transparent;
  color: #1E1E1E;
  border-color: #1E1E1E;
}

.sr-bb .srt-base-1-home-2 {
  background-color: transparent;
  color: #8DC63F;
  border-color: #527325;
}

.sr-bb .srt-base-1-away-2 {
  background-color: transparent;
  color: #1E1E1E;
  border-color: #527325;
}

.sr-bb .srt-base-1-home-3 {
  background-color: transparent;
  color: #8DC63F;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-away-3 {
  background-color: transparent;
  color: #1E1E1E;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-home-4 {
  background-color: transparent;
  color: #add577;
  border-color: #add577;
}

.sr-bb .srt-base-1-away-4 {
  background-color: transparent;
  color: #606060;
  border-color: #606060;
}

.sr-bb .srt-base-1-home-5 {
  background-color: transparent;
  color: #5c8129;
  border-color: #5c8129;
}

.sr-bb .srt-base-1-away-5 {
  background-color: transparent;
  color: #141414;
  border-color: #141414;
}

.sr-bb .srt-base-1-background {
  background-color: #f9f9f9;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-2 {
  background-color: #f9f9f9;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-2-is-active {
  background-color: #ecf3e3;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-2-is-hoverable:hover {
  background-color: #ecf3e3;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3 {
  background-color: #ecf3e3;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3-is-active {
  background-color: #e7f0d9;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3-is-hoverable:hover {
  background-color: #e7f0d9;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-3-background {
  background-color: #f9f9f9;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-4 {
  background-color: #f4f6f0;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-5-is-active {
  background-color: #ddecc9;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-5-is-hoverable:hover {
  background-color: #ddecc9;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-6 {
  background-color: transparent;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.3);
}

.sr-bb .srt-primary-1 {
  background-color: #8DC63F;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-1-is-active {
  background-color: #7fb239;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-1-is-hoverable:hover {
  background-color: #7fb239;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-1-is-disabled {
  background-color: #719e32;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-2 {
  background-color: #638b2c;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-3 {
  background-color: #7fb239;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-4 {
  background-color: #b8da89;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-5 {
  background-color: #b8da89;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-5-is-hoverable:hover {
  background-color: #8DC63F;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-6 {
  background-color: #527325;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-7 {
  background-color: #8DC63F;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-8 {
  background-color: #8DC63F;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-8-is-active-1 {
  background-color: #7fb239;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-8-is-active-2 {
  background-color: #7fb239;
  color: #000000;
  border-color: #F9F9F9;
}

.sr-bb .srt-primary-9 {
  background-color: #d9eac1;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-primary-10 {
  background-color: #a3d064;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-11 {
  background-color: #7fb239;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-primary-12 {
  background-color: #8DC63F;
  color: #000000;
  border-color: #000000;
}

.sr-bb .srt-primary-13 {
  background-color: #f4f6f0;
  color: #000000;
  border-color: rgba(141, 198, 63, 0.3);
}

.sr-bb .srt-base-1-primary-1 {
  background-color: transparent;
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-primary-2 {
  background-color: transparent;
  color: #638b2c;
  border-color: #638b2c;
}

.sr-bb .srt-base-1-primary-3 {
  background-color: transparent;
  color: #7fb239;
  border-color: #7fb239;
}

.sr-bb .srt-base-1-primary-4 {
  background-color: transparent;
  color: #b8da89;
  border-color: #b8da89;
}

.sr-bb .srt-base-1-primary-5 {
  background-color: transparent;
  color: #b8da89;
  border-color: #b8da89;
}

.sr-bb .srt-base-1-primary-6 {
  background-color: transparent;
  color: #527325;
  border-color: #527325;
}

.sr-bb .srt-base-1-primary-7 {
  background-color: transparent;
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-primary-8 {
  background-color: transparent;
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-primary-9 {
  background-color: transparent;
  color: #d9eac1;
  border-color: #d9eac1;
}

.sr-bb .srt-base-1-primary-10 {
  background-color: transparent;
  color: #a3d064;
  border-color: #a3d064;
}

.sr-bb .srt-base-1-primary-11 {
  background-color: transparent;
  color: #7fb239;
  border-color: #7fb239;
}

.sr-bb .srt-base-1-primary-13 {
  background-color: #F9F9F9;
  color: #f4f6f0;
  border-color: rgba(141, 198, 63, 0.3);
}

.sr-bb .srt-base-1-neutral-1 {
  background-color: transparent;
  color: #d9d9d9;
  border-color: #d9d9d9;
}

.sr-bb .srt-base-1-neutral-2 {
  background-color: transparent;
  color: #191919;
  border-color: #191919;
}

.sr-bb .srt-base-1-neutral-3 {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-neutral-4 {
  background-color: transparent;
  color: #575757;
  border-color: #575757;
}

.sr-bb .srt-base-1-neutral-5 {
  background-color: transparent;
  color: #3e3e3e;
  border-color: #3e3e3e;
}

.sr-bb .srt-base-1-neutral-6 {
  background-color: transparent;
  color: #bdbdbd;
  border-color: #bdbdbd;
}

.sr-bb .srt-base-1-neutral-7 {
  background-color: transparent;
  color: #575757;
  border-color: #575757;
}

.sr-bb .srt-base-1-neutral-8 {
  background-color: transparent;
  color: #d9d9d9;
  border-color: #d9d9d9;
}

.sr-bb .srt-base-1-neutral-9 {
  background-color: transparent;
  color: #eaeaea;
  border-color: #eaeaea;
}

.sr-bb .srt-base-1-neutral-10 {
  background-color: transparent;
  color: #dbdbdb;
  border-color: #dbdbdb;
}

.sr-bb .srt-base-1-neutral-11 {
  background-color: transparent;
  color: #959595;
  border-color: #959595;
}

.sr-bb .srt-base-1-neutral-12 {
  background-color: transparent;
  color: #4b4b4b;
  border-color: #4b4b4b;
}

.sr-bb .srt-base-1-neutral-13 {
  background-color: transparent;
  color: #e0e0e0;
  border-color: #e0e0e0;
}

.sr-bb .srt-base-1-is-active-primary {
  background-color: rgba(141, 198, 63, 0.12);
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-is-active-home-1 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-is-active-away-1 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #1E1E1E;
  border-color: #1E1E1E;
}

.sr-bb .srt-base-1-is-active-home-2 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #8DC63F;
  border-color: #527325;
}

.sr-bb .srt-base-1-is-active-away-2 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #1E1E1E;
  border-color: #527325;
}

.sr-bb .srt-base-1-is-active-home-3 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #8DC63F;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-away-3 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #1E1E1E;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-home-4 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #add577;
  border-color: #add577;
}

.sr-bb .srt-base-1-is-active-away-4 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #606060;
  border-color: #606060;
}

.sr-bb .srt-base-1-is-active-home-5 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #5c8129;
  border-color: #5c8129;
}

.sr-bb .srt-base-1-is-active-away-5 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #141414;
  border-color: #141414;
}

.sr-bb .srt-base-1-is-active-primary-1 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-is-active-primary-2 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #638b2c;
  border-color: #638b2c;
}

.sr-bb .srt-base-1-is-active-primary-3 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #7fb239;
  border-color: #7fb239;
}

.sr-bb .srt-base-1-is-active-primary-4 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #b8da89;
  border-color: #b8da89;
}

.sr-bb .srt-base-1-is-active-primary-5 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #b8da89;
  border-color: #b8da89;
}

.sr-bb .srt-base-1-is-active-primary-6 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #527325;
  border-color: #527325;
}

.sr-bb .srt-base-1-is-active-primary-7 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-is-active-primary-8 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #8DC63F;
  border-color: #8DC63F;
}

.sr-bb .srt-base-1-is-active-primary-9 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #d9eac1;
  border-color: #d9eac1;
}

.sr-bb .srt-base-1-is-active-primary-10 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #a3d064;
  border-color: #a3d064;
}

.sr-bb .srt-base-1-is-active-primary-11 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #7fb239;
  border-color: #7fb239;
}

.sr-bb .srt-base-1-is-active-neutral-1 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #d9d9d9;
  border-color: #d9d9d9;
}

.sr-bb .srt-base-1-is-active-neutral-2 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #191919;
  border-color: #191919;
}

.sr-bb .srt-base-1-is-active-neutral-3 {
  background-color: rgba(141, 198, 63, 0.12);
  color: rgba(0, 0, 0, 0.12);
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-base-1-is-active-neutral-4 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #575757;
  border-color: #575757;
}

.sr-bb .srt-base-1-is-active-neutral-5 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #3e3e3e;
  border-color: #3e3e3e;
}

.sr-bb .srt-base-1-is-active-neutral-6 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #bdbdbd;
  border-color: #bdbdbd;
}

.sr-bb .srt-base-1-is-active-neutral-7 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #575757;
  border-color: #575757;
}

.sr-bb .srt-base-1-is-active-neutral-8 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #d9d9d9;
  border-color: #d9d9d9;
}

.sr-bb .srt-base-1-is-active-neutral-9 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #eaeaea;
  border-color: #eaeaea;
}

.sr-bb .srt-base-1-is-active-neutral-10 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #dbdbdb;
  border-color: #dbdbdb;
}

.sr-bb .srt-base-1-is-active-neutral-11 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #959595;
  border-color: #959595;
}

.sr-bb .srt-base-1-is-active-neutral-12 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #4b4b4b;
  border-color: #4b4b4b;
}

.sr-bb .srt-base-1-is-active-neutral-13 {
  background-color: rgba(141, 198, 63, 0.12);
  color: #e0e0e0;
  border-color: #e0e0e0;
}

.sr-bb .srt-home-1 {
  background-color: #8DC63F;
  color: #000000;
  border-color: #8DC63F;
}

.sr-bb .srt-away-1 {
  background-color: #1E1E1E;
  color: #ffffff;
  border-color: #1E1E1E;
}

.sr-bb .srt-home-2 {
  background-color: #8DC63F;
  color: #000000;
  border-color: #527325;
}

.sr-bb .srt-away-2 {
  background-color: #1E1E1E;
  color: #ffffff;
  border-color: #527325;
}

.sr-bb .srt-home-3 {
  background-color: #8DC63F;
  color: #000000;
  border-color: #F9F9F9;
}

.sr-bb .srt-away-3 {
  background-color: #1E1E1E;
  color: #ffffff;
  border-color: #F9F9F9;
}

.sr-bb .srt-home-4 {
  background-color: #add577;
  color: #000000;
  border-color: #add577;
}

.sr-bb .srt-away-4 {
  background-color: #606060;
  color: #ffffff;
  border-color: #606060;
}

.sr-bb .srt-home-5 {
  background-color: #5c8129;
  color: #000000;
  border-color: #5c8129;
}

.sr-bb .srt-away-5 {
  background-color: #141414;
  color: #ffffff;
  border-color: #141414;
}

.sr-bb .srt-home-6 {
  background-color: rgba(141, 198, 63, 0.2);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-away-6 {
  background-color: rgba(30, 30, 30, 0.2);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-home-6-is-hoverable:hover {
  background-color: rgba(141, 198, 63, 0.2);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-away-6-is-hoverable:hover {
  background-color: rgba(30, 30, 30, 0.2);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-1 {
  background-color: #d9d9d9;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-2 {
  background-color: #191919;
  color: #ffffff;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-neutral-3 {
  background-color: rgba(0, 0, 0, 0.12);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-4 {
  background-color: #575757;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-5 {
  background-color: #3e3e3e;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-6 {
  background-color: #bdbdbd;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-7 {
  background-color: #575757;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-8 {
  background-color: #d9d9d9;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-9 {
  background-color: #eaeaea;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-10 {
  background-color: #dbdbdb;
  color: rgba(0, 0, 0, 0.82);
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-11 {
  background-color: #959595;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-neutral-12 {
  background-color: #4b4b4b;
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.16);
}

.sr-bb .srt-neutral-13 {
  background-color: #e0e0e0;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-win {
  background-color: #8DC63F;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-draw {
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-lose {
  background-color: #1E1E1E;
  color: #ffffff;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-text-secondary {
  opacity: 0.7;
  -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-text-disabled {
  opacity: 0.35;
}

.sr-bb .srt-text-tertiary {
  opacity: 0.54;
}

.sr-bb .srt-primary-1 .srt-text-secondary, .sr-bb .srt-primary-1.srt-text-secondary,
.sr-bb .srt-primary-1-is-active .srt-text-secondary,
.sr-bb .srt-primary-1-is-active.srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled .srt-text-secondary,
.sr-bb .srt-primary-1-is-disabled.srt-text-secondary,
.sr-bb .srt-primary-2 .srt-text-secondary,
.sr-bb .srt-primary-2.srt-text-secondary,
.sr-bb .srt-primary-3 .srt-text-secondary,
.sr-bb .srt-primary-3.srt-text-secondary,
.sr-bb .srt-primary-4 .srt-text-secondary,
.sr-bb .srt-primary-4.srt-text-secondary,
.sr-bb .srt-primary-5 .srt-text-secondary,
.sr-bb .srt-primary-5.srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-secondary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-secondary,
.sr-bb .srt-primary-6 .srt-text-secondary,
.sr-bb .srt-primary-6.srt-text-secondary,
.sr-bb .srt-primary-7 .srt-text-secondary,
.sr-bb .srt-primary-7.srt-text-secondary,
.sr-bb .srt-primary-8 .srt-text-secondary,
.sr-bb .srt-primary-8.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-1.srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-secondary,
.sr-bb .srt-primary-8-is-active-2.srt-text-secondary,
.sr-bb .srt-primary-9 .srt-text-secondary,
.sr-bb .srt-primary-9.srt-text-secondary,
.sr-bb .srt-primary-10 .srt-text-secondary,
.sr-bb .srt-primary-10.srt-text-secondary,
.sr-bb .srt-primary-11 .srt-text-secondary,
.sr-bb .srt-primary-11.srt-text-secondary,
.sr-bb .srt-primary-12 .srt-text-secondary,
.sr-bb .srt-primary-12.srt-text-secondary,
.sr-bb .srt-primary-13 .srt-text-secondary,
.sr-bb .srt-primary-13.srt-text-secondary {
  opacity: 0.7;
  -webkit-font-smoothing: antialiased;
}

.sr-bb .srt-primary-1 .srt-text-disabled, .sr-bb .srt-primary-1.srt-text-disabled,
.sr-bb .srt-primary-1-is-active .srt-text-disabled,
.sr-bb .srt-primary-1-is-active.srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled .srt-text-disabled,
.sr-bb .srt-primary-1-is-disabled.srt-text-disabled,
.sr-bb .srt-primary-2 .srt-text-disabled,
.sr-bb .srt-primary-2.srt-text-disabled,
.sr-bb .srt-primary-3 .srt-text-disabled,
.sr-bb .srt-primary-3.srt-text-disabled,
.sr-bb .srt-primary-4 .srt-text-disabled,
.sr-bb .srt-primary-4.srt-text-disabled,
.sr-bb .srt-primary-5 .srt-text-disabled,
.sr-bb .srt-primary-5.srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-disabled,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-disabled,
.sr-bb .srt-primary-6 .srt-text-disabled,
.sr-bb .srt-primary-6.srt-text-disabled,
.sr-bb .srt-primary-7 .srt-text-disabled,
.sr-bb .srt-primary-7.srt-text-disabled,
.sr-bb .srt-primary-8 .srt-text-disabled,
.sr-bb .srt-primary-8.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-1.srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2 .srt-text-disabled,
.sr-bb .srt-primary-8-is-active-2.srt-text-disabled,
.sr-bb .srt-primary-9 .srt-text-disabled,
.sr-bb .srt-primary-9.srt-text-disabled,
.sr-bb .srt-primary-10 .srt-text-disabled,
.sr-bb .srt-primary-10.srt-text-disabled,
.sr-bb .srt-primary-11 .srt-text-disabled,
.sr-bb .srt-primary-11.srt-text-disabled,
.sr-bb .srt-primary-12 .srt-text-disabled,
.sr-bb .srt-primary-12.srt-text-disabled,
.sr-bb .srt-primary-13 .srt-text-disabled,
.sr-bb .srt-primary-13.srt-text-disabled {
  opacity: 0.35;
}

.sr-bb .srt-primary-1 .srt-text-tertiary, .sr-bb .srt-primary-1.srt-text-tertiary,
.sr-bb .srt-primary-1-is-active .srt-text-tertiary,
.sr-bb .srt-primary-1-is-active.srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-1-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled .srt-text-tertiary,
.sr-bb .srt-primary-1-is-disabled.srt-text-tertiary,
.sr-bb .srt-primary-2 .srt-text-tertiary,
.sr-bb .srt-primary-2.srt-text-tertiary,
.sr-bb .srt-primary-3 .srt-text-tertiary,
.sr-bb .srt-primary-3.srt-text-tertiary,
.sr-bb .srt-primary-4 .srt-text-tertiary,
.sr-bb .srt-primary-4.srt-text-tertiary,
.sr-bb .srt-primary-5 .srt-text-tertiary,
.sr-bb .srt-primary-5.srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover .srt-text-tertiary,
.sr-bb .srt-primary-5-is-hoverable:hover.srt-text-tertiary,
.sr-bb .srt-primary-6 .srt-text-tertiary,
.sr-bb .srt-primary-6.srt-text-tertiary,
.sr-bb .srt-primary-7 .srt-text-tertiary,
.sr-bb .srt-primary-7.srt-text-tertiary,
.sr-bb .srt-primary-8 .srt-text-tertiary,
.sr-bb .srt-primary-8.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-1.srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2 .srt-text-tertiary,
.sr-bb .srt-primary-8-is-active-2.srt-text-tertiary,
.sr-bb .srt-primary-9 .srt-text-tertiary,
.sr-bb .srt-primary-9.srt-text-tertiary,
.sr-bb .srt-primary-10 .srt-text-tertiary,
.sr-bb .srt-primary-10.srt-text-tertiary,
.sr-bb .srt-primary-11 .srt-text-tertiary,
.sr-bb .srt-primary-11.srt-text-tertiary,
.sr-bb .srt-primary-12 .srt-text-tertiary,
.sr-bb .srt-primary-12.srt-text-tertiary,
.sr-bb .srt-primary-13 .srt-text-tertiary,
.sr-bb .srt-primary-13.srt-text-tertiary {
  opacity: 0.54;
}

.sr-bb .srt-icon {
  opacity: 0.33;
}

.sr-bb .srt-icon-secondary {
  opacity: 0.7;
}

.sr-bb .srt-elevation-1 {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}

.sr-bb .srt-elevation-2 {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.23), 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-elevation-3 {
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.26), 0px 10px 20px 0px rgba(0, 0, 0, 0.19);
}

.sr-bb .srt-elevation-center-2 {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.23), 0 1px 5px rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-inset-top-1 {
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-1 {
  box-shadow: inset 0 -1px 2px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-2 {
  box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-2 {
  box-shadow: inset 0 -3px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-top-3 {
  box-shadow: inset 0 6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-inset-bottom-3 {
  box-shadow: inset 0 -6px 6px 0 rgba(0, 0, 0, 0.23);
}

.sr-bb .srt-fill-blue-card {
  fill: #2579ad;
}

.sr-bb .srt-stroke-blue-card {
  stroke: #2579ad;
}

.sr-bb .srt-fill-green-card {
  fill: #2cbd00;
}

.sr-bb .srt-stroke-green-card {
  stroke: #2cbd00;
}

.sr-bb .srt-fill-soccer-yellow-card {
  fill: #ffbf00;
}

.sr-bb .srt-stroke-soccer-yellow-card {
  stroke: #ffbf00;
}

.sr-bb .srt-fill-soccer-red-card {
  fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-red-card {
  stroke: #e43b3b;
}

.sr-bb .srt-stroke-soccer-substitution-in {
  stroke: #4fbe30;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-in {
  fill: #4fbe30;
}

.sr-bb .srt-stroke-soccer-substitution-out {
  stroke: #e43b3b;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-substitution-out {
  fill: #e43b3b;
}

.sr-bb .srt-stroke-soccer-own-goal {
  stroke: #e43b3b;
  fill: transparent;
}

.sr-bb .srt-fill-soccer-own-goal {
  fill: #e43b3b;
}

.sr-bb .srt-fill-soccer-relegation-1 {
  fill: #fdd835;
}

.sr-bb .srt-stroke-soccer-relegation-1 {
  stroke: #fdd835;
}

.sr-bb .srt-fill-soccer-relegation-2 {
  fill: #ffb848;
}

.sr-bb .srt-stroke-soccer-relegation-2 {
  stroke: #ffb848;
}

.sr-bb .srt-fill-soccer-relegation-3 {
  fill: #ef6c00;
}

.sr-bb .srt-stroke-soccer-relegation-3 {
  stroke: #ef6c00;
}

.sr-bb .srt-fill-soccer-relegation-4 {
  fill: #e93a34;
}

.sr-bb .srt-stroke-soccer-relegation-4 {
  stroke: #e93a34;
}

.sr-bb .srt-fill-soccer-relegation-5 {
  fill: #941d1d;
}

.sr-bb .srt-stroke-soccer-relegation-5 {
  stroke: #941d1d;
}

.sr-bb .srt-fill-soccer-promotion-1 {
  fill: #51d151;
}

.sr-bb .srt-stroke-soccer-promotion-1 {
  stroke: #51d151;
}

.sr-bb .srt-fill-soccer-promotion-2 {
  fill: #1b911b;
}

.sr-bb .srt-stroke-soccer-promotion-2 {
  stroke: #1b911b;
}

.sr-bb .srt-fill-soccer-promotion-3 {
  fill: #0e8094;
}

.sr-bb .srt-stroke-soccer-promotion-3 {
  stroke: #0e8094;
}

.sr-bb .srt-fill-soccer-promotion-4 {
  fill: #0a6cce;
}

.sr-bb .srt-stroke-soccer-promotion-4 {
  stroke: #0a6cce;
}

.sr-bb .srt-fill-soccer-promotion-5 {
  fill: #4a9fe4;
}

.sr-bb .srt-stroke-soccer-promotion-5 {
  stroke: #4a9fe4;
}

.sr-bb .srt-nfl-timeout-1 {
  background-color: #f5a623;
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-nfl-timeout-2 {
  background-color: rgba(245, 166, 35, 0.3);
  color: #000000;
  border-color: rgba(0, 0, 0, 0.16);
}

.sr-bb .srt-nfl-penalty {
  background-color: #f4a621;
  color: #000000;
  border-color: #f4a621;
}

.sr-bb .srt-nfl-negative-yards-1 {
  background-color: #ac182e;
  color: #000000;
  border-color: #ac182e;
}

.sr-bb .srt-nfl-negative-yards-2 {
  background-color: #ac182e;
  color: #000000;
  border-color: #ac182e;
}

.sr-bb .srt-nfl-first-line {
  background-color: #4b90de;
  color: #000000;
  border-color: #4b90de;
}

.sr-bb .srt-nfl-ten-line {
  background-color: #f5a623;
  color: #000000;
  border-color: #f5a623;
}

.sr-bb .srt-fill-nfl-penalty {
  fill: #f4a621;
}

.sr-bb .srt-stroke-nfl-penalty {
  stroke: #f4a621;
}

.sr-bb .srt-fill-nfl-negative-yards-1 {
  fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-1 {
  stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-negative-yards-2 {
  fill: #ac182e;
}

.sr-bb .srt-stroke-nfl-negative-yards-2 {
  stroke: #ac182e;
}

.sr-bb .srt-fill-nfl-first-line {
  fill: #4b90de;
}

.sr-bb .srt-stroke-nfl-first-line {
  stroke: #4b90de;
}

.sr-bb .srt-fill-nfl-ten-line {
  fill: #f5a623;
}

.sr-bb .srt-stroke-nfl-ten-line {
  stroke: #f5a623;
}

.sr-bb .srt-mlb-run-1 {
  background-color: #009900;
  color: #ffffff;
  border-color: #009900;
}

.sr-bb .srt-mlb-run-2 {
  background-color: #ffffff;
  color: #009900;
  border-color: #009900;
}

.sr-bb .srt-mlb-hit-1 {
  background-color: #f7ab11;
  color: #000000;
  border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-2 {
  background-color: #ffffff;
  color: #f7ab11;
  border-color: #f7ab11;
}

.sr-bb .srt-mlb-hit-3 {
  background-color: #00a4ff;
  color: #ffffff;
  border-color: #00a4ff;
}

.sr-bb .srt-mlb-error-1 {
  background-color: #d0021b;
  color: #ffffff;
  border-color: #d0021b;
}

.sr-bb .srt-mlb-error-2 {
  background-color: #ffffff;
  color: #d0021b;
  border-color: #d0021b;
}

.sr-bb .srt-mlb-neutral-1 {
  background-color: #212121;
  color: #ffffff;
  border-color: #ffffff;
}

.sr-bb .srt-fill-mlb-base {
  fill: #00a4ff;
}

.sr-bb .srt-strole-mlb-base {
  fill: #00a4ff;
}

.sr-bb .srt-fill-change-increase {
  fill: #8DC63F;
}

.sr-bb .srt-stroke-change-increase {
  stroke: #8DC63F;
}

.sr-bb .srt-fill-change-decrease {
  fill: #1E1E1E;
}

.sr-bb .srt-stroke-change-decrease {
  stroke: #1E1E1E;
}

.sr-bb .srt-fill-text {
  fill: #000000;
}

.sr-bb .srt-fill-text-invert {
  fill: #000000;
}

.sr-bb .srt-fill-text-secondary {
  fill-opacity: 0.7;
}

.sr-bb .srt-fill-text-disabled {
  fill-opacity: 0.35;
}

.sr-bb .srt-fill-base-1 {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1 {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-active {
  fill: rgba(141, 198, 63, 0.12);
}

.sr-bb .srt-stroke-base-1-active {
  stroke: rgba(141, 198, 63, 0.12);
}

.sr-bb .srt-fill-base-1-active-2 {
  fill: #e0e0e0;
}

.sr-bb .srt-stroke-base-1-active-2 {
  stroke: #e0e0e0;
}

.sr-bb .srt-fill-base-1-primary {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-primary {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-home {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-home {
  stroke: transparent;
}

.sr-bb .srt-fill-base-1-away {
  fill: transparent;
}

.sr-bb .srt-stroke-base-1-away {
  stroke: transparent;
}

.sr-bb .srt-fill-base-2 {
  fill: currentColor;
}

.sr-bb .srt-stroke-base-2 {
  stroke: currentColor;
}

.sr-bb .srt-fill-base-2-active {
  fill: #ecf3e3;
}

.sr-bb .srt-stroke-base-2-active {
  stroke: #ecf3e3;
}

.sr-bb .srt-fill-base-2-hover {
  fill: #ecf3e3;
}

.sr-bb .srt-stroke-base-2-hover {
  stroke: #ecf3e3;
}

.sr-bb .srt-fill-base-3 {
  fill: #ecf3e3;
}

.sr-bb .srt-stroke-base-3 {
  stroke: #ecf3e3;
}

.sr-bb .srt-fill-base-3-active {
  fill: #e7f0d9;
}

.sr-bb .srt-stroke-base-3-active {
  stroke: #e7f0d9;
}

.sr-bb .srt-fill-base-3-hover {
  fill: #e7f0d9;
}

.sr-bb .srt-stroke-base-3-hover {
  stroke: #e7f0d9;
}

.sr-bb .srt-fill-primary-1 {
  fill: #8DC63F;
}

.sr-bb .srt-stroke-primary-1 {
  stroke: #8DC63F;
}

.sr-bb .srt-fill-primary-2 {
  fill: #638b2c;
}

.sr-bb .srt-stroke-primary-2 {
  stroke: #638b2c;
}

.sr-bb .srt-fill-primary-3 {
  fill: #7fb239;
}

.sr-bb .srt-stroke-primary-3 {
  stroke: #7fb239;
}

.sr-bb .srt-fill-primary-4 {
  fill: #b8da89;
}

.sr-bb .srt-stroke-primary-4 {
  stroke: #b8da89;
}

.sr-bb .srt-fill-primary-5 {
  fill: #b8da89;
}

.sr-bb .srt-stroke-primary-5 {
  stroke: #b8da89;
}

.sr-bb .srt-fill-primary-6 {
  fill: #527325;
}

.sr-bb .srt-stroke-primary-6 {
  stroke: #527325;
}

.sr-bb .srt-fill-primary-7 {
  fill: #8DC63F;
}

.sr-bb .srt-stroke-primary-7 {
  stroke: #8DC63F;
}

.sr-bb .srt-fill-primary-8 {
  fill: #8DC63F;
}

.sr-bb .srt-stroke-primary-8 {
  stroke: #8DC63F;
}

.sr-bb .srt-fill-primary-8-is-active-1 {
  fill: #7fb239;
}

.sr-bb .srt-stroke-primary-8-is-active-1 {
  stroke: #7fb239;
}

.sr-bb .srt-fill-primary-8-is-active-2 {
  fill: #7fb239;
}

.sr-bb .srt-stroke-primary-8-is-active-2 {
  stroke: #7fb239;
}

.sr-bb .srt-fill-primary-9 {
  fill: #d9eac1;
}

.sr-bb .srt-stroke-primary-9 {
  stroke: #d9eac1;
}

.sr-bb .srt-fill-primary-10 {
  fill: #a3d064;
}

.sr-bb .srt-stroke-primary-10 {
  stroke: #a3d064;
}

.sr-bb .srt-fill-primary-11 {
  fill: #7fb239;
}

.sr-bb .srt-stroke-primary-11 {
  stroke: #7fb239;
}

.sr-bb .srt-fill-primary-12 {
  fill: #8DC63F;
}

.sr-bb .srt-stroke-primary-12 {
  stroke: #8DC63F;
}

.sr-bb .srt-fill-home-1 {
  fill: #8DC63F;
}

.sr-bb .srt-stroke-home-1 {
  stroke: #8DC63F;
}

.sr-bb .srt-fill-home-2 {
  fill: #8DC63F;
}

.sr-bb .srt-stroke-home-2 {
  stroke: #8DC63F;
}

.sr-bb .srt-fill-home-3 {
  fill: #8DC63F;
}

.sr-bb .srt-stroke-home-3 {
  stroke: #8DC63F;
}

.sr-bb .srt-fill-home-4 {
  fill: #add577;
}

.sr-bb .srt-stroke-home-4 {
  stroke: #add577;
}

.sr-bb .srt-fill-home-5 {
  fill: #5c8129;
}

.sr-bb .srt-stroke-home-5 {
  stroke: #5c8129;
}

.sr-bb .srt-fill-away-1 {
  fill: #1E1E1E;
}

.sr-bb .srt-stroke-away-1 {
  stroke: #1E1E1E;
}

.sr-bb .srt-fill-away-2 {
  fill: #1E1E1E;
}

.sr-bb .srt-stroke-away-2 {
  stroke: #1E1E1E;
}

.sr-bb .srt-fill-away-3 {
  fill: #1E1E1E;
}

.sr-bb .srt-stroke-away-3 {
  stroke: #1E1E1E;
}

.sr-bb .srt-fill-away-4 {
  fill: #606060;
}

.sr-bb .srt-stroke-away-4 {
  stroke: #606060;
}

.sr-bb .srt-fill-away-5 {
  fill: #141414;
}

.sr-bb .srt-stroke-away-5 {
  stroke: #141414;
}

.sr-bb .srt-fill-neutral-1 {
  fill: #d9d9d9;
}

.sr-bb .srt-stroke-neutral-1 {
  stroke: #d9d9d9;
}

.sr-bb .srt-fill-neutral-2 {
  fill: #191919;
}

.sr-bb .srt-stroke-neutral-2 {
  stroke: #191919;
}

.sr-bb .srt-fill-neutral-3 {
  fill: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-stroke-neutral-3 {
  stroke: rgba(0, 0, 0, 0.12);
}

.sr-bb .srt-fill-neutral-4 {
  fill: #575757;
}

.sr-bb .srt-stroke-neutral-4 {
  stroke: #575757;
}

.sr-bb .srt-fill-neutral-5 {
  fill: #3e3e3e;
}

.sr-bb .srt-stroke-neutral-5 {
  stroke: #3e3e3e;
}

.sr-bb .srt-fill-neutral-6 {
  fill: #bdbdbd;
}

.sr-bb .srt-stroke-neutral-6 {
  stroke: #bdbdbd;
}

.sr-bb .srt-fill-neutral-7 {
  fill: #575757;
}

.sr-bb .srt-stroke-neutral-7 {
  stroke: #575757;
}

.sr-bb .srt-fill-neutral-8 {
  fill: #d9d9d9;
}

.sr-bb .srt-stroke-neutral-8 {
  stroke: #d9d9d9;
}

.sr-bb .srt-fill-neutral-9 {
  fill: #eaeaea;
}

.sr-bb .srt-stroke-neutral-9 {
  stroke: #eaeaea;
}

.sr-bb .srt-fill-neutral-10 {
  fill: #dbdbdb;
}

.sr-bb .srt-stroke-neutral-10 {
  stroke: #dbdbdb;
}

.sr-bb .srt-fill-neutral-11 {
  fill: #959595;
}

.sr-bb .srt-stroke-neutral-11 {
  stroke: #959595;
}

.sr-bb .srt-fill-neutral-12 {
  fill: #4b4b4b;
}

.sr-bb .srt-stroke-neutral-12 {
  stroke: #4b4b4b;
}

.sr-bb .srt-fill-neutral-13 {
  fill: #e0e0e0;
}

.sr-bb .srt-stroke-neutral-13 {
  stroke: #e0e0e0;
}

.sr-bb .srt-fill-win {
  fill: #8DC63F;
}

.sr-bb .srt-stroke-win {
  stroke: #8DC63F;
}

.sr-bb .srt-fill-draw {
  fill: rgba(0, 0, 0, 0.4);
}

.sr-bb .srt-stroke-draw {
  stroke: rgba(0, 0, 0, 0.4);
}

.sr-bb .srt-fill-lose {
  fill: #1E1E1E;
}

.sr-bb .srt-stroke-lose {
  stroke: #1E1E1E;
}

.sr-bb .srt-stop-base-1 {
  stop-color: transparent;
}

.sr-bb .srt-stop-primary-1 {
  stop-color: #8DC63F;
}

.sr-bb .srt-stop-primary-2 {
  stop-color: #638b2c;
}

.sr-bb .srt-stop-primary-3 {
  stop-color: #7fb239;
}

.sr-bb .srt-stop-primary-4 {
  stop-color: #b8da89;
}

.sr-bb .srt-stop-primary-5 {
  stop-color: #b8da89;
}

.sr-bb .srt-stop-primary-6 {
  stop-color: #527325;
}

.sr-bb .srt-stop-primary-7 {
  stop-color: #8DC63F;
}

.sr-bb .srt-stop-primary-8 {
  stop-color: #8DC63F;
}

.sr-bb .srt-stop-primary-9 {
  stop-color: #d9eac1;
}

.sr-bb .srt-stop-primary-10 {
  stop-color: #a3d064;
}

.sr-bb .srt-stop-primary-11 {
  stop-color: #7fb239;
}

.sr-bb .srt-stop-primary-12 {
  stop-color: #8DC63F;
}

.sr-bb .srt-stop-home-1 {
  stop-color: #8DC63F;
}

.sr-bb .srt-stop-away-1 {
  stop-color: #1E1E1E;
}

.sr-bb .srt-fill-neutral-14 {
  fill: #F9F9F9;
}

.sr-bb .srt-stroke-neutral-14 {
  stroke: #F9F9F9;
}

.sr-bb .srt-logo-powered-by-light {
  display: inline-block;
}

.sr-bb .srt-logo-powered-by-dark {
  display: none;
}`,
        },
        isPresto: deviceType.isPresto(),
    }),
    computed: {
        ...mapGetters({
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            srJWT: sport.getter.GET_SPORT_RADAR_JWT,
            brandPreference: getter.GET_BRAND_PREFERENCE,
            user: getter.GET_USER_SETTINGS,
        }),
        widgetId() {
            return `sr-widget-${helper.createUUID()}`;
        },
        isSRStreamEnabled() {
            const { SRVideoEnabled } = this.brandPreference;
            const { userUuid } = this.user;
            return SRVideoEnabled === true || SRVideoEnabled === userUuid;
        },
        srOptions() {
            const { SRStreamToggle, SRDisableTitle, SRActiveStreamToggle, SRDemoStream } = this.brandPreference;

            if (this.isAuthenticated && this.srJWT && this.isSRStreamEnabled) {
                const newOptions = {
                    ...this.options,
                    jwt: this.srJWT,
                    streamToggle: SRStreamToggle || 'onPitchButton',
                };
                if (typeof SRDisableTitle !== 'undefined') {
                    newOptions.disableStreamSwitchTabsTitle = SRDisableTitle;
                }
                if (typeof SRActiveStreamToggle !== 'undefined') {
                    newOptions.activeStreamToggle = SRActiveStreamToggle;
                }
                if (typeof SRDemoStream !== 'undefined') {
                    newOptions.demoStream = SRDemoStream;
                }
                return newOptions;
            }
            return this.options;
        },
    },
    watch: {
        options(value) {
            const { theme } = value;
            this.setTheme(theme);
            this.$srwidget.update(this.matchId, this.name, this.srOptions, `#${this.widgetId}`);
        },
    },
    mounted() {
        const { theme } = this.options;

        new Promise((resolve) => {
            if (this.isAuthenticated && this.name === 'match.lmtPlus') {
                this.$store.dispatch(sport.action.AUTHORIZE_SPORT_RADAR).finally(() => {
                    resolve();
                });
            } else {
                resolve();
            }
        }).then(() => {
            this.$srwidget.add(this.matchId, this.name, this.srOptions, `#${this.widgetId}`, 'addWidget', false, () => {
                document.addEventListener('click', this.collapseFooterClick);
                if (this.onInit) {
                    this.onInit();
                }
            });
        });

        this.setTheme(theme);
    },
    beforeDestroy() {
        this.$srwidget.remove(`#${this.widgetId}`);
        document.removeEventListener('click', this.collapseFooterClick);
    },
    methods: {
        setTheme(name) {
            const css = this.themes[name] || this.themes.dark;
            if (!css) {
                alert('Please add your css first.');
                return;
            }
            const setAttr = (el, theme) => {
                el.setAttribute('href', 'data:text/css;charset=UTF-8,' + encodeURIComponent(theme));
            };
            const linkStyleID = 'custom-sr-theme';
            let element = document.getElementById(linkStyleID);
            if (element) {
                setAttr(element, css);
            } else {
                const link = document.createElement('link');
                link.setAttribute('rel', 'stylesheet');
                link.setAttribute('id', linkStyleID);
                link.setAttribute('type', 'text/css');
                setAttr(link, css);
                document.head.insertBefore(link, document.head.lastChild);
            }
        },
        collapseFooterClick(e) {
            const el = e.target.closest(`#${this.widgetId} .sr-lmt-plus__expand-wrapper`);
            if (el) {
                this.$emit('onFooterClick', this.widgetId);
            }
        },
    },
};
</script>

<style scoped lang="scss">
#sr-widget svg.sr-error__icon.srt-icon.sr-error-icon-license.srm-is-medium {
    fill: magenta;
}

.sr-widget {
    position: relative;

    &-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: transparent;
        opacity: 0;
        cursor: pointer;
    }
}
</style>
