export const badgeType = {
    DEFAULT: 'default',
    WIN: 'win',
    LOSE: 'lose',
    CANCELLED: 'cancelled',
    PENDING: 'pending',
    PENDING_DARK: 'pending-dark',
    NEW_MESSAGES: 'new-messages',
    LOCKED: 'locked',
    NEW: 'new',
    BEST_ODDS: 'best-odds',
};
