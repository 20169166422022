<template>
    <table :class="{ 'no-border': data.attrs.class === 'no-border' }">
        <TableColgroup :data="data.content" />
        <tbody>
            <tr v-for="(row, index) in data.content" :key="index">
                <TableCell v-for="(cell, index) in row.content" :key="index" :data="cell" />
            </tr>
        </tbody>
    </table>
</template>
<script>
import TableCell from './TableCell';
import TableColgroup from './TableColgroup';

export default {
    name: 'Table',
    components: { TableCell, TableColgroup },
    props: ['data'],
};
</script>
<style lang="scss" scoped>
table {
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
    table-layout: fixed;

    ::v-deep {
        .presto & {
            border-collapse: separate;
            border-spacing: 0px;
        }
    }

    th,
    td {
        padding: 8px 12px;
        border: 1px solid black;
    }
}

.no-border {
    border: none;

    table,
    tbody,
    th,
    tr,
    td {
        border: none;
    }
}
</style>
