<template>
    <div class="banner">
        <ResponsiveImage :background="images" class="banner-image">
            <h2 class="bigger-heading">{{ $t('project.content.banner.mtnAirTimeBanner.line1') }}</h2>
            <h2>{{ $t('project.content.banner.mtnAirTimeBanner.line2') }}</h2>
            <h3>{{ $t('project.content.banner.mtnAirTimeBanner.line3') }}</h3>
            <div class="banner-description">{{ $t('project.content.banner.mtnAirTimeBanner.description') }}</div>
            <router-link to="signup?r=/airtime-deposit" class="button button-primary">
                {{ $t('project.content.banner.mtnAirTimeBanner.button') }}
            </router-link>
        </ResponsiveImage>
    </div>
</template>
<script>
import mtnBgSmall from '@/assets/images/banners/mtn_bg_small.jpg';
import mtnBgMedium from '@/assets/images/banners/mtn_bg_medium.jpg';
import mtnBgLarge from '@/assets/images/banners/mtn_bg_large.jpg';

const ResponsiveImage = () => import('@/components/ResponsiveImage.vue');

export default {
    name: 'Mtnairtimebanner',
    components: { ResponsiveImage },
    props: {
        data: Object,
    },
    data() {
        return {
            images: [
                {
                    url: mtnBgSmall,
                    width: 480,
                },
                {
                    url: mtnBgMedium,
                    width: 767,
                },
                {
                    url: mtnBgLarge,
                    width: 990,
                },
            ],
        };
    },
};
</script>

<style scoped lang="scss">
.banner {
    @include only_mini {
        // A workaround for https://aliengain.atlassian.net/browse/BP-11914.
        // The overflow gets hidden by .center-view
        margin: 0 -20px 0 -22px;
    }

    h2,
    h3 {
        margin: 0 0 2px 0;
    }

    h2 {
        font-size: 1.3rem;

        .bigger-heading {
            font-size: 1.6rem;
        }
    }

    h3 {
        font-size: 376%;
    }

    .button {
        margin-top: 10px;
    }

    &-image {
        transition: background-image 0.05s ease;
        padding: 10px;
        color: white;
        text-align: center;
    }
}
</style>
