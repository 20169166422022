<template>
    <div>
        <div v-if="rounds.length && upcomingRound && !invalidDeviceTime">
            <VirtualStickyHeader
                v-if="seasonId && stickyHeaderEnabled"
                :season-title="seasonId && $t('ui.virtualSports.seasonId', { seasonId })"
            />
            <Headline
                v-else-if="seasonId"
                :title="$t('ui.virtualSports.seasonId', { seasonId })"
                class="text-mid upperCase virtual-sports-headline"
                :is-small-font="true"
            />
            <Tabs
                v-if="liveRound && upcomingRound"
                :tabs="Object.values(sections)"
                :active="activeSection"
                class="virtual-tabs"
                @select="setCurrentTab"
            >
                <template #tab-suffix="{ tab }">
                    <span v-if="tab.key === 'live'" :class="['tab-badge', 'live-label', { active: activeSection.key === 'live' }]">{{
                        $t('ui.virtualSports.live')
                    }}</span>
                    <span v-else-if="tab.key === 'upcoming'" class="tab-badge">{{ getBettingCloseTime() }}</span>
                </template>
                <Tab name="live">
                    <div v-if="!isPresto && !isLoadingLiveRound" class="score-refresh">
                        <div class="table">
                            <div class="row-cell align-middle">
                                <span class="game-minute">{{ liveEvents.gameMinute }}'</span>
                                <span class="game-status">{{ liveRoundLabel }}</span>
                            </div>
                            <Countdown
                                v-if="!liveEvents.finished"
                                class="row-cell align-middle live-countdown"
                                :size-of-icon="2"
                                :start="startCounter"
                                @update="fetchEvents"
                            />
                        </div>
                    </div>
                    <div v-for="league in liveEvents.inPlayLeagues" :key="activeSection.key + league.league.name" class="separator-wrapper">
                        <div class="separator" @click="toggleLeagueById(league.league.id)">
                            <div class="league-name">
                                <ImageIcon
                                    class="icon-region icon-size-small"
                                    :src="`img/flags/${virtualLeagueMap[league.league.id]}.png`"
                                />
                                <span class="league-name-text">{{ league.league.name }}</span>
                            </div>
                            <SvgIcon
                                class="icon-arrow icon-size-small"
                                :icon-id="showLeague(league.league.id) ? 'arrow_down' : 'arrow_right'"
                            />
                        </div>
                        <transition name="slide">
                            <div v-show="showLeague(league.league.id)">
                                <VirtualGame
                                    v-for="event in league.matches"
                                    :key="league.league.name + event.id"
                                    :event="event"
                                    :season="currentSeason"
                                    :market-group-to-show="currentMarketGroup.key"
                                    :live="true"
                                    :show-h-t-score="showHTScore(event)"
                                />
                            </div>
                        </transition>
                    </div>
                </Tab>
                <Tab name="upcoming">
                    <Tabs :tabs="marketGroups" :active="currentMarketGroup" tab-type="text" bottom-border @select="setCurrentMarketGroup">
                        <div v-for="league in upcomingEvents" :key="activeSection.key + league.league.name" class="separator-wrapper">
                            <div class="separator" @click="toggleLeagueById(league.league.id)">
                                <div class="league-name">
                                    <ImageIcon
                                        class="icon-region icon-size-small"
                                        :src="`img/flags/${virtualLeagueMap[league.league.id]}.png`"
                                    />
                                    <span class="league-name-text">{{ league.league.name }}</span>
                                </div>
                                <SvgIcon
                                    class="icon-arrow icon-size-small"
                                    :icon-id="showLeague(league.league.id) ? 'arrow_down' : 'arrow_right'"
                                />
                            </div>
                            <transition name="slide">
                                <div v-show="showLeague(league.league.id)">
                                    <VirtualGame
                                        v-for="event in league.matches"
                                        :key="event.ExId"
                                        :event="event"
                                        :season="currentSeason"
                                        :market-group-to-show="currentMarketGroup.key"
                                    />
                                </div>
                            </transition>
                        </div>
                    </Tabs>
                </Tab>
                <Tab name="results">
                    <VirtualStanding :season-id="seasonId" />
                </Tab>
            </Tabs>
        </div>
        <div class="spinner-wrapper">
            <Spinner :listen="[upcomingSpinnerTrigger, liveSpinnerTrigger, pageSpinnerTrigger]" />
        </div>
        <div v-if="allRoundsOutdated" class="notify error">
            {{ $t('ui.virtualSports.error.virtualRoundsOutdated') }}
        </div>
        <div v-if="invalidDeviceTime" class="notify error">
            {{ $t('ui.virtualSports.error.invalidDeviceTime') }}
        </div>
        <div v-if="error" class="notify error">
            {{ $t('ui.virtualSports.error.virtualRoundsUnavailable') }}
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { helper, deviceType, getter as coreGetter } from '@agi.packages/core';
import { Tab, Tabs } from '@agi.packages/core/components';
import { sport, MARKET_TABS, VIRTUAL_LEAGUE_MAP } from '@agi.packages/sport';
import { getter as platformGetter } from '@agi.packages/platform';

import Countdown from '@/components/Countdown.vue';
import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';

import VirtualGame from './Virtual/VirtualGame.vue';
import VirtualStanding from './Virtual/VirtualStanding.vue';
import VirtualStickyHeader from './Virtual/VirtualStickyHeader.vue';
import { routeName } from '@/router/const-name';

const ROUND_TIME = {
    secondHalf: 45,
    fullTime: 90,
};

export default {
    name: 'VirtualSportsView',
    components: { Tabs, Tab, VirtualGame, VirtualStanding, VirtualStickyHeader, Countdown },
    mixins: [PageMixin, SEOMixin],
    data() {
        return {
            startCounter: null,
            isPresto: deviceType.isPresto(),
            pageSpinnerTrigger: sport.action.GET_VIRTUAL_ROUNDS,
            upcomingSpinnerTrigger: sport.action.GET_VIRTUAL_EVENTS_BY_ROUND_ID,
            liveSpinnerTrigger: sport.action.GET_VIRTUAL_LIVE_ROUND,
            standingsSpinnerTrigger: sport.action.GET_VIRTUAL_STANDINGS,
            currentMarketGroup: MARKET_TABS[0],
            marketTabs: MARKET_TABS,
            activeSection: {},
            virtualLeagueMap: VIRTUAL_LEAGUE_MAP,
            initialLeagueIndex: 0,
            expandedLeagues: [],
            upcomingRoundIndex: null,
            roundSwitchTimeoutId: null,
            livePollingIntervalId: null,
            liveScoreRefreshIntervalId: null,
            liveScroreRefreshRate: 5,
            liveScroreRefreshCountown: null,
        };
    },
    computed: {
        ...mapState({
            rounds: (state) => state.sport.virtualSports.rounds,
            upcomingEvents: (state) => state.sport.virtualSports.events.upcoming,
            liveEvents: (state) => state.sport.virtualSports.events.live,
            events: (state) => state.sport.virtualSports.events,
            seasons: (state) => state.sport.virtualSports.seasons,
            error: (state) => state.sport.virtualSports.error,
            upcomingRound() {
                return this.rounds[this.upcomingRoundIndex];
            },
            liveRound() {
                return this.rounds[this.upcomingRoundIndex - 1];
            },
            currentSeason() {
                return this.seasons[this.upcomingRound.seasonId];
            },
            seasonId() {
                if ((!this.upcomingRound && !this.liveRound) || this.invalidDeviceTime) {
                    return 0;
                }
                return this.activeSection === this.sections.upcoming.key ? this.upcomingRound.seasonId : this.liveRound.seasonId;
            },
            liveRoundLabel() {
                if (this.liveEvents.gameMinute <= ROUND_TIME.secondHalf) {
                    return this.$t('ui.virtualSports.firstHalf');
                } else if (this.liveEvents.gameMinute < ROUND_TIME.fullTime) {
                    return this.$t('ui.virtualSports.secondHalf');
                } else {
                    return this.$t('ui.virtualSports.fullTime');
                }
            },
            invalidDeviceTime() {
                // check if upcoming round start time is more than 10 minutes (600000ms)
                return !!this.upcomingRound && this.upcomingRound.bettingClosesTime - Math.round(new Date().getTime()) > 600000;
            },
            allRoundsOutdated() {
                return !!(this.rounds.length && !this.upcomingRound);
            },
            stickyHeaderEnabled() {
                return helper.getObjectField(this.brandPreference, 'isStickyHeaderEnabled', false) && !this.isPresto;
            },
        }),
        ...mapGetters({
            initialUpcomingRoundIndex: sport.getter.GET_UPCOMING_VIRTUAL_ROUND_INDEX,
            dateOptions: platformGetter.GET_DATE_OPTIONS,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            isLoading: coreGetter.IS_LOADING,
        }),
        marketGroups() {
            return this.marketTabs.map(({ key, text }) => ({ text: this.$t(`marketSelector.markets.${text}`), key }));
        },
        sections() {
            return {
                live: { text: this.liveRound.gameRoundName, key: 'live' },
                upcoming: { text: this.upcomingRound.gameRoundName, key: 'upcoming' },
                results: { text: this.$t('ui.virtualSports.results'), key: 'results' },
            };
        },
        isLoadingLiveRound() {
            return this.isLoading(sport.action.GET_VIRTUAL_LIVE_ROUND);
        },
    },
    watch: {
        $route(to) {
            this.updateSection(to.query);
        },
        liveEvents() {
            this.startCounter = new Date();
        },
        rounds() {
            this.upcomingRoundIndex = this.initialUpcomingRoundIndex;
            this.setRoundSwitchTimeout();
        },
        upcomingRoundIndex() {
            this.updateSection(this.$route.query);
        },
    },
    beforeDestroy() {
        clearTimeout(this.roundSwitchTimeoutId);
        clearInterval(this.livePollingIntervalId);
        clearInterval(this.liveScoreRefreshIntervalId);
    },
    created() {
        this.$store.dispatch(sport.action.GET_VIRTUAL_ROUNDS);
        this.unwatchEvents = this.$watch(
            'events',
            ({ live, upcoming }) => {
                const { inPlayLeagues } = live;
                const leagues = (upcoming?.length && upcoming) || (inPlayLeagues?.length && inPlayLeagues);
                if (leagues) {
                    this.toggleLeagueById(leagues[this.initialLeagueIndex].league.id);
                    this.unwatchEvents();
                }
            },
            { deep: true }
        );

        if (this.isPresto) {
            // rounds watcher is inconsistently triggered when navigating from betslip to virtuals, hence we manually set index value
            this.upcomingRoundIndex = this.initialUpcomingRoundIndex;
        }
    },
    methods: {
        setSection(section = this.sections.upcoming) {
            this.activeSection = section;
            if (this.activeSection.key && this.activeSection.key === this.sections.live.key && this.livePollingIntervalId) {
                clearInterval(this.livePollingIntervalId);
                clearInterval(this.liveScoreRefreshIntervalId);
            }
            this.fetchEvents(section);
        },
        setCurrentTab(section) {
            this.$router.push({ name: routeName.VIRTUAL_SPORTS, query: { virtualTab: section.key } });
        },
        setCurrentMarketGroup(marketGroup) {
            this.currentMarketGroup = marketGroup;
        },
        toggleLeagueById(id) {
            const leagueIndex = this.expandedLeagues.indexOf(id);
            leagueIndex < 0 ? this.expandedLeagues.push(id) : this.expandedLeagues.splice(leagueIndex, 1);
        },
        showLeague(id) {
            return this.expandedLeagues.includes(id);
        },
        showHTScore(event) {
            return this.liveEvents.gameMinute > ROUND_TIME.secondHalf && event.homeHTScore !== null && event.awayHTScore !== null;
        },
        getBettingCloseTime() {
            return helper.formatDateTime(this.upcomingRound.bettingClosesTime, { needDate: false, isUtc: true, ...this.dateOptions });
        },
        setRoundSwitchTimeout() {
            if (this.invalidDeviceTime || !this.upcomingRound) {
                return;
            }

            const bettingClosesTime = parseInt(this.upcomingRound.bettingClosesTime - new Date().getTime());
            this.roundSwitchTimeoutId = setTimeout(() => {
                this.upcomingRoundIndex += 1;
                this.setRoundSwitchTimeout();
            }, bettingClosesTime);
        },
        fetchEvents(section = this.activeSection) {
            if (!this.upcomingRoundIndex || this.invalidDeviceTime) {
                return;
            }
            if (section.key === this.sections.upcoming.key) {
                this.$store.dispatch(sport.action.GET_VIRTUAL_EVENTS_BY_ROUND_ID, this.upcomingRound.gameRoundId);
            } else if (section.key === this.sections.live.key) {
                this.$store.dispatch(sport.action.GET_VIRTUAL_LIVE_ROUND, this.liveRound.gameRoundId);
            }
        },
        updateSection({ virtualTab }) {
            if (virtualTab && this.sections[virtualTab]) {
                this.setSection(this.sections[virtualTab]);
            } else {
                this.$router.replace({
                    name: routeName.VIRTUAL_SPORTS,
                    query: { ...(this.$route.query || {}), virtualTab: this.sections.upcoming.key },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
    transition: max-height 0.4s, opacity 0.4s;
    max-height: 500px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    opacity: 0;
    max-height: 0;
}

.separator {
    @extend %body-normal-font-400;
    background: white;
    color: $main-text;
    padding: 15px 12px;
    margin: 0;
    cursor: pointer;

    @include no_flex_box_supported {
        .league-name {
            display: inline-block;
        }

        .icon-arrow {
            float: right;
        }
    }

    @include all_but_mini {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
    }

    .icon-region {
        margin-right: 5px;
    }

    .league-name-text {
        vertical-align: middle;
    }
}

.separator-wrapper {
    border-bottom: 1px solid $separator-border-color;
}

.separator-wrapper:last-child {
    border-bottom: 0;
}

.score-refresh {
    @extend %body-normal-font-700;
    width: 100%;
    background-color: $white-bg;
    color: $main-text;
    text-align: center;
    padding: 14px;
    border-bottom: 1px solid $border-color;

    .table {
        margin: auto;
        width: auto;
    }

    .live-countdown {
        position: relative;
        left: 8px;
    }

    .game-minute {
        color: $virtual-sports-live-tab-text-color;
        font-weight: bold;
        padding: 1px 8px;
        margin-right: 7px;
        border-radius: $virtual-game-minute-radius;
    }

    .game-status {
        color: $virtuals-tabs-game-status;
        text-transform: capitalize;
    }
}

.season-id {
    @extend %body-normal-font-700;
    color: $grey-text;
    @include maxonecolumn {
        text-align: center;
    }
}

.spinner-wrapper {
    position: relative;
}

.virtual-sports-headline {
    @extend %body-big-font-700;
    background: $headline-background;
}

.virtual-tabs {
    .tab-badge {
        margin-left: 8px;
        text-transform: uppercase;

        &.live-label {
            color: $virtual-sports-live-tab-text-color;

            &.active {
                font-weight: 700;
            }
        }
    }

    > ::v-deep .tabs-menu {
        @include only_mini {
            display: flex;
            flex-wrap: wrap;
        }
        .tabs-selector {
            @include only_mini {
                width: auto;
                flex-grow: 1;
                flex-basis: 80px;

                @include smartmin {
                    padding-left: 12px;
                    padding-right: 12px;
                }
            }
        }
    }

    // all tabs except for the last one should always be bold, but not the suffix content
    > ::v-deep .tabs-menu .tabs-selector:not(.last) .tab-text {
        font-weight: 700;
    }

    > ::v-deep .tabs-menu .tabs-selector {
        &.first {
            border-left: none;
        }

        &.last {
            border-right: none;
        }
    }
}
</style>
