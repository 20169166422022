export const authType = {
    LOGIN: 'login',
    REGISTER: 'sign_up',
    JOIN_NOW: 'join-now',
};

export const postAuthenticationRouteQueries = {
    openBetslip: {
        reopenBetslip: '1',
        returnPrevPath: '1',
    },
};
