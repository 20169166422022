<template>
    <div class="live-event">
        <EventHeader :event="event" :market-id="marketId">
            <template #date-time>
                <div class="live-event-start-wrapper">
                    <span v-if="event.category.id === '2' && !!getGameMinute" class="live-event-start-time indicator-orange">
                        {{ $t('ui.live.live') }} {{ getGameMinute }}'
                    </span>
                    <span v-else class="live-event-start-time indicator-orange">
                        {{ $t('ui.live.started') }} {{ getEventTime(event.startTime) }}
                    </span>
                    <Countdown
                        v-if="!isPresto"
                        class="live-event-countdown"
                        :text="$t('ui.live.pricesRefresh')"
                        :start="startCounter"
                        theme="live-event"
                        stroke-width="6"
                        :refresh-rate="refreshRate"
                        @update="updateLiveEvent"
                    />
                </div>
            </template>
        </EventHeader>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { helper, deviceType, getter as coreGetter } from '@agi.packages/core';
import { sport, marketTypeCategory } from '@agi.packages/sport';
import { getter as platformGetter } from '@agi.packages/platform';

import Countdown from '@/components/Countdown';
import EventHeader from './EventHeader.vue';

export default {
    name: 'LiveEvent',
    components: { Countdown, EventHeader },
    props: {
        event: {
            required: true,
        },
        marketId: {
            default: marketTypeCategory.ALL,
        },
    },
    data() {
        return {
            startCounter: null,
            countdown: null,
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        ...mapState({
            liveRefreshRate: (state) => state.ui.liveRefreshRate,
        }),
        ...mapGetters({
            dateOptions: platformGetter.GET_DATE_OPTIONS, // Implement global date time BP-17850
            isEventPageSocketsEnabled: platformGetter.IS_EVENT_PAGE_SOCKETS_ENABLED,
            isLoading: coreGetter.IS_LOADING,
        }),
        getGameMinute() {
            const { scoreboard } = this.event;
            return scoreboard && scoreboard.display && scoreboard.display.minute;
        },
        refreshRate() {
            const { refresh } = this.$route.query;
            return Number(refresh) || this.liveRefreshRate;
        },
        eventId() {
            return this.event.id;
        },
        eventDataLoading() {
            return this.isLoading(sport.action.GET_EVENT_WITH_PRICES_BY_ID);
        },
    },
    watch: {
        event: {
            immediate: true,
            handler(newValue) {
                if (!this.$isEmpty(newValue) && newValue.additionalInfo?.live) {
                    this.startCounter = new Date();
                }
            },
        },
    },
    methods: {
        $isEmpty: helper.isEmpty,
        getEventTime(dateTime) {
            return helper.formatDateTime(dateTime, { needDate: false, ...this.dateOptions, isUtc: true });
        },
        updateLiveEvent() {
            if (this.isEventPageSocketsEnabled || this.eventDataLoading) return;
            this.$store.dispatch(sport.action.GET_EVENT_WITH_PRICES_BY_ID, this.eventId);
        },
    },
};
</script>

<style scoped lang="scss">
.live-event {
    display: unset;

    &-countdown {
        position: absolute;
        top: 4px;
        right: 0;
    }

    &-start-wrapper {
        position: relative;
        width: 100%;
        text-align: center;
    }

    &-start-time {
        @extend %small-details-font-400;
        display: inline-block;
        vertical-align: top;
    }
}
</style>
