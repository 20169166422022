import { mapGetters, mapState } from 'vuex';
import { action } from '@/store/store';
import { auth } from '@agi.packages/platform';
import { routeName } from '@/router/const-name';

export default {
    data() {
        return {
            prevRoutePath: null,
            reopenBetslip: false,
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoutePath = from.fullPath;
        });
    },
    computed: {
        returnPath() {
            const query = this.$router.currentRoute.query || {};

            // check history
            const numberOfEntries = window.history.length;
            let lastPath = null;
            if (query.returnPrevPath && numberOfEntries > 2) {
                lastPath = this.prevRoutePath;
            }

            if (
                query.reopenBetslip &&
                (this.$store.state.sport.betslip.regular.bets.length || // getter BP-16141
                    this.$store.state.sport.betslip.virtual.bets.length) // getter BP-16141
            ) {
                this.reopenBetslip = true;
            }

            return query.returnPath || query.r || lastPath || '/';
        },
        ...mapState({
            auth: (state) => state.platform.auth,
        }),
        ...mapGetters({
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
        }),
    },
    watch: {
        isAuthenticated(newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                if (this.auth.forcePasswordUpdate) {
                    this.$router.push({ name: routeName.SET_PASSWORD, query: { returnPath: this.returnPath } });
                } else {
                    this.$router.push({ path: this.returnPath }, () => {
                        if (this.reopenBetslip) {
                            this.$store.dispatch(action.SET_BETSLIP_STATE, true);
                        }
                    });
                }
            }
        },
    },
};
