export const copyIntoClipboard = (function clipboardCopy() {
    const clipboardWrite =
        window.navigator.clipboard && window.navigator.clipboard.writeText ? window.navigator.clipboard.writeText : undefined;

    return (textValue) => {
        const text = String(textValue);

        return new Promise((resolve) => {
            if (clipboardWrite) {
                clipboardWrite(text)
                    .then(() => {
                        resolve(true);
                    })
                    .catch(() => {
                        execCommand(text, resolve);
                    });

                return;
            }

            execCommand(text, resolve);
        });
    };
})();

function execCommand(value, resolve) {
    const el = getCopyNode(value);

    let copyResult = execCopyCmd(el);

    if (!copyResult) {
        copyResult = execCopyCmdWithRange(el);
    }

    el.remove();
    resolve(copyResult);
}

function getCopyNode(value) {
    const el = document.createElement('textarea');

    el.style.height = '0';
    el.style.opacity = '0';
    el.value = value;

    document.body.prepend(el);

    return el;
}

function execCopyCmd(el) {
    el.focus();
    el.select();
    el.setSelectionRange(0, el.value.length);

    return copy();
}

function execCopyCmdWithRange(el) {
    if (!document.createRange) {
        return false;
    }

    const selection = window.getSelection();
    const range = document.createRange();

    if (range.selectNodeContents) {
        range.selectNodeContents(el);
    } else {
        range.selectNode(el);
    }

    selection.removeAllRanges();
    selection.addRange(range);
    el.setSelectionRange(0, el.value.length);

    return copy();
}

function copy() {
    try {
        return document.execCommand('copy');
    } catch (ex) {
        console.error('document.execCommand is not supported');
    }

    return false;
}
