<template>
    <div class="deposit-form">
        <Spinner :visible="fetchingDetails" class="inset" />
        <div v-show="showSelect" class="deposit-header">
            <SvgIcon icon-id="icon-deposit-menu" class="icon-deposit" vertical-align="top" />
            <span class="deposit-header-title">{{ $t('ui.payment.deposit.title') }}</span>

            <span class="deposit-close" @click="onCloseClick">
                <SvgIcon icon-id="icon-close" class="icon-deposit-close icon-size-very-small" />
            </span>
        </div>
        <form v-if="!fetchingDetails" novalidate @submit.prevent="deposit">
            <SelectField
                v-show="showSelect"
                class="select-method"
                :options="selectOptions"
                :selected-value="initialOption"
                :v="$v.selectActiveOption"
                :error-messages="selectErrorMessages"
                :attrs="{ disabled: isInitialized || inProgress }"
                @input="onSelectChange"
            />
            <template v-if="!errorText">
                <slot name="amount">
                    <div v-if="errorMessage" class="notify error">
                        <renderer :input="errorMessage" />
                    </div>

                    <InputField
                        v-if="!showLink"
                        :type="amountInputAttr.type"
                        name="amount-input"
                        form-name="deposit-form"
                        class="deposit-amount"
                        :value="depositAmount"
                        :v="$v.depositAmount"
                        :error-messages="errorMessages"
                        :currency-symbol="currencySymbol"
                        :attrs="{
                            pattern: amountInputAttr.pattern,
                            inputmode: amountInputAttr.inputmode,
                            'data-test-id': 'depositAmountInput',
                        }"
                        :disabled="isInitialized || inProgress || isProviderUnavailable"
                        :help-text="helpText"
                        :step="amountInputAttr.step"
                        @value="onValueChange"
                    >
                        <template slot="label">
                            <span class="label-wrapper">
                                <span>{{ inputLabel || $t('ui.payment.deposit.enterAmount') }}</span>
                                <DepositTelcoFeeLabel
                                    v-if="isTelcoFeeEnabled"
                                    :compensation-amount="selectedProvider.minDepositAmountForFeeCompensation"
                                    :provider-name="selectedProvider.text"
                                />
                                <span v-else-if="selectedProviderFee">{{ selectedProviderFee }}</span>
                            </span>
                        </template>
                    </InputField>
                </slot>
                <slot name="fee" :provider="selectedProvider" :fee="fee" />
                <DepositTelcoFee
                    v-if="!fee && isTelcoFeeEnabled && depositAmount"
                    :amount="depositAmount"
                    :is-loading="isTelcoFeeLoading"
                    :provider-name="selectedProvider.text"
                    :telco-fee="telcoFee"
                />
                <div>
                    <template v-if="showLink">
                        <router-link
                            v-if="selectedProvider && selectedProvider.pageUrl"
                            class="button button-primary button-full"
                            :to="{ path: selectedProvider.pageUrl }"
                        >
                            {{ $t('ui.payment.deposit.linkTitle') }}
                        </router-link>
                        <div v-else class="button button-primary button-full disabled">
                            {{ $t('ui.payment.deposit.linkTitle') }}
                        </div>
                    </template>
                    <button
                        v-else
                        class="button button-primary button-full"
                        :class="{ processing: isInitialized }"
                        :disabled="isSubmitButtonDisabled"
                        data-test-id="depositButton"
                        type="submit"
                    >
                        <span>{{ depositButtonText }}</span>
                    </button>
                </div>
            </template>
        </form>
        <div
            v-if="boxMessage"
            data-test-id="boxMessage"
            class="notify"
            :class="{
                error: errorText,
                warning: boxWarningMessage,
            }"
        >
            <renderer :input="boxMessage" />
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { integer, minValue, maxValue, required } from 'vuelidate/lib/validators';
import { InputField, Spinner, SelectField } from '@agi.packages/core/components';
import { helper, deviceType, getter as coreGetter, mutation as coreMutation, attributesForNumberInput } from '@agi.packages/core';
import { depositStatus, action, mutation, getter, telcoIcon } from '@agi.packages/payment';
import { auth, mutation as platformMutation, getter as platformGetter, messaging } from '@agi.packages/platform';
import { action as generalAction, mutation as generalMutation } from '@/store/store';
import DepositTelcoFee from '@agi.packages/payment/components/DepositTelcoFee';
import DepositTelcoFeeLabel from '@agi.packages/payment/components/DepositTelcoFeeLabel';
import { routeName } from '@/router/const-name';
const spinnerSize = 40;
const needSize = 24;

const REDIRECT_URL_PARAMETERS = {
    amount: 'amount',
    depositId: 'depositId',
    selectedProviderId: 'selectedProviderId',
    status: 'status',
};

const REDIRECT_STATUS = {
    success: 'success',
    failure: 'failure',
};

export default {
    name: 'Deposit',
    components: { InputField, Spinner, SelectField, DepositTelcoFee, DepositTelcoFeeLabel },
    props: {
        errorText: {
            type: String,
            required: false,
            default: '',
        },
        amount: [Number, String],
        showSelect: {
            type: Boolean,
            default: false,
        },
        polling: {},
        counter: {
            type: Boolean,
            default: true,
        },
        buttonText: String,
        inputLabel: String,
        isButtonAlwaysActive: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            boxErrorMessage: null,
            boxWarningMessage: null,
            depositAmount: null,
            selectActiveOption: null,
            isPresto: deviceType.isPresto(),
            spinnerZoomPercent: (needSize / spinnerSize) * 100,
            isFetchTelcoFeeDebouncing: false,
        };
    },
    validations() {
        const { amountIsNumberRule } = helper;
        const rules = {
            depositAmount: {
                amountIsNumberRule,
            },
            selectActiveOption: {
                required,
            },
        };
        if (!this.selectedProvider.fractionalSupported) {
            rules.depositAmount = {
                ...rules.depositAmount,
                integer,
            };
        }

        if (this.limits.min) {
            rules.depositAmount = {
                ...rules.depositAmount,
                minAmountRule: minValue(this.limits.min),
            };
        }
        if (this.limits.max) {
            rules.depositAmount = {
                ...rules.depositAmount,
                maxAmountRule: maxValue(this.limits.max),
            };
        }

        return rules;
    },
    computed: {
        ...mapGetters({
            balance: auth.getter.GET_BALANCE,
            isLoading: coreGetter.IS_LOADING,
            isInitialized: getter.GET_DEPOSIT_INIT,
            depositDetails: getter.GET_DEPOSIT_DETAILS,
            reservedDeposit: getter.GET_RESERVED_DEPOSIT,
            providers: getter.GET_PROVIDERS_OPTIONS,
            selectedProvider: getter.GET_SELECTED_PROVIDER,
            country: platformGetter.GET_COUNTRY,
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            error: getter.GET_DEPOSIT_ERROR,
            showLink: getter.SHOW_LINK,
            isTelcoFeeEnabled: getter.IS_DEPOSIT_TELCO_FEE_ENABLED,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            isProviderUnavailable: getter.IS_PROVIDER_UNAVAILABLE,
        }),
        ...mapState({
            currencySymbol: (state) => state.platform.settings.currency.symbol,
            telcoFee: (state) => state.payment.deposit.telcoFee,
        }),
        inProgress() {
            return this.isLoading(action.DEPOSIT_IN_PROGRESS);
        },
        fetchingDetails() {
            return this.isLoading(action.FETCH_PROVIDER_DETAILS);
        },
        isValid() {
            const { min, max } = this.limits;
            return min && max ? min <= this.depositAmount && max >= this.depositAmount : true;
        },
        errorMessages() {
            const rounded = Math.floor(this.depositAmount);
            const { min, max } = this.limits;
            return {
                integer: this.$t('ui.common.form.error.integer', { down: rounded, up: rounded + 1 }),
                minAmountRule: this.$t('ui.payment.deposit.error.limitMin', { limit: this.$numberFormat(min) }),
                maxAmountRule: this.$t('ui.payment.deposit.error.limitMax', { limit: this.$numberFormat(max) }),
                amountIsNumberRule: this.$t('ui.common.error.invalidAmount'),
            };
        },
        selectErrorMessages() {
            return {
                required: this.$t('ui.payment.deposit.error.selectMethod'),
            };
        },
        boxMessage() {
            return this.errorText || this.boxWarningMessage;
        },
        selectOptions() {
            const options = [{ disabled: true, value: null, label: this.$t('ui.payment.deposit.selectMethod') }];
            const localIcons = Object.entries(telcoIcon);
            const getProviderLocalIcon = ({ paymentProvider }) => {
                const [, name] = localIcons.find(([key, value]) => paymentProvider?.includes(key) && value) || [];
                return name || telcoIcon.DEFAULT;
            };
            const providersList = [...this.providers]
                .filter((item) => {
                    if (this.showSelect) {
                        return item.applicableForUserTelco;
                    }
                    return true;
                })
                .map(({ id, text, mediaUrl, paymentProvider }) => ({
                    value: id,
                    label: text,
                    icon: mediaUrl || `img/providers/${getProviderLocalIcon(paymentProvider)}.png`,
                }));

            return [...options, ...providersList];
        },
        initialOption() {
            if (this.showSelect) {
                const { value } = this.selectOptions.find(({ value }) => this.selectedProvider.id === value) || {};
                return value;
            }

            return this.selectedProvider.id;
        },
        limits() {
            return {
                min: this.selectedProvider.minDepositAmount,
                max: this.selectedProvider.maxDepositAmount,
            };
        },
        runReservedDeposit() {
            return this.selectedProvider.depositTypeName && this.reservedDeposit;
        },
        depositButtonText() {
            if (this.isInitialized) {
                return this.$t('ui.payment.deposit.processing');
            }
            return this.buttonText || this.$t('ui.payment.deposit.button');
        },
        helpText() {
            const { min, max } = this.limits;
            const minAmountText = min ? this.$t('ui.payment.deposit.minAmount', { amount: this.$numberFormat(min, 0) }) : '';
            const maxAmountText = max ? this.$t('ui.payment.deposit.maxAmount', { amount: this.$numberFormat(max, 0) }) : '';
            const separator = minAmountText ? ', ' : '';
            return `${minAmountText}${separator}${maxAmountText}`;
        },
        selectedProviderFee() {
            const { additionalData, showFeeAsNumber } = this.selectedProvider;
            if (additionalData) {
                const { depositFee, badgeForDepositComponent } = additionalData || {};
                const { label } = badgeForDepositComponent || {};
                const fee = `${depositFee}${showFeeAsNumber ? '' : '%'}`;
                return depositFee ? this.$t('ui.payment.deposit.fee', { fee }) : label;
            }
            return null;
        },
        isSubmitButtonDisabled() {
            return (
                this.isInitialized ||
                this.inProgress ||
                (!this.depositAmount && !this.isButtonAlwaysActive) ||
                !this.selectedProvider.applicableForUserTelco ||
                this.isTelcoFeeLoading ||
                this.isProviderUnavailable
            );
        },
        operator() {
            const { phoneOperator, text, phoneOperatorDescription } = this.selectedProvider;
            return phoneOperator || text || phoneOperatorDescription;
        },
        provider() {
            return this.selectedProvider.text || this.selectedProvider.paymentProvider;
        },
        errorMessage() {
            if (this.boxErrorMessage) {
                return this.boxErrorMessage;
            }
            if (!this.selectedProvider.applicableForUserTelco && !this.showSelect) {
                return this.$t('ui.payment.deposit.error.mismatchedProvider', { operator: this.operator, provider: this.provider });
            }
            return null;
        },
        fee() {
            const { depositFee } = this.selectedProvider.additionalData || {};
            if (depositFee && Number(this.depositAmount)) {
                const fee = Math.floor(((this.depositAmount * depositFee) / 100.0) * 100.0) / 100.0;
                return {
                    feeAmount: 0 - fee,
                    netAmount: this.depositAmount - fee,
                };
            }
            return null;
        },
        isTelcoFeeLoading() {
            return this.isFetchTelcoFeeDebouncing || this.isLoading(action.FETCH_DEPOSIT_TELCO_FEE);
        },
        depositRedirectUrls() {
            const { amount, depositId, selectedProviderId, status } = REDIRECT_URL_PARAMETERS;
            const { depositTypeName } = this.selectedProvider;
            const urlBase = window.location.origin + window.location.pathname;
            const urlParams = `?${amount}=${this.depositAmount}&${depositId}=DEPOSIT_ID&${selectedProviderId}=${depositTypeName}&${status}=`;

            return {
                depositSuccessfulUrl: urlBase + urlParams + REDIRECT_STATUS.success,
                depositFailedUrl: urlBase + urlParams + REDIRECT_STATUS.failure,
            };
        },
        amountInputAttr() {
            const { fractionalSupported } = this.selectedProvider || {};
            return attributesForNumberInput({ fractionalSupported });
        },
    },
    watch: {
        error: {
            deep: true,
            handler(value) {
                this.boxErrorMessage = value;
            },
        },
        initialOption(type) {
            if (type) {
                this.selectActiveOption = type;
            }
        },
        amount: {
            immediate: true,
            handler(value) {
                this.depositAmount = value;
            },
        },
        inProgress(value) {
            this.$emit('inProgress', value);
        },
        depositDetails: {
            deep: true,
            handler({ StatusType, providerUrl, DepositInitId, GrossAmount, redirectUrl }) {
                switch (StatusType) {
                    case depositStatus.SUCCESS:
                        this.$store.dispatch(action.RESET_DEPOSIT);
                        this.$store.commit(generalMutation.CLEAR_NOTIFICATIONS);
                        const { netAmount } = this.fee || {};
                        const amount = netAmount || this.telcoFee.totalAmount || this.depositAmount;

                        const notificationOptions = {
                            type: 'payment',
                            data: {
                                GrossAmount,
                                Amount: amount,
                                balance: this.balance,
                            },
                        };
                        this.getBalance({ force: true }).finally(() => {
                            if (this.$route.name === routeName.HOMEPAGE) {
                                notificationOptions.data.trigger = false;
                                this.depositAmount = null;
                                this.$scroll.scrollTo(0, 1);
                            } else {
                                notificationOptions.data.trigger = true;
                                this.$router.push({ name: routeName.HOMEPAGE });
                            }

                            if (notificationOptions.data.Amount) {
                                this.$store.dispatch(generalAction.NOTIFY, notificationOptions);
                            }
                        });

                        this.depositAmount = null;
                        setTimeout(() => {
                            this.$store.dispatch(messaging.action.GET_ONSITE_MESSAGES_AND_COUNT, { force: true });
                            this.$store.commit(platformMutation.UPDATE_PREFERENCE, {
                                deposit_option: this.selectedProvider.depositTypeName,
                            });
                        }, 1000);
                        break;
                    case depositStatus.PENDING:
                    case depositStatus.AWAITING_USER_CONFIRMATION:
                        // only for payments with external redirect
                        const paymentProviderUrl = providerUrl ?? redirectUrl;

                        if (paymentProviderUrl && DepositInitId !== this.reservedDeposit.DepositInitId) {
                            this.$store.commit(mutation.RESERVE_DEPOSIT_ID, {
                                type: this.selectedProvider.id,
                                DepositInitId,
                                Amount: this.depositAmount,
                            });
                            window.location.href = paymentProviderUrl;
                        } else if (
                            StatusType === depositStatus.AWAITING_USER_CONFIRMATION &&
                            !this.$route.query[REDIRECT_URL_PARAMETERS.depositId]
                        ) {
                            window.location.href = paymentProviderUrl;
                        }

                        break;
                }
            },
        },
        runReservedDeposit(deposit) {
            if (deposit) {
                const { type, DepositInitId, Amount } = deposit;
                const queryDepositId = Number(this.$route.query[REDIRECT_URL_PARAMETERS.depositId]);
                const { id: providerId, depositTypeName } = this.selectedProvider;

                const providerIdMatches = type === providerId;
                const depositIdMatches = DepositInitId === queryDepositId;
                if (providerIdMatches && depositIdMatches && depositTypeName) {
                    this.$store.commit(mutation.SET_DEPOSIT_INITIALIZED);
                    this.$store.commit(mutation.SET_POLL_IN_PROGRESS, true);
                    this.$store.dispatch(action.POLL_DEPOSIT, {
                        DepositInitId,
                        inProgress: false,
                        providerName: depositTypeName,
                        providerLabel: this.provider,
                        Amount,
                        step: 'ON_RESERVED',
                    });
                }
            }
        },
        isInitialized(value) {
            this.$emit('initialized', value);
        },
        isTelcoFeeEnabled: {
            immediate: true,
            handler(value) {
                if (value && !this.debouncedFetchFeeCallback) {
                    const { depositTelcoFeeDebounceTimeout } = this.brandPreference;
                    this.debouncedFetchFeeCallback = helper.debounce(
                        (amount) =>
                            this.$store.dispatch(action.FETCH_DEPOSIT_TELCO_FEE, {
                                amount,
                                depositType: this.selectedProvider.depositTypeName,
                            }),
                        Number(depositTelcoFeeDebounceTimeout) || 1000,
                        (isDebouncing) => {
                            this.isFetchTelcoFeeDebouncing = isDebouncing;
                        }
                    );
                }
            },
        },
    },
    mounted() {
        const { DepositInitId, type, Amount } = this.reservedDeposit;
        const {
            [REDIRECT_URL_PARAMETERS.status]: status,
            [REDIRECT_URL_PARAMETERS.depositId]: depositId,
            [REDIRECT_URL_PARAMETERS.amount]: amount,
            [REDIRECT_URL_PARAMETERS.selectedProviderId]: selectedProviderId,
        } = this.$route.query;

        this.$store.dispatch(action.RESET_DEPOSIT).then(() => {
            selectedProviderId && this.$store.commit(mutation.SET_SELECTED_PROVIDER, selectedProviderId);

            const depositHasFailed = status === REDIRECT_STATUS.failure;

            if (DepositInitId && type === this.selectedProvider.id) {
                if (depositHasFailed) {
                    return this.handleFailedDeposit();
                }

                this.depositAmount = Number(amount) ?? this.depositAmount;
                this.$store.commit(mutation.RESERVE_DEPOSIT_ID, {
                    type,
                    DepositInitId,
                    Amount,
                });
            }

            if (status && depositId && this.isPresto) {
                if (depositHasFailed) {
                    return this.handleFailedDeposit();
                }

                this.depositAmount = Number(amount) ?? this.depositAmount;
                this.fetchPrestoDepositDetails(depositId);
            }
        });
    },
    beforeDestroy() {
        this.$store.commit(mutation.SET_POLL_IN_PROGRESS, false);
        this.$store.commit(coreMutation.END_LOAD, action.DEPOSIT_IN_PROGRESS, { root: true });
    },
    methods: {
        ...mapActions({
            getBalance: auth.action.GET_BALANCE,
        }),
        $interpolate: helper.interpolate,
        $numberFormat: helper.numberFormat,
        checkForm() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$v.$reset();
            }
        },
        deposit() {
            this.checkForm();
            const { depositTypeName } = this.selectedProvider;
            if (!this.isAuthenticated) {
                this.$v.$touch();
                this.$gtm.query({
                    event: 'deposit_show_login_message',
                });
                this.boxWarningMessage = this.$t('ui.payment.deposit.pleaseLogin');
                return;
            }
            if (this.$v.depositAmount?.$invalid) {
                this.$gtm.query({
                    event: 'deposit_invalid_amount_fe',
                    deposit_amount: this.depositAmount,
                    method: depositTypeName,
                });
            }
            if (this.isPresto && this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            if (this.isValid && !this.$v.$invalid) {
                const payload = {
                    providerName: depositTypeName,
                    Amount: this.depositAmount,
                    polling: this.polling,
                    depositComponentWithSelect: this.showSelect,
                    providerLabel: this.provider,
                    ...this.depositRedirectUrls,
                };
                this.$emit('deposit', payload);
                this.$store.dispatch(action.DEPOSIT, payload);
                this.$gtm.query({
                    event: 'deposit_initiate',
                    deposit_amount: this.depositAmount,
                    deposit_type_name: depositTypeName,
                });
            }
        },
        onSelectChange(value) {
            this.selectActiveOption = value;
            this.$store.commit(mutation.SET_SELECTED_PROVIDER, value);
            this.$store.commit(mutation.SET_DEPOSIT_ERROR, null);
        },
        onCloseClick() {
            this.$emit('closeClick');
        },
        onValueChange(value) {
            this.depositAmount = value;
            if (this.debouncedFetchFeeCallback && this.isTelcoFeeEnabled) {
                this.telcoFee.feeAmount && this.$store.commit(mutation.RESET_DEPOSIT_TELCO_FEE);
                value && this.debouncedFetchFeeCallback(value);
            }
            this.$emit('valueChanged', value);
        },
        fetchPrestoDepositDetails(DepositInitId) {
            this.$store.commit(mutation.SET_DEPOSIT_INITIALIZED);
            this.$store.commit(mutation.SET_POLL_IN_PROGRESS, true);
            this.$store.dispatch(action.POLL_DEPOSIT, {
                DepositInitId,
                Amount: this.depositAmount,
                inProgress: true,
                providerName: this.selectedProvider.depositTypeName,
            });
        },
        handleFailedDeposit() {
            this.$store.commit(mutation.SET_DEPOSIT_ERROR, this.$t('ui.common.error.unexpectedError'));
            this.$router.push({ path: this.$route.path });
        },
    },
};
</script>

<style scoped lang="scss">
.select-method {
    margin-bottom: 12px;
}

.icon-deposit {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.deposit-close {
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    vertical-align: top;
    float: right;
    cursor: pointer;
}

.deposit-header {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 22px;
}

.deposit-header-title {
    text-transform: uppercase;
    font-weight: bold;
    vertical-align: top;
}

.notify {
    padding: 12px 14px;

    &.center {
        display: flex;
        justify-content: center;
    }
}

.deposit-amount {
    margin-bottom: 20px;
}

.processing {
    &:after {
        display: inline-block;
        animation: ellipsis 1s infinite;
        content: '.';
        width: 16px;
        text-align: left;
    }
}

.label-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

@keyframes ellipsis {
    0% {
        content: '.';
    }
    33% {
        content: '..';
    }
    66% {
        content: '...';
    }
}
</style>
