<template>
    <div>
        <div class="header" :class="{ 'header-border-color': chips }">
            <h2>{{ $t('ui.statement.statement').toUpperCase() }}</h2>
        </div>
        <Tabs v-if="chips" :tabs="tabs" :active="activeTab" @select="onTabChange" />
        <Spinner v-if="pageIsLoading" :visible="true" class="inset" />
        <div v-else-if="unexpectedNetworkError">
            <ErrorPage
                :img-src="unexpectedErrorImage"
                :title="$t('ui.statement.unexpectedError')"
                :section-list="[$t('ui.statement.unexpectedErrorDescription')]"
                :button="{ text: $t('critical.refreshPage'), emit: true }"
                @button:click="reloadPage"
            />
        </div>
        <div v-else-if="hasError" class="notify error">
            <renderer :input="error.message" />
        </div>
        <div v-else-if="!transactions.length">
            <ErrorPage :title="$t('ui.statement.emptyData')" :section-list="[emptyData.text]">
                <template v-if="!isPresto" slot="header">
                    <img :src="noActivityImage" alt="" class="no-activity" />
                </template>
                <template slot="footer">
                    <router-link :to="{ path: emptyData.button.path }" class="button button-primary error-button">
                        {{ emptyData.button.text }}
                    </router-link>
                </template>
            </ErrorPage>
        </div>
        <div v-else>
            <div v-for="(transaction, index) in filteredTransactions" :key="`${transaction.transactionId}-${index}`" class="statement">
                <div class="statement-actions">
                    <div v-if="isPresto || $mq.isVerySmall" class="date">{{ formatTimestamp(transaction.dateTime) }}</div>
                    <div v-if="hasBetslip(transaction)" class="action">
                        <router-link :to="{ name: 'Betslip', params: { id: transaction.transactionId } }" class="block">
                            <renderer :input="getTransactionText(transaction)" />
                        </router-link>
                    </div>
                    <div v-else class="action">
                        <span class="block">
                            <renderer :input="getTransactionText(transaction)" />
                        </span>
                    </div>
                    <div v-if="!isPresto && !$mq.isVerySmall" class="date">{{ formatTimestamp(transaction.dateTime) }}</div>
                </div>
                <div class="statement-amounts">
                    <div :class="[transaction.totalAmount.amount < 0 ? 'negative' : 'positive', 'amount']">
                        <Currency
                            class="value"
                            :class="{ upperCase: isChipsTab }"
                            :amount="Math.abs(transaction.totalAmount.amount)"
                            :format="statementCurrency"
                        >
                            {{ transaction.totalAmount.amount > 0 ? '+' : '-' }}
                        </Currency>
                    </div>
                    <div class="balance">
                        <Currency
                            class="value balance-value"
                            :class="{ upperCase: isChipsTab }"
                            :amount="Number(transaction.totalBalance.amount)"
                            :format="statementCurrency"
                        >
                            <template slot>
                                <span class="balance-label">{{ $t('ui.statement.balance') }}:</span>
                            </template>
                        </Currency>
                    </div>
                </div>
            </div>
            <div v-if="isTZ && hasDepositTzTransfer" class="statement-message">
                {{ $t('ui.statement.extrabetLimitedMessage') }}
            </div>
            <div v-if="hasMoreResults" class="text-mid">
                <button class="button button-accent" @click="loadTransactions">{{ $t('ui.statement.older') }}</button>
            </div>
            <div class="contact-us">
                <renderer :input="$t('ui.statement.contactUs')" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Spinner, Currency, Tabs } from '@agi.packages/core/components';
import { helper, getter as coreGetter, deviceType } from '@agi.packages/core';
import {
    auth,
    statementTypes,
    action as platformAction,
    BETSLIP_TRANSACTION_TYPES,
    getter as platformGetter,
} from '@agi.packages/platform';
import { getter as translationsGetter } from '@/store/modules/translations';

import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import ErrorPage from '@/components/Pages/ErrorPage';
import { ERROR_IMAGES } from '@/components/content/content-const';

const EXTRABET_LIMITED_MESSAGE_TYPE = 'DEPOSIT_TZ_TRANSFER';
const STATEMENTS_DATE_BEFORE = '2022-09-26T21:00:00Z';

export default {
    name: 'StatementView',
    components: { ErrorPage, Spinner, Currency, Tabs },
    mixins: [PageMixin, SEOMixin],
    data() {
        return {
            resultsPerBatch: 50,
            offset: 0,
            statementsBefore: STATEMENTS_DATE_BEFORE,
            extrabetLimitedMessage: EXTRABET_LIMITED_MESSAGE_TYPE,
            activeTab: {
                key: statementTypes.GENERAL,
            },
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        ...mapState({
            transactions: (state) => state.platform.statement.transactions,
            hasMoreResults: (state) => state.platform.statement.hasMoreResults,
            nextPageId: (state) => state.platform.statement.nextPageId,
            chips: (state) => state.platform.settings.chips,
            chipsCurrency: (state) => state.platform.settings.chips.currency,
            currencyFormat: (state) => state.platform.settings.currency.format,
            error: (state) => state.platform.statement.error,
        }),
        ...mapGetters({
            dateOptions: platformGetter.GET_DATE_OPTIONS, // Implement global date time BP-17850
            balance: auth.getter.GET_BALANCE,
            isLoading: coreGetter.IS_LOADING,
            casinoName: translationsGetter.CASINO_NAME,
            country: platformGetter.GET_COUNTRY,
            countryCodeIs: platformGetter.COUNTRY_CODE_IS,
        }),
        isTZ() {
            return this.countryCodeIs.TZ;
        },
        isChipsTab() {
            return this.activeTab?.key === statementTypes.BETPAWA_CHIPS;
        },
        statementCurrency() {
            return this.isChipsTab ? this.chipsCurrency.format : this.currencyFormat;
        },
        filteredTransactions() {
            if (!this.isTZ) {
                return this.transactions;
            }

            return this.transactions.filter((transaction) => {
                return transaction.dateTime > this.statementsBefore;
            });
        },
        hasDepositTzTransfer() {
            return this.transactions.some((transaction) => {
                return transaction.operationType === this.extrabetLimitedMessage;
            });
        },
        pageIsLoading() {
            return this.isLoading(platformAction.GET_STATEMENT);
        },
        hasError() {
            return !!(this.error && this.error.message);
        },
        noActivityImage() {
            return ERROR_IMAGES.errorNoActivity;
        },
        unexpectedNetworkError() {
            return this.hasError && Math.trunc(this.error.statusCode / 100) === 5;
        },
        unexpectedErrorImage() {
            return this.isPresto ? '' : ERROR_IMAGES.errorUnexpected;
        },
        tabs() {
            return [
                {
                    key: statementTypes.GENERAL,
                    text: this.$t('ui.statement.general'),
                },
                {
                    key: statementTypes.BETPAWA_CHIPS,
                    text: this.$t('ui.statement.betPawaChips'),
                },
            ];
        },
        emptyData() {
            return {
                text: this.$t(`ui.statement.${this.isChipsTab ? 'emptyDataChipsDescription' : 'emptyDataDescription'}`),
                button: {
                    text: this.$t(this.isChipsTab ? 'ui.statement.browseGames' : 'ui.eventPage.eventEnded.browseEvents'),
                    path: this.isChipsTab ? '/games' : '/upcoming',
                },
            };
        },
    },
    created() {
        this.loadTransactions();
    },
    beforeDestroy() {
        this.$store.dispatch(platformAction.RESET_STATEMENT);
    },
    methods: {
        $numberFormat: helper.numberFormat,
        formatTimestamp(timestamp) {
            return helper.formatDateTime(timestamp, { isUtc: true, ...this.dateOptions });
        },
        getTransactionText({ operationType, transactionId, ledger }) {
            const { metadata } = ledger || {};
            const { text = '', gameName = '', jackpotName } = metadata || {};
            transactionId = transactionId.replace(/-/g, '-<wbr>');
            const templateText =
                this.$t(`project.ui.statement.transactionTypes.${this.country}.${operationType}`, { indefinite: true, transactionId }) ||
                this.$t(`ui.statement.transactionTypes.${operationType}`, { casinoName: this.casinoName, transactionId, jackpotName });
            if (!templateText) {
                return `${operationType} ${transactionId}`;
            }
            const gameNameText = gameName.length ? `- <span class="capitalize">${gameName}</span>` : '';
            const metaDataDetails = `${text} ${gameNameText}`;

            return `${templateText} ${metaDataDetails}`;
        },
        loadTransactions() {
            if (this.hasMoreResults) {
                this.offset += this.resultsPerBatch;
            }
            const nextPageId = this.nextPageId;

            this.$store.dispatch(platformAction.GET_STATEMENT, {
                take: this.resultsPerBatch,
                ...(this.isChipsTab && { currency: this.chipsCurrency.platformCode }),
                ...(this.hasMoreResults && { nextPageId }),
            });
        },
        onTabChange(selectedTab) {
            this.$store.dispatch(platformAction.RESET_STATEMENT);
            this.activeTab = selectedTab;
            this.loadTransactions();
        },
        hasBetslip(transaction) {
            return BETSLIP_TRANSACTION_TYPES.includes(transaction.operationType);
        },
        reloadPage() {
            window.location.reload();
        },
    },
};
</script>

<style scoped lang="scss">
.header {
    display: flex;
    padding: 11px 12px;
    background: $headline-background;
    align-items: center;
    justify-content: center;

    &-border-color {
        border-bottom-color: #e6e7e2;
    }

    h2 {
        color: $betpawa-black;
        margin: 0 0 0 8px;

        @include maxoldschoolandmini {
            font-size: 18px;
            line-height: 21px;
        }
    }

    &-icon {
        width: 26px;
        height: 26px;

        svg {
            max-width: 100%;
            max-height: 100%;
        }

        @include maxoldschoolandmini {
            width: 20px;
            height: 20px;
        }
    }
}

.statement {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid $light-grey;
    overflow: hidden;

    @include only_mini {
        border-bottom: 2px solid $light-grey;
        padding-bottom: 20px;
    }

    @include maxoldschoolandmini {
        flex-direction: column;
    }

    &-actions,
    &-amounts {
        @extend %small-details-font-400;
        display: flex;
        flex-direction: column;
        width: 50%;
        justify-content: space-between;

        @include maxoldschoolandmini {
            width: 100%;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
        }
    }

    &-actions {
        padding: 12px 6px 12px 12px;

        @include maxoldschoolandmini {
            padding: 8px 8px 4px 8px;
        }
    }

    &-amounts {
        text-align: right;
        padding: 12px 12px 12px 6px;

        @include maxoldschoolandmini {
            padding: 4px 8px 8px 8px;
            text-align: left;
        }
    }
}

.date {
    color: $grey-text;
    margin-bottom: 0;

    @include maxoldschoolandmini {
        margin-bottom: 4px;
    }
}

.amount {
    margin-bottom: 4px;
    font-weight: 700;

    &.positive {
        color: $green-success;
    }

    &.negative {
        color: $light-red;
    }
}

.balance {
    font-weight: 700;

    ::v-deep {
        .balance-value {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            @include maxoldschoolandmini {
                justify-content: flex-start;
            }
        }
        .amount {
            margin-left: 4px;
            word-break: break-all;

            @include maxoldschoolandmini {
                margin-left: 0;
            }
        }
    }
}

.balance-label {
    font-weight: 400;
    text-transform: capitalize;

    @include maxoldschoolandmini {
        margin-right: 4px;
    }
}
.action {
    margin-bottom: 4px;

    @include maxoldschoolandmini {
        margin-bottom: 0;
    }

    a:active {
        color: $light-green;
    }
}

.text-mid {
    border-top: 1px solid $betpawa-black;
    padding: 8px;
}

.statement-message {
    @extend %small-details-font-400;
    background-color: $light-grey;
    padding: 10px 25px;
    margin: 20px;
    text-align: center;
}

.contact-us {
    @extend %body-normal-font-400;
    font-style: italic;
    padding: 12px 8px;
}

.no-activity {
    width: 140px;
    margin-bottom: 12px;

    @include maxoldschoolandmini {
        width: 88px;
    }

    @include oldschooltoxmain {
        width: 118px;
    }
}

.block {
    display: inline-block;
}
</style>
