<template>
    <router-link :to="{ path }" :target="target" :class="['underline', className]" @click.native="trackLinkClick">
        {{ data.text }}
    </router-link>
</template>
<script>
import { auth, getter as platformGetter } from '@agi.packages/platform';
import { mapGetters } from 'vuex';
import { linkTarget } from '@/components/content/content-const';

export default {
    name: 'Linkinternal',
    props: {
        data: {
            require: true,
            type: Object,
        },
    },
    data() {
        return {
            className: this.data.class || this.data.cssClass,
            target: linkTarget[this.data.subset],
        };
    },
    computed: {
        ...mapGetters({
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            country: platformGetter.GET_COUNTRY,
        }),
        path() {
            const { link } = this.data;
            return link.startsWith('/') ? link : `/${link}`;
        },
    },
    methods: {
        trackLinkClick() {
            this.$gtm.query({
                event: 'link_internal_click',
                link: this.path,
                text: this.data.text,
                linkTarget: this.target,
                jurisdiction: this.country,
                isAuthenticated: this.isAuthenticated,
                pageUrl: this.$route.path,
            });
        },
    },
};
</script>

<style scoped lang="scss">
a {
    display: block;
}

.button {
    margin: 15px 0;
}
</style>
