<template>
    <EventsView
        :data="eventsData"
        :params="params"
        @action="forwardEvent($event, 'action')"
        @showMessage="forwardEvent($event, 'showMessage')"
    />
</template>

<script>
import { EventsView } from '@agi.packages/sport/components';
export default {
    name: 'Events',
    components: { EventsView },
    props: {
        data: Object,
        params: Object,
    },
    computed: {
        eventsData() {
            return {
                ...this.data.content,
                categoryId: this.data.content.categoryIds?.toString(),
                live: this.data.content.live?.toString(),
                boosted: this.data.content.boosted?.toString(),
                popular: this.data.content.popular?.toString(),
            };
        },
    },
    methods: {
        forwardEvent(event, eventName) {
            this.$emit(eventName, event);
        },
    },
};
</script>
