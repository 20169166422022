<template>
    <div>
        <div class="select-wrapper">
            <div class="select-icon" :style="selectIconStyle" />
            <select
                v-model="selectedOption"
                class="global-select"
                :class="{
                    'with-left-icon': !!icon,
                }"
                v-bind="attrs"
            >
                <option v-for="(option, index) of options" :key="index" :disabled="option.disabled" :value="option.value">
                    {{ option.label }}
                </option>
            </select>
        </div>
        <div v-if="v && v.$error" class="input-field-error">
            <renderer :input="getErrorMessage()" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'SelectField',
    props: {
        attrs: {
            default: () => ({}),
        },
        options: {
            type: Array,
            default: () => [],
        },
        selectedValue: {
            default: null,
        },
        v: {
            type: Object,
        },
        errorMessages: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            selectedOption: null,
            icon: null,
        };
    },
    computed: {
        selectIconStyle() {
            return this.icon ? `background-image: url(${this.icon});` : '';
        },
    },
    watch: {
        selectedOption(seloption) {
            this.setIcon(seloption);
            if (this.v) {
                this.v.$touch();
            }
            this.$emit('input', seloption);
        },
        '$props.selectedValue': {
            immediate: true,
            handler(value) {
                this.selectedOption = value;
            },
        },
    },
    methods: {
        setIcon(selectValue) {
            this.icon = this.options.find((option) => {
                return option.value === selectValue;
            })?.icon;
        },
        getErrorMessage() {
            for (const param of Object.keys(this.v.$params)) {
                if (this.v[param] === false) {
                    return this.errorMessages[param] || this.$t('ui.common.form.error.required');
                }
            }
            return '';
        },
    },
};
</script>

<style scoped lang="scss">
.select-wrapper {
    position: relative;
    background-color: $white-bg;

    .select-icon {
        position: absolute;
        left: 8px;
        top: 50%;
        width: 16px;
        height: 16px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        transform: translateY(-50%);
        z-index: 0;
    }

    .global-select {
        &.with-left-icon {
            padding-left: 30px;
            background-color: transparent;
            position: relative;
            z-index: 1;
        }
    }
}

.input-field-error {
    @extend %form-validation-error;
}
</style>
