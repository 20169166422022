<template>
    <div v-if="data.content.isTocEnabled" class="toc">
        <h2 v-if="data.content.title">{{ data.content.title }}</h2>
        <div v-for="link in getToc()" :key="link.id">
            <a class="underline" :href="'#' + link.id">
                {{ link.text }}
            </a>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { getter } from '@agi.packages/platform';

export default {
    name: 'TocComponent',
    props: ['data'],
    computed: {
        ...mapGetters({
            getPages: getter.GET_PAGES,
        }),
    },
    mounted() {
        this.getToc();
    },
    methods: {
        getToc() {
            const listOfLinks = [];
            const pageName = this.$route.params.pathMatch?.split('/').pop();
            const content = this.getPages[pageName]?.content;

            content.forEach((pageContentFragment) => {
                if (pageContentFragment.type === 'RichText') {
                    const parsedConted = JSON.parse(pageContentFragment.content.content);
                    parsedConted.content.forEach((node) => {
                        if (node.type === 'anchor') {
                            const id = node.attrs.id.split(' ').join('');
                            listOfLinks.push({ id, text: node.attrs.text });
                        }
                    });
                }
            });

            return listOfLinks.filter((link) => link.id && link.text);
        },
    },
};
</script>
<style lang="scss" scoped>
.toc {
    margin: 20px 0;
}
</style>
