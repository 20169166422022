import { getObjectField, isNil } from '../helper';

export function flatDataAttributes(entityData) {
    const id = getObjectField(entityData, 'id');
    const attributes = getObjectField(entityData, 'attributes');

    if (isNil(attributes) || isNil(id)) {
        return entityData;
    }

    return { id, ...attributes };
}
