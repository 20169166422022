import { meetCondition } from './async-utils';

export const version = getVersion();

export function setup() {
    // We do need this on body and not #app,
    // see https://aliengain.atlassian.net/browse/BP-12091
    document.body.classList.add('ios');

    preventZoomOnTextInputs();
    if (isSafari()) {
        hackWhiteSpaceAfterReturningFromLandscape();
    }
}

function preventZoomOnTextInputs() {
    document.querySelector('meta[name="viewport"]').content += ', maximum-scale=1';
}

function hackWhiteSpaceAfterReturningFromLandscape() {
    // A hack for https://aliengain.atlassian.net/browse/BP-12155.
    // The idea is to trigger a scroll _after_ the glitch has happened.
    window.addEventListener('orientationchange', async () => {
        // if we’re transitioning from landscape…
        if (window.innerHeight < window.innerWidth) {
            try {
                // wait for the transition to finish
                await meetCondition(30, 500, 30, () => window.innerHeight > window.innerWidth);
                // at this moment innerHeight has a correct value
                const okHeight = window.innerHeight;
                // but then the glitch happens, increasing the innerHeight
                await meetCondition(150, 1000, 50, () => window.innerHeight > okHeight);
                // and now it’s time to trigger
                window.scrollBy(0, 1);
                // it won’t scroll us anyway (body has height:100%), but just to be sure
                window.scrollBy(0, -1);
            } catch {
                // A timeout was reached. It doesn’t necessarily mean we failed to fix the glitch.
                // Maybe, we didn’t catch the moment the innerHeight was correct
            }
        }
    });
}

function getVersion(userAgent = navigator.userAgent) {
    const match = userAgent.match(/ OS (\d+)_(\d+)(?:_(\d+))? /);
    if (match) {
        const numbers = match.slice(1).map((x) => Number.parseInt(x, 10));
        return {
            major: numbers[0],
            minor: numbers[1],
            patch: numbers[2],
        };
    }
}

function isSafari(userAgent = navigator.userAgent) {
    return /Version\/[\d.]+.+Safari\/[\d.]+$/.test(userAgent);
}

export default {
    setup,
    isSafari,
    version,
};
