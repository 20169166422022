import { badgeType } from '../badge-type-const';

const MESSAGES_MENU_ITEM = 'messages';

const processMenu = {
    processDefaultItemBadge: ({ item, newMessagesNumber }) => {
        if ((item.name || '').toLowerCase() === MESSAGES_MENU_ITEM) {
            if (newMessagesNumber) {
                item.badge = newMessagesNumber.toString();
                item.badgeType = badgeType.NEW_MESSAGES;
            } else {
                item.badge = '';
                item.badgeType = badgeType.DEFAULT;
            }
        }

        return item;
    },
};

export default processMenu;
