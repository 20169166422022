<template>
    <Link :data="data" />
</template>
<script>
import Link from './Link';
export default {
    name: 'Brandname',
    components: { Link },
    props: ['data'],
};
</script>
