<template>
    <div class="cat">
        <img :src="getImage()" />
        <span v-if="data.type"><b>Type:</b> {{ data.type }}</span>
        <span v-if="data.tag"><b>Tag: </b>{{ data.tag }}</span>
        <span v-if="data.text"><b>Text: </b>{{ data.text }}</span>
        <span v-if="!data.type"><b>Data: </b>{{ data }}</span>
    </div>
</template>

<script>
import cat1 from '@/assets/images/develop/cat1.jpg';
import cat2 from '@/assets/images/develop/cat2.jpg';
import cat4 from '@/assets/images/develop/cat4.jpg';
import cat7 from '@/assets/images/develop/cat7.jpg';

export default {
    name: 'LoadingStub',
    props: {
        data: {
            required: true,
        },
    },
    data() {
        return {
            images: [cat1, cat2, cat4, cat7],
        };
    },
    mounted() {
        console.warn('Meow: ', this.$el, this.data);
    },
    methods: {
        getImage() {
            return this.images[Math.floor(Math.random() * this.images.length)];
        },
    },
};
</script>

<style scoped>
.cat {
    border: 1px solid black;
    background: black;
    color: white;
}

img {
    width: 100%;
}
</style>
