import { socialShareType } from '@/modules/sport';

export function getSocialLinks(originLink, text) {
    return {
        [socialShareType.DIRECT]: originLink,
        [socialShareType.FB]: `https://wwww.facebook.com/sharer/sharer.php?u=${originLink}&description=${text}`,
        [socialShareType.WU]: `whatsapp://send?text=${text} ${originLink}`,
        [socialShareType.TWITTER]: `https://twitter.com/intent/tweet?text=${text} ${originLink}`,
    };
}
