import { render, staticRenderFns } from "./PrestoImageWrapper.vue?vue&type=template&id=256f9ed8&scoped=true"
import script from "./PrestoImageWrapper.vue?vue&type=script&lang=js"
export * from "./PrestoImageWrapper.vue?vue&type=script&lang=js"
import style0 from "./PrestoImageWrapper.vue?vue&type=style&index=0&id=256f9ed8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "256f9ed8",
  null
  
)

export default component.exports