<template>
    <div class="error">
        <slot v-if="$slots.header || imgSrc || icon" name="header">
            <template v-if="showComponent">
                <component :is="showComponent.component" :class="showComponent.className" />
            </template>
            <template v-else>
                <img v-if="imgSrc" class="error-image" :src="imgSrc" />
                <SvgIcon v-else-if="icon" :icon-id="icon" class="error-icon" />
            </template>
        </slot>
        <slot>
            <h4 class="error-title">
                {{ title }}
            </h4>
            <p v-for="(text, index) in sectionList" :key="`error-section-${index}`" class="error-description">
                {{ text }}
            </p>
        </slot>
        <slot v-if="$slots.footer || button.text" name="footer">
            <div class="button-wrapper">
                <button class="button button-primary error-button" @click="buttonClick()">
                    {{ button.text }}
                </button>
            </div>
        </slot>
    </div>
</template>

<script>
import { helper } from '@agi.packages/core';

export default {
    name: 'ErrorPage',
    props: {
        imgSrc: {
            type: [String, Object],
            required: false,
        },
        icon: {
            type: [String, Object],
            required: false,
        },
        buttonText: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        sectionList: {
            type: Array,
            default: () => [],
        },
        button: {
            type: Object,
            default: () => ({}),
        },
        trackError: {
            type: String,
            default: null,
        },
    },
    computed: {
        showComponent() {
            const imgComponent = helper.isObject(this.imgSrc) ? this.imgSrc : null;
            const iconComponent = helper.isObject(this.icon) ? this.icon : null;
            if (imgComponent || iconComponent) {
                const { component, className } = imgComponent || iconComponent;
                return { component, className };
            }
            return false;
        },
    },
    mounted() {
        if (this.trackError) {
            this.$gtm.query({ event: this.trackError });
        }
    },
    methods: {
        buttonClick() {
            if (this.button.emit) {
                this.$emit('button:click');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.error {
    margin: 60px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include maxbasic {
        margin: 32px 8px;
    }

    &-image {
        margin-bottom: 12px;
        width: 130px;

        @include xxmaintofull {
            width: 162px;
        }
    }

    &-icon {
        height: 56px;
        width: 56px;
        margin: 22px 0;
        fill: $dark-green;
    }

    &-title {
        text-align: center;
        margin-bottom: 8px;

        @include oldschool {
            font-size: 18px;
            line-height: 21px;
        }
    }

    &-description,
    &-link {
        @extend %body-normal-font-400;
        text-align: center;

        @include oldschool {
            font-size: 18px;
            line-height: 21px;
        }
    }

    &-description {
        color: $disabled-text;
    }

    &-button {
        margin-top: 12px;
        padding: 7.5px 12px;
        font-size: 14px;
        line-height: 17px;

        @include oldschool {
            font-size: 18px;
            line-height: 21px;
            margin: 12px 14px 0 14px;
            white-space: pre-wrap;
        }
    }

    &-link {
        margin: 12px 12px 0;
    }
}
</style>
