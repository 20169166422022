import { ENV, ENV_LIST } from '../env';
import { ENV_NAMES } from '../env-const';

const { VUE_APP_CUSTOM_API_SERVER } = process.env;

export default {
    getCurrentUrl() {
        return location.protocol + '//' + location.hostname;
    },
    getApiUrl() {
        return VUE_APP_CUSTOM_API_SERVER;
    },
    getEnv() {
        return ENV;
    },
    isProd() {
        return ENV_LIST[ENV_NAMES.PRODUCTION];
    },
    isTest() {
        return ENV_LIST[ENV_NAMES.TEST];
    },
    isStaging() {
        return ENV_LIST[ENV_NAMES.STAGING];
    },
    isDev() {
        return ENV_LIST[ENV_NAMES.DEVELOPMENT];
    },
    isCordova() {
        return false;
    },
};
