import { userVerificationStatuses } from '@agi.packages/platform';

/**
 * selects the status with the latest date
 * @param {Object} preferences
 * @param {String[]} statuses
 * @returns {boolean}
 */
export const hasUserVerificationStatus = (preferences, statuses) => {
    const verificationStatus = Object.values(userVerificationStatuses).reduce((latestStatus, status) => {
        return preferences[status] &&
            (new Date(preferences[status]).getTime() > new Date(preferences[latestStatus]).getTime() || !latestStatus)
            ? status
            : latestStatus;
    }, '');

    return !!verificationStatus && statuses.includes(verificationStatus);
};
