import { deviceType } from '@agi.packages/core';

export default {
    data() {
        return {
            previousRoute: null,
            isPresto: deviceType.isPresto(),
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            const { path } = from;
            const {
                meta: { backButtonPathName },
            } = to;
            vm.previousRoute = path !== '/' ? { path } : { name: backButtonPathName };
        });
    },
    methods: {
        goBack() {
            // Opera mini doesn't support window.history.back() from version 24 or less
            if (this.isPresto) {
                return this.$router.push(this.previousRoute);
            }
            this.$router.back();
        },
    },
};
