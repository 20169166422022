import { render, staticRenderFns } from "./Subheadline.vue?vue&type=template&id=6a854a50&scoped=true"
import script from "./Subheadline.vue?vue&type=script&lang=js"
export * from "./Subheadline.vue?vue&type=script&lang=js"
import style0 from "./Subheadline.vue?vue&type=style&index=0&id=6a854a50&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a854a50",
  null
  
)

export default component.exports