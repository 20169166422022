<template>
    <div class="breadcrumbs">
        <div v-for="(crumb, index) in breadcrumbs" :key="'crumb-' + index" class="crumb">
            <router-link :to="{ path: `/${crumb.url}` }">
                {{ crumb.name }}
            </router-link>
            <span class="separator">/</span>
        </div>
        <div>{{ label || link }}</div>
    </div>
</template>

<script>
export default {
    name: 'Breadcrumbs',
    props: {
        breadcrumbs: {
            type: Array,
            default: () => [],
        },
        link: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped lang="scss">
.breadcrumbs {
    display: flex;
    margin-bottom: 26px;

    .crumb {
        display: flex;
    }
    .separator {
        margin: 0 6px;
    }
}
</style>
