<template>
    <div class="general-payment">
        <Deposit
            :show-select="showSelect"
            :polling="true"
            :button-text="buttonText"
            :input-label="inputLabel"
            :is-button-always-active="isButtonAlwaysActive"
            @closeClick="$emit('closeClick')"
        />
    </div>
</template>

<script>
import Deposit from './Deposit.vue';

export default {
    name: 'GeneralPaymentcomponent',
    components: { Deposit },
    props: {
        showSelect: {
            type: Boolean,
            required: false,
        },
        buttonText: {
            type: String,
            required: false,
        },
        inputLabel: {
            type: String,
            required: false,
        },
        isButtonAlwaysActive: {
            type: Boolean,
            required: false,
        },
    },
};
</script>
<style scoped lang="scss">
.general-payment {
    .deposit-form + * {
        margin-top: 16px;
    }

    h3 {
        font-size: 1rem;
        margin-top: 0;
    }

    h4 {
        font-size: 0.8rem;
    }

    p {
        font-size: 14px;

        &:not(:last-child) {
            margin: 0 0 0.8em 0;
        }
    }
}
</style>
