import { mapGetters } from 'vuex';

import { helper } from '@agi.packages/core';
import { getter } from '@/store/modules/translations';

export default {
    name: 'renderer',
    props: {
        input: {
            type: String,
            required: true,
        },
        options: {
            type: Object,
            default: () => {},
        },
        regex: {
            type: RegExp,
            default: undefined,
        },
    },
    render(h) {
        const self = this;
        return h({
            template: `<span>${this.context}</span>`,
            methods: {
                clickEvent(event) {
                    self.$emit('clickEvent', { event });
                },
            },
        });
    },
    computed: {
        ...mapGetters({
            pinOrPassword: getter.PIN_OR_PASSWORD,
        }),
        context() {
            if (this.input.match(/{{(.*?)}}/g)) {
                const props = [...new Set(this.input.match(/{{(.*?)}}/g))]
                    .map((it) => it.replace('{{', '').replace('}}', ''))
                    .reduce((acc, prop) => {
                        const value = this.$t(`ui.common.${this.pinOrPassword}.${prop}`, { indefinite: true });
                        return { ...acc, ...(value && { [prop]: value }) };
                    }, {});
                return helper.interpolate(this.input, { ...props, ...this.options }, this.regex);
            } else {
                return this.input;
            }
        },
    },
};
