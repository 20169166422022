<template>
    <li>{{ data.text }}</li>
</template>
<script>
export default {
    name: 'Unordereditem',
    props: ['data'],
};
</script>
<style lang="scss" scoped>
li {
    padding: 0 0 0 20px;
}
</style>
