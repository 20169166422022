<template>
    <MainMenu v-if="showDropDown" :menus="menus" :config="config" />
</template>

<script>
import { mapState } from 'vuex';
import MainMenu from './MainMenu';

export default {
    name: 'TopMenuDropdown',
    components: { MainMenu },
    computed: {
        ...mapState({
            showDropDown: (state) => state.ui.topMenuOpen,
            menus: (state) => state.menus.top,
            config: (state) => state.platform.config?.topMenu,
        }),
    },
};
</script>

<style lang="scss" scoped></style>
