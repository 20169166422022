<template>
    <BaseCashOut is-old-cash-out />
</template>

<script>
import BaseCashOut from '@/modules/sport/components/Fragments/Cashout/BaseCashOut.vue';

export default {
    name: 'CashOut',
    components: { BaseCashOut },
};
</script>

<style scoped lang="scss">
::v-deep .request-offer {
    border: 1px solid $dark-grey;
    padding: 8px;
    margin-top: 8px;

    &.error {
        border-color: $error-red;
    }

    &.warn {
        border-color: $golden-brown;
    }

    .offer-message {
        .cashout-icon {
            margin: -3px 8px 0;
        }
    }

    ::v-deep .icon-white-color {
        fill: $white-text;
    }
}
</style>
