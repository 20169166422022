<template>
    <div v-if="ribbon" class="casino-item-ribbon" :style="dynamicStyles" @click="openCasinoGame">
        {{ ribbon.name }}
    </div>
</template>

<script>
import { getObjectField } from '@/modules/core/utils/helper';

export default {
    name: 'CasinoGameRibbon',
    props: {
        game: Object,
    },
    computed: {
        ribbon() {
            const ribbonData = getObjectField(this.game, 'ribbon');
            if (!ribbonData) {
                return null;
            }

            const { textColor, backgroundColor, name } = ribbonData;
            return {
                textColor,
                backgroundColor,
                name,
            };
        },
        dynamicStyles() {
            return {
                color: this.ribbon.textColor,
                background: this.ribbon.backgroundColor,
            };
        },
    },
    methods: {
        openCasinoGame() {
            this.$emit('onOpenCasinoGame');
        },
    },
};
</script>

<style scoped lang="scss">
.casino-item-ribbon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    border-radius: 8px 0 8px 0;
    padding: 2px 4px;
    @extend %caption-font-700;
}
</style>
