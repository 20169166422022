<template>
    <RangeSlider
        :min-text="$t('ui.common.minLabel')"
        :max-text="$t('ui.common.maxLabel')"
        :number-formatter="numberFormat"
        v-bind="$attrs"
        @input="onValueChanged"
    />
</template>

<script>
import RangeSlider from '@/components/RangeSlider.vue';
import { numberFormat } from '@/modules/core/utils/helper';

export default {
    name: 'LabeledRangeSlider',
    components: { RangeSlider },
    methods: {
        numberFormat,
        onValueChanged(v) {
            this.$emit('input', v);
        },
    },
};
</script>
