<template>
    <div class="call-me-component">
        <h3 v-if="isTitleVisible">{{ title }}</h3>
        <form @submit.prevent="callMeRequest() && trackCallMe()">
            <div class="form-content">
                <div v-if="errorMessage" class="notify error">
                    <renderer :input="errorMessage" />
                </div>
                <div v-if="isCallRequested && successMessage" class="notify success">
                    <slot name="successMessage" />
                    <template v-if="!$slots.successMessage">{{ successMessage }}</template>
                </div>
                <PhoneNumber
                    v-if="!isCallRequested"
                    form-name="call-me-form"
                    data-test-id="callMePhone"
                    :value="phone"
                    :disabled="!!phoneNumber"
                    :label="$t('ui.callMeComponent.mobileNumber')"
                    class="phone-wrapper"
                    v-bind="{
                        ...(phoneNumberValidation && {
                            placeholder: phoneNumberValidation.placeholder,
                            v: $v.phone,
                            errorMessages: { phoneNumberFormat: $t(phoneNumberValidation.errorMessageKey) },
                        }),
                    }"
                    @value="phone = $event"
                />
                <div v-if="isReasonVisible" class="select">
                    <div class="select-header" @click="isReasonSelectCollapsed = !isReasonSelectCollapsed">
                        <div class="select-header-icon">
                            <SvgIcon
                                class="icon-size-medium"
                                :icon-id="isReasonSelectCollapsed ? 'arrow_down' : 'icon-arrow-up'"
                                vertical-align="top"
                            />
                        </div>
                        <span class="select-header-title text-small">{{ $t('ui.callMeComponent.tellUsMore') }}</span>
                    </div>
                    <div v-show="!isReasonSelectCollapsed" class="select-content">
                        <label for="call-me-reason-select" class="select-label text">
                            {{ $t('ui.callMeComponent.howCanWeHelp') }}
                            <span class="select-label-optional">
                                {{ $t('ui.callMeComponent.optional') }}
                            </span>
                        </label>
                        <select
                            id="call-me-reason-select"
                            v-model="selectedCategoryId"
                            class="global-select"
                            :class="{ 'placeholder-shown': !selectedCategoryId }"
                        >
                            <option value="" selected>{{ $t('ui.callMeComponent.pickOption') }}</option>
                            <option v-for="category in callMeCategories" :key="category.id" :value="category.id">
                                {{ category.reason }}
                            </option>
                        </select>
                    </div>
                </div>
                <div v-if="Number.isInteger(estimatedWaitingTimeMin) && estimatedWaitingTimeMin >= 0" class="expect-bar">
                    <span class="expect-bar-title text">{{ $t('ui.callMeComponent.expectCallIn') }}</span>
                    <div class="expect-bar-content">
                        <div class="expect-bar-item" :class="{ active: isExpectationFree }">
                            <div class="expect-bar-status free" />
                            <span class="expect-bar-text text-small">
                                {{ $t('ui.callMeComponent.estimateTime', [`0-${expectationType.FREE}`]) }}
                            </span>
                        </div>
                        <div class="expect-bar-item" :class="{ active: isExpectationBusy }">
                            <div class="expect-bar-status busy" />
                            <span class="expect-bar-text text-small">
                                {{ $t('ui.callMeComponent.estimateTime', [`${expectationType.FREE}-${expectationType.BUSY}`]) }}
                            </span>
                        </div>
                        <div class="expect-bar-item" :class="{ active: isExpectationVeryBusy }">
                            <div class="expect-bar-status very-busy" />
                            <span class="expect-bar-text text-small">
                                {{ $t('ui.callMeComponent.estimateTime', [`+${expectationType.BUSY}`]) }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <input
                v-if="!isCallRequested"
                type="submit"
                data-test-id="callMeButton"
                class="button button-primary button-full"
                :value="buttonText"
                :disabled="isCallButtonDisabled"
            />
        </form>
        <div class="call-me-footer">
            <div v-if="isCallRequested && selectedCategory" class="call-me-summary text">
                <div class="call-me-result">
                    <span v-if="selectedCategory.reason">
                        <span class="bold">{{ $t('ui.callMeComponent.reason') }}</span>
                        {{ selectedCategory.reason }}
                    </span>
                    <div v-if="selectedCategory.text" class="call-me-result-additional">
                        <renderer :input="selectedCategory.text" />
                    </div>
                </div>
            </div>
            <div v-if="!isExpectationFree || !Number.isInteger(estimatedWaitingTimeMin)" class="call-me-summary text">
                <div class="call-me-busy-info">
                    <span><renderer :input="$t('ui.callMeComponent.important')" /></span>
                    <div v-if="mediaLinks" class="socials">
                        <a v-if="mediaLinks.facebook" class="socials-button button button-full" :href="mediaLinks.facebook">
                            <div class="socials-button-icon">
                                <SvgIcon icon-id="icon-facebook-white" class="icon-size-medium" vertical-align="top" />
                            </div>
                            {{ $t('ui.callMeComponent.socials.facebook') }}
                        </a>
                        <span v-if="mediaLinks.telegram && mediaLinks.facebook" class="socials-divider">{{ $t('ui.common.or') }}</span>
                        <a v-if="mediaLinks.telegram" class="socials-button button button-full" :href="mediaLinks.telegram">
                            <div class="socials-button-icon">
                                <SvgIcon icon-id="icon-telegram-white" class="icon-size-medium" vertical-align="top" />
                            </div>
                            {{ $t('ui.callMeComponent.socials.telegram') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import PhoneNumber from '@/components/PhoneNumber.vue';
import { helper, getter as coreGetter } from '@agi.packages/core';
import { action, mutation, auth, getter } from '@agi.packages/platform';
import { required } from 'vuelidate/lib/validators';

const expectationType = {
    FREE: 5,
    BUSY: 10,
};

const minuteInMilliseconds = 60 * 1000;

export default {
    name: 'Callmecomponent',
    components: { PhoneNumber },
    props: {
        data: {
            type: Object,
            required: false,
        },
        phoneNumber: {
            type: String,
            required: false,
        },
    },
    emits: ['callRequested'],
    data() {
        return {
            expectationType,
            phone: '',
            isReasonSelectCollapsed: true,
            selectedCategoryId: '',
            isCallRequested: false,
            resetTimer: null,
        };
    },
    computed: {
        ...mapState({
            errorMessage: (state) => state.platform.callMeRequest.errorMessage,
            successMessage: (state) => state.platform.callMeRequest.successMessage,
            phonePrefix: (state) => state.platform.settings?.brandIdentity?.phoneCountryCode,
            mediaLinks: (state) => state.platform.settings?.brand?.media || {},
            estimatedWaitingTimeMin: (state) => state.platform.callMeData.estimatedWaitingTimeMin,
            callMeCategories: (state) => state.platform.callMeData.callMeCategories,
        }),
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
            storedPhoneNumber: auth.getter.GET_STORED_PHONE_NUMBER,
            trackingId: auth.getter.GET_RESET_PASSWORD_TRACKING_ID,
            brandPreference: getter.GET_BRAND_PREFERENCE,
            country: getter.GET_COUNTRY,
            phoneNumberValidation: getter.GET_PHONE_NUMBER_VALIDATION,
        }),
        selectedCategory() {
            return this.callMeCategories.find((category) => category.id === this.selectedCategoryId);
        },
        isCallButtonDisabled() {
            return this.formIsLoading || (this.phoneNumberValidation && this.$v.$invalid);
        },
        formIsLoading() {
            return this.isLoading(action.CALL_ME_REQUEST);
        },
        isExpectationFree() {
            return this.estimatedWaitingTimeMin <= expectationType.FREE;
        },
        isExpectationBusy() {
            return this.estimatedWaitingTimeMin > expectationType.FREE && this.estimatedWaitingTimeMin <= expectationType.BUSY;
        },
        isExpectationVeryBusy() {
            return this.estimatedWaitingTimeMin > expectationType.BUSY;
        },
        title() {
            if (this.isCallRequested) return this.$t('ui.callMeComponent.callRequested');
            return this.data?.content?.title || this.$t('ui.callMeComponent.requestCall');
        },
        buttonText() {
            return this.data?.content?.buttonText || this.$t('ui.callMeComponent.callMe');
        },
        isTitleVisible() {
            return !this.data?.content?.hideTitle;
        },
        isReasonVisible() {
            return !this.data?.content?.hideReasonField && !this.isCallRequested && this.callMeCategories.length;
        },
    },
    beforeMount() {
        this.setCallMeStatus({});
    },
    beforeDestroy() {
        this.setCallMeStatus({});
        if (this.resetTimer) {
            clearTimeout(this.resetTimer);
        }
    },
    validations() {
        return {
            phone: {
                required,
                ...(this.phoneNumberValidation && { phoneNumberFormat: this.phoneNumberValidation.validator }),
            },
        };
    },
    mounted() {
        if (this.phoneNumberValidation && this.phone) this.$v.phone.$touch();
    },
    created() {
        this.phone = helper.removeCountryCode(this.storedPhoneNumber || this.phoneNumber, this.phonePrefix) || '';
        if (!this.isLoading(action.GET_CALL_ME_DATA)) {
            this.$store.dispatch(action.GET_CALL_ME_DATA, { root: true });
        }
    },
    methods: {
        ...mapActions({
            callMeRequest: action.CALL_ME_REQUEST,
        }),
        ...mapMutations({
            setCallMeStatus: mutation.SET_CALL_ME_STATUS,
        }),
        callMeRequest() {
            const resetTime =
                this.estimatedWaitingTimeMin && this.isExpectationFree
                    ? expectationType.FREE * minuteInMilliseconds
                    : expectationType.BUSY * minuteInMilliseconds;

            this.$store
                .dispatch(action.CALL_ME_REQUEST, {
                    phoneNumber: helper.formatPhoneNumber(this.phone, this.phonePrefix, this.brandPreference.phoneNumberFormat),
                    categoryId: this.selectedCategoryId,
                })
                .then((success) => {
                    if (!success) return;
                    this.isCallRequested = true;
                    this.$emit('callRequested', true);
                    if (resetTime) {
                        this.resetTimer = setTimeout(() => {
                            this.reset();
                        }, resetTime);
                    }
                });
        },
        trackCallMe() {
            this.$gtm.query({
                event: 'contact',
                trackingId: this.trackingId,
                reason: 'request a call',
            });
        },
        reset() {
            this.setCallMeStatus({});
            this.isCallRequested = false;
            this.selectedCategoryId = '';
            this.isReasonSelectCollapsed = true;
        },
    },
};
</script>
<style scoped lang="scss">
.call-me-component {
    margin-bottom: 20px;
    padding: 16px;
    background: $light-grey-bg;
    border-radius: $border-radius;

    h3 {
        font-size: 18px;
        line-height: 1.2em;
        text-transform: uppercase;
        margin: 0 0 16px 0;
    }

    ul {
        padding-left: 20px;
    }

    .form-content {
        padding-bottom: 16px;

        & > div:not(:first-of-type) {
            margin-top: 16px;
        }
    }

    .phone-wrapper {
        ::v-deep .input-field {
            margin: 0;
        }
        ::v-deep .country-code {
            color: #aaaeb0;
        }
    }

    .select {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        select {
            option {
                color: black;
            }
            option[value=''] {
                color: grey;
            }
            &.placeholder-shown {
                color: #aaaeb0;
            }
        }

        &-header {
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;

            &-icon {
                margin-right: 6px;
                height: 16px;
            }
        }

        &-label {
            margin-top: 0;

            &-optional {
                color: #aaaeb0;
            }
        }

        &-content {
            padding: 16px 0;
            border-bottom: 1px solid $greyish;
            width: 100%;
        }
    }

    .expect-bar {
        &-title {
            font-style: italic;
            font-weight: 500;
        }

        &-content {
            margin-top: 5px;
            display: flex;
            justify-content: space-between;
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 0 1 32%;
            position: relative;
            min-height: 30px;
        }

        &-status {
            height: 6px;
            width: 100%;

            &.free {
                background-color: $primary-color;
            }
            &.busy {
                background-color: #add601;
            }
            &.very-busy {
                background-color: $golden-brown;
            }
        }

        &-text {
            margin: 6px auto 0 auto;
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            white-space: nowrap;
        }

        &-item.active {
            .expect-bar-status {
                border: 1px solid black;
            }
            .expect-bar-text {
                font-weight: bold;
                display: flex;
                justify-content: center;
            }
        }
    }

    .call-me-summary {
        padding-top: 16px;
        border-top: 1px solid $greyish;
        margin-top: 16px;

        &:first-child {
            margin-top: 0;
        }
    }

    .call-me-result-additional {
        margin-top: 6px;

        ul {
            margin-top: 6px;
        }
    }

    .socials {
        display: flex;
        align-items: center;
        margin-top: 16px;

        &-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $betpawa-black;
            color: white;
            font-weight: normal;
            text-transform: unset;

            &-icon {
                height: 16px;
                margin-right: 6px;
            }
        }

        &-divider {
            margin: 8px;
        }

        @include maxbasic {
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &-button {
                max-width: 125px;
            }
        }
    }
}
</style>
