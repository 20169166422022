<template>
    <MainMenu :menus="menus" :config="config" :is-bottom="true" />
</template>

<script>
import { mapState } from 'vuex';
import MainMenu from './MainMenu';

export default {
    name: 'BottomMenu',
    components: { MainMenu },
    computed: {
        ...mapState({
            menus: (state) => state.menus.bottom,
            config: (state) => state.platform.config?.bottomMenu,
        }),
    },
};
</script>

<style scoped lang="scss"></style>
