export const jackpotType = {
    SINGLE: 'SINGLE_EVENT',
    MULTIJURISDICTION: 'MULTIJURISDICTION',
    MULTI: 'MULTI_EVENTS',
};

export const jackpotStatus = {
    WON: 'WON',
    PENDING: 'PENDING',
    ONGOING: 'ONGOING',
    CLOSED: 'CLOSED',
};

export const jackpotRequestType = {
    ACTIVE: 'active',
    RESULTS: 'resulted',
};
