<template>
    <div :id="widgetId" ref="container" class="cloudflare-widget" />
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { deviceType } from '@agi.packages/core';
import { auth } from '@agi.packages/platform';

import { widgetSize } from '@/js/plugins/cloudflare/const';
import { errorCodes } from '@/js/captcha-const';

export default {
    name: 'CaptchaWidget',
    props: {
        widgetId: {
            type: String,
            required: false,
            default: 'captcha-widget',
        },
    },
    data: () => ({
        isPresto: deviceType.isPresto(),
    }),
    computed: {
        ...mapGetters({
            needUpdate: auth.getter.NEED_UPDATE_SECURED_TOKEN,
            token: auth.getter.SECURED_TOKEN,
        }),
        accessDeniedError() {
            return this.$t('errors.ACCESS_DENIED');
        },
        byPass() {
            const { protection } = this.$route.query;
            return this.isPresto || protection;
        },
        hasToken() {
            return this.byPass || this.token;
        },
        isEnabled() {
            return !this.byPass;
        },
    },
    watch: {
        needUpdate(value) {
            if (value) {
                this.$store.dispatch(auth.action.NEED_UPDATE_SECURED_TOKEN, { needUpdate: false });
                this.callCloudflare({ needUpdateSecuredToken: value });
            }
        },
    },
    mounted() {
        this.enableAccess(this.hasToken);
        this.callCloudflare({ needUpdateSecuredToken: this.needUpdate });
    },
    beforeDestroy() {
        this.enableAccess();
        this.resetCaptchaError();
        this.isEnabled && this.$cloudflare.turnstile && this.$cloudflare.turnstile.remove();
    },
    methods: {
        ...mapActions({
            enableAccess: auth.action.ENABLE_ACCESS,
        }),
        ...mapMutations({
            setCaptchaError: auth.mutation.SET_CAPTCHA_ERROR,
            resetCaptchaError: auth.mutation.RESET_CAPTCHA_ERROR,
        }),
        callback(token) {
            this.resetCaptchaError();
            this.enableAccess(token).then(() => {
                this.$emit('token', token);
            });
        },
        expired(expired) {
            this.enableAccess().then(() => {
                this.resetCaptcha();
                this.handleError(this.accessDeniedError, { expired, errorCode: errorCodes.EXPIRED });
            });
        },
        error(error) {
            this.enableAccess().then(() => {
                this.resetCaptcha();
                this.handleError(this.accessDeniedError, { error, errorCode: errorCodes.UNKNOWN });
            });
        },
        timeout(timeout) {
            this.enableAccess().then(() => {
                this.resetCaptcha();
                this.handleError(this.accessDeniedError, { timeout, errorCode: errorCodes.TIMEOUT }, false);
            });
        },
        handleError(message, data, emitEvent = true) {
            if (emitEvent) {
                this.setCaptchaError(message || '');
                this.$emit('error', message || '');
            }

            this.$sentry.withScope((scope) => {
                scope.setExtras({ message, data });
                scope.setTag('scope', 'turnstile');
                scope.setTag('turnstile', 'error');
                scope.setLevel('fatal');
                this.$sentry.captureMessage(`FATAL_ERROR_${data.errorCode}`);
            });

            message && console.error(message, data);
        },
        callCloudflare({ needUpdateSecuredToken }) {
            this.isEnabled &&
                this.$cloudflare
                    .bootstrap({
                        callback: this.callback,
                        expired: this.expired,
                        error: this.error,
                        timeout: this.timeout,
                        widgetOptions: {
                            size: this.$mq.xsUp ? widgetSize.NORMAL : widgetSize.COMPACT,
                        },
                        id: `#${this.widgetId}`,
                    })
                    .catch((error) => {
                        this.handleError(this.accessDeniedError, {
                            error,
                            reason: 'Challenge not loaded!',
                            errorCode: errorCodes.NOT_LOADED,
                            needUpdateSecuredToken,
                        });
                    });
        },
        resetCaptcha() {
            this.$cloudflare.turnstile && this.$cloudflare.turnstile.reset();
        },
    },
};
</script>

<style scoped lang="scss">
.cloudflare-widget {
    &:has(div) {
        text-align: center;
        padding: 0 0 16px 0;
    }
}
</style>
