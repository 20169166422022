<template>
    <router-link v-if="isInternal" :to="{ path: href }">
        <slot />
    </router-link>
    <a v-else :href="href" target="_blank">
        <slot />
    </a>
</template>

<script>
/* We need this wrapper component since router link currently doesn't support external links
For further details have a look at https://github.com/vuejs/vue-router/issues/1280 */
import { helper } from '@agi.packages/core';

export default {
    name: 'ExtendedLink',
    props: {
        link: {
            type: String,
            require: true,
        },
    },
    computed: {
        href() {
            return this.$formulateLink(this.link);
        },
        isInternal() {
            return this.$isInternalLink(this.link);
        },
    },
    methods: {
        $formulateLink: helper.formulateLink,
        $isInternalLink: helper.isInternalLink,
    },
};
</script>
