import { deviceType } from '@agi.packages/core';

export const SCROLL_TOP_HASH = '#top';

export function prestoScrollTop(router, route) {
    if (deviceType.isPresto()) {
        window.location.hash = '';
        if (route) {
            router.push({ ...route, hash: SCROLL_TOP_HASH });
            return;
        }
        router.push(SCROLL_TOP_HASH);
    }
}
