import { render, staticRenderFns } from "./LiveVideoStreamLabel.vue?vue&type=template&id=12357d82&scoped=true"
import script from "./LiveVideoStreamLabel.vue?vue&type=script&lang=js"
export * from "./LiveVideoStreamLabel.vue?vue&type=script&lang=js"
import style0 from "./LiveVideoStreamLabel.vue?vue&type=style&index=0&id=12357d82&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12357d82",
  null
  
)

export default component.exports