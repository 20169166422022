export const SPORT_CATEGORY_MAP = [
    { name: 'football', key: 2, markets: ['1', 'X', '2'] },
    { name: 'basketball', key: 3, markets: ['1', '2'] },
    { name: 'tennis', key: 452, markets: ['1', '2'] },
    { name: 'rugby', key: 453, markets: ['1', 'X', '2'] },
    { name: 'boxing', key: 454, markets: ['1', 'X', '2'] },
    { name: 'motorsport', key: 455, markets: ['1'] },
    { name: 'chess', key: 456, markets: ['1', '2'] },
    { name: 'rugby-league', key: 458, markets: ['1', 'X', '2'] },
    { name: 'american-football', key: 459, markets: ['1', '2'] },
    { name: 'volleyball', key: 461, markets: ['1', '2'] },
    { name: 'cricket', key: 462, markets: ['1', '2'] },
    { name: 'table-tennis', key: 463, markets: ['1', '2'] },
    { name: 'ice-hockey', key: 464, markets: ['1', 'X', '2'] },
];
