import { mutation } from './index';

export function actionLoader(globalActionType, actionFn) {
    return function (context, payload) {
        const payloadActionName = payload && payload.type ? `${globalActionType}/${payload.type}` : globalActionType;
        this.commit(mutation.START_LOAD, payloadActionName);
        return actionFn
            .call(this, context, payload)
            .catch((error) => {
                console.error(`Failed to load action ${payloadActionName}`, error);
                throw error;
            })
            .finally(() => {
                this.commit(mutation.END_LOAD, payloadActionName);
            });
    };
}
