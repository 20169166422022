export const progressiveJpType = {
    SPORT: 'sport',
    CASINO: 'casino',
};

export const progressiveJpTypes = Object.values(progressiveJpType);

export const progressiveJpPagePath = {
    [progressiveJpType.SPORT]: '/sports-jp-rules',
    [progressiveJpType.CASINO]: '/casino-jp-rules',
};
