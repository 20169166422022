<template>
    <svg width="217" height="200" viewBox="0 0 217 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M76.7198 185.139C76.7198 193.347 59.7346 200.001 38.7823 200.001C17.83 200.001 0.844727 193.347 0.844727 185.139C0.844727 176.931 16.4037 187.253 37.3561 187.253C58.3084 187.253 76.7198 176.931 76.7198 185.139Z"
            fill="#C4C4C4"
        />
        <path
            d="M201.916 178.236C201.192 184.46 181.145 187.24 157.14 184.447C133.134 181.654 114.261 174.345 114.986 168.121C115.71 161.897 132.417 156.75 156.679 164.69C174.766 171.749 199.028 176.16 201.916 178.236Z"
            fill="#C4C4C4"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M198.331 177.699L204.713 164.99L194.779 157.289L185.153 174.322L198.331 177.699ZM150.736 108.315L125.957 133.498L122.554 168.43L114.749 164.814L119.388 130.107L144.721 105.556L150.736 108.315ZM177.336 143.921L185.59 150.687L175.72 170.783L165.669 168.537L177.336 143.921ZM170.591 138.856L162.536 132.186L146.401 163.832L156.993 165.971L170.591 138.856ZM145.782 141.892C149.883 133.585 153.598 129.658 156.232 126.966L150.94 123.208C150.75 123.34 150.521 123.491 150.257 123.665C147.72 125.335 142.005 129.097 137.773 137.934C133.437 146.985 132.163 156.198 133.699 159.461L139.416 162.077C139.658 161.166 139.91 160.099 140.19 158.912C141.265 154.351 142.76 148.013 145.782 141.892ZM150.703 120.893L152.085 109.327L128.522 133.502L124.393 169.187L131.755 161.219C129.181 154.85 130.238 148.198 132.697 141.127C135.613 132.741 141.496 124.798 150.703 120.893Z"
            fill="#252A2D"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M198.332 177.702L204.713 164.992L194.779 157.291L185.153 174.324L198.332 177.702ZM185.591 150.687L177.337 143.922L165.669 168.537L175.721 170.783L185.591 150.687ZM162.536 132.188L170.591 138.858L156.993 165.972L146.401 163.834L162.536 132.188ZM156.233 126.966C153.598 129.658 149.883 133.585 145.782 141.892C142.76 148.013 141.266 154.351 140.19 158.912C139.91 160.099 139.659 161.165 139.416 162.076L133.699 159.46C132.164 156.198 133.438 146.985 137.773 137.934C142.006 129.097 147.721 125.335 150.257 123.664C150.521 123.491 150.751 123.34 150.94 123.208L156.233 126.966Z"
            fill="#8DC63F"
        />
        <ellipse cx="107.624" cy="180.658" rx="41.2119" ry="11.3448" fill="#C4C4C4" />
        <path d="M103.545 182.841L93.053 177.245L77.1963 71.5419L92.9338 62.7988L103.545 182.841Z" fill="#252A2D" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M47.6608 104.573L33.4418 104.308L30.7874 116.594L50.3076 117.917L47.6608 104.573ZM5.64258 177.47L39.0586 188.939L72.0335 176.918L72.1438 185.52L38.8381 196.328L5.75286 184.087L5.64258 177.47ZM26.2659 138.099L28.8024 127.732L51.19 127.953L53.506 137.989L26.2659 138.099ZM24.6114 146.37L22.0748 156.516L57.5863 157.399L54.9395 146.922L24.6114 146.37ZM38.066 167.435C28.8021 167.324 23.6555 165.67 20.0897 164.457L18.9868 170.854C19.1877 170.968 19.423 171.109 19.6936 171.272C22.2957 172.839 28.1578 176.368 37.9557 176.368C47.9915 176.368 56.851 173.537 59.1302 170.743L59.0199 164.457C58.0936 164.632 57.0229 164.866 55.8313 165.126L55.8312 165.126C51.2538 166.126 44.8914 167.516 38.066 167.435ZM17.0018 172.067L5.97339 175.817L37.9557 186.625L71.9232 174.935L61.5565 171.736C56.9246 176.809 50.4682 178.73 43.0288 179.566C34.2061 180.559 24.5011 178.684 17.0018 172.067Z"
            fill="#8DC63F"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.0018 172.067L5.97339 175.817L37.9557 186.625L71.9232 174.935L61.5565 171.736C56.9246 176.809 50.4682 178.73 43.0288 179.566C34.2061 180.559 24.5011 178.684 17.0018 172.067ZM5.64258 177.47L39.0586 188.94L72.0335 176.919L72.1438 185.521L38.8381 196.329L5.75286 184.087L5.64258 177.47Z"
            fill="#252A2D"
        />
        <path
            d="M106.676 3.48638L64.8392 0L39.2725 38.9312L61.9339 75.5382L110.162 76.1193L131.08 37.7691L106.676 3.48638Z"
            fill="#8DC63F"
        />
        <path
            d="M80.1273 50.1522C78.3925 50.1522 76.6576 50.1441 74.9228 50.1603C74.6502 50.1603 74.4849 50.1036 74.328 49.8526C73.2293 48.0788 72.0975 46.3213 70.9905 44.5475C70.8831 44.3774 70.8335 44.1183 70.8666 43.9158C71.4448 40.2225 72.0396 36.5373 72.6179 32.844C72.6675 32.5525 72.7831 32.3338 73.0227 32.1394C75.2945 30.3414 77.5416 28.519 79.8134 26.7291C79.9869 26.5914 80.2512 26.4942 80.4743 26.4942C83.9274 26.4699 87.3888 26.4618 90.842 26.4375C91.1394 26.4375 91.3046 26.5104 91.4616 26.7696C92.5686 28.5433 93.6921 30.3009 94.7991 32.0746C94.8982 32.2366 94.9478 32.4796 94.9147 32.6659C94.3365 36.3591 93.7417 40.0443 93.1634 43.7376C93.1138 44.0292 93.0229 44.2479 92.7751 44.4503C90.4868 46.2646 88.215 48.1031 85.9266 49.9255C85.7697 50.047 85.5301 50.1198 85.3318 50.1198C83.597 50.136 81.8621 50.1279 80.1273 50.1279C80.1273 50.1279 80.1273 50.1441 80.1273 50.1522ZM78.2107 44.2317C78.3677 44.2398 78.4668 44.2479 78.5577 44.2479C80.7882 44.2398 83.0187 44.2155 85.2492 44.2317C85.6127 44.2317 85.7036 44.1021 85.7531 43.7781C86.1992 40.9271 86.6619 38.0762 87.1162 35.2333C87.2649 34.2938 87.4136 33.3462 87.5706 32.3905C85.0757 32.3905 82.6222 32.3905 80.1356 32.3905C79.4912 36.3348 78.8551 40.263 78.2107 44.2317Z"
            fill="#252A2D"
        />
        <path
            d="M104.292 50.1522C102.574 50.1522 100.847 50.1441 99.1288 50.1603C98.8562 50.1603 98.691 50.0956 98.534 49.8526C97.4353 48.0788 96.3035 46.3213 95.1965 44.5475C95.0891 44.3774 95.0396 44.1183 95.0726 43.9158C95.6509 40.2225 96.2457 36.5373 96.824 32.844C96.8736 32.5525 96.9892 32.3338 97.2288 32.1394C99.5006 30.3414 101.748 28.519 104.019 26.7291C104.193 26.5914 104.457 26.4942 104.68 26.4942C108.133 26.4699 111.595 26.4618 115.048 26.4375C115.345 26.4375 115.511 26.5104 115.668 26.7696C116.775 28.5433 117.898 30.3009 119.005 32.0746C119.104 32.2366 119.154 32.4796 119.121 32.6659C118.543 36.3591 117.948 40.0443 117.369 43.7376C117.32 44.0292 117.229 44.2479 116.981 44.4503C114.693 46.2646 112.421 48.1031 110.133 49.9255C109.976 50.047 109.736 50.1198 109.538 50.1198C107.787 50.136 106.043 50.1279 104.292 50.1279C104.292 50.1279 104.292 50.1441 104.292 50.1522ZM111.777 32.3905C109.265 32.3905 106.812 32.3905 104.333 32.3905C103.689 36.351 103.053 40.2711 102.417 44.2074C104.92 44.2074 107.382 44.2074 109.877 44.2074C110.513 40.2549 111.149 36.3348 111.777 32.3905Z"
            fill="#252A2D"
        />
        <path
            d="M51.2227 47.2773C52.1066 46.5645 52.9492 45.8761 53.8001 45.1957C54.3949 44.7098 55.0063 44.24 55.5928 43.746C55.8158 43.5597 55.9646 43.5354 56.2206 43.7055C57.3855 44.4992 58.6659 44.8718 60.0951 44.7908C62.1356 44.6693 63.6061 42.9927 63.3913 41.0003C63.2839 40.0122 62.4991 39.2509 61.3591 39.0484C59.9712 38.8054 58.6825 39.1375 57.4598 39.753C57.212 39.8826 57.0302 39.8826 56.7907 39.7368C55.8406 39.1456 54.8823 38.5786 53.8993 37.9793C54.0893 37.0721 54.271 36.165 54.461 35.2498C55.0889 32.2207 55.5763 29.1996 56.1959 26.1705C56.2289 25.968 56.295 25.8789 56.5346 25.8789C61.1195 25.887 68.6537 25.887 73.2469 25.887C73.3047 25.887 73.3542 25.8951 73.4699 25.8951C73.3377 26.0166 73.2469 26.0976 73.1477 26.1705C71.1733 27.766 69.1989 29.3616 67.2162 30.941C67.0593 31.0625 66.8197 31.1516 66.6214 31.1516C65.6053 31.1758 61.6317 31.1677 60.6073 31.1596C60.3677 31.1596 60.2934 31.2406 60.2521 31.4674C60.0456 32.5284 59.9547 33.5813 59.7399 34.6423C59.7316 34.6828 59.7399 34.7314 59.7399 34.7638C60.3925 34.6181 61.0204 34.4318 61.6647 34.3427C63.6557 34.0754 65.5805 34.286 67.2906 35.428C68.695 36.3594 69.4219 37.7282 69.5541 39.348C69.7854 42.1666 69.0585 44.7341 67.1419 46.8966C65.6301 48.6055 63.6805 49.5856 61.4417 49.9986C58.889 50.4684 56.4024 50.274 54.0149 49.1968C52.9905 48.7189 52.0818 48.0872 51.2227 47.2773Z"
            fill="#252A2D"
        />
    </svg>
</template>

<script>
export default {
    name: 'UnexpectedError',
    props: {},
};
</script>

<style scoped></style>
