<script>
function createColNodes(h, data) {
    let cols = [];
    const colsWidth = data[0].content.map((item) => item.attrs.colwidth);

    colsWidth.forEach((item) => {
        if (item) {
            cols.push(h('col', { style: { minWidth: `${item[0]}px` } }));
        } else {
            cols.push(h('col'));
        }
    });

    return cols;
}

export default {
    name: 'TableColgroup',
    functional: true,
    props: {
        data: {
            default: () => [],
        },
    },
    render(h, context) {
        return h('colgroup', createColNodes(h, context.props.data));
    },
};
</script>
