<script>
import { DynamicComponent } from '@agi.packages/platform/components';

function createCellNodes(h, cellContent) {
    let nodes = [];
    cellContent.forEach((item) => nodes.push(h(DynamicComponent, { props: { data: item, path: 'content' } })));

    return nodes;
}

export default {
    name: 'TableCell',
    functional: true,
    components: { DynamicComponent },
    props: {
        data: {
            default: () => [],
        },
    },
    render(h, context) {
        return h(
            context.props.data.type === 'tableHeader' ? 'th' : 'td',
            { attrs: context.props.data.attrs },
            createCellNodes(h, context.props.data.content)
        );
    },
};
</script>
