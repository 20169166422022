export default [
    'eventId',
    'name',
    'isLive',
    'exId',
    'price.PriceRaw',
    'price.Name',
    'price.Price',
    'price.Id',
    'price.EligibleForBonus',
    'price.Hcp',
    'market.name',
    'market.groupName',
    'market.groupedMarketName',
    'market.handicapType',
    'market.cashoutable',
];
