<template>
    <div :class="['odometer', { animated: isAnimated, 'animated-up': isAnimatedUp, 'animated-down': !isAnimatedUp }]">
        <div v-for="digit in digits" :key="digit.key" class="odometer-digit">
            <div class="odometer-digit-placeholder">
                {{ digit.placeholder }}
            </div>
            <div class="odometer-digit-wrapper">
                <div class="odometer-digit-container">
                    <div class="odometer-digit-numbers">
                        <div v-for="(number, index) in digit.value" :key="index" class="odometer-digit-value">
                            {{ number }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import helper from '@agi.packages/core/utils/helper';
import { createNumberSequence } from '@agi.packages/core/utils/odometer';
import { isNumber } from '@agi.packages/core/utils/number/isNumber';

export default {
    name: 'Odometer',
    props: {
        value: Number,
        startValue: Number,
        comma: {
            type: Boolean,
            default: true,
        },
        decimal: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            digits: [],
            isAnimated: false,
            isAnimatedUp: false,
        };
    },
    watch: {
        value(value, oldVal) {
            this.generateNumbers(value, oldVal);
        },
    },
    created() {
        if (this.startValue >= 0) {
            this.generateNumbers(this.value, this.startValue);
        } else {
            this.generateNumbers(this.value, this.value);
        }
    },
    methods: {
        generateNumbers(value, oldValue) {
            this.isAnimated = false;
            this.isAnimatedUp = value > oldValue || value === oldValue;

            const thousandSeparator = this.comma ? ',' : '';
            const oldValueStr = helper.numberFormat(oldValue, this.decimal, '.', thousandSeparator).toString();
            const valueStr = helper.numberFormat(value, this.decimal, '.', thousandSeparator).toString();

            const oldSplitNumbers = oldValueStr.split('');
            const splitNumbers = valueStr.split('');
            const isNewValueBigger = splitNumbers.length > oldSplitNumbers.length;
            const results = [];

            let isSomePrevDigitChanged = false; // indicates that the next numbers should be recalculated

            for (let i = 0; i < splitNumbers.length; i++) {
                if (!isNumber(Number(splitNumbers[i]))) {
                    results.push({
                        key: helper.createUUID(5),
                        value: splitNumbers[i],
                        placeholder: splitNumbers[i],
                    });
                    continue;
                }

                if (!isSomePrevDigitChanged) {
                    isSomePrevDigitChanged = splitNumbers[i] !== oldSplitNumbers[i];
                }

                const prev = isNewValueBigger ? Number(oldSplitNumbers[i - 1]) || 0 : Number(oldSplitNumbers[i]);
                const numbers = createNumberSequence(prev || 0, Number(splitNumbers[i]), {
                    increasing: this.isAnimatedUp,
                    shakeSame: isSomePrevDigitChanged,
                });

                results.push({
                    key: helper.createUUID(5),
                    value: this.isAnimatedUp ? numbers : [...numbers].reverse(),
                    placeholder: 9,
                });
            }
            this.digits = results;

            setTimeout(() => {
                this.isAnimated = true;
            }, 100);
        },
    },
};
</script>

<style scoped>
.odometer {
    overflow: hidden;
    position: relative;
    vertical-align: top;
    display: inline-block;
    user-select: none;
}

.odometer-digit {
    display: inline-block;
    position: relative;
}

.odometer-digit-value {
    display: block;
    transform: translateZ(0);
}
.odometer-digit-value:last-child {
    position: absolute;
}

.odometer-digit-placeholder {
    opacity: 0;
    visibility: hidden;
}

.odometer-digit-wrapper {
    text-align: left;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.odometer-digit-container {
    display: block;
}

.odometer-digit-numbers {
    transition: transform 2s ease;
    will-change: transform;
}

.animated-up .odometer-digit-numbers {
    transform: translateY(0%);
}
.animated-down .odometer-digit-numbers {
    transform: translateY(-100%);
}
.animated.animated-up .odometer-digit-numbers {
    transform: translateY(-100%);
}
.animated.animated-down .odometer-digit-numbers {
    transform: translateY(0%);
}
</style>
