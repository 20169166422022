const defaultConfig = {
    path: '/',
};

export default {
    install(Vue) {
        Vue.prototype.$cookie = this;
        Vue.cookie = this;
    },
    isKey(key) {
        return this._getCookieRegExp(key).test(document.cookie);
    },
    set(key, value, path) {
        if (!key) {
            throw new Error('cookie name is required');
        }

        // stringify object
        if (value && typeof value === 'object') {
            value = JSON.stringify(value);
        }

        document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(value)}; path=${path || defaultConfig.path}`;
    },
    get(key) {
        if (!this.isKey(key)) {
            return;
        }
        const [, result] = this._getCookieRegExp(key).exec(document.cookie);
        return decodeURIComponent(result);
    },
    _getCookieRegExp(key) {
        const escapedName = key.replace(/([[]{}()|=;+?,.*^$])/gi, '\\$1');

        return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
    },
};
