<template>
    <div class="rules-page">
        <Spinner :listen="spinnerTriggers" class="inset" />
        <template v-if="page && tocContent.length">
            <h2>{{ $t('seo.keywords.rules') }}</h2>
            <select v-model="selected" class="select" name="current-rule">
                <option v-for="(item, index) of tocContent" :key="index" :value="item.id">{{ item.text }}</option>
            </select>
            <ul v-if="currentRule" class="rules-list">
                <li v-for="subItem in currentRule.subContent" :key="subItem.id" class="rule">
                    <a :id="subItem.id + '-toc'" class="underline" :href="'#' + subItem.id">
                        {{ subItem.text }}
                    </a>
                </li>
            </ul>
            <DynamicComponent
                v-for="(item, index) in content"
                :key="`${path}-${item.type}-${index}`"
                :data="item"
                path="content"
                :data-test-id="`tag-${item.type}`"
                data-test-class="cmsTag"
            />
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Spinner } from '@agi.packages/core/components';
import { action, getter } from '@agi.packages/platform';
import { DynamicComponent } from '@agi.packages/platform/components';

import SEOMixin from '@/components/Pages/SEO.mixin';

export default {
    name: 'RulesPage',
    components: { Spinner, DynamicComponent },
    mixins: [SEOMixin],
    data() {
        return {
            spinnerTriggers: [action.GET_PAGE],
            path: 'rules',
            selected: null,
        };
    },
    computed: {
        page() {
            return this.getPages[this.path] || {};
        },
        tocContent() {
            return this.page.tocContent || [];
        },
        currentRule() {
            if (!this.selected) {
                this.getSelection();
            }
            return this.tocContent.find(({ id }) => id === this.selected);
        },
        content() {
            const currentIndex = this.tocContent.findIndex((rule) => rule === this.currentRule);
            const nextRule = this.tocContent[currentIndex + 1];
            return this.page.content.slice(this.currentRule.start, nextRule ? nextRule.start - 1 : undefined);
        },
        ...mapGetters({
            getPages: getter.GET_PAGES,
        }),
    },
    watch: {
        tocContent() {
            this.getSelection();
        },
    },
    created() {
        this.$store.dispatch(action.GET_PAGE, this.path).catch(() => {});
    },
    methods: {
        getSelection() {
            if (this.selected || !this.tocContent) {
                return;
            }
            const [{ id }] = this.tocContent;
            this.selected = id;
        },
    },
};
</script>

<style scoped lang="scss">
.rules-page {
    margin: 20px;
}

.rules-list {
    margin-top: 10px;
    list-style: none;
}

.rule {
    margin: 5px 0;
    color: $rules-page-rule-color;
}

.select {
    display: inline-block;
    background: $rules-page-select-background;
    border: 2px solid $rules-page-select-border-color;
    color: $rules-page-select-color;
    line-height: 1.2rem;
    vertical-align: middle;
    width: 100%;
}
</style>
