<template>
    <div v-if="showSpinner" class="spinner" :class="type" :style="{ zIndex }">
        <div class="container">
            <div class="image-container" :style="{ zoom: zoom + '%' }">
                <div class="image" />
            </div>
            <span v-if="$slots.default">
                <slot />
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

const spinnerType = {
    DEFAULT: 'default-spinner',
    BP: 'bp-spinner',
};

export default {
    name: 'Spinner',
    props: {
        listen: {
            type: [Array, String],
            default: '',
        },
        visible: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: spinnerType.DEFAULT,
        },
        zoom: {
            type: Number,
            default: null,
        },
        elevation: {
            type: [String, Number],
            default: 0,
        },
    },
    data: () => ({
        default: {
            elevation: 9999,
        },
    }),
    computed: {
        ...mapGetters('core', ['areLoading', 'isLoading']),
        zIndex() {
            return this.type === spinnerType.DEFAULT && this.default.elevation + Number(this.elevation);
        },
        showSpinner() {
            let isListen = false;
            if (this.listen) {
                isListen = Array.isArray(this.listen) ? this.areLoading(this.listen) : this.isLoading(this.listen);
            }
            return this.visible || isListen;
        },
    },
};
</script>

<style lang="scss">
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.spinner {
    display: flex;
    @include only_ie {
        display: block;
    }
    align-items: center;

    .presto & {
        // TODO: temp, do global device type var to hide
        display: none;
    }

    .container {
        display: flex;
        @include only_ie {
            display: block;
        }
        align-items: center;

        span {
            padding-left: 10px;
        }
    }

    &.justify-center {
        justify-content: center;
    }

    &.bp-spinner {
        .image-container {
            zoom: 35%;
            position: relative;
            display: inline-block;
            vertical-align: middle;

            .image {
                display: inline-block;
                background: transparent url('./../../assets/bp-spinner.png') no-repeat 50%;
                width: 40px;
                height: 40px;
                animation: spin 1s infinite linear;
            }
        }
    }

    &.default-spinner {
        background: $body-background-color;
        justify-content: center;
        align-items: end;
        padding-top: 200px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;

        .image {
            background: transparent url('./../../assets/default-spinner.gif') no-repeat center center;
            width: 34px;
            height: 34px;
            background-size: contain;
        }

        &.viewport-padding,
        &.viewport-padding.inset {
            padding-top: 10vw;
        }

        &.transparent {
            background: transparent;
        }

        &.inset {
            position: relative;
            padding: 20px;
            background: transparent;
        }

        &.align-center {
            align-items: center;
            padding-top: 0;
        }

        &.align-top {
            align-items: start;
        }
    }
}
</style>
