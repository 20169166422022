let defaults = {
    selector: 'body',
    container: null,
    duration: 250,
    easing: true,
    offset: 0,
    extraOffset: 0,
};

export function setDefaults(options) {
    defaults = { ...defaults, ...options };
}

function easeInOut(t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
}

const scrollTo = function (scrollElement) {
    let container, targetOffset, offset, duration, now, elapsed, easing;
    return function (target, _duration, options = {}) {
        if (typeof _duration === 'object') {
            options = { ...options, ..._duration };
        } else if (typeof _duration === 'number') {
            options.duration = _duration;
        }

        container = scrollElement || options.container || document.querySelector(options.selector || defaults.selector);
        offset = (options.offset || defaults.offset) + (options.extraOffset || defaults.extraOffset);

        if (typeof target === 'number') {
            targetOffset = target - container.scrollTop + offset;
        } else if (typeof target === 'string') {
            const targetElem = document.querySelector(target);
            if (!targetElem) {
                return console.warn('[scrollTo() warn]: Element to scroll into, does not exist: ' + targetOffset);
            }
            targetOffset = targetElem.getBoundingClientRect().top;
        }

        duration = options.duration || defaults.duration;
        easing = options.easing || defaults.easing;
        now = Date.now();
        targetOffset = container.scrollTop + targetOffset - offset;
        let step = function () {
            elapsed = Date.now() - now;
            let position = targetOffset;
            if (elapsed < duration && easing && container.scrollTop !== targetOffset) {
                position = container.scrollTop + (targetOffset - container.scrollTop) * easeInOut(elapsed / duration);
                window.requestAnimationFrame && window.requestAnimationFrame(step);
            }
            container.scrollTop = position;
        };
        step();
    };
};

export default scrollTo;
