<script>
import { createNodes } from '@/js/utils/cms';

export default {
    name: 'Paragraph',
    functional: true,
    props: {
        data: {
            default: () => [],
        },
    },
    render(h, context) {
        const defaultContent = [{ type: 'br', text: '' }];

        return h(
            'p',
            { style: { textAlign: context.props.data.attrs.textAlign } },
            createNodes(h, context.props.data.content || defaultContent)
        );
    },
};
</script>
