export const userState = {
    NOT_LOGGED_IN: 'not-logged-in',
    LOGGED_IN: 'logged-in',
    LOGGED_IN_NEVER_DEPOSITED: 'logged-in-never-deposited',
    LOGGED_IN_OUT_OF_MONEY: 'logged-in-out-of-money',
    LOGGED_IN_OUT_OF_MONEY_ANY_DEPOSITED: 'logged-in-out-of-money-any-deposited',
    LOGGED_IN_LOT_OF_MONEY: 'logged-in-lot-of-money',
    LOGGED_IN_HAS_MONEY: 'logged-in-has-money',
    EVER_DEPOSITED: 'ever-deposited',
};
