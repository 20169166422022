<template>
    <h3 :id="data.id">{{ data.text }}</h3>
</template>
<script>
export default {
    name: 'Subheadline',
    props: ['data'],
};
</script>
<style scoped lang="scss">
h3 {
    @extend %h3-font-700;
    margin: 14px 0;
}
</style>
