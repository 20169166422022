/**
 * Creates a sequence of numbers between two values for odometer animation
 * @param {Number} start - start value
 * @param {Number} end - end value
 * @param {object} [options] - options
 * @param {boolean} [options.increasing] - sequence is increasing or decreasing
 * @param {boolean} [options.shakeSame] - indicates that a sequence will be created for identical numbers or not.
 * @returns {Array} - array of numbers, ex: 4 -> 7, [4,5,6,7] or decreasing 4 -> 7, [4,3,2,1,0,9,8,7]
 */
export function createNumberSequence(start, end, { increasing, shakeSame }) {
    const result = [];
    const isSame = shakeSame && start === end;
    const startIncreased = start + 1 > 9 ? 0 : start + 1;
    const startDecreased = start - 1 < 0 ? 9 : start - 1;
    const startForSame = increasing ? startIncreased : startDecreased;

    let last = isSame ? startForSame : start;
    while (result[result.length - 1] !== end) {
        result.push(last);
        if (increasing) {
            last++;
            if (last > 9) {
                last = 0;
            }
        } else {
            last--;
            if (last < 0) {
                last = 9;
            }
        }
    }
    return isSame ? [start, ...result] : result;
}
