<template>
    <hr />
</template>
<script>
export default {
    name: 'HorizontalRule',
};
</script>

<style scoped lang="scss">
hr {
    border: none;
    border-bottom: $border;

    & + hr {
        display: none;
    }
}

.generic-page-content-wrapper {
    > hr,
    *:not(.rich-text-components) hr {
        &:first-child,
        &:last-child {
            display: none;
        }
    }
    .events:last-of-type {
        & + hr {
            display: none;
        }
    }
}
</style>
