/* global PaystackPop */
export default {
    loadScript() {
        const id = 'js-paystack';
        if (!document.getElementById(id)) {
            const paystack = document.createElement('script');
            paystack.setAttribute('src', 'https://js.paystack.co/v1/inline.js');
            paystack.id = id;
            document.head.appendChild(paystack);
        }
    },
    deposit(deposit, callBack = () => {}, onClose = () => {}) {
        const { email, amount, ref, channels, customerId, paystackkey } = deposit;
        const handler = PaystackPop.setup({
            key: paystackkey,
            email,
            amount,
            ref,
            channels,
            metadata: {
                custom_fields: [
                    {
                        display_name: 'Mobile Number',
                        variable_name: 'mobile_number',
                        value: customerId,
                    },
                ],
            },
            callback(response) {
                return callBack(response);
            },
            onClose(asd) {
                return onClose(asd);
            },
        });

        handler.openIframe();
    },
};
