<template>
    <div class="mtn-nigeria-airtime column">
        <div class="container">
            <Deposit
                :amount="depositAmount"
                :counter="false"
                :show-select="showSelect"
                :button-text="buttonText"
                :input-label="inputLabel"
                :is-button-always-active="isButtonAlwaysActive"
                @inProgress="inProgress = $event"
                @valueChanged="depositAmount = $event"
                @closeClick="$emit('closeClick')"
            >
                <template slot="fee" slot-scope="{ provider, fee }">
                    <div v-if="fee" class="fee-summary">
                        <div class="row">
                            <span>{{ $t('project.ui.mtn-nigeria-airtime.grossAmount') }}</span>
                            <Currency
                                id="mtn-airtime-gross-value"
                                class="amount"
                                :contrast="false"
                                :amount="depositAmount"
                                :format="currency"
                            />
                        </div>
                        <div class="row">
                            <span>{{ $t('project.ui.mtn-nigeria-airtime.fee') }}</span>
                            <Currency
                                id="mtn-airtime-fee-value"
                                class="amount"
                                :contrast="false"
                                :amount="fee.feeAmount"
                                :format="currency"
                            />
                        </div>
                        <div class="row">
                            <span>{{ $t('project.ui.mtn-nigeria-airtime.netAmount') }}</span>
                            <Currency
                                id="mtn-airtime-net-value"
                                class="amount bold"
                                :contrast="false"
                                :amount="fee.netAmount"
                                :format="currency"
                            />
                        </div>
                    </div>
                </template>
            </Deposit>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { auth } from '@agi.packages/platform';
import { Currency } from '@agi.packages/core/components';

import Deposit from './Deposit.vue';

export default {
    name: 'Mtnnigeriaairtime',
    components: { Deposit, Currency },
    props: ['showSelect', 'buttonText', 'inputLabel', 'isButtonAlwaysActive'],
    data() {
        return {
            depositAmount: '',
            inProgress: false,
        };
    },
    computed: {
        ...mapState({
            currency: (state) => state.platform.settings.currency,
        }),
        ...mapGetters({
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
        }),
    },
};
</script>
<style scoped lang="scss">
.mtn-nigeria-airtime {
    h2 {
        margin: 0 1.2rem;
        padding: 0.64rem 0;
    }

    .container {
        font-size: 14px;
        color: $grey-text;

        .notify {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &::v-deep .fee-summary {
            padding-bottom: 8px;

            .row {
                display: flex;
                justify-content: space-between;
            }

            .amount {
                color: $main-text;
            }
        }
    }
}
</style>
