<template>
    <component :is="isPresto ? 'div' : 'button'" v-bind="{ ...attrs }" @[clickEventName]="$emit('click')">
        <slot />
    </component>
</template>

<script>
import { deviceType } from '@/modules/core';

export default {
    name: 'Button',
    props: ['disabled'],
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        clickEventName() {
            return this.isPrestoBtnDisabled ? '' : 'click';
        },
        attrs() {
            return {
                class: `button ${this.isPrestoBtnDisabled ? 'btn-presto-disabled' : ''}`,
                ...(this.disabled && { disabled: true }),
            };
        },
        isPrestoBtnDisabled() {
            return this.isPresto && this.disabled;
        },
    },
};
</script>

<style scoped lang="scss">
.btn-presto-disabled {
    cursor: not-allowed;
}
</style>
