import Modal from './Modal.vue';
import ModalsContainer from './ModalsContainer.vue';

const defaultComponentName = 'Modal';
const unmountedRootErrorMessage = '[modal] Add <modals-container> component to the page';
const duplicateModalErrorMessage = '[modal] Duplicate model name: "{modelName}", won\'t be added';

const Plugin = {
    install(Vue, options = {}) {
        if (this.installed) {
            return;
        }
        this.installed = true;
        this.event = new Vue();
        this.rootInstance = null;
        this.componentName = options.componentName || defaultComponentName;
        Vue.$modal = Vue.prototype.$modal = {
            show(modal, paramsOrProps, params, events = {}) {
                if (typeof modal === 'string') {
                    Plugin.event.$emit('toggle', modal, true, paramsOrProps);
                    Plugin.event.$emit('show', modal, paramsOrProps);
                    return;
                }
                const root = params && params.root ? params.root : Plugin.rootInstance;
                const container = getModalsContainer(Vue, root);
                if (container && !isCreated(params?.name || params, container.modals)) {
                    container.add(modal, paramsOrProps, params, events);
                    return;
                } else if (container) {
                    console.warn(duplicateModalErrorMessage.replace('{modelName}', params.name || params));
                    return;
                }
                console.warn(unmountedRootErrorMessage);
            },
            hide(name, params) {
                Plugin.event.$emit('toggle', name, false, params);
                Plugin.event.$emit('hide', name, params);
            },
            toggle(name, params) {
                Plugin.event.$emit('toggle', name, undefined, params);
            },
            active: [],
            params: {},
        };
        Vue.component(this.componentName, Modal);
        if (options.dynamic) {
            Vue.component('ModalsContainer', ModalsContainer);
            Vue.mixin({
                beforeMount() {
                    if (Plugin.rootInstance === null) {
                        Plugin.rootInstance = this.$root;
                    }
                },
            });
        }
    },
};

function isCreated(params, source) {
    return source.map((modal) => modal.modalAttrs.name).includes(params.name || params);
}

function getModalsContainer(Vue, root) {
    if (!root._dynamicContainer) {
        const modalsContainer = document.createElement('div');
        document.body.appendChild(modalsContainer);

        new Vue({
            parent: root,
            render: (h) => h(ModalsContainer),
        }).$mount(modalsContainer);
    }
    return root._dynamicContainer;
}

export default Plugin;
