<template>
    <div class="user-phone table">
        <div class="row-cell align-middle user-phone-icon">
            <ImageIcon :src="providerIcon" :alt-src="defaultIconSrc" />
        </div>
        <div class="row-cell align-middle user-phone-number">
            <div class="user-phone-info-wrapper">
                <div class="user-phone-info">
                    <label class="user-phone-label">{{ $t('ui.common.yourMobileNumber') }}</label>
                    <div>{{ userPhoneNumber }}</div>
                </div>
                <div v-if="isStatusVisible" class="user-phone-verification-status">
                    <div class="table">
                        <label class="row-cell align-middle">{{ statusText }}</label>
                        <div class="user-phone-verification-status-icon">
                            <SvgIcon class="row-cell align-middle icon-size-medium" icon-id="icon-user-verified" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getter as platformGetter, auth } from '@agi.packages/platform';
const DEFAULT_ICON_SRC = 'img/providers/default.png';

export default {
    name: 'UserPhoneOperator',
    props: {
        showVerifiedStatus: {
            type: Boolean,
            default: false,
        },
        providerIcon: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            defaultIconSrc: DEFAULT_ICON_SRC,
        };
    },
    computed: {
        ...mapGetters({
            user: platformGetter.GET_USER_SETTINGS,
            preference: platformGetter.GET_PREFERENCE,
            isUserVerified: auth.getter.IS_USER_VERIFIED,
        }),
        userPhoneNumber() {
            return `${this.user.phoneNumber?.substring(0, 4)} ${this.user.phoneNumber?.substring(4)}`;
        },
        isShareholder() {
            return !!this.preference.shareholder;
        },
        statusText() {
            return this.isShareholder ? this.$t('ui.common.shareholder') : this.$t('ui.common.verified');
        },
        isStatusVisible() {
            return (this.isUserVerified || this.isShareholder) && this.showVerifiedStatus;
        },
    },
};
</script>

<style lang="scss" scoped>
.user-phone {
    background: $light-grey-bg;
    padding: 8px;
    margin: 10px 0;

    &-verification-status {
        label {
            padding-right: 4px;
        }

        &-icon {
            width: 16px;
            height: 16px;
        }
    }

    &-icon {
        padding-right: 8px;
    }
    &-number {
        width: 100%;
        line-height: 17px;
        font-size: 14px;
    }
    &-label {
        color: $disabled-text;
        margin: 0 0 2px;
        font-size: 12px;
        line-height: 14px;
    }
    &-info-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}
</style>
