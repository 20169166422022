<template>
    <div class="progress-line">
        <div class="progress-line-fill" :class="{ 'progress-line-fill-disabled': disabled }" :style="`width: ${currentPercent}%;`" />
    </div>
</template>

<script>
export default {
    name: 'ProgressLine',
    props: {
        min: {
            type: Number,
            required: true,
        },
        max: {
            type: Number,
            required: true,
        },
        current: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        currentPercent() {
            const diff = this.max - this.min;
            const percent = ((this.current - this.min) * 100) / diff;
            return Math.min(Math.max(percent, 0), 100);
        },
    },
};
</script>

<style lang="scss" scoped>
.progress-line {
    position: relative;
    width: 100%;
    height: 4px;
    background: $light-grey;
}

.progress-line-fill {
    position: absolute;
    height: 4px;
    background: $green-success;
    left: 0;
    width: 0%;

    &-disabled {
        background: $disabled-bg;
    }
}
</style>
