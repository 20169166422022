<template>
    <div class="note">
        <RichText :data="richTextContent" />
    </div>
</template>
<script>
import RichText from '@/components/content/RichText';

export default {
    name: 'Note',
    components: { RichText },
    props: ['data'],
    computed: {
        richTextContent() {
            return { content: { content: this.data.content.description } };
        },
    },
};
</script>
<style lang="scss" scoped>
.note {
    padding: 12px;
    background-color: #feeeb3;
}
</style>
