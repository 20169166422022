import { lazyLoad } from '@/js/plugins/util';

export let options = {
    resourceURL: 'https://www.googletagmanager.com/gtm.js',
    lazyLoadEnabled: true,
    enabled: true,
    disableScriptLoad: false,
    bootstrap: true,
    id: null,
};

export default {
    install(Vue, _options = {}) {
        options = { ...options, ..._options };

        Vue.$gtm = Vue.prototype.$gtm = this.api;

        if (!options.bootstrap) {
            return;
        }
        this.bootstrap();
    },
    api: {
        query(data) {
            if (typeof window === 'undefined') {
                return;
            }
            window.dataLayer.push(data);
        },
        makeValidEventName(name) {
            if (typeof name !== 'string') {
                console.warn(`Event name must be a string, current value: ${name}`);
                return '';
            }
            return name.replace(/[-\s]+/g, '_');
        },
    },
    bootstrap() {
        if (typeof document === 'undefined' || typeof window === 'undefined') {
            return;
        }

        const { resourceURL, enabled, disableScriptLoad, id, lazyLoadEnabled } = options;

        if (!enabled) {
            this.disableTracking();
        }

        if (disableScriptLoad) {
            return;
        }

        window.dataLayer = window.dataLayer || [];

        return lazyLoad(lazyLoadEnabled).then(() => {
            return this.loadScript(`${resourceURL}?id=${id}`);
        });
    },
    loadScript(url) {
        return new Promise((resolve, reject) => {
            const head = document.head || document.getElementsByTagName('head')[0];
            const script = document.createElement('script');
            script.async = true;
            script.src = url;
            script.charset = 'utf-8';
            head.appendChild(script);
            script.onload = resolve;
            script.onerror = reject;
        }).catch((error) => {
            console.warn("GTM Response error: GTM.js couldn't be loaded", error);
            return error;
        });
    },
    // works with gtm?
    disableTracking(value = true) {
        window[`ga-disable-${options.id}`] = value;
    },
};
