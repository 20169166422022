<template>
    <div>
        <Spinner v-if="!bets" :visible="true" class="align-center inset viewport-padding" />
        <div v-else-if="bets.length === 0" class="no-data-text">
            {{ $t('ui.myBets.emptyData.jackpots') }}
        </div>
        <template v-else>
            <div
                v-for="bet in bets"
                :key="bet.id"
                class="bet"
                :data-test-id="`bet-jackpot-${bet.id}`"
                data-test-class="bet-jackpot"
                @click="openJackpotTicket(bet)"
            >
                <div class="bet-line bet-header">
                    <div>
                        {{ formatTime(bet.purchaseDate) }}
                        <span class="date">{{ formatDate(bet.purchaseDate) }}</span>
                        <div class="id" data-test-id="bet-id">
                            {{ $t('ui.myBets.id').toUpperCase() }}: #{{ bet.id }} - <span>{{ bet.pool.name }}</span>
                        </div>
                    </div>
                    <div class="status">
                        <span v-if="bet.isWinner" class="positive">{{ $t('ui.myBets.won').toUpperCase() }}</span>
                        <span v-else-if="bet.isOngoing" class="neutral">{{ $t('ui.myBets.pending').toUpperCase() }}</span>
                        <span v-else class="negative">{{ $t('ui.myBets.lost').toUpperCase() }}</span>
                    </div>
                </div>
                <div class="bet-line">
                    <div class="bet-detail">
                        <span class="label">{{ $t('ui.myBets.tickets').toUpperCase() }}</span>
                        <span class="value">{{ formatNumber(bet.rows, 0) }}</span>
                    </div>
                    <div class="bet-detail">
                        <span class="label">{{ $t('ui.myBets.stake').toUpperCase() }}</span>
                        <Currency class="value" :amount="bet.rows * bet.pool.fee" :format="currencyFormat" />
                    </div>
                    <div v-if="bet.isWinner" class="bet-detail success end">
                        <span class="label">{{ $t('ui.myBets.return').toUpperCase() }}</span>
                        <span class="value">
                            {{ bet.payoutDetails.payout | currency(currencyFormat) }}{{ bet.payoutDetails.tax ? '*' : '' }}
                        </span>
                    </div>
                    <div v-else-if="bet.isOngoing" class="bet-detail end">
                        <span class="label">{{ $t('ui.myBets.jackpot').toUpperCase() }}</span>
                        <Currency class="value" :amount="bet.pool.maxPrize" :format="currencyFormat" />
                    </div>
                    <div v-else class="bet-detail end">
                        <div>
                            <span class="label">{{ $t('ui.myBets.hit').toUpperCase() }}: </span>
                            <span class="value positive">{{ bet.payoutDetails.hitCountMax }}</span>
                        </div>
                        <div>
                            <span class="label">{{ $t('ui.myBets.miss').toUpperCase() }}: </span>
                            <span class="value negative">{{ bet.pool.rowCount - bet.payoutDetails.hitCountMax }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <p v-if="taxEnabled" class="tax-message">{{ $t('ui.myBets.taxEnabled') }}</p>
        </template>
        <div v-if="showMore" class="show-more">
            <span class="button button-accent" @click="$emit('load-more')">
                {{ $t('ui.myBets.showMore') }}
            </span>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Spinner, Currency } from '@agi.packages/core/components';
import { helper, deviceType } from '@agi.packages/core';
import { sport } from '@agi.packages/sport';

import { getter } from '@agi.packages/platform';
import { routeName } from '@/router/const-name';

export default {
    name: 'JackpotBet',
    components: { Spinner, Currency },
    props: {
        hasMore: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        ...mapState({
            decimalBetPrecision: (state) => state.platform.settings.betting.real.precision,
            currencyFormat: (state) => state.platform.settings.currency.format,
        }),
        ...mapGetters({
            dateOptions: getter.GET_DATE_OPTIONS, // Implement global date time BP-17850
            jackpot: sport.getter.GET_JACKPOT_BETS,
            taxEnabled: getter.IS_POOL_TAX_ENABLED,
        }),
        bets() {
            return this.jackpot && this.jackpot.items;
        },
        showMore() {
            return this.isPresto && this.hasMore && this.jackpot && this.jackpot.hasMore;
        },
    },
    methods: {
        openJackpotTicket(bet) {
            this.$router.push({ name: routeName.JACKPOT_TICKET, params: { ticketId: bet.id } });
        },
        formatDate(dateTime) {
            return helper.formatDateTime(dateTime, {
                ...this.dateOptions,
                isUtc: true,
                needDate: true,
                needTime: false,
            });
        },
        formatTime(dateTime) {
            return helper.formatDateTime(dateTime, {
                ...this.dateOptions,
                isUtc: true,
                needDate: false,
                needTime: true,
            });
        },
        formatNumber(number, decimals = this.decimalBetPrecision) {
            return helper.numberFormat(number, decimals, '.', ',');
        },
    },
};
</script>

<style lang="scss" scoped>
.tax-message {
    @extend %caption-font-400;
    padding: 8px 12px 0;
}

.no-data-text {
    @extend %body-big-font-400;
    margin: 14px;
    text-align: center;
}

.show-more {
    margin-top: 10px;
    text-align: center;
}

.bet {
    @extend %small-details-font-400;
    border-bottom: $single-betslip-border;
    color: $main-text;
    padding: 8px;

    .bet-line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        &:last-child {
            margin-bottom: 0;
        }

        &.bet-header {
            align-items: flex-start;
            @extend %body-normal-font-500;
            margin-bottom: 8px;

            .date {
                font-weight: 700;
            }

            .id {
                @extend %small-details-font-400;
                color: $disabled-text;

                @include oldschool {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 160px;
                }
            }

            .status {
                color: $jackpot-bet-status-color;
                font-weight: 700;
                line-height: 16px;
                text-align: right;
                white-space: nowrap;
            }
        }

        .bet-detail {
            display: flex;
            flex-direction: column;
            font-weight: 700;
            width: 50%;
            padding-right: 4px;

            &.end {
                align-items: flex-end;
                padding-right: 0px;
            }

            &.success {
                .value {
                    color: $green-success;
                }
            }

            .label {
                @extend %caption-font-400;
                color: $disabled-text;
            }

            .value {
                font-weight: 500;
            }
        }

        .positive {
            color: $green-success;
        }

        .neutral {
            color: $disabled-text;
        }

        .negative {
            color: $error-red;
        }
    }
}
</style>
