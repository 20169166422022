<template>
    <div v-if="!isPresto">
        <slot />
    </div>

    <table v-else>
        <tr>
            <td>
                <slot />
            </td>
        </tr>
    </table>
</template>

<script>
import { deviceType } from '@agi.packages/core';

export default {
    name: 'PrestoImageWrapper',
    data() {
        return {
            isPresto: deviceType.isPresto(),
        };
    },
};
</script>

<style scoped lang="scss">
/* reset table styles: */
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    border-spacing: 0;
}

table {
    table-layout: fixed;
    max-width: 100%;
    width: 100%;
}
</style>
