<template>
    <div class="expansion-panel">
        <template v-if="title">
            <div class="title table" :class="{ pointer: control }" @click="toggleOpen">
                <div class="row-cell align-middle">
                    <h3>{{ title }}</h3>
                </div>
                <div v-if="control" class="row-cell align-middle">
                    <SvgIcon
                        class="icon-size-very-small"
                        icon-id="icon-arrow-up"
                        :style="{ transform: `rotate(${isOpen ? '0' : '180'}deg)` }"
                    />
                </div>
            </div>
        </template>
        <transition name="slide">
            <div v-if="isOpen" class="expansion-panel-content">
                <slot />
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    name: 'ExpansionPanel',
    props: {
        title: {
            type: String,
        },
        expanded: {
            type: Boolean,
            default: null,
        },
        control: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        isOpen: false,
    }),
    watch: {
        expanded: {
            immediate: true,
            handler(value) {
                this.setOpen(value);
            },
        },
    },
    methods: {
        toggleOpen() {
            if (this.control) {
                this.isOpen = !this.isOpen;
            }
        },
        setOpen(value) {
            this.isOpen = value;
        },
    },
};
</script>
<style lang="scss" scoped>
.expansion-panel {
    @include slide;

    .title {
        background: $left-menu-headline-background;
        color: $left-menu-headline-text-color;
        padding: $left-menu-headline-padding;
        border-top: $left-menu-headline-border;
        fill: $left-menu-headline-text-color;

        h3 {
            @extend %h4-font-700;
            text-transform: uppercase;
            margin: 0;
        }

        @include only_mini {
            svg {
                float: right;
            }
        }

        @include all_but_mini {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
        }
    }
}
</style>
