<template>
    <blockquote>
        <Paragraph v-for="(item, index) in data.content" :key="index" :data="item" />
    </blockquote>
</template>
<script>
import Paragraph from './Paragraph';

export default {
    name: 'Blockquote',
    components: { Paragraph },
    props: ['data'],
};
</script>
<style lang="scss" scoped>
blockquote {
    padding: 1rem;
    font-size: 1.5rem;
    margin: 2rem;
    font-style: italic;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 0.25rem;
}
</style>
