<template>
    <QuickLinks />
</template>

<script>
import QuickLinks from '@/components/Fragments/QuickLinks';

export default {
    name: 'QuickLinksWrapper',
    components: { QuickLinks },
};
</script>
