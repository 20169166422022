<template>
    <p class="referral-link">
        {{ data.link }}
    </p>
</template>
<script>
export default {
    name: 'Referral',
    props: ['data'],
};
</script>
<style scoped lang="scss">
.referral-link {
    margin: 0 0 0.8em 0;
    word-break: break-word;
}
</style>
