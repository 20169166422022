export function createConditionalComponent(resolveFn) {
    return (resolve) => {
        resolve({
            render(h) {
                const Page = resolveFn(this.$route);
                return h(Page, {
                    props: {
                        params: { isRenderFunction: true },
                    },
                });
            },
        });
    };
}
