<template>
    <StickyScrollElement>
        <div class="virtual-sticky-header with-countdown">
            <div class="season-title">
                <span class="season-title-text">{{ seasonTitle }}</span>
            </div>
            <div class="season-timer">
                <span class="virtual-round-timer">
                    <span class="kick-off-label">
                        <template v-if="!isShowCountdownOnly">
                            <span :class="{ highlight: stopCountdown }">
                                {{ $t('ui.openBetslip.matchday') }}
                                {{ upcomingVirtualRound.gameRoundName }}
                            </span>
                            <span v-if="stopCountdown" class="highlight bold">
                                {{ $t('ui.openBetslip.hasStarted') }}
                            </span>
                        </template>
                        <span v-if="!stopCountdown" class="kick-off-text"> {{ $t('ui.openBetslip.kicksOff') }}: </span>
                    </span>
                    <template v-if="stopCountdown && $mq.isSmallMin && $mq.isBeforeSmall">
                        <span v-if="$mq.isBeforeSmallMin" class="bold divider-symbol">&#183;</span>
                        {{ $t('ui.openBetslip.betOn') }}
                        <span class="bold">
                            {{ $t('ui.openBetslip.matchday') }}
                            {{ getNextRoundNumber() }}
                        </span>
                    </template>
                    <span
                        v-if="!stopCountdown"
                        class="kick-off-timer bold"
                        :class="{ highlight: highlightCountdown, flash: flashCountdown }"
                    >
                        {{ remainingTime }}
                    </span>
                </span>
            </div>
        </div>
    </StickyScrollElement>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { deviceType } from '@agi.packages/core';
import { sport } from '@agi.packages/sport';
import StickyScrollElement from '@/modules/sport/components/StickyScrollElement.vue';

const countdownMilestones = {
    SHOW: 300,
    HIGHLIGHT: 120,
    FLASH: 30,
    END: 0,
    HIDE: -10,
};

export default {
    name: 'VritualStickyHeader',
    components: { StickyScrollElement },
    props: {
        seasonTitle: String,
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
            showCountdown: false,
            highlightCountdown: false,
            stopCountdown: false,
            flashCountdown: false,
            virtualRoundIndex: null,
            remainingTime: null,
            countdown: null,
        };
    },
    computed: {
        ...mapState({
            rounds: (state) => state.sport.virtualSports.rounds,
        }),
        ...mapGetters({
            upcomingVirtualRoundIndex: sport.getter.GET_UPCOMING_VIRTUAL_ROUND_INDEX,
        }),
        isShowCountdownOnly() {
            return this.showCountdown && !this.stopCountdown && this.$mq.isXXSmall;
        },
        upcomingVirtualRound() {
            return this.rounds[this.virtualRoundIndex];
        },
    },
    watch: {
        rounds() {
            this.setUpcomingRoundIndex();
            this.launchCountdown();
        },
    },
    created() {
        this.setUpcomingRoundIndex();
        this.updateCountdownData();
        this.launchCountdown();
    },
    beforeDestroy() {
        clearInterval(this.countdown);
    },
    methods: {
        getNextRoundNumber() {
            return parseInt(this.upcomingVirtualRound?.gameRoundName) + 1;
        },
        setUpcomingRoundIndex() {
            if (this.rounds.length && !this.virtualRoundIndex) {
                this.virtualRoundIndex = this.upcomingVirtualRoundIndex;
            }
        },
        launchCountdown() {
            this.countdown = setInterval(this.updateCountdownData, 1000);
        },
        setRemainingTime(remainingSeconds) {
            const minutes = '0' + parseInt(remainingSeconds / 60, 10);
            let seconds = parseInt(remainingSeconds % 60, 10);
            seconds = seconds < 10 ? '0' + seconds : seconds;
            this.remainingTime = `${minutes}:${seconds}`;
        },
        clearCountDown() {
            this.showCountdown = false;
            this.stopCountdown = false;
            clearInterval(this.countdown);
            this.countdown = null;
            this.remainingTime = null;
        },
        updateCountdownData() {
            let remainingSeconds = parseInt((this.upcomingVirtualRound.bettingClosesTime - new Date().getTime()) / 1000);
            if (remainingSeconds <= countdownMilestones.SHOW && remainingSeconds > countdownMilestones.HIDE) {
                if (remainingSeconds > countdownMilestones.END) {
                    this.setRemainingTime(remainingSeconds);
                    this.highlightCountdown = remainingSeconds <= countdownMilestones.HIGHLIGHT;
                    this.flashCountdown = remainingSeconds <= countdownMilestones.FLASH;
                    this.showCountdown = true;
                } else {
                    this.stopCountdown = true;
                    this.flashCountdown = false;
                    this.highlightCountdown = false;
                }
            } else if (remainingSeconds === countdownMilestones.HIDE) {
                this.clearCountDown();
                this.virtualRoundIndex += 1;
                this.launchCountdown();
            }
            --remainingSeconds;
        },
    },
};
</script>

<style lang="scss" scoped>
@keyframes flash {
    50% {
        opacity: 0;
    }
}

.virtual-sticky-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    height: 32px;
    color: $light-grey;
    font-size: 12px;
    font-weight: 700;
    border-top: $border;
    background: $brand-secondary-lighten-1;
    z-index: 2;

    .season-title {
        flex-grow: 1;

        .season-title-text {
            position: relative;
            left: 50%;
            display: inline-block;
            transform: translateX(-50%);
            transition: all 0.5s ease-in-out;
        }
    }

    .season-timer {
        position: absolute;
        right: 12px;
        flex-grow: 0;
        white-space: nowrap;
        text-align: right;
        opacity: 0;
        transition: flex-grow 0.5s ease-in-out, opacity 0.5s ease-in-out;

        .highlight {
            color: $virtual-timer-color;
        }

        .kick-off-text {
            font-weight: 500;
        }

        .kick-off-timer {
            &.flash {
                animation: flash 0.5s linear infinite;
            }
        }
    }

    &.with-countdown {
        .season-title {
            flex-grow: 4;
            border-right: $border;

            .season-title-text {
                left: 0;
                transform: translateX(0);
            }
        }

        .season-timer {
            position: relative;
            right: 0;
            opacity: 1;
            flex-grow: 4;
        }
    }
}
</style>
