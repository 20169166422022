import { DEVICE_TYPE_NAMES } from './deviceType-const';
import android from './android';
import { version as iosVersion } from './ios';

export const DEVICE_TYPES = {
    [DEVICE_TYPE_NAMES.APP]: false,
    [DEVICE_TYPE_NAMES.ANY_PRESTO]: false,
    [DEVICE_TYPE_NAMES.SMARTPHONE_PRESTO]: false,
    [DEVICE_TYPE_NAMES.SMART_PHONE]: false,
    [DEVICE_TYPE_NAMES.IOS]: false,
    [DEVICE_TYPE_NAMES.IOS_LE_12]: false,
    [DEVICE_TYPE_NAMES.IE]: false,
    [DEVICE_TYPE_NAMES.WEB]: false,
    [DEVICE_TYPE_NAMES.ANDROID]: false,
    [DEVICE_TYPE_NAMES.MACOS]: false,
    [DEVICE_TYPE_NAMES.OTHER_PC]: false,
};

export function deviceTypesArray() {
    return Object.keys(DEVICE_TYPES).filter((x) => DEVICE_TYPES[x]);
}

export const DEVICE_TYPE = (function () {
    // Feature Phone or Smartphone using Presto
    if (navigator.userAgent.indexOf('Presto/') > -1) {
        DEVICE_TYPES[DEVICE_TYPE_NAMES.ANY_PRESTO] = true;
        if (/Android/i.test(navigator.userAgent)) {
            DEVICE_TYPES[DEVICE_TYPE_NAMES.SMARTPHONE_PRESTO] = true;
        }
        return DEVICE_TYPE_NAMES.ANY_PRESTO;
    }

    // App
    if (android.state.isBridgeEnabled) {
        DEVICE_TYPES[DEVICE_TYPE_NAMES.APP] = true;
        return DEVICE_TYPE_NAMES.APP;
    }

    // Internet explorer
    if (/MSIE/i.test(navigator.userAgent) || /Trident.*rv:11./i.test(navigator.userAgent)) {
        DEVICE_TYPES[DEVICE_TYPE_NAMES.IE] = true;
    }

    // SmartPhone
    // https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
    if (
        /Android/i.test(navigator.userAgent) ||
        /webOS/i.test(navigator.userAgent) ||
        /iPhone/i.test(navigator.userAgent) ||
        /iPad/i.test(navigator.userAgent) ||
        /iPod/i.test(navigator.userAgent) ||
        /BlackBerry/i.test(navigator.userAgent) ||
        /Windows Phone/i.test(navigator.userAgent) ||
        /mobile/i.test(navigator.userAgent) ||
        /MSIE/i.test(navigator.userAgent) ||
        /Trident.*rv:11./i.test(navigator.userAgent)
    ) {
        if (/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
            DEVICE_TYPES[DEVICE_TYPE_NAMES.IOS] = true;
            if ((iosVersion && iosVersion.major) <= 12) {
                DEVICE_TYPES[DEVICE_TYPE_NAMES.IOS_LE_12] = true;
            }
        }
        if (/Android/i.test(navigator.userAgent)) {
            DEVICE_TYPES[DEVICE_TYPE_NAMES.ANDROID] = true;
        }

        DEVICE_TYPES[DEVICE_TYPE_NAMES.SMART_PHONE] = true;
        return DEVICE_TYPE_NAMES.SMART_PHONE;
    }

    // MacOS
    if (/macOS|Macintosh|MacIntel|MacPPC|Mac68K/i.test(navigator.userAgent)) {
        DEVICE_TYPES[DEVICE_TYPE_NAMES.MACOS] = true;
    }

    // Other PC
    if (!DEVICE_TYPES[DEVICE_TYPE_NAMES.SMART_PHONE] && !DEVICE_TYPES[DEVICE_TYPE_NAMES.MACOS]) {
        DEVICE_TYPES[DEVICE_TYPE_NAMES.OTHER_PC] = true;
    }

    // Other is Web
    DEVICE_TYPES[DEVICE_TYPE_NAMES.WEB] = true;
    return DEVICE_TYPE_NAMES.WEB;
})();

export default {
    isApp() {
        return android.state.isBridgeEnabled;
    },
    isPresto() {
        return DEVICE_TYPES[DEVICE_TYPE_NAMES.ANY_PRESTO];
    },
    isSmartphonePresto() {
        return DEVICE_TYPES[DEVICE_TYPE_NAMES.SMARTPHONE_PRESTO];
    },
    isSmartPhone() {
        return DEVICE_TYPES[DEVICE_TYPE_NAMES.SMART_PHONE];
    },
    isIos() {
        return DEVICE_TYPES[DEVICE_TYPE_NAMES.IOS];
    },
    isWeb() {
        return DEVICE_TYPES[DEVICE_TYPE_NAMES.WEB];
    },
    isIE() {
        return DEVICE_TYPES[DEVICE_TYPE_NAMES.IE];
    },
    hasNoSymbolSupport() {
        const iTelVendorName = 'SpreadTrum';
        return this.isPresto() && navigator.userAgent.indexOf(iTelVendorName) > -1 && navigator.userAgent.indexOf('Opera Mini/4.4') > -1;
    },
    isWebOrAndroidWeb() {
        const androidUserAgent = /Android/i.test(navigator.userAgent);

        return (this.isWeb() && !this.isSmartPhone()) || (androidUserAgent && !this.isApp());
    },
    isAndroid() {
        return DEVICE_TYPES[DEVICE_TYPE_NAMES.ANDROID];
    },
    isMacOs() {
        return DEVICE_TYPES[DEVICE_TYPE_NAMES.MACOS];
    },
    isOtherPc() {
        return DEVICE_TYPES[DEVICE_TYPE_NAMES.OTHER_PC];
    },
};
