export const toolbarIconType = {
    LIST: 'list',
    TILE: 'tile',
};

export const casinoGameMode = {
    demo: 'DEMO',
    real: 'REAL',
};

export const casinoProviderType = {
    WAZDAN: 'wazdan',
    NATIVE: 'betpawa',
    SMARTSOFT: 'smartsoft',
    SPRIBE: 'spribe',
};

export const sortType = {
    FAVOURITES_ADDED_ASC: 'favourites_added_asc',
    FAVOURITES_ADDED_DESC: 'favourites_added_desc',
    POPULAR: 'popular',
    NAME_ASC: 'name:asc',
    NAME_DESC: 'name:desc',
};

const defaultGameCategory = {
    ALL: 'all',
    FAVOURITES: 'favourites',
};

export const gameCategory = {
    ...defaultGameCategory,
};
