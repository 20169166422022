export const EventType = {
    UPCOMING: 'UPCOMING',
    LIVE: 'LIVE',
};

export const EventCategory = {
    EVENTS: 'events',
    BOOSTED: 'boosted',
    POPULAR: 'popular',
};

export const DEFAULT_TAKE = 100;
