<template>
    <p v-if="data.text" :class="data.cssClass">{{ data.text }}</p>
    <br v-else />
</template>
<script>
export default {
    name: 'TextContent',
    props: ['data'],
};
</script>

<style scoped lang="scss">
.centered {
    text-align: center;
}
</style>
