import { getAttribute } from './attribute';

export function getParentPage(entity) {
    const records = getAttribute('parentPage')(entity);

    if (Array.isArray(records)) {
        return records[0];
    }

    return records;
}
