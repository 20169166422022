export const MESSAGE_STATUS = {
    delivered: 'DELIVERED',
    seen: 'SEEN',
    clicked: 'CLICKED',
};

export const MESSAGE_TYPE = {
    operational: 'OPERATIONAL',
    marketing: 'MARKETING',
    all: 'ALL',
    compensation: 'COMPENSATION',
    customer_support: 'CUSTOMER_SUPPORT',
    critical_update: 'CRITICAL_UPDATE',
    general: 'GENERAL',
    promotion: 'PROMOTION',
    big_win: 'BIG_WIN',
    regular_win: 'REGULAR_WIN',
    betslip_issue: 'BETSLIP_ISSUE',
};

export const MESSAGE_ICONS_MAP = {
    [MESSAGE_TYPE.operational]: 'message-operational',
    [MESSAGE_TYPE.marketing]: 'message-marketing',
    [MESSAGE_TYPE.betslip_issue]: 'message-betslip-issue',
    [MESSAGE_TYPE.big_win]: 'message-big-win',
    [MESSAGE_TYPE.compensation]: 'message-compensation',
    [MESSAGE_TYPE.critical_update]: 'message-critical-update',
    [MESSAGE_TYPE.customer_support]: 'message-customer-support',
    [MESSAGE_TYPE.general]: 'message-general',
    [MESSAGE_TYPE.promotion]: 'message-promotions',
    [MESSAGE_TYPE.regular_win]: 'message-regular-win',
};

export const NEW_MESSAGES_MIN_CHECK_INTERVAL_MS = 600000; // 10min
