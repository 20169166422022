import scrollTo from './scrollTo';

let bindings = {
    scrollElement: null,
    handler: undefined,
};

function setBindings(scrollElement, binding) {
    bindings = { ...binding, scrollElement };
}

function getScroll() {
    return bindings.scrollElement;
}

function setHandler(handler) {
    const scroll = getScroll();
    scroll.addEventListener('scroll', handler);
}

function removeHandler(handler) {
    const scroll = getScroll();
    scroll.removeEventListener('scroll', handler);
}

function scroll(to, duration) {
    return scrollTo(bindings.scrollElement)(to, duration, bindings);
}

export default {
    bind(el, binding) {
        if (bindings.scrollElement) {
            let output = bindings.scrollElement.localName;
            for (let attr of bindings.scrollElement.attributes) {
                output += ' -> ' + attr.name + ': ' + attr.value;
            }
            return console.warn('[v-scroll warn]: Trying to bind scroll element that is already exists: ' + output);
        }
        setBindings(el, binding.value);
        if (typeof bindings.handler === 'function') {
            el.addEventListener('scroll', bindings.handler);
        }
    },
    unbind(el) {
        el.removeEventListener('scroll', bindings.handler);
        bindings.handler = undefined;
        bindings.scrollElement = null;
    },
    update(el, binding) {
        setBindings(el, binding.value);
    },
    getScroll,
    setHandler,
    removeHandler,
    scrollTo: scroll,
    bindings,
};
