<template>
    <div class="bullet-list">
        <ul>
            <li v-for="(listItem, index) in data.content" :key="index">
                <Paragraph :data="listItem.content[0]" />
                <DynamicComponent v-if="listItem.content[1]" :data="listItem.content[1]" path="content" />
            </li>
        </ul>
    </div>
</template>
<script>
import Paragraph from './Paragraph';
import { DynamicComponent } from '@agi.packages/platform/components';

export default {
    name: 'BulletList',
    components: { Paragraph, DynamicComponent },
    props: ['data'],
};
</script>
<style lang="scss" scoped>
.bullet-list {
    ul {
        margin: 0.5em 0;
        padding-left: 16px;

        &.indent {
            padding: 0 0 0 16px;
        }
    }
}
</style>
