var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide"}},[(_vm.isSideBarOpen)?_c('div',{staticClass:"side-bar content",class:[
            _vm.className,
            _vm.right && 'side-bar-right',
            _vm.isSideBarOpen ? 'open' : 'close',
            {
                overlap: _vm.isOverlaps,
                transition: _vm.isReady,
                'full-screen': _vm.isFullScreen,
                'side-bar-with-delay': _vm.hasTransitionDelay,
            } ],style:({ width: _vm.width })},[_vm._t("default")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }