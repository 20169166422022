<template>
    <div class="casino-game no-margins">
        <CasinoPills />
        <div class="loading-element">
            <LoadingElement :text="$t('ui.casinoLobby.loadingGame')" />
        </div>
        <CasinoCollectionGameTileModals v-if="game" :game="game" :modal-type="modalType" :modal-name="modalName" @closeModal="closeModal" />
    </div>
</template>

<script>
import {
    CasinoFilter,
    GAME_DEPOSIT_MODAL_NAME,
    GAME_FAVOURITES_MODAL_NAME,
    GAME_LAUNCHER_MODAL_NAME,
    REMOVE_GAME_FAVOURITES_MODAL_NAME,
} from '@/modules/casino/utils/CasinoCollection-const';
import { mapGetters, mapState } from 'vuex';
import { auth, getter as platformGetter, TOKEN_ERRORS } from '@/modules/platform';
import { getter as translationsGetter } from '@/store/modules/translations';
import CasinoCollectionGameTileModals from '@/modules/casino/strapiComponents/components/CasinoCollectionGameTileModals.vue';
import CasinoPills from '@/components/content/CasinoPills.vue';
import LoadingElement from '@/components/Fragments/LoadingElement.vue';
import { getObjectField } from '@/modules/core/utils/helper';
import { action as casinoAction, getter as casinoGetter } from '@/store/modules/casino';
import ModalPlugin from '@/js/plugins/modal';
export default {
    name: 'CasinoGameDetails',
    components: { LoadingElement, CasinoPills, CasinoCollectionGameTileModals },
    data() {
        return {
            errorModalType: false,
            modalName: null,
            modalType: null,
        };
    },
    computed: {
        ...mapState({
            gameLaunchError: (state) => state.casino.gameLaunchError,
            isBalanceReady: (state) => state.platform.auth.isBalanceReady,
        }),
        ...mapGetters({
            settings: platformGetter.GET_SETTINGS,
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            balance: auth.getter.GET_BALANCE,
            chipsBalance: auth.getter.GET_CHIPS_BALANCE,
            casinoName: translationsGetter.CASINO_NAME,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            country: platformGetter.GET_COUNTRY,
            games: casinoGetter.GET_GAMES,
        }),
        chips() {
            return this.settings.chips;
        },
        game() {
            const toOpenGameId = this.$router.currentRoute.query[CasinoFilter.GAME_ID];
            return getObjectField(this.games, 'data', []).find(
                ({ id, gameId }) => id === Number(toOpenGameId) || String(gameId).toLowerCase() === String(toOpenGameId).toLowerCase()
            );
        },
    },
    watch: {
        games: {
            immediate: true,
            handler() {
                if (!this.games.data) return;
                this.gameLauncher();
            },
        },
        balance(value) {
            if (value !== null) {
                this.gameLauncher();
            }
        },
        chipsBalance(value) {
            if (value !== null) {
                this.gameLauncher();
            }
        },
        gameLaunchError(value) {
            this.$router.back();
            if (TOKEN_ERRORS.includes(getObjectField(value, 'errorCode'))) {
                this.openModal(this.errorModalType, this.game.name);
            } else {
                !!value &&
                    this.$modal.show(
                        {
                            template: `<p style="padding-bottom: 15px; text-align: center;">{{$t('ui.casinoLobby.gameLaunchUnexpectedError')}}</p>`,
                        },
                        {},
                        { name: `${GAME_LAUNCHER_MODAL_NAME}-error`, options: { overlay: true } }
                    );
            }
        },
    },
    mounted() {
        this.$scroll.scrollTo(0, 1);
        this.$store.dispatch(casinoAction.GET_CASINO_GAMES_NEW);
        this.$gtm.query({ event: 'casino_game_page_view' });
        ModalPlugin.event.$on('hide', this.onChipsModalClose);
    },
    beforeDestroy() {
        ModalPlugin.event.$off('hide', this.onChipsModalClose);
    },
    methods: {
        gameLauncher() {
            if (!this.game) {
                return;
            }
            this.errorModalType = GAME_LAUNCHER_MODAL_NAME;
            const { name, provider, providerGameId } = this.game;
            const hasNoBetpawaChips = this.isAuthenticated && this.chips && this.chipsBalance < this.settings.betting.real.minStake;
            const hasNoBalance = this.isAuthenticated && !this.chips && this.balance <= 0;
            if (hasNoBetpawaChips) {
                this.$modal.show('chips-modal');
                return;
            }

            if (!this.isBalanceReady && this.isAuthenticated) {
                return;
            }

            if (!this.isAuthenticated || hasNoBalance) {
                this.openModal(hasNoBalance ? GAME_DEPOSIT_MODAL_NAME : GAME_LAUNCHER_MODAL_NAME, this.game.name);
                return;
            }

            const redirectFn = () => {
                this.$router.push({ ...this.$route, query: { ...this.$route.query, gameId: undefined } });
            };
            const lobbyUrl = this.$route.fullPath.replace(new RegExp(`${CasinoFilter.GAME_ID}=\\d*`), '');
            this.$store.dispatch(casinoAction.OPEN_CASINO_GAME, {
                name,
                provider,
                providerGameId,
                redirectFn,
                lobbyUrl,
            });
        },
        closeModal(type) {
            this.$router.back();
            this.$modal.hide(`casino-modal-${this.game.id}`);
            this.modalType = this.modalName = null;
        },
        onChipsModalClose(name) {
            if (name === 'chips-modal') this.$router.back();
        },
        openModal(modalName, gameName) {
            this.modalName = gameName;
            switch (modalName) {
                case GAME_FAVOURITES_MODAL_NAME:
                    return; // not needed in this component for now
                case GAME_LAUNCHER_MODAL_NAME:
                    this.modalType = GAME_LAUNCHER_MODAL_NAME;
                    break;
                case REMOVE_GAME_FAVOURITES_MODAL_NAME:
                    return; // not needed in this component for now
                default:
                    this.modalType = GAME_DEPOSIT_MODAL_NAME;
                    break;
            }

            this.$nextTick(() => {
                this.$modal.show(`casino-modal-${this.game.id}`);
            });
        },
    },
};
</script>

<style scoped lang="scss">
.casino-game {
    display: flex;
    background: $body-background-color;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    .loading-element {
        flex: 1;
    }
}
</style>
