export function meetCondition(waitInitial, waitMax, checkEvery, condition) {
    return new Promise((resolve, reject) => {
        let timeout;
        setTimeout(function f() {
            if (condition()) {
                resolve();
            } else {
                timeout = setTimeout(f, checkEvery);
            }
        }, waitInitial);
        setTimeout(() => {
            clearTimeout(timeout);
            reject(new Error('Timeout reached'));
        }, waitMax);
    });
}
