<template>
    <Modal
        class="chips-modal"
        name="chips-modal"
        :is-empty-header="true"
        :is-highest="true"
        :fixed="true"
        :options="{ backDrop: true }"
        @close="$modal.hide('chips-modal')"
        @closed="closesModal()"
    >
        <Tabs :tabs="tabs" :active="activeTab" class="chips-tabs" @select="handleTabClick">
            <Tab :name="tabType.TOP_UP">
                <ChipsTopUp />
            </Tab>
            <Tab :name="tabType.CASH_IN">
                <ChipsCashIn />
            </Tab>
        </Tabs>
    </Modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { Tabs, Tab } from '@agi.packages/core/components';
import { chipsType } from '@/js/chips-const';
import { auth, mutation as platformMutation } from '@agi.packages/platform';

import ChipsTopUp from './ChipsTopUp.vue';
import ChipsCashIn from './ChipsCashIn.vue';

export default {
    name: 'ChipsModal',
    components: {
        ChipsTopUp,
        ChipsCashIn,
        Tabs,
        Tab,
    },
    data() {
        return {
            tabType: chipsType,
            activeTab: {
                key: chipsType.TOP_UP,
            },
        };
    },
    computed: {
        ...mapGetters({
            balance: auth.getter.GET_CHIPS_BALANCE,
        }),
        tabs() {
            return [
                {
                    text: this.$t('ui.chips.topUp.topUpTab'),
                    key: chipsType.TOP_UP,
                },
                {
                    text: this.$t('ui.chips.cashIn.cashInTab'),
                    key: chipsType.CASH_IN,
                },
            ];
        },
    },
    methods: {
        ...mapMutations({
            setChipsCashInTaxDetails: platformMutation.SET_CHIPS_CASH_IN_TAX_DETAILS,
        }),
        closesModal() {
            this.activeTab = this.activeTabDetails(chipsType.TOP_UP);
        },
        activeTabDetails(key) {
            return this.tabs.find((item) => item.key === key);
        },
        validateCashInTab(key) {
            return (!this.balance || this.balance === 0) && key !== chipsType.TOP_UP;
        },
        handleTabClick({ key }) {
            if (this.validateCashInTab(key)) {
                return;
            }
            this.setChipsCashInTaxDetails({});
            this.activeTab = this.activeTabDetails(key);
        },
    },
};
</script>

<style lang="scss">
.chips {
    &-title {
        font-size: 18px;
        margin-bottom: 6px;
        text-transform: uppercase;
    }
    &-sub-title {
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: 325;
    }
    &-content {
        padding: 12px;
        border: 1px solid #e6e7e2;
    }
    &-item {
        display: flex;
        justify-content: space-between;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        .symbol {
            font-size: 12px;
        }
        &-text {
            font-size: 14px;
            font-weight: 325;
        }
    }

    &-tabs {
        border: 1px solid $border-color;
        border-top: none;

        .tabs-menu {
            .tabs-selector {
                border-top: none;

                &.first {
                    border-left: none;
                }

                &.last {
                    border-right: none;
                }
            }
        }

        section {
            padding: 16px;
        }
    }
}

.chips-modal {
    top: 0;
    .modal-body {
        padding: 10px;
    }
    .modal-container {
        padding: 0;
    }
    .modal-header-empty {
        padding: 0;
    }
}

.button {
    &.button-confirm {
        margin-top: 10px;
        padding: 8px;
    }
    &-cancel {
        margin-top: 4px;
        text-align: center;
        padding: 8px;
        font-size: 14px;
        text-decoration: underline;
    }
}
</style>
