import { render, staticRenderFns } from "./BetBonus.vue?vue&type=template&id=30827468&scoped=true"
import script from "./BetBonus.vue?vue&type=script&lang=js"
export * from "./BetBonus.vue?vue&type=script&lang=js"
import style0 from "./BetBonus.vue?vue&type=style&index=0&id=30827468&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30827468",
  null
  
)

export default component.exports