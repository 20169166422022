<template>
    <ol v-if="isOrdered" :class="[data.cssClass]">
        <li v-for="(item, index) of data.values" :key="index">{{ item }}</li>
    </ol>
    <ul v-else>
        <li v-for="(item, index) of data.values" :key="index">{{ item }}</li>
    </ul>
</template>
<script>
const listType = {
    ORDERED: 'ordered',
    UNORDERED: 'unordered',
};

export default {
    name: 'List',
    props: ['data'],
    computed: {
        isOrdered() {
            return this.data.listType === listType.ORDERED;
        },
    },
};
</script>
<style lang="scss" scoped>
ol,
ul {
    list-style-position: inside;
    margin: 1em 0;

    &.indent {
        padding: 0 0 0 20px;
    }
}
</style>
