<template>
    <div v-if="isPagesPreviewEnabled" class="pages-preview-component">
        <h2 v-if="data.content.title">{{ data.content.title }}</h2>
        <div v-for="(previewPage, index) in sortedPreviewPagesByPopularity" :key="'preview-' + index" class="previews-list">
            <h2>{{ previewPage.forPreview.title }}</h2>
            <div class="preview-banner">
                <a v-if="getPosterUrl(previewPage)" @click="goTo(previewPage.path)">
                    <img class="preview-image" :src="`${getPosterUrl(previewPage)}`" />
                </a>
                <div
                    v-if="isBadgeEnabled(previewPage)"
                    class="preview-badge"
                    :class="{ right: previewPage.pinToTop.iconPosition === 'right' }"
                    :style="{ background: previewPage.pinToTop.bgColor, color: previewPage.pinToTop.textColor }"
                >
                    <div v-if="previewPage.pinToTop.icon" class="preview-badge-icon">
                        <SvgIcon class="icon-size-small" :icon-id="previewPage.pinToTop.icon" vertical-align="text-top" />
                    </div>
                    <template v-if="previewPage.pinToTop.title">{{ previewPage.pinToTop.title }}</template>
                </div>
            </div>

            <p class="preview-description">{{ previewPage.forPreview.description }}</p>

            <button
                v-if="previewPage.forPreview.buttonText"
                class="cta-button"
                :style="{ 'background-color': previewPage.forPreview.buttonColor || '#252a2d' }"
                @click="goTo(previewPage.path)"
            >
                {{ previewPage.forPreview.buttonText }}
            </button>
            <hr />
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { getter as platformGetter } from '@agi.packages/platform';
import { routeName } from '@/router/const-name';
import { getObjectField } from '@/modules/core/utils/helper';
import { getStrapiData } from '@/modules/core/utils/strapi/strapiData';
import { getAttribute } from '@/modules/core/utils/strapi/attribute';

export default {
    name: 'PagesPreview',
    components: {},
    props: ['data'],
    computed: {
        ...mapState({}),
        ...mapGetters({
            cmsPages: platformGetter.GET_PAGES,
        }),
        isPagesPreviewEnabled() {
            return this.data.content.isPagesPreviewEnabled;
        },
        pageName() {
            if (routeName.PREVIEW === this.$route.name) {
                return routeName.PREVIEW;
            }

            const { pathMatch = '' } = this.$route.params;

            return pathMatch.replace(/\/+$/, '').split('/').slice(-1)[0];
        },
        cmsPage() {
            return this.cmsPages[this.pageName];
        },
        previewPages() {
            const page = this.cmsPage;
            const previewPages = getObjectField(this, 'data.content.includeRelatedPagesByTag', false) ? page.mergedPages : page.childPages;

            return previewPages.filter((page) => page.forPreview);
        },
        sortedPreviewPagesByDate() {
            return [...this.previewPages].sort((a, b) => (a.publishedAt < b.publishedAt ? 1 : -1));
        },
        sortedPreviewPagesByPopularity() {
            const popular = [];
            const nonPopular = [];

            this.sortedPreviewPagesByDate.forEach((page) => {
                const isPinned = getObjectField(page, 'pinToTop.isPinned', false);

                (isPinned ? popular : nonPopular).push(page);
            });

            return popular.concat(nonPopular);
        },
    },
    methods: {
        goTo(path) {
            this.$router.push({ path });
        },
        getPosterUrl(previewPage) {
            return getAttribute('url', '')(getStrapiData('image', '')(previewPage.forPreview));
        },
        isBadgeEnabled(previewPage) {
            return getObjectField(previewPage, 'pinToTop.isPinned', false) && getObjectField(previewPage, 'pinToTop.isBadgeEnabled', false);
        },
    },
};
</script>

<style scoped lang="scss">
.pages-preview-component {
    margin: 20px 0;

    .previews-list {
        &:last-child {
            hr {
                display: none;
            }
        }
    }

    .preview-banner {
        position: relative;
        text-align: center;
    }

    .preview-image {
        width: 100%;
        margin: 0 0 20px 0;
    }

    .preview-badge {
        position: absolute;
        display: flex;
        align-items: center;
        padding: 0 10px 0 6px;
        top: 20px;
        left: 0;
        height: 24px;
        font-weight: 700;
        background-color: $orange;
        border-radius: 0 12px 12px 0;

        &.right {
            flex-direction: row-reverse;
            padding: 0 10px;

            .preview-badge-icon {
                margin-left: 5px;
                margin-right: 0;
            }
        }

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
        }
    }

    .preview-description {
        margin: 0 0 20px 0;
    }

    .cta-button {
        width: 100%;
        margin: 0 0 30px 0;
        display: block;
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
        font-weight: 700;
        font-size: 14px;
        padding: 10px 20px;
        text-transform: uppercase;
        border: 0;

        &:hover {
            color: #9ce800;
        }
    }

    hr {
        border-top: 1px solid black;
        margin: 0 0 30px 0;
    }
}
</style>
