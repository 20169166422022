const SortConverterType = {
    NUMBER: 'number',
    STRING: 'string',
};
const sortConverter = {
    [SortConverterType.NUMBER]: (val) => Number(val),
    [SortConverterType.STRING]: (val) => String(val).toLocaleLowerCase(),
};

export const getCasinoGamesLimitForGridPerRow = (currentSize) => {
    if (!currentSize) {
        // bigger than xm
        return 4;
    }
    const oneRowLimitPerSize = { vxs: 2, ivxs: 2, xxxs: 2, xxs: 3, xs: 3, sm: 4, md: 4 };
    return oneRowLimitPerSize[currentSize];
};

const getSortGamesBy = (param, asc, type = SortConverterType.STRING) => {
    const ascValue = asc ? 1 : -1;
    return (a, b) => (sortConverter[type](a[param]) >= sortConverter[type](b[param]) ? ascValue : -ascValue);
};
export const getSortGamesByFavourites = (favourites, asc) => (a, b) =>
    asc ? favourites.indexOf(a.id) - favourites.indexOf(b.id) : favourites.indexOf(b.id) - favourites.indexOf(a.id);

export const sortGamesByPopular = getSortGamesBy('position', true, SortConverterType.NUMBER);

export const sortGamesByName = (asc) => getSortGamesBy('name', asc);

export const getGamesFilterFunction = (showFavourites, favourites, categoryId) => {
    let filterFn = () => true;
    if (showFavourites) {
        filterFn = ({ id }) => favourites.includes(id);
    } else if (categoryId) {
        filterFn = ({ categories }) => categories.includes(categoryId);
    }
    return filterFn;
};

export const fillGamesGapWithFakeGames = (games, gamesLimit) => {
    const newGames = [...games];
    for (let i = newGames.length; i < gamesLimit; i += 1) {
        newGames.push({
            id: `fake-${i}`,
            isFake: true,
            name: '',
        });
    }
    return newGames;
};
