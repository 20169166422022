<template>
    <div v-if="isNewUser" class="back" @click="onBack">
        <div class="back-icon">
            <SvgIcon icon-id="arrow_left" />
        </div>
        <p class="back-title">
            {{ backTitle }}
        </p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getter } from '@agi.packages/payment';
import { auth } from '@agi.packages/platform';

export default {
    name: 'BackButton',
    props: ['data'],
    computed: {
        ...mapGetters({
            firstDepositData: getter.GET_FIRST_DEPOSIT_DATA,
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
        }),
        isNewUser() {
            return !this.isAuthenticated || (this.isAuthenticated && this.firstDepositData);
        },
        backTitle() {
            return this.data?.content?.title || this.$t('ui.common.back');
        },
    },
    methods: {
        onBack() {
            const { skipRedirect, page, metrika } = this.data?.content || {};
            const actualLink = page?.data?.attributes?.links?.find(({ isActual }) => isActual);
            if (metrika) {
                this.$gtm.query({
                    event: 'back_button_click',
                    isAuthenticated: this.isAuthenticated,
                    metrika,
                });
            }
            if (actualLink) {
                this.$route.meta.skipDepositRedirect = !!skipRedirect;
                this.$router.push({ path: actualLink.link });
            } else {
                this.$router.back();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.back {
    padding: 12px;
    background-color: $light-grey;
    display: flex;
    margin: -20px -20px 0 -20px;
    cursor: pointer;
    border-bottom: 1px solid $border-color;

    &-icon {
        margin-right: 8px;

        svg {
            height: 16px;
            width: 16px;
            fill: $main-text;
        }
    }
    &-title {
        @extend %body-normal-font-500;
        color: $main-text;
        align-self: flex-end;
    }
}
</style>
