<template>
    <div class="video-streamer-container">
        <div ref="streamContentRef" class="stream-content">
            <div v-if="!isAuthenticated || !widget.isLive" class="stream-content-text">
                <renderer
                    v-if="!isAuthenticated"
                    :input="$t('ui.eventPage.betpawaTv.unauthenticatedUserText')"
                    @clickEvent="handleG4Event"
                />
                <div v-else>
                    <renderer :input="$t('ui.eventPage.betpawaTv.prematchText', startDateTime)" />
                    <renderer class="view-stats-cta" :input="$t('ui.eventPage.betpawaTv.viewStatistics')" @clickEvent="openStatisticsTab" />
                </div>
            </div>
            <div v-else>
                <VideoStreamSpinner v-if="!isVideoStreamLoaded" />
                <script
                    v-if="isReady"
                    :key="uniqueStreamKey"
                    type="application/javascript"
                    :data-uuid="widget.fixtureUuid"
                    :data-outletkey="widget.outletAuthKey"
                    data-apptype="csb"
                    data-env="betting"
                    :src="widget.playerUrl"
                    :data-streamuuid="widget.livestreamUuid"
                    :data-streamuser="widget.userUuid"
                    :data-oauthtoken="widget.accessToken"
                    v-bind="{ ...streamAttrs }"
                    @load="isVideoStreamLoaded = true"
                />
            </div>
        </div>
        <template>
            <div class="close-tv" @click="closeTv">
                <SvgIcon class="icon-size-small" icon-id="icon-arrow-up" />
                {{ $t('ui.eventPage.betpawaTv.closeTvText') }}
            </div>
            <div class="disclaimer">
                {{ $t('ui.eventPage.disclaimer.statsPerform') }}
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { sport } from '@agi.packages/sport';
import { VideoStreamSpinner } from '@agi.packages/sport/components';
import { helper } from '@agi.packages/core';
import { auth, getter as platformGetter } from '@agi.packages/platform';

export default {
    name: 'VideoStream',
    components: { VideoStreamSpinner },
    props: {
        streamData: {
            type: Object,
            required: true,
            default: () => ({
                fixtureUuid: undefined,
                outletAuthKey: undefined,
                playerUrl: undefined,
                livestreamUuid: undefined,
                userUuId: undefined,
                accessToken: undefined,
                eventStartTime: undefined,
                isLive: false,
            }),
            validator(data) {
                return data.livestreamUuid && data.playerUrl && data.fixtureUuid;
            },
        },
    },
    data() {
        return {
            isTvStreamClosed: false,
            isVideoStreamLoaded: false,
            streamAttrs: {},
            uniqueStreamKey: 1,
        };
    },
    computed: {
        ...mapState({
            statsPerform: (state) => state.sport.sports.statsPerform,
        }),
        ...mapGetters({
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
            country: platformGetter.GET_COUNTRY,
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            dateOptions: platformGetter.GET_DATE_OPTIONS,
        }),
        widget() {
            return {
                ...this.streamData,
                ...this.statsPerform,
            };
        },
        startDateTime() {
            return {
                time: helper.formatDateTime(this.streamData.eventStartTime, {
                    needTime: true,
                    needDate: false,
                    ...this.dateOptions,
                    isUtc: true,
                }),
                date: helper.formatDateTime(this.streamData.eventStartTime, {
                    needTime: false,
                    needDate: true,
                    ...this.dateOptions,
                    isUtc: true,
                }),
            };
        },
        isReady() {
            const { accessToken, outletAuthKey } = this.widget;
            return !!(outletAuthKey && accessToken);
        },
        tvWidth() {
            return this.$refs.streamContentRef.clientWidth;
        },
    },
    mounted() {
        this.uniqueStreamKey = Date.now();
        if (this.isAuthenticated) {
            this.$store.dispatch(sport.action.AUTHORIZE_STATS_PERFORM, this.widget.livestreamUuid);
        }
        this.streamAttrs['data-width'] = this.tvWidth;
        const { SPMode, SPDefaultView, SPVideoOnly, SPStreamOnly } = this.brandPreference;
        this.streamAttrs['data-mode'] = SPMode || 'mini';
        this.streamAttrs['data-defaultview'] = SPDefaultView || 'vid';
        this.streamAttrs['data-videoonly'] = SPVideoOnly || true;
        this.streamAttrs['data-streamonly'] = SPStreamOnly || true;
    },
    methods: {
        handleG4Event({ event }) {
            const g4Events = {
                loginGtmCapture: () => {
                    this.$gtm.query({
                        event: 'login_link_from_betpawa_tv_click',
                        jurisdiction: this.country,
                        pageUrl: this.$route.path,
                    });
                },
                joinNowGtmCapture: () => {
                    this.$gtm.query({
                        event: 'join_now_link_from_betpawa_tv_click',
                        jurisdiction: this.country,
                        pageUrl: this.$route.path,
                    });
                },
            };
            g4Events[event] && g4Events[event]();
        },
        closeTv() {
            this.$gtm.query({
                event: 'close_betpawa_tv_click',
                isAuthenticated: this.isAuthenticated,
                jurisdiction: this.country,
                pageUrl: this.$route.path,
            });
            this.$emit('closeBetpawaTv');
        },
        openStatisticsTab() {
            this.$emit('openStatsTab');
            this.$gtm.query({
                event: 'view_statistics_from_betpawa_tv_prematch_click',
                jurisdiction: this.country,
                pageUrl: this.$route.path,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.video-streamer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $event-widget-dark-bg;
    .stream-content {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 196px;
        transition: all 0.2s linear;
        overflow: hidden;
        color: $event-widget-dark-bg-text-color;
        text-align: center;

        &-text {
            padding: 12px;
            @extend %small-details-font-400;
        }
    }
    .view-stats-cta {
        margin-top: 8px;
        display: block;
    }
}
.close-tv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    color: $event-widget-dark-bg-close-tv-text-color;
    column-gap: 4px;
    cursor: pointer;
    border-top: 1px solid $event-widget-dark-border-color;
    @extend %small-details-font-400;
    svg {
        fill: $close-tv-icon-color;
    }
}
</style>
