<template>
    <div v-if="country && phonePrefix" class="country-code-container">
        <InputField
            class="phone-number-input"
            :name="name"
            type="tel"
            :form-name="formName"
            :value="value"
            :help-text="helpText"
            :v="v"
            :attrs="{ ...$attrs }"
            :error-messages="errors"
            @value="$emit('value', $event)"
        >
            <template slot="label">{{ label || $t('ui.common.user.phoneNumber') }}</template>
            <template slot="optional">
                <slot name="optional" />
            </template>
            <template>
                <CountryFlag />
                <span class="country-code">+{{ phonePrefix }}</span>
            </template>
        </InputField>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { InputField } from '@agi.packages/core/components';
import { getter as platformGetter } from '@agi.packages/platform';
import { CountryFlag } from '@agi.packages/platform/components';

export default {
    name: 'PhoneNumber',
    components: { InputField, CountryFlag },
    props: {
        label: {
            type: String,
        },
        value: {
            default: '',
        },
        formName: { required: true },
        name: {
            type: String,
            default: 'phoneNumber',
        },
        validator: {
            type: Function,
            default: () => true,
        },
        helpText: {
            type: String,
        },
        error: {
            type: String,
        },
        v: {
            type: Object,
        },
        errorMessages: {
            type: Object,
        },
    },
    computed: {
        ...mapState({
            phonePrefix: (state) => state.platform.settings.brandIdentity.phoneCountryCode,
        }),
        ...mapGetters({
            country: platformGetter.GET_COUNTRY,
        }),
        errors() {
            const defaultErrors = {
                required: this.$t('ui.common.form.error.phoneNumberRequired'),
            };
            return { ...defaultErrors, ...this.errorMessages };
        },
    },
};
</script>

<style scoped lang="scss">
.country-code-container {
    width: 100%;
}
.phone-number-input {
    width: 100%;

    ::v-deep .input-field-wrapper {
        background-color: $input-wrapper-background-color;
        border-radius: 2px 5px 5px 2px;
        align-items: center;
        width: 100%;

        @include only_mini_feature_phone {
            display: table-row;
        }
    }

    ::v-deep input {
        padding-left: 40px;

        [disabled] & {
            color: $grey-text;
            font-weight: bold;
        }

        @include only_mini {
            padding-left: 0;
        }
    }

    .flag {
        flex: 0 0 24px;
        width: 24px;
        height: 16px;
        margin: 0 7px;

        @include only_mini_feature_phone {
            background-position: center;
            display: table-cell;
        }
    }

    .country-code {
        position: absolute;
        left: 44px;
        font-size: 14px;
        line-height: 1.1rem;
        color: $grey-text;
        @include ios_le_12 {
            top: 50%;
            transform: translateY(-50%);
        }

        [disabled] & {
            //color: #252A2D;
            font-weight: bold;
        }

        @include only_mini {
            position: static;
            margin-right: 7px;
        }

        @include only_mini_feature_phone {
            display: table-cell;
        }
    }
}
</style>
