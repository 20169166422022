import Vue from 'vue';

import { actionLoader, deviceType, helper } from '@agi.packages/core';
import { getter as platformGetter } from '@agi.packages/platform';

import { getLocalTypes } from '@/store/utils'; // move to core BP-16141
import { user } from '../../endpoints';
import { MESSAGE_STATUS } from '../../const/messaging';

export const action = {
    GET_MESSAGES: 'platform/messaging/getMessages',
    MARK_AS_CLICKED: 'platform/messaging/markAsClicked',
    RESET_MESSAGING: 'platform/messaging/resetMessaging',
    GET_ONSITE_MESSAGES_AND_COUNT: 'platform/messaging/getOnsiteMessagesAndCount',
};

export const mutation = {
    SET_MESSAGES: 'platform/messaging/setMessages',
    RESET_MESSAGING: 'platform/messaging/resetMessaging',
    SET_ONSITE_MESSAGE_STATUS: 'platform/messaging/setMessageStatus',
    SET_ONSITE_MESSAGES_AND_COUNT: 'platform/messaging/setOnsiteMessagesAndCount',
    SET_MESSAGES_ERROR: 'platform/messaging/setMessagesError',
    RESET_MESSAGES_ERROR: 'platform/messaging/resetMessagesError',
};

export const getter = {
    GET_NOTSEEN: 'platform/messaging/getNotseen',
};

const DEFAULT_STATE = {
    messages: [],
    currentPage: 0,
    totalPages: 0,
    notSeen: 0,
    nextCheckTimestamp: 0,
    onsiteMessages: null,
    error: null,
};

const state = { ...DEFAULT_STATE };

const _mutation = getLocalTypes(mutation);

const getters = {
    getNotseen: (state) => state.notSeen,
};

const mutations = {
    [_mutation.SET_MESSAGES_ERROR](state, payload) {
        state.error = payload;
    },
    [_mutation.RESET_MESSAGES_ERROR](state) {
        state.error = DEFAULT_STATE.error;
    },
    [_mutation.SET_MESSAGES](state, { messages, currentPage, totalPages, notSeen }) {
        const messageList = currentPage ? [...state.messages, ...messages] : messages;
        state.messages = messageList;
        state.currentPage = currentPage;
        state.totalPages = totalPages;
        state.notSeen = notSeen;
    },
    [_mutation.RESET_MESSAGING](state) {
        Object.assign(state, DEFAULT_STATE);
    },
    [_mutation.SET_ONSITE_MESSAGE_STATUS](state, id) {
        const messages = [...state.onsiteMessages];
        const message = messages.find((message) => message.id === id);
        if (message) {
            message.messageStatus = MESSAGE_STATUS.clicked;
            state.onsiteMessages = [...messages];
        }
    },
    [_mutation.SET_ONSITE_MESSAGES_AND_COUNT](state, { count, messages, cooldownTime }) {
        state.notSeen = count;
        state.onsiteMessages = messages.sort((a, b) => b.startTime - a.startTime);
        state.nextCheckTimestamp = new Date().getTime() + cooldownTime;
    },
};

const _action = getLocalTypes(action);

const actions = {
    [_action.GET_MESSAGES]: actionLoader(action.GET_MESSAGES, ({ commit, rootGetters }, pageNumber = 0) => {
        commit(_mutation.RESET_MESSAGES_ERROR);
        const signupDate = rootGetters[platformGetter.GET_SETTINGS].user.signedUp;
        // TODO: BP-16235
        return Vue.$http
            .post(user.getMessages, { pageNumber, ...(signupDate && { signupDate }) })
            .then((response) =>
                commit(_mutation.SET_MESSAGES, {
                    messages: response.data.userMessages,
                    currentPage: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    notSeen: response.data.newMessagesCount,
                })
            )
            .catch((error) => {
                commit(_mutation.SET_MESSAGES_ERROR, { message: helper.processErrorResponse(error) });
            });
    }),
    [_action.MARK_AS_CLICKED]({ state, commit, dispatch }, id) {
        commit(_mutation.SET_ONSITE_MESSAGE_STATUS, id);
        return Vue.$http
            .put(user.updateMessage, [{ id, messageStatus: MESSAGE_STATUS.clicked }])
            .then(() => {
                dispatch(_action.GET_ONSITE_MESSAGES_AND_COUNT);
            })
            .catch((error) => {
                console.error(`${_action.MARK_AS_CLICKED} Response Error`, [error]);
            });
    },
    [_action.GET_ONSITE_MESSAGES_AND_COUNT]({ state, commit, rootGetters }, { force } = {}) {
        if (state.nextCheckTimestamp > new Date().getTime() && !force) return;
        commit(_mutation.RESET_MESSAGES_ERROR);

        return Vue.$http
            .get(user.getOnsiteMessagesAndCount)
            .then(({ data }) => {
                const { count, pathMessages } = data;
                const messages = pathMessages && Object.keys(pathMessages).length ? Object.values(pathMessages).flat() : [];
                const { messagingCooldownMinutes, operaMessagingCooldownMinutes } = rootGetters[platformGetter.GET_BRAND_PREFERENCE];
                const cooldownTime = ((deviceType.isPresto() ? operaMessagingCooldownMinutes : messagingCooldownMinutes) || 0) * 60000;
                commit(_mutation.SET_ONSITE_MESSAGES_AND_COUNT, { count, messages, cooldownTime });
            })
            .catch((error) => {
                commit(_mutation.SET_MESSAGES_ERROR, { message: helper.processErrorResponse(error), errorCode: error.errorCode });
            });
    },
};

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations,
};
