<template>
    <a :href="data.link" :target="data.target" :class="[data.class, 'button']" @click="sendEvent">
        <SvgIcon v-if="icon" class="icon" :icon-id="icon" />
        {{ data.text }}
    </a>
</template>
<script>
const NO_SUBSET = 'no-subset';

const CLASS_ICONS = {
    'button-facebook-full': 'fb',
    'button-facebook': 'fb',
    'button-whatsapp-full': 'wa',
    'button-whatsapp': 'wa',
    'button-twitter-full': 'tw',
    'button-twitter': 'tw',
};

export default {
    name: 'Social',
    props: ['data'],
    computed: {
        icon() {
            return this.data.icon || CLASS_ICONS[this.data.class];
        },
    },
    methods: {
        sendEvent() {
            const subset = this.data.subset.toLowerCase() || NO_SUBSET;
            this.$gtm.query({ event: 'social_button_click', subset });
        },
    },
};
</script>

<style scoped lang="scss">
a:focus {
    color: white;
}

.icon {
    width: 20px;
    height: 20px;
}

.button {
    &[class$='-full'] {
        width: 100%;
    }

    &-facebook,
    &-facebook-full,
    &-twitter,
    &-twitter-full,
    &-whatsapp,
    &-whatsapp-full {
        @extend %generic_button;
        color: $white-text;
        text-align: left;
        font-weight: 400;
        padding: 10px 19px 10px 12px;
        background-size: 20px 20px !important;
        text-transform: none !important;
        text-decoration: none !important;
    }

    &-twitter,
    &-twitter-full {
        background: $social-twitter-blue;

        &:hover,
        &:active {
            background: $social-twitter-blue-hover;
        }
    }

    &-facebook,
    &-facebook-full {
        background: $social-facebook-blue;

        &:hover,
        &:active {
            background: $social-facebook-blue-hover;
        }
    }

    &-whatsapp,
    &-whatsapp-full {
        background: $social-whatsapp-green;
    }
}
</style>
