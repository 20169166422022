<template>
    <p>{{ data.text }}</p>
</template>
<script>
export default {
    name: 'Sendername',
    props: ['data'],
};
</script>
<style scoped lang="scss">
p {
    margin: 0 0 0.8em 0;
}
</style>
