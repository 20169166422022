<script>
import SvgIcon from '@/js/globalComponents/SvgIcon';
import router from '@/router';
import actionsHandler from '@/js/utils/cms/actions-handler';

function getClassName(size) {
    switch (size) {
        case 'xs':
            return 'very-small';
        case 'sm':
            return 'small';
        case 'md':
            return 'medium';
        case 'lg':
            return 'large';
        case 'xl':
            return 'very-large';
        default:
            return 'medium';
    }
}

export default {
    name: 'Button',
    functional: true,
    props: {
        data: {
            default: () => {},
        },
    },
    render(h, context) {
        const { color, backgroundColor, fullSize, alignment, icon, isIconAlignRight, link, text, iconSize, actionName } =
            context.props.data.attrs;

        return h('div', { class: ['button-wrapper', `button-wrapper--${alignment}`] }, [
            h(
                'button',
                {
                    on: {
                        click() {
                            const { href, external, target } = link || {};
                            const isHrefAction = href && (href.match(/^(mailto|tel):(.*)/) || []).length;
                            if (isHrefAction) {
                                actionName && actionsHandler(actionName);
                                location.href = href;
                                return;
                            }
                            if (actionName) {
                                actionsHandler(actionName);
                            } else {
                                external ? window.open(href, target) : router.push({ path: href });
                            }
                        },
                    },
                    class: ['button'],
                    style: {
                        color,
                        backgroundColor,
                        width: fullSize && '100%',
                        maxWidth: fullSize && '100%',
                    },
                },
                icon
                    ? [
                          isIconAlignRight && h('span', { style: { marginRight: '6px' } }, text),
                          h(SvgIcon, { props: { iconId: icon }, class: `icon-size-${getClassName(iconSize)}` }),
                          !isIconAlignRight && h('span', { style: { marginLeft: '6px' } }, text),
                      ]
                    : text
            ),
        ]);
    },
};
</script>
<style scoped lang="scss">
.button-wrapper {
    width: 100%;
    display: flex;

    &--left {
        justify-content: flex-start;
    }
    &--center {
        justify-content: center;
    }
    &--right {
        justify-content: flex-end;
    }
}
.button {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
