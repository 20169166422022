export default [
    {
        path: ['currentCategoryId'],
    },
    {
        path: ['appVer'],
    },
    {
        path: ['sport', 'jackpot', 'selected'],
    },
    {
        path: ['sport', 'jackpot', 'uniqueTicketId'],
    },
    {
        path: ['sport', 'betslip', 'regular', 'status'],
    },
    {
        path: ['sport', 'betslip', 'regular', 'stake'],
    },
    {
        path: ['sport', 'betslip', 'regular', 'acceptAnyPrice'],
    },
    {
        path: ['sport', 'betslip', 'regular', 'selectedBetsIds'],
    },
    {
        path: ['sport', 'betslip', 'virtual', 'status'],
    },
    {
        path: ['sport', 'betslip', 'virtual', 'stake'],
    },
    {
        path: ['sport', 'betslip', 'virtual', 'season'],
    },
    {
        path: ['sport', 'betslip', 'virtual', 'selectedBetsIds'],
    },
    {
        path: ['sport', 'betslip', 'selectedEventId'],
    },
    {
        path: ['sport', 'betslip', 'selectedPriceId'],
    },
    {
        path: ['sport', 'myBets', 'offerPolling'],
    },
    {
        path: ['platform', 'settings', 'preference', 'first_bet'],
    },
    {
        path: ['platform', 'auth', 'phoneNumber'],
    },
    {
        path: ['platform', 'auth', 'otpTimer'],
    },
    {
        path: ['platform', 'auth', 'nextCheckBalanceTimestamp'],
    },
    {
        path: ['platform', 'auth', 'accountBalance'],
    },
    {
        path: ['platform', 'auth', 'isBalanceReady'],
    },
    {
        path: ['platform', 'auth', 'otpAttempts'],
    },
    {
        path: ['platform', 'auth', 'nextOtpAttemptTimestamp'],
    },
    {
        path: ['platform', 'auth', 'resetPasswordTrackingId'],
    },
    {
        path: ['platform', 'content', 'isNeedToSendPaymentComponentVisibilityEvent'],
    },
    {
        path: ['platform', 'campaignEligibility'],
    },
    {
        path: ['platform', 'messaging', 'nextCheckTimestamp'],
    },
    {
        path: ['platform', 'messaging', 'notSeen'],
    },
    {
        path: ['translations', 'language'],
    },
    {
        path: ['translations', 'persist'],
    },
    {
        path: ['isTermsDeclined'],
    },
    {
        path: ['payment', 'deposit', 'providers', 'isDepositComponentVisible'],
    },
];
