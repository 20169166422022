const name = 'click-outside';
const ClickOutside = {
    name,
    install(Vue) {
        Vue.directive(name, {
            bind(el, binding, vNode) {
                if (!binding.arg && typeof binding.arg !== 'undefined') {
                    return;
                }
                if (typeof binding.value !== 'function') {
                    console.warn(`[v-${name}:] provided expression '${binding.expression}' is not a function in ${vNode.context.name}`);
                }
                const isBoolean = (val) => typeof val === 'boolean';
                const activator = (target) => (isBoolean(binding.arg) ? !binding.arg : binding.arg.contains(target));
                const { bubble } = binding.modifiers;
                const handler = (e) => {
                    if (bubble || (!el.contains(e.target) && !activator(e.target) && el !== e.target)) {
                        binding.value(e);
                    }
                };
                el.__vueClickOutside__ = handler;
                document.addEventListener('click', handler, { capture: !bubble });
            },

            unbind(el, binding) {
                if (!binding.arg) {
                    return;
                }
                const { bubble } = binding.modifiers;
                document.removeEventListener('click', el.__vueClickOutside__, { capture: !bubble });
                el.__vueClickOutside__ = null;
            },
        });
    },
};

export default ClickOutside;
