import { isSameDepositOption } from '@agi.packages/payment';
import { helper } from '@agi.packages/core';

const updateDepositOptions = ({ optionsConfig, options }) => {
    if (options?.length && optionsConfig?.data) {
        const updatedOptions = options.map((option) => {
            const updatedOption = { ...option, id: option.depositTypeName };
            const optionFromConfig = optionsConfig.data.find((optionConfig) => isSameDepositOption(optionConfig.attributes, updatedOption));
            return optionFromConfig ? processConfigOption(optionFromConfig, updatedOption) : updatedOption;
        });

        return sortOptions([...updatedOptions]);
    }
    return sortOptions((options || []).map((option) => ({ ...option, id: option.depositTypeName })));
};

const processConfigOption = (optionFromConfig, updatedOption) => {
    if (!updatedOption) {
        updatedOption = { ...optionFromConfig.attributes };
    }

    const notNullKeys = Object.entries(optionFromConfig.attributes).filter(([k, v]) => {
        if (v === null) {
            return false;
        }
        if (typeof v === 'object') {
            if (Array.isArray(v)) {
                return v.length > 0;
            }
            return v.data !== null;
        }
        return true;
    });
    notNullKeys.forEach(([k, v]) => {
        switch (k) {
            case 'page':
                const actualLink = v.data?.attributes.links.find((link) => link.isActual);
                if (actualLink) {
                    updatedOption.pageUrl = actualLink.link;
                }
                break;
            case 'icon':
                updatedOption.mediaUrl = v.data?.attributes.url;
                break;
            case 'minDepositAmount':
            case 'maxDepositAmount':
                updatedOption[k] = Number(v);
                break;
            case 'applicableForUserTelco':
            case 'webAvailable':
            case 'ussdAvailable':
                updatedOption[k] = v === 'true';
                break;
            default:
                if (helper.isObject(updatedOption[k])) {
                    updatedOption[k] = { ...updatedOption[k], ...(v || {}) };
                } else if (Array.isArray(updatedOption[k])) {
                    updatedOption[k] = [...updatedOption[k], ...(v || [])];
                } else {
                    updatedOption[k] = v;
                }
                break;
        }
    });

    return updatedOption;
};

const sortOptions = (options) => {
    return [...options].sort((a, b) => (a.sorting >= b.sorting ? 1 : -1));
};

export default updateDepositOptions;
