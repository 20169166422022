export const ROUND_STATUS = {
    BETTING_NOT_OPEN: 'upcoming',
    BETTING_ACTIVE: 'live',
    PAST_GAME_ROUND: 'finished',
};

export const MARKET_TABS = [
    { key: 3743, text: '_1X2' },
    { key: 5000, text: 'OU' },
    { key: 3795, text: 'BTTS' },
    { key: 4693, text: 'DC' },
    { key: 4706, text: 'HT_FT' },
];

export const VIRTUAL_LEAGUE_MAP = {
    1: 'england',
    2: 'spain',
    3: 'italy',
    4: 'germany',
    5: 'france',
    6: 'netherlands',
    7: 'portugal',
};

export const VIRTUAL_UPCOMING_LEAGUE_MAP = {
    288: 1,
    237: 2,
    230: 3,
    242: 4,
    241: 5,
    246: 6,
    233: 7,
};
