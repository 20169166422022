<template>
    <div class="input-range-wrapper">
        <input
            ref="inputRangeRef"
            v-model="currentVal"
            class="input-range"
            type="range"
            :min="min"
            :max="max"
            :disabled="disabled"
            :step="step"
            @input="onValueChanged"
        />
        <div class="line-wrapper">
            <ProgressLine class="line" :min="min" :max="max" :current="+currentVal" :disabled="disabled" />
        </div>
        <div class="min-label">{{ minText }} {{ numberFormatter(min) }}</div>
        <div ref="thumbLabelRef" class="thumb-label">{{ numberFormatter(value) }}</div>
        <div class="max-label">{{ maxText }} {{ numberFormatter(max) }}</div>
    </div>
</template>

<script>
import { ProgressLine } from '@agi.packages/sport/components';
export default {
    name: 'RangeSlider',
    components: { ProgressLine },
    props: {
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        minText: {
            type: String,
        },
        maxText: {
            type: String,
        },
        step: {
            type: Number,
            default: 1,
        },
        value: {
            type: Number,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        numberFormatter: {
            type: Function,
            default: (number) => number,
        },
    },
    data() {
        return {
            currentVal: this.value || 0,
        };
    },
    watch: {
        min() {
            this.rePositionThumbLabel();
        },
        max() {
            this.rePositionThumbLabel();
        },
        value() {
            this.rePositionThumbLabel();
        },
    },
    mounted() {
        window.addEventListener('resize', this.rePositionThumbLabel);
        this.rePositionThumbLabel();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.rePositionThumbLabel);
    },
    methods: {
        onValueChanged(e) {
            this.$emit('input', +e.target.value);
        },
        rePositionThumbLabel() {
            this.$nextTick(() => {
                if (!this.$refs.inputRangeRef) return;
                this.currentVal = this.value;
                const inputRangeWidth = this.$refs.inputRangeRef.clientWidth;
                const thumbLabelWidth = this.$refs.thumbLabelRef.clientWidth;
                const maxPosition = ((inputRangeWidth - thumbLabelWidth) * (this.max - this.min)) / (this.max - this.min);

                if (this.value < this.min) {
                    this.$refs.thumbLabelRef.style.left = `0px`;
                    return;
                } else if (this.value > this.max) {
                    this.$refs.thumbLabelRef.style.left = `${maxPosition}px`;
                    return;
                }

                // Calculate thumb position relative to inputValue value and max value
                const thumbPosition = ((inputRangeWidth - thumbLabelWidth) * (this.value - this.min)) / (this.max - this.min);
                this.$refs.thumbLabelRef.style.left = `${thumbPosition}px`;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
$track-height: 4px;
$thumb-height: 16px;

.line-wrapper {
    position: absolute;
    width: 100%;
    height: $track-height;
    bottom: 6px; // $thumb-height/2 - $track-height/2;
    z-index: 0;
    padding: 0 6px;
}

.line {
    height: $track-height;
}

// for ios 12
@mixin thumb_style {
    &::-webkit-slider-thumb {
        @content;
    }
    &::-moz-range-thumb {
        @content;
    }
}

.input-range {
    height: $thumb-height; /* needed for Firefox*/
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    cursor: pointer;
    overflow: hidden;
    bottom: 0;
    position: absolute;
    transition: all 0.2s ease-in-out;
    margin: 0;
    left: -2px;
    right: -2px;
    z-index: 1;

    &:not(:disabled):hover ~ .thumb-label {
        visibility: visible;
    }

    &:focus,
    &:active {
        outline: none;
    }

    @include thumb_style {
        height: $thumb-height;
        width: $thumb-height;
        border-radius: 50%;
        appearance: none;
        -moz-appearance: none;
        aspect-ratio: 1;
        background: $white-bg;
        border: 1px solid $disabled-bg;
        box-shadow: none;
        box-sizing: border-box;
    }
    &:disabled {
        opacity: 1; // for ios 12
        @include thumb_style {
            background: $light-grey;
        }
    }
}

.max-label,
.min-label {
    position: absolute;
    top: 0;
    font-size: 12px;
    font-weight: 400;
    color: $dark-grey-3;
}

.max-label {
    right: 10px;
}

.min-label {
    left: 10px;
}

.thumb-label {
    font-size: 12px;
    font-weight: 500;
    color: rgba(37, 42, 45, 1);
    background-color: rgba(230, 231, 226, 1);
    z-index: 1;
    padding: 2px 4px;
    border-radius: 4px;
    position: absolute;
    top: -2px;
    height: 16px;
    display: flex;
    visibility: hidden;
    align-items: center;
    justify-content: center;
}

.input-range-wrapper {
    position: relative;
    width: 100%;
    height: 35px;
}
</style>
