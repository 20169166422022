<template>
    <form class="page" @submit.prevent>
        <h2>{{ $t(`ui.common.${pinOrPassword}.setYourPassword`) }}</h2>
        <span class="sub-header">{{ $t(`ui.common.${pinOrPassword}.setPasswordText`) }}</span>
        <PhoneNumber disabled form-name="set-password-form" :value="phoneNumber" data-test-id="phoneNumber" />
        <renderer v-if="!inProgress && error" class="notify error" :input="error" />
        <Password
            name="newPassword"
            :label="$t(`ui.common.${pinOrPassword}.newPassword`)"
            form-name="set-password-form"
            :value="password"
            :v="$v.password"
            @value="password = $event"
        />
        <Password
            help-disabled
            name="passwordConfirm"
            :label="$t(`ui.common.${pinOrPassword}.confirmNewPassword`)"
            form-name="set-password-form"
            :value="passwordConfirm"
            :error-messages="errorMessages"
            :v="$v.passwordConfirm"
            @value="passwordConfirm = $event"
        />
        <input
            type="submit"
            class="button button-submit button-full"
            data-test-id="savePassword"
            :disabled="inProgress || (!isPresto && $v.$invalid)"
            :value="$t(`ui.common.${pinOrPassword}.savePassword`).toUpperCase()"
            @click="setPassword()"
        />
    </form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { required, minLength, numeric, sameAs } from 'vuelidate/lib/validators';
import { deviceType, getter as coreGetter } from '@agi.packages/core';
import { auth, action as platformAction, getter as platformGetter } from '@agi.packages/platform';

import { getter } from '@/store/modules/translations';
import PageMixin from '@/components/Pages/Page.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import Password from '@/components/Password.vue';
import PhoneNumber from '@/components/PhoneNumber';

import AuthenticationMixin from '../mixins/Authentication.mixin';
import { routeName } from '@/router/const-name';

export default {
    name: 'SetPassword',
    components: { Password, PhoneNumber },
    mixins: [PageMixin, AuthenticationMixin, SEOMixin],
    data() {
        return {
            password: '',
            passwordConfirm: '',
            isPresto: deviceType.isPresto(),
        };
    },
    validations() {
        const isPin = this.brandPreference.pin;
        return {
            password: {
                required,
                minLength: minLength(4),
                ...(isPin && { numeric }),
            },
            passwordConfirm: {
                required,
                minLength: minLength(4),
                ...(isPin && { numeric }),
                passwordConfirm: sameAs('password'),
            },
        };
    },
    computed: {
        inProgress() {
            return this.$store.getters[coreGetter.IS_LOADING](auth.action.SET_PASSWORD);
        },
        ...mapState({
            error: (state) => state.platform.auth.error,
            phoneNumber: (state) => state.platform.auth.phoneNumber,
        }),
        ...mapGetters({
            pinOrPassword: getter.PIN_OR_PASSWORD,
            brandPreference: platformGetter.GET_BRAND_PREFERENCE,
        }),
        errorMessages() {
            return {
                passwordConfirm: this.$t(`ui.common.${this.pinOrPassword}.wrongConfirmPassword`),
            };
        },
    },
    created() {
        if (!this.phoneNumber) {
            this.$store.dispatch(platformAction.LOAD_AGI_SETTINGS);
        }
    },
    beforeDestroy() {
        this.$store.dispatch(auth.action.RESET_ERROR);
    },
    methods: {
        setPassword() {
            if (this.isPresto && this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.$store
                .dispatch(auth.action.SET_PASSWORD, { password: this.password })
                .then(() => {
                    this.$router.push({
                        name: routeName.LOGOUT,
                        query: { nextPath: routeName.LOGIN },
                    });
                })
                .catch(() => {});
        },
    },
};
</script>

<style scoped>
.button {
    margin-top: 8px;
}
</style>
