export const telcoIcon = {
    AIRTEL: 'airtel',
    ARTLTIGO: 'airtel_tigo',
    DEFAULT: 'default',
    HALOTEL: 'halotel',
    MTN: 'mtn',
    ORANGE: 'orange',
    SAFARICOM: 'safaricom',
    MPESA: 'safaricom',
    TIGO: 'tigo',
    TNM: 'tnm',
    VODAFONE: 'vodafone',
    VODACOM: 'vodafone',
    ZAMTEL: 'zamtel',
};

export const depositStatus = {
    SUCCESS: 'SUCCESS',
    GENERAL_FAILURE: 'GENERAL_FAILURE',
    PENDING: 'PENDING',
    AMOUNT_TOO_SMALL: 'AMOUNT_TOO_SMALL',
    NOT_REGISTERED: 'NOT_REGISTERED',
    INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
    FAILED: 'FAILED',
    INIT: 'INIT',
    AWAITING_USER_CONFIRMATION: 'AWAITING_USER_CONFIRMATION',
};

export const paymentOptions = {
    NIGERIA_MTN_AIRTIME_MADAPI: 'NIGERIA_MTN_AIRTIME_MADAPI',
    PAYSTACK_CARD: 'NIGERIA_PAYSTACK_CARD',
    PAYSTACK_BANK: 'NIGERIA_PAYSTACK_BANK',
    PAYSTACK_USSD: 'NIGERIA_PAYSTACK_USSD',
};

export const paystackOptions = [
    paymentOptions.PAYSTACK_CARD.toLowerCase(),
    paymentOptions.PAYSTACK_BANK.toLowerCase(),
    paymentOptions.PAYSTACK_USSD.toLowerCase(),
];

export const paymentMethodStatus = {
    OPERATIONAL: 'OPERATIONAL',
    DEGRADED_PERFORMANCE: 'DEGRADED_PERFORMANCE',
    MAJOR_OUTAGE: 'MAJOR_OUTAGE',
    UNDER_MAINTENANCE: 'UNDER_MAINTENANCE',
    CLOSED: 'CLOSED',
};

export default {
    depositStatus,
};
