import Vue from 'vue';
import SelfExclusion from '@/components/modals/SelfExclusion';

export const modalNames = {
    SELF_EXCLUSION: 'self-exclusion-modal',
    VERIFICATION_MODAL: 'verification-modal',
};

export const showModal = (modalName) => {
    const modalContent = {
        [modalNames.SELF_EXCLUSION]: SelfExclusion,
    };

    const componentAttrs = {
        [modalNames.SELF_EXCLUSION]: {},
    };
    const modalAttrs = {
        [modalNames.SELF_EXCLUSION]: {
            name: modalName,
            width: '356px',
            clickOutside: false,
            isHighest: true,
            isScrollable: true,
            options: {
                overlay: true,
            },
        },
    };
    const modalListeners = {
        [modalNames.SELF_EXCLUSION]: {
            close: () => {
                Vue.$gtm.query({ event: 'self_exclude_close_button_click' });
            },
        },
    };

    Vue.$modal.show(modalContent[modalName], componentAttrs[modalName], modalAttrs[modalName], modalListeners[modalName]);
};
