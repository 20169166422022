<template>
    <div v-if="model && model.length" class="game-category">
        <Modal :name="modalName" :width="'80%'" @close="closeInfo()">
            <div v-if="headline.description">
                {{ headline.description }}
            </div>
            <div v-else-if="info && info.content">
                <DynamicComponent v-for="(item, index) in info.content" :key="'info' + index" :data="item" :path="'content'" />
            </div>
            <div v-else-if="contentError">
                <renderer :input="contentError" />
            </div>
            <Spinner v-else :visible="true" class="inset" />
        </Modal>
        <Headline
            v-if="sportsTitle"
            :icon="sportsIcon"
            :info="infoCategoryId || headline.description"
            :is-clickable="!isLive && !!moreEvents"
            :is-category="true"
            @open-info="openInfo"
            @open-sport="moreSports()"
        >
            {{ sportsTitle }}
        </Headline>
        <Game v-for="(event, index) in model" :key="`${event.id}-${index}`" :event="event" :show-market-count="true" />
        <div v-if="moreEvents && enableShowMore" class="event-counter" @click="moreSports(sportsTitle)">
            <span class="pointer">
                <span>{{ $t('ui.common.more') }}{{ sportsTitle && ` ${sportsTitle}` }}</span>
                <span class="bold">{{ moreEvents }}</span>
                <SvgIcon icon-id="arrow_right" class="icon-size-very-small" vertical-align="baseline" />
            </span>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { Spinner } from '@agi.packages/core/components';
import { DynamicComponent } from '@agi.packages/platform/components';
import { action as generalAction, getter as generalGetter } from '@agi.packages/platform';
import { SPORT_CATEGORY_MAP } from '@agi.packages/sport';
import { helper } from '@agi.packages/core';

import Headline from '@/components/Headline.vue';

import Game from './Game.vue';
import { routeName } from '@/router/const-name';

const LIVE_SPORT_PAGES = ['football', 'tennis', 'basketball', 'ice-hockey'];

export default {
    name: 'GameCategory',
    components: { Game, DynamicComponent, Headline, Spinner },
    props: {
        model: {
            require: true,
        },
        type: {
            require: true,
        },
        headline: {
            type: Object,
            default: () => ({}),
        },
        moreEvents: {
            require: false,
        },
        infoCategoryId: {
            require: false,
        },
        isLive: {
            type: Boolean,
        },
        isBoosted: {
            type: Boolean,
        },
        enableShowMore: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapState({
            contentError: (state) => state.platform.content.error.message,
        }),
        sportsTitle() {
            return this.headline.title || this.$t(`project.sports.${this.type}.text`, { indefinite: true });
        },
        sportsIcon() {
            return this.headline.icon || this.$t(`project.sports.${this.type}.icon`);
        },
        info() {
            return this.$store.getters[generalGetter.GET_CONTENT_SLOTS][this.infoCategoryId];
        },
        modalName() {
            return `category-info-${this.type}-${helper.createUUID()}`;
        },
    },
    methods: {
        moreSports(sectionName = null) {
            if (sectionName) {
                this.$gtm.query({
                    event: 'more_events',
                    section: sectionName,
                });
            }
            const { name, key } = SPORT_CATEGORY_MAP.filter(({ name }) => LIVE_SPORT_PAGES.includes(name)).find(
                ({ key }) => `${key}` === this.model[0].category.id
            );
            if (this.isLive) {
                const isParent = this.$route.name === routeName.LIVE;
                this.$router.push(isParent ? { path: `/live-${name}` } : { name: routeName.LIVE });
            } else if (this.isBoosted) {
                this.$router.push({ name: routeName.BOOSTED_EVENTS });
            } else {
                this.$emit('onCategoryChange', key);
                this.$router.push({ name: routeName.UPCOMING });
            }
        },
        closeInfo() {
            this.$modal.hide(this.modalName);
        },
        openInfo() {
            if (!this.headline.description) {
                this.$store.dispatch(generalAction.GET_CONTENT, this.infoCategoryId);
            }
            this.$modal.show(this.modalName);
        },
    },
};
</script>

<style lang="scss" scoped>
.event-counter {
    color: $betpawa-black;
    display: block;
    font-size: 14px;
    line-height: 14px;
    text-decoration: none;
    padding: 12px;
    text-align: center;

    .bold {
        padding: 0 2px 0 10px;
    }

    > span {
        border-bottom: 1px solid $betpawa-black;
        padding-bottom: 1px;
    }
}

.icon-info {
    width: 18px;
    height: 18px;
    cursor: pointer;
}
</style>
