<template>
    <div class="nested-components">
        <DynamicComponent v-for="(item, index) in data.content" :key="index" :data="item" :path="'content'" />
    </div>
</template>
<script>
import { DynamicComponent } from '@agi.packages/platform/components';

export default {
    name: 'NestedComponents',
    components: { DynamicComponent },
    props: {
        data: {
            type: Object,
            require: true,
        },
    },
};
</script>

<style lang="scss">
.nested-components {
    p,
    a {
        display: inline;
        color: $grey-text;
    }
}
</style>
