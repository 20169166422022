import { isNumber } from '@/modules/core/utils/number/isNumber';

/**
 * Parses x into valid number or returns null
 * @param {string} x
 * @return {number | null}
 */
export function tryParseNumber(x) {
    const xParsed = parseInt(x);
    return isNumber(xParsed) ? xParsed : null;
}
