import Vue from 'vue';

// init state
const state = (window.androidState = Vue.observable({
    isBridgeEnabled: window.isBridgeEnabled || false,
    versionName: window.versionName || null,
    loginPhone: window.loginPhone || null,
}));

// check window arguments
if (Object.defineProperty) {
    const descriptor = (key) => {
        return {
            set(value) {
                state[key] = value;
            },
            get() {
                return state[key];
            },
        };
    };
    Object.keys(state).forEach((key) => Object.defineProperty(window, key, descriptor(key)));
}

/**
 * Set data to Android/state
 * @param {String} key
 * @param {*} value
 */
window.setAndroidState = function (key, value) {
    if (state[key] === undefined) {
        console.error(`Not found: store["${key}"]`);
        return;
    }
    state[key] = value;
};

/**
 * Function generator for AndroidJSBridge
 * @param {string} name - function name
 * @param {Array|Arguments} [args] - function arguments
 * @returns {*}
 */
function androidJSBridgeFunction(name, args = []) {
    if (!state.isBridgeEnabled) {
        return;
    }

    if (!window.AndroidJSBridge || !window.AndroidJSBridge[name]) {
        console.error(`Not found: window.AndroidJSBridge.${name}`);
        return;
    }
    return window.AndroidJSBridge[name].apply(window.AndroidJSBridge, args);
}

export default {
    state,
    trackState(username, password, areaCode) {
        return androidJSBridgeFunction('trackState', [username, password, areaCode]);
    },
    betPlaced(type) {
        return androidJSBridgeFunction('betPlaced', [type]);
    },
    setFlag: () => androidJSBridgeFunction('setFlag'),
    openFlag: () => androidJSBridgeFunction('openFlag'),
    siteRdy: () => androidJSBridgeFunction('siteRdy'),
};
