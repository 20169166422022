<template>
    <svg width="217" height="201" viewBox="0 0 217 201" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M76.6332 185.542C76.6332 193.779 59.6479 200.456 38.6955 200.456C17.7431 200.456 0.757812 193.779 0.757812 185.542C0.757812 177.305 16.3168 187.664 37.2692 187.664C58.2217 187.664 76.6332 177.305 76.6332 185.542Z"
            fill="#C4C4C4"
        />
        <path
            d="M201.833 178.617C201.109 184.862 181.062 187.653 157.057 184.85C133.051 182.047 114.178 174.711 114.902 168.466C115.627 162.22 132.334 157.054 156.596 165.023C174.683 172.106 198.945 176.533 201.833 178.617Z"
            fill="#C4C4C4"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M198.246 178.078L204.628 165.323L194.693 157.595L185.068 174.689L198.246 178.078ZM150.652 108.445L125.873 133.717L122.47 168.774L114.664 165.144L119.304 130.314L144.636 105.676L150.652 108.445ZM177.25 144.18L185.504 150.969L175.634 171.136L165.583 168.882L177.25 144.18ZM170.504 139.096L162.449 132.401L146.313 164.16L156.906 166.306L170.504 139.096ZM145.696 142.142C149.797 133.805 153.512 129.864 156.147 127.163L150.854 123.392C150.664 123.524 150.435 123.675 150.171 123.85C147.634 125.526 141.919 129.302 137.687 138.17C133.352 147.253 132.078 156.499 133.613 159.773L139.33 162.398C139.573 161.484 139.824 160.413 140.104 159.222C141.18 154.645 142.674 148.284 145.696 142.142ZM150.618 121.07L152 109.463L128.437 133.725L124.308 169.536L131.67 161.54C129.096 155.148 130.153 148.472 132.612 141.376C135.528 132.961 141.411 124.989 150.618 121.07Z"
            fill="#252A2D"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M198.244 178.076L204.626 165.322L194.691 157.593L185.066 174.687L198.244 178.076ZM185.502 150.968L177.248 144.178L165.581 168.881L175.632 171.135L185.502 150.968ZM162.447 132.401L170.502 139.095L156.904 166.306L146.311 164.16L162.447 132.401ZM156.145 127.164C153.51 129.865 149.795 133.806 145.694 142.143C142.672 148.285 141.178 154.646 140.102 159.223C139.822 160.414 139.571 161.485 139.328 162.399L133.611 159.774C132.076 156.5 133.35 147.254 137.685 138.171C141.918 129.303 147.633 125.527 150.169 123.851C150.433 123.676 150.663 123.525 150.852 123.393L156.145 127.164Z"
            fill="#8DC63F"
        />
        <ellipse cx="107.536" cy="181.046" rx="41.212" ry="11.3851" fill="#C4C4C4" />
        <path d="M103.456 183.235L92.9642 177.62L77.1074 71.5417L92.845 62.7676L103.456 183.235Z" fill="#252A2D" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M47.5779 104.692L33.3589 104.426L30.7044 116.756L50.2248 118.084L47.5779 104.692ZM5.55859 177.849L38.9747 189.359L71.9498 177.296L72.06 185.928L38.7542 196.774L5.66888 184.489L5.55859 177.849ZM26.181 138.337L28.7175 127.934L51.1052 128.155L53.4212 138.226L26.181 138.337ZM24.5274 146.638L21.9909 156.82L57.5024 157.706L54.8556 147.191L24.5274 146.638ZM37.9815 167.777C28.7176 167.667 23.571 166.006 20.0052 164.789L18.9023 171.208C19.1032 171.323 19.3385 171.465 19.6091 171.629C22.2112 173.201 28.0733 176.742 37.8712 176.742C47.9071 176.742 56.7666 173.901 59.0458 171.098L58.9356 164.789C58.0093 164.964 56.9387 165.199 55.7472 165.46L55.7469 165.46C51.1694 166.464 44.807 167.859 37.9815 167.777ZM16.9151 172.426L5.88672 176.189L37.8692 187.035L71.8367 175.303L61.47 172.094C56.8381 177.185 50.3817 179.112 42.9422 179.952C34.1195 180.948 24.4145 179.066 16.9151 172.426Z"
            fill="#8DC63F"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.9151 172.426L5.88672 176.189L37.8692 187.035L71.8367 175.303L61.47 172.094C56.8381 177.185 50.3817 179.112 42.9422 179.952C34.1195 180.948 24.4145 179.066 16.9151 172.426ZM5.55859 177.849L38.9747 189.359L71.9498 177.296L72.06 185.928L38.7542 196.774L5.66888 184.489L5.55859 177.849Z"
            fill="#252A2D"
        />
        <path
            d="M106.495 3.95578L64.6586 0.457031L39.0918 39.5264L61.7533 76.2632L109.982 76.8464L130.9 38.3601L106.495 3.95578Z"
            fill="#8DC63F"
        />
        <path
            d="M79.5946 52.2334C77.7637 52.2334 75.9329 52.2248 74.102 52.242C73.8143 52.242 73.6399 52.1819 73.4743 51.916C72.3147 50.0374 71.1203 48.176 69.952 46.2975C69.8387 46.1173 69.7864 45.8428 69.8212 45.6284C70.4315 41.7168 71.0592 37.8139 71.6695 33.9023C71.7218 33.5935 71.8439 33.3619 72.0967 33.1561C74.4943 31.2518 76.8657 29.3217 79.2633 27.426C79.4464 27.2802 79.7254 27.1772 79.9608 27.1772C83.6051 27.1515 87.2581 27.1429 90.9024 27.1172C91.2163 27.1172 91.3906 27.1944 91.5563 27.4689C92.7246 29.3475 93.9103 31.2089 95.0785 33.0874C95.1832 33.259 95.2355 33.5163 95.2006 33.7136C94.5903 37.6252 93.9626 41.5281 93.3523 45.4397C93.3 45.7485 93.2041 45.9801 92.9425 46.1945C90.5275 48.116 88.1299 50.0632 85.7149 51.9932C85.5493 52.1219 85.2965 52.1991 85.0872 52.1991C83.2563 52.2162 81.4255 52.2077 79.5946 52.2077C79.5946 52.2077 79.5946 52.2248 79.5946 52.2334ZM77.5719 45.9629C77.7376 45.9715 77.8422 45.9801 77.9381 45.9801C80.2921 45.9715 82.646 45.9458 85 45.9629C85.3836 45.9629 85.4795 45.8257 85.5319 45.4825C86.0026 42.4631 86.4909 39.4437 86.9704 36.4328C87.1273 35.4378 87.2843 34.4342 87.4499 33.422C84.8169 33.422 82.2276 33.422 79.6033 33.422C78.9233 37.5994 78.252 41.7597 77.5719 45.9629Z"
            fill="#252A2D"
        />
        <path
            d="M105.096 52.2334C103.282 52.2334 101.46 52.2248 99.6469 52.242C99.3592 52.242 99.1848 52.1733 99.0192 51.916C97.8596 50.0374 96.6652 48.176 95.4969 46.2975C95.3836 46.1173 95.3313 45.8428 95.3662 45.6284C95.9764 41.7168 96.6042 37.8139 97.2145 33.9023C97.2668 33.5935 97.3888 33.3619 97.6417 33.1561C100.039 31.2518 102.411 29.3217 104.808 27.426C104.991 27.2802 105.27 27.1772 105.506 27.1772C109.15 27.1515 112.803 27.1429 116.447 27.1172C116.761 27.1172 116.936 27.1944 117.101 27.4689C118.269 29.3475 119.455 31.2089 120.623 33.0874C120.728 33.259 120.78 33.5163 120.746 33.7136C120.135 37.6252 119.508 41.5281 118.897 45.4397C118.845 45.7485 118.749 45.9801 118.487 46.1945C116.072 48.116 113.675 50.0632 111.26 51.9932C111.094 52.1219 110.841 52.1991 110.632 52.1991C108.784 52.2162 106.944 52.2077 105.096 52.2077C105.096 52.2077 105.096 52.2248 105.096 52.2334ZM112.995 33.422C110.344 33.422 107.755 33.422 105.14 33.422C104.459 37.6166 103.788 41.7683 103.117 45.9372C105.759 45.9372 108.357 45.9372 110.99 45.9372C111.661 41.7511 112.332 37.5994 112.995 33.422Z"
            fill="#252A2D"
        />
        <path
            d="M61.2669 39.6747L62.4708 33.75L56.7654 39.6871L61.2669 39.6747ZM58.5397 51.5597L58.6138 45.6165L47.5191 45.6693L49.3261 39.7269L58.6262 27.8017L69.5517 27.7634L67.6909 39.6488L69.4983 39.6424L67.7116 45.5847L65.8839 45.5911L65.8301 51.5341L58.5397 51.5597Z"
            fill="#252A2D"
        />
    </svg>
</template>

<script>
export default {
    name: 'NetworkError',
    props: {},
};
</script>

<style scoped></style>
