import { copyIntoClipboard } from './copyIntoClipboard';

/**
 * ]
 * @param {string} text
 * @param {number} duration
 * @param {function} [cb=]
 */
export function copyIntoClipboardDelay(text, duration, cb) {
    return new Promise((resolve) => {
        copyIntoClipboard(text).finally((result) => {
            window.setTimeout(() => {
                cb && cb(result);
                resolve(result);
            }, duration);
        });
    });
}
