export function lazyLoad(enabled = true, delay = 6666) {
    if (!enabled) {
        return Promise.resolve();
    }
    let startedLoadScript = false;
    return new Promise((resolve) => {
        window.addEventListener('load', () => {
            if (startedLoadScript) {
                return;
            }
            startedLoadScript = true;
            resolve();
        });

        setTimeout(() => {
            if (startedLoadScript) {
                return;
            }
            startedLoadScript = true;
            resolve();
        }, delay);
    });
}
