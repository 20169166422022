<template>
    <div v-click-outside:[true]="handleClickOutside" class="dropdown" :class="{ presto: isPresto }" @click="show = !show">
        <div class="dropdown-icon">
            <SvgIcon class="icon icon-size-medium" :icon-id="active.icon" vertical-align="text-top" />
        </div>
        <div class="dropdown-title">{{ active.text }}</div>
        <div class="dropdown-select">
            <div class="dropdown-arrow">
                <SvgIcon class="icon icon-size-small" icon-id="icon-arrow-down" />
            </div>
            <div v-show="show" class="dropdown-select-content">
                <span class="dropdown-select-content-arrow">
                    <SvgIcon class="icon" icon-id="icon-triangle" vertical-align="top" />
                </span>
                <div
                    v-for="(option, index) in options"
                    :key="index"
                    class="dropdown-select-option"
                    :class="{ active: option.type === selected }"
                    @click="handleSelect(option.type)"
                >
                    {{ option.text }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { deviceType } from '@agi.packages/core';

export default {
    name: 'DropDown',
    props: {
        options: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['change'],
    data() {
        return {
            selected: this.options?.[0]?.type || '',
            show: false,
            isPresto: deviceType.isPresto(),
        };
    },
    computed: {
        active() {
            return this.options.find((option) => option.type === this.selected) || '';
        },
    },
    methods: {
        handleSelect(selected) {
            this.selected = selected;
            this.$emit('change', selected);
        },
        handleClickOutside() {
            if (this.show) {
                this.show = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.dropdown {
    display: flex;
    align-items: center;
    cursor: default;
    user-select: none;

    &-icon {
        margin-right: 6px;

        svg {
            fill: #aaaeb0;
        }
    }

    &-title {
        font-size: 14px;
        line-height: 17px;
        margin-right: 12px;
    }

    &-arrow {
        cursor: pointer;

        svg {
            fill: white;
        }
    }

    &-select {
        display: flex;
        align-items: center;
        position: relative;

        &-content {
            min-width: 82px;
            max-width: 110px;
            width: max-content;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.24);
            background: white;
            position: absolute;
            top: 22px;
            right: -12px;
            z-index: 9;

            .presto & {
                border: 1px solid $betslip-border-color;
            }

            &-arrow {
                position: absolute;
                top: -8px;
                right: 12px;
                width: 12px;
                height: 12px;
                z-index: -1;

                svg {
                    width: 12px;
                    height: 12px;
                    filter: drop-shadow(0 4px 12px rgba(0, 0, 0, 0.24));
                }

                .presto & {
                    right: 11px;

                    svg {
                        width: 12px;
                        height: 12px;
                        stroke: $betslip-border-color;
                    }
                }
            }
        }

        &-option {
            font-size: 14px;
            line-height: 17px;
            background: white;
            padding: 8px;
            cursor: pointer;
            color: $main-text;

            &.active {
                color: $menu-item-color-selected;
            }

            &:hover {
                background: $menu-item-background-selected;
            }
        }
    }
}
</style>
