<template>
    <Paymentcomponent :data="paymentData" />
</template>
<script>
import Paymentcomponent from '@/components/content/Paymentcomponent.vue';

import PageMixin from '@/components/Pages/Page.mixin';

export default {
    name: 'Payment',
    components: { Paymentcomponent },
    mixins: [PageMixin],
    props: ['data'],
    computed: {
        paymentData() {
            const { deposit_option: depositOption, isButtonAlwaysActive, showBackground } = this.data.content;
            return {
                identifier: depositOption?.data?.attributes.depositTypeName,
                isButtonAlwaysActive,
                showBackground,
            };
        },
    },
};
</script>
