<template>
    <div class="view-all">
        <router-link :to="{ name: 'My Bets', params: { section } }" class="view-all-link">
            {{ $t('ui.common.viewAll') }}
            <div class="view-all-link-icon-wrapper">
                <SvgIcon class="view-all-link-icon" icon-id="arrow_right" vertical-align="top" />
            </div>
        </router-link>
    </div>
</template>
<script>
export default {
    name: 'ViewAllButton',
    props: {
        section: {
            type: String,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>
.view-all {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $betslip-bottom-border-color;

    &-link {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 17px;
        user-select: none;
        cursor: pointer;
        border-bottom: 1px solid black;

        &-icon {
            width: 100%;
            height: 100%;
        }

        &-icon-wrapper {
            margin-left: 6px;
            width: 12px;
            height: 12px;
        }

        &:hover {
            border-bottom: 1px solid $primary-color;

            svg {
                fill: $primary-color;
            }
        }
    }
}
</style>
