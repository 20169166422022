<template>
    <Modal
        name="terms-and-conditions"
        class="terms-and-conditions"
        width="336px"
        is-highest
        is-scrollable
        :is-empty-header="isTermsDeclined"
        :options="{
            overlay: true,
        }"
        :click-outside="false"
        :fixed="true"
        @close="onClose"
    >
        <template v-if="isTermsDeclined">
            <div class="reject-terms-and-conditions">
                <p class="title upperCase">{{ $t('ui.termsAndConditions.rejectTitle') }}</p>
                <p class="description">{{ $t('ui.termsAndConditions.rejectDescription') }}</p>
                <div class="footer">
                    <button class="button button-primary button-full" @click="goBack">
                        {{ $t('ui.termsAndConditions.goBack') }}
                    </button>
                    <button class="button button-accent button-full" @click="logOut">
                        {{ $t('ui.termsAndConditions.yes') }}
                    </button>
                </div>
            </div>
        </template>
        <template v-else>
            <p class="title upperCase">{{ $t('ui.termsAndConditions.title') }}</p>
            <p class="description">{{ $t('ui.termsAndConditions.description') }}</p>
            <div class="introduction">
                <template v-if="terms && terms.content">
                    <DynamicComponent
                        v-for="(item, index) in terms.content"
                        :key="`${item.type}-${index}`"
                        :data="item"
                        path="content"
                        :data-test-id="`tag-${item.type}`"
                        data-test-class="cmsTag"
                    />
                </template>
            </div>
            <template slot="footer">
                <button class="button button-primary button-full" @click="onAccept">
                    {{ $t('ui.termsAndConditions.accept') }}
                </button>
            </template>
        </template>
    </Modal>
</template>
<script>
import { auth, getter as platformGetter, action as platformAction } from '@agi.packages/platform';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { action, mutation } from '@/store/store';
import { DynamicComponent } from '@agi.packages/platform/components';
import { routeName } from '@/router/const-name';

const PAGE_NAME_TERMS = 'terms';

export default {
    name: 'TermsAndConditions',
    components: {
        DynamicComponent,
    },
    props: {
        date: {
            type: Date,
            required: true,
        },
    },
    computed: {
        ...mapState({
            isTermsDeclined: (state) => state.isTermsDeclined,
        }),
        ...mapGetters({
            getContentGetter: platformGetter.GET_CONTENT_SLOTS,
            getPages: platformGetter.GET_PAGES,
        }),
        terms() {
            return this.getPages['terms'];
        },
    },
    created() {
        this.$store.dispatch(platformAction.GET_PAGE, PAGE_NAME_TERMS);
    },
    mounted() {
        this.$modal.show('terms-and-conditions');
    },
    methods: {
        ...mapActions({
            acceptTerms: action.ACCEPT_TERMS,
        }),
        ...mapMutations({
            updateTermsDeclineStatus: mutation.UPDATE_TERMS_DECLINE_STATUS,
        }),
        onAccept() {
            const value = { key: 'terms_accepted_date', value: new Date().toISOString() };
            this.acceptTerms(value);
            if (this.$route.name === routeName.HOMEPAGE) {
                this.$store.dispatch('Notify', {
                    type: 'terms',
                    data: {
                        trigger: true,
                    },
                });
            } else {
                this.$router.push({ name: routeName.HOMEPAGE }).then(() => {
                    this.$store.dispatch('Notify', {
                        type: 'terms',
                        data: {
                            trigger: true,
                        },
                    });
                });
            }
        },
        onClose() {
            this.updateTermsDeclineStatus(true);
        },
        goBack() {
            this.updateTermsDeclineStatus(false);
        },
        logOut() {
            this.$store.dispatch(auth.action.LOGOUT).then(() => {
                this.goBack();
                this.$router.push({ name: routeName.HOMEPAGE });
            });
        },
    },
};
</script>

<style scoped lang="scss">
.terms-and-conditions {
    color: $main-text;
}
.title {
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 8px;
}
.description {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 16px;
}
.introduction {
    padding: 0 16px 16px;
    background-color: $light-grey;
}

.reject-terms-and-conditions {
    button {
        margin-top: 10px;
    }
}
</style>
