<template>
    <router-link v-if="isLink && !forceTextView" class="match-link bold underline" :to="{ name: routeName.EVENT, params: { id: eventId } }">
        {{ title }}<SvgIcon icon-id="arrow_right" class="icon-link" />
    </router-link>
    <span v-else>{{ title }}</span>
</template>

<script>
import { routeName } from '@/router/const-name';
export default {
    name: 'EventNameWithLink',
    props: {
        title: {
            type: String,
            required: true,
        },
        eventId: {
            type: [String, Number],
            required: true,
        },
        forceTextView: {
            type: Boolean,
            required: false,
            default: false,
        },
        isLink: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            routeName,
        };
    },
};
</script>

<style lang="scss" scoped>
.icon-link {
    height: 12px;
    width: 12px;
    margin-left: 6px;
}
.match-link {
    &:hover {
        color: inherit;
    }
}
</style>
